import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TitledCard from '../device/middle/TitledCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 700,
      fontSize: 25,
      textTransform: "uppercase",
      justifySelf: "center",
      marginBottom: 0,
    },
    subtitle: {
      color: theme.palette.grey[400],
      justifySelf: "center"
    },
    header: {
      fontSize: 19,
      fontWeight: 700,
      color: "black"
    },
    main: {
    },



    tableHolder: {
      textAlign: "left",
      padding: ".9375rem 20px",
      position: "relative",
      flex: "1 1 auto",
      color: "#333",
      display: "flex",
      flexDirection: "column",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundColor: "#fff",
      backgroundClip: "border-box",
      borderRadius: ".25rem",
      fontSize: ".875rem",
      width: "max-content",
      justifySelf: "center",
    },



    table: {
      marginBottom: "1rem",
      color: "#212529",
      display: "table",
      boxSizing: "border-box",
      textIndent: "initial",
      borderSpacing: "11px",
      borderColor: "grey",
      borderCollapse: "collapse",
      "& tr": {
        "& th": {
          padding: "12px 20px",
          verticalAlign: "middle",
          borderTop: "1px solid rgba(0,0,0,.06)",
          textAlign: "left"
        },

        borderTop: "1px solid #eee",
      },
      "& tr:nth-child(odd)": {
        backgroundColor: "#ececec7a",
      },
    },
  }),
);

interface HelpProps {
  title: string,
  subtitle: string,
  variablesDescriptions: Array<string>;
}

export default function HelpList(props: PropsWithChildren<HelpProps>) {
  const { title, subtitle, variablesDescriptions } = props
  const classes = useStyles();
  const intl = useIntl()

  return (<div>
    <TitledCard>
      <h3 className={classes.title}><FormattedMessage id={title}/></h3>
      <h4 className={classes.subtitle}><FormattedMessage id={subtitle}></FormattedMessage></h4>
      <div className={classes.tableHolder}>

        <table className={classes.table}>
          <tbody>
            <tr>
              <th className={classes.header}><FormattedMessage id={"variables"} /></th>
              <th className={classes.header}><FormattedMessage id={"description"} /></th>
            </tr>
            {variablesDescriptions.map((varDesc, i) => <tr key={`help-key-${title}-${i}`} className={classes.main}>
              <th><FormattedMessage id={intl.formatMessage({ id: varDesc })} /></th>
              <th><FormattedMessage id={intl.formatMessage({ id: (varDesc + '-description') })} /></th>
            </tr>)}
          </tbody></table></div>
    </TitledCard>
  </div>
  );
}
/// TROCAR A PARTE DO TCM  PARA CHILDREN