import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { EntitiesContext } from "../../../contexts/entities-context";
import { DeviceType } from '../../../interfaces/Device';
import { calculateSFLevels, levelColor, si_format } from '../../../utils/format-data';
import { gcmOrder } from '../../../utils/gcm-functions';
import { icmOrder } from '../../../utils/icm-functions';
import { mcmOrder } from '../../../utils/mcm-functions';


const useStyles = makeStyles((theme) => ({
    ControlGrid: {
        alignItems: "center",
        gridAutoFlow: "column",
        display: "grid",
        [theme.breakpoints.down('md')]: { //menos de 960 esta bom
            width: "100%",
            overflow: "scroll",
            height: 46,
        },
        flexWrap: "wrap"
    },
    ControlBox: {
        gridColumn: "span 3",
        padding: "8px",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: "45px",
        justifyContent: "space-evenly",
        background: "#ffff",
        borderRadius: "10px",
        marginRight: "5px",
        whiteSpace: "nowrap"
    },
    ControlBox2: {
        gridColumn: "span 1",
        marginRight: 0
    },
    ControlHolder: {
        alignItems: "center",
        display: "flex"
    },
    bulb: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
    },
    ControlText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: "4px",
        marginRight: "8px",
        fontSize: "13.5px"
    },
    ControlTextMain: {
        color: theme.palette.primary.main,
        fontSize: "18px",
        padding: "9px 20px 8px",
        fontWeight: 700,
        lineHeight: 1.53,

    }
}));

interface ControlBarProps {
    maxSFCount?: number
}

export default function ControlInfoBar(props: ControlBarProps) {
    const { deviceReport, deviceFull } = useContext(EntitiesContext);
    const { maxSFCount } = props;
    const type = deviceFull ? deviceFull.type : 0
    var hasTap = true;
    var hasExc = true;
    var hasLoad = true;
    var sfNames = (type === DeviceType['I']) ? icmOrder : type === DeviceType['M'] ? mcmOrder : type === DeviceType['Gi'] || type === DeviceType['Gc'] || type === DeviceType['Gm'] || type === DeviceType['Gs'] ? gcmOrder : ['severity_factor']
    if (type === DeviceType['I']) { hasTap = false; hasExc = false; hasLoad = false }
    if (type === DeviceType['M'] || type === DeviceType['Gc'] || type === DeviceType['Gm'] || type === DeviceType['Gs'] || type === DeviceType['Gi']) { hasTap = false; hasExc = false }
    const classes = useStyles();

    return (
        <div className={classes.ControlGrid}>
            <div className={classes.ControlBox}>
                <h3 className={classes.ControlTextMain}><FormattedMessage id={"diagnostic"} /></h3>
                {deviceFull?.severity_param && deviceReport && calculateSFLevels(deviceFull.severity_param, deviceReport.diagnostic.sF, deviceFull.type).filter((sv, i) => maxSFCount ? i < maxSFCount : true).map((sfFinal, indx) => {
                    return <div key={`controlinfobar-${indx}`} className={classes.ControlHolder}>
                        <div style={{ backgroundColor: `${levelColor[sfFinal]}` }} className={classes.bulb}></div>
                        <p className={classes.ControlText}><FormattedMessage id={sfNames[indx] ? sfNames[indx] : 'empty'} /></p>
                        <p className={classes.ControlText}>{deviceReport.diagnostic.sF && deviceReport.diagnostic.sF[indx] !== undefined && deviceReport.diagnostic.sF[indx] !== undefined ? si_format(deviceReport.diagnostic.sF[indx], '%', undefined, undefined, true) : si_format(0, '%', undefined, undefined, true)}</p>
                    </div>
                })}
                {hasLoad && <div className={classes.ControlHolder}>
                    <p className={classes.ControlText}><FormattedMessage id={"load_factor"} /></p>
                    <p className={classes.ControlText}>{si_format(deviceReport?.electricParam?.loadRatio, '%', undefined, undefined, true)}</p>
                </div>}
            </div>
            {hasExc && <div className={classes.ControlBox}>
                <h3 className={classes.ControlTextMain}><FormattedMessage id={"excitation"} /></h3>
                <div className={classes.ControlHolder}>
                    <p className={classes.ControlText} style={{ color: "#B30909" }}><FormattedMessage id={"exa"} /></p>
                    <p className={classes.ControlText}>{si_format(deviceReport?.diagnostic?.iexc ? deviceReport.diagnostic.iexc[0] : 0, 'A', undefined, undefined, true)}</p>
                </div>
                <div className={classes.ControlHolder}>
                    <p className={classes.ControlText} style={{ color: "#016004" }}><FormattedMessage id={"exb"} /></p>
                    <p className={classes.ControlText}>{si_format(deviceReport?.diagnostic?.iexc ? deviceReport.diagnostic.iexc[1] : 0, 'A', undefined, undefined, true)}</p>
                </div>
                <div className={classes.ControlHolder}>
                    <p className={classes.ControlText} style={{ color: "#09549F" }}><FormattedMessage id={"exc"} /></p>
                    <p className={classes.ControlText}>{si_format(deviceReport?.diagnostic?.iexc ? deviceReport.diagnostic.iexc[2] : 0, 'A', undefined, undefined, true)}</p>
                </div>
            </div>}
            {hasTap && deviceReport?.tapChanger && <div className={classes.ControlBox2 + ' ' + classes.ControlBox}>
                <h3 className={classes.ControlTextMain}><FormattedMessage id={"tap_changer"} /></h3>
                <div className={classes.ControlHolder}>
                    <p className={classes.ControlText}><FormattedMessage id={"position"} /></p>
                    <p className={classes.ControlText}>{si_format(deviceReport?.tapChanger?.tapPos, undefined, 0, undefined, true)}</p>
                </div>
            </div>}
        </div>
    );
}