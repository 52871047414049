import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Tooltip } from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TodayIcon from '@material-ui/icons/Today';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'; // TYPES ESTAO NESTE REPOSITORIO E NAO VEM NO PAKCAGE
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { typeToText } from "../../components/alarms/CreateAlarmDialog";
import EntityTable, { HeadCell } from "../../components/entities/EntityTable";
import { AlarmContext } from "../../contexts/alarm-context";
import APIRequestContext from '../../contexts/api-context';
import { EntitiesContext } from "../../contexts/entities-context";
import { ToastContext } from "../../contexts/toast-context";
import { received, receivedExtended } from "../../interfaces/Alerts";
import { DeviceList, DeviceType } from "../../interfaces/Device";
import { OrganizationList } from "../../interfaces/Organization";
import { PageResponse, SingleResponse } from "../../interfaces/Responses";
import { Roles } from "../../interfaces/User";
import { clientType2types, deviceListToDeviceListExpanded, deviceTypeToRouteString, types2clientType } from "../../utils/format-data";


const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "1.61em",
    marginRight: "14px",
    marginLeft: 10,
    width: "220px"
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: "107px"
  },
  formControlMedium: {
    margin: theme.spacing(1),
    width: "200px"
  },
  pageLegend: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  }
}));
type Order = 'asc' | 'desc';

export const receivedToExtended = (alert2: received, devices: DeviceList[]): receivedExtended => {
  var alert = alert2 as receivedExtended
  alert.alarm_name = alert.alarm.name;
  alert.alarm_message = alert.alarm.message;
  alert.acknowledged_email = alert.acknowledged_user ? alert.acknowledged_user.email : '';
  alert.device_name = alert.device.name;
  const found = devices.find(d => d._id === alert.device.id)?.type
  alert.device_type_name = found ? typeToText(found) : undefined
  return alert;
}
enum Verification { all, verified, notVerified }

export default function AlertReceived(props: any) {
  //const { location } = props
  //const [selectedDevice, setSelectedDevice] = useState<DeviceList | undefined>(undefined)
  const { apiRequest } = useContext(APIRequestContext);
  const [total, setTotal] = useState<number>(0)
  const classes = useStyles();
  const { displayToast } = useContext(ToastContext);
  const { openAlertDialog, alerts, setAlerts } = useContext(AlarmContext);
  const { devices, setDevices, organizations } = useContext(EntitiesContext);
  const { user } = useContext(APIRequestContext);
  const intl = useIntl();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('created_at');
  const addHours = (value: Date, hours: number): Date => {
    var newDate = new Date()
    newDate.setTime(value.getTime() + hours * 60 * 60 * 1000)
    return newDate
  }
  const handleChange = (value: any, key: string) => {
    setFilters({ ...filters, [key]: value });
  };
  const [filters, setFilters] = useState({
    date: [addHours(new Date(), -24 * 7), new Date()],
    device_id: '-1',
    page: 0,
    limit: 15,
    verified: Verification.all,
    solution: 0 as (DeviceType | 0),
    organization: ""
  });

  const getDevices = () => {
    apiRequest<PageResponse<DeviceList>>("GET", "api/v1/device")
      .then(result => { setDevices(deviceListToDeviceListExpanded(result.values)) })
      .catch(error => { });
  }

  const getAlerts = (page: number, limit: number, orderby: string, ordertype: number, since: Date, until: Date, device_id?: string, notAck?: boolean, count?: boolean, organization_id?: string, types?: string[]) => {
    let vars = {
      page: page + 1,
      limit: limit,
      orderby: orderby,
      ordertype: ordertype,
      since: since.toISOString(),
      until: until.toISOString(),
      organization_id: organization_id,
      types: types,
      not_acknowledged: notAck,
      ...(device_id ? ({ device_id: device_id }) : {}),
      ...(count ? ({ count: count }) : {})
    }
    apiRequest<PageResponse<received> | SingleResponse<string>>("GET", "api/v1/alarm-occurrence/", { query: vars })
      .then(result => {
        if (!count) {
          setAlerts((result as PageResponse<receivedExtended>).values.map((alert) => receivedToExtended(alert, devices)))
        } else {
          setTotal(parseInt((result as SingleResponse<string>).value ? (result as SingleResponse<string>).value : '0'))
        }
      })
      .catch(error => {
        //console.log(error)
        displayToast('error', 'failedLoading', 'nothing');
      });
  }

  const openAlertDialogHelper = (id: string) => {
    const a = alerts.find(al => al._id === id)
    if (a) {
      openAlertDialog(a);
    }
  }

  const availablesolution = useMemo(() => {
    const ret = new Set();
    devices.forEach(v => ret.add(types2clientType[(v.type as keyof typeof types2clientType)]) as unknown as DeviceType);
    return Array.from(ret)
  }, [devices])

  useEffect(() => {
    const orderByFixed = orderBy === 'device_name' ?
      'device.name' :
      orderBy === 'alarm_name' ?
        'alarm.name' :
        orderBy === 'alarm_message' ?
          'alarm.message' :
          orderBy === 'acknowledged_email' ?
            'acknowledged_user.email' :
            orderBy === 'created_at' ?
              'created_at' :
              ''
    const orderFixed = order === 'asc' ? -1 : 1
    if (devices.length === 0) {
      getDevices()
    }
    let di = undefined
    if (filters.device_id !== '-1') {
      di = filters.device_id
    }
    const sols = filters.solution === 0 ? undefined : clientType2types[(filters.solution as unknown as keyof typeof clientType2types)];
    getAlerts(
      filters.page,
      filters.limit,
      orderByFixed,
      orderFixed,
      filters.date[0],
      filters.date[1],
      di,
      filters.verified === Verification.notVerified,
      false,
      filters.organization,
      sols
    );
    getAlerts(
      filters.page,
      filters.limit,
      orderByFixed,
      orderFixed,
      filters.date[0],
      filters.date[1],
      di,
      filters.verified === Verification.notVerified,
      true,
      filters.organization,
      sols
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, order, orderBy])


  const headCells: HeadCell<keyof receivedExtended | 'actions'>[] = [
    { id: 'device_name', numeric: false, disablePadding: true, label: 'device' },
    { id: 'alarm_name', numeric: false, disablePadding: false, label: 'alert' },
    { id: 'alarm_message', numeric: true, disablePadding: false, label: 'message' },
    { id: 'created_at', numeric: true, disablePadding: false, label: 'dateTime' },
    { id: 'acknowledged_email', numeric: true, disablePadding: false, label: 'verified' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'actions' },
  ];

  //getAlerts(1,2,'d',1,new Date(),new Date(),undefined,true)
  return (<div>
    <Grid container>
      <Grid item>
        <DateRangePicker
          className={classes.formControl}
          onChange={(val) => { handleChange(val, 'date') }}
          value={filters.date}
          calendarIcon={<TodayIcon />}
          clearIcon={null}
          required
        />
      </Grid>
      <Grid item>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-device"><FormattedMessage id={'device'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'device', id: 'select-device' }}
            value={filters.device_id}
            onChange={evt => handleChange(evt.target.value, "device_id")}
          >
            <MenuItem aria-label="None" value={'-1'} ><i><FormattedMessage id={'none'} /></i></MenuItem>
            {devices.map((x: DeviceList, i: number) => <MenuItem key={`select-device-${i}`} value={x._id}>{x.name}</MenuItem >)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-verified"><FormattedMessage id={'verification'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'verified', id: 'select-verified' }}
            value={filters.verified}
            onChange={evt => handleChange(evt.target.value, "verified")}
          >
            <MenuItem value={0}><FormattedMessage id={"all"} /></MenuItem >
            {/* <MenuItem value={1}><FormattedMessage id={"yesVerified"} /></MenuItem > */}
            <MenuItem value={2}><FormattedMessage id={"notVerified"} /></MenuItem >
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControlMedium}>
          <InputLabel htmlFor="select-solution"><FormattedMessage id={'solutions'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'solution', id: 'select-solution' }}
            value={filters.solution}
            onChange={evt => handleChange(evt.target.value, "solution")}
          >
            <MenuItem value={0}><FormattedMessage id={"all"} /></MenuItem >
            {availablesolution.map((s, i) => <MenuItem key={"sol" + s + i} value={(s as DeviceType)}><FormattedMessage id={deviceTypeToRouteString((s as DeviceType)).toUpperCase()} /></MenuItem >)}
          </Select>
        </FormControl>
      </Grid>
      {user && user.role === Roles.ADMIN && <Grid item>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-organization"><FormattedMessage id={'organization'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'organization', id: 'select-organization' }}
            value={filters.organization}
            onChange={evt => handleChange(evt.target.value, "organization")}
          >
            <MenuItem value={""}><FormattedMessage id={"all"} /></MenuItem >
            {organizations && organizations.map((s, i) => <MenuItem key={"org" + s + i} value={(s as OrganizationList)._id}>{s.name}</MenuItem >)}
          </Select>
        </FormControl>
      </Grid>}
    </Grid>
    <EntityTable
      dates={[3]}
      dot={1}
      list={alerts}
      formatColor={(alert: any) => { return alert.alarm?.color }}
      headCells={headCells}
      total={total}
      actions1={openAlertDialogHelper}
      buttons1={<Tooltip title={intl.formatMessage({ id: 'seeDetails' })}><OpenInNewIcon /></Tooltip>}
      setRows={(n: number) => { handleChange(n, 'limit') }}
      setPage={(n: number) => { handleChange(n, 'page') }}
      page={filters.page}
      rows={filters.limit}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
    />
  </div >
  );
}
