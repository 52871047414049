
import { Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplatePQ: {
      gridTemplateAreas: `
          "gr1"
          "gr2"`,
      gridTemplateRows: "repeat(2,49.6%)",
      gridTemplateColumns: "100%",
      maxHeight: "670px",
      gridGap: "6px",
      padding: "10px",
      height: theme.shape.graphBox.bigger.height,
    }
  }),
);

interface PowerQualityProps {
  sideNames: Array<string>//the names of the sides
}

export default withWidth()(function PowerQuality(props: React.PropsWithChildren<PowerQualityProps>) {
  const classes = useStyles();
  const { deviceFull, deviceMainHistory } = useContext(EntitiesContext);
  const { sideNames } = props;
  const translationAppend = useMemo(() => {
    switch (sideNames.length) {
      case 2:
        return "";
      case 3:
        return "-triple";
      case 4:
        return "-quad";
      case 5:
        return "-quin";
      case 6:
        return "-sext";
    }
  }, [sideNames.length]);
  return (
    <Grid container className={classes.SideContainer}>
      {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplatePQ} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1' title={"unbalance"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[(`unbi-${side}` as 'unbi-1')] : undefined,
              ]]}
              ysnames={[[
                `unbi-${side}${translationAppend}`,
              ]]}
              colors={[[
                '#e23434',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['unbalanceUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              removeLegendPrefix
              forceLegend
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"thdc"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[(`thdi-${side * 4}` as 'thdi-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdi-${1 + side * 4}` as 'thdi-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdi-${2 + side * 4}` as 'thdi-1')] : undefined,
              ]]}
              ysnames={[[
                (`thdi-${side * 4}${translationAppend}`),
                (`thdi-${1 + side * 4}${translationAppend}`),
                (`thdi-${2 + side * 4}${translationAppend}`),
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






