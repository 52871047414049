
import { Grid, isWidthDown, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forGaugesICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga0 ga1"
          "gr0 gr1"`,
        gridTemplateRows: "calc(15% - 3px) calc(85% - 3px)",
        gridTemplateColumns: "repeat(2, calc(50% - 3px))",
        maxHeight: "670px",
        width: "100%",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "ga0"
          "gr0"
          "ga1"
          "gr1"`,
        gridTemplateRows: "repeat(2, 7% 42.3%)",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "ga0"
          "gr0"
          "ga1"
          "gr1"`,
        gridTemplateRows: "0.3fr 1fr 0.3fr 1fr",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

interface ElectricParamProps {

}

export interface gaugeFinal {
  name: string,
  value: number | undefined,
  color: string,
  max: number | undefined
}

export interface gaugeMid {
  title: string
  array: Array<gaugeFinal>
}

export interface gaugeProps extends Array<gaugeMid> {
}


export default withWidth()(function ElectricParamI(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forGaugesICM(deviceReport);
  //primario e secundario
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1} >
          {gaugeData.map((g, index) => <React.Fragment key={`graphsholder-${index}`}><SubTitledCard gridArea={`ga${index}`} title={g.title}>
            <GaugeGraph bigValue horizontal={!isWidthDown('xs', (props as any).width)} data={g.array} variable={g.variable}></GaugeGraph>
          </SubTitledCard>
            <SubTitledCard gridArea={`gr${index}`} title={g.title}>
              <DraggableGraph
                ys={[[
                  deviceMainHistory ? index === 0 ? deviceMainHistory[`ppv-0`] : deviceMainHistory[`iv-0`] : undefined,
                  deviceMainHistory ? index === 0 ? deviceMainHistory[`ppv-1`] : deviceMainHistory[`iv-1`] : undefined,
                  deviceMainHistory ? index === 0 ? deviceMainHistory[`ppv-2`] : deviceMainHistory[`iv-2`] : undefined,
                ]]}
                ysnames={[[
                  (index === 0 ? `ppv-0-icm` : 'iv-0-icm'),
                  (index === 0 ? `ppv-1-icm` : 'iv-1-icm'),
                  (index === 0 ? `ppv-2-icm` : 'iv-2-icm'),
                ]]}
                colors={[[
                  '#e23434',
                  '#2aa060',
                  '#3473ba'
                ]]}
                x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
                titlex={''}
                titley={index === 0 ? ['voltageUnits'] : ['currentUnits']}
                graphType={"linegl"}
                emptyMessage={'no7days'}
                enableReportLinks
              ></DraggableGraph>
            </SubTitledCard></React.Fragment>)}
        </TitledCard>

      </Grid>
    </Grid >
  );
})
