
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PolarGraph from '../../../components/device/final/PolarGraph';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forVectorGraphs } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateVG: {
      [theme.breakpoints.up('md')]: {
        gridTemplateAreas: `
          "gr1 gr2 gr3"`,
        gridTemplateColumns: "0.5fr 0.25fr 0.25fr",
        maxHeight: "670px",
        height: theme.shape.graphBox.smallest.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "gr1 gr1" 
          "gr2 gr3"`,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "50% 50%",
        maxHeight: "unset",
        height: "500px",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
        "gr1" 
        "gr2" 
        "gr3"`,
        gridTemplateRows: "repeat(3, 32.76%)",
        gridTemplateColumns: '100%',
        maxHeight: "unset",
        height: "630px",
        gridGap: "6px",
        padding: "10px"
      },
    }
  }),
);

export default function VectorGraph(props: React.PropsWithChildren<{ hasV?: boolean }>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceReport } = useContext(EntitiesContext);
  const data = forVectorGraphs(deviceReport)
  const intl = useIntl()
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateVG}>
          <SubTitledCard gridArea='gr1'>
            <PolarGraph
              ys={data ? data.map(form => form.angulo).filter((v, ind) => ind < 3 || hasV) : [[]]}
              ysnames={['Iu', 'Iv', 'Iw', 'Vu', 'Vv', 'Vw']}
              colors={['#f15d5d', '#51c787', '#6299d8', '#e23434', '#2aa060', '#3473ba']}
              xs={data ? data.map(form => form.amplitude).filter((v, ind) => ind < 3 || hasV) : []}
            ></PolarGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title='voltage'>
            <SmallCornerBox
              empty={!hasV}
              wide
              parts={[
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: 'pnv-0-mcm', value: [si_format(data ? data[3].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[3].angulo[1] : 0, 'º', 1, true)] },
                { title: 'pnv-1-mcm', value: [si_format(data ? data[4].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[4].angulo[1] : 0, 'º', 1, true)] },
                { title: 'pnv-2-mcm', value: [si_format(data ? data[5].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[5].angulo[1] : 0, 'º', 1, true)] },
              ]}
            ></SmallCornerBox>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title='current'>
            <SmallCornerBox
              wide
              parts={[
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: 'iv-0-mcm', value: [si_format(data ? data[0].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[0].angulo[1] : 0, 'º', 1, true)] },
                { title: 'iv-1-mcm', value: [si_format(data ? data[1].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[1].angulo[1] : 0, 'º', 1, true)] },
                { title: 'iv-2-mcm', value: [si_format(data ? data[2].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[2].angulo[1] : 0, 'º', 1, true)] },
              ]}
            ></SmallCornerBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






