import React, { useState } from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { LPTCMpermissions } from '../../utils/lptcm-functions';
import ElectricParamTCM from '../tcm/tabs/ElectricParam';
import ExcitationCurrentTCM from '../tcm/tabs/ExcitationCurrent';
import GlobalStatusTCM from '../tcm/tabs/GlobalStatus';
import HarmonicsTCM from '../tcm/tabs/Harmonics';
import LoadDiagramTCM from '../tcm/tabs/LoadDiagram';
import PowerQualityTCM from '../tcm/tabs/PowerQuality';
import SingleReportTCM from '../tcm/tabs/SingleReport';
import VectorGraphTCM from '../tcm/tabs/VectorGraph';
import WaveformsTCM from '../tcm/tabs/Waveforms';
import LptcmInfoDialog from './dialogs/LptcmInfoDialog';
import ElectricParam from './tabs/ElectricParam';
import ExcitationCurrent from './tabs/ExcitationCurrent';
import GlobalStatus from './tabs/GlobalStatus';
import Harmonics from './tabs/Harmonics';
import LoadDiagram from './tabs/LoadDiagram';
import PowerQuality from './tabs/PowerQuality';
import SingleReport from './tabs/SingleReport';
import TransformRatio from './tabs/TransformRatio';
import VectorGraph from './tabs/VectorGraph';
import Waveforms from './tabs/Waveforms';

interface LPTCMPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}

const variables = ["ldratio", "iv-0", "iv-1", "iv-2", "iv-4", "iv-5", "iv-6", "unbi-0", "unbi-1", "thdi-0", "thdi-1", "thdi-2", "thdi-4", "thdi-5", "thdi-6",
  'sf-0', 'iexc-0', 'iexc-1', 'iexc-2', 'rt-7', 'rt-11', 'rt-15'] as const //baixo diag, cima control

type LUV = typeof variables[number];
type time = { 'timestamp': string[] }
const variables2 = (variables as any)
variables2.unshift('timestamp')

export type LptcmUsableVariables = time & {
  [K in LUV]: Array<number>;
}

export default function LPTCMPage(props: LPTCMPageProps) {
  const { location } = props
  const [hasV, setHasV] = React.useState(false);
  const [sideNames, setSideNames] = useState<Array<string>>(['1o', '2o']);
  const [hasV1, setHasV1] = useState<boolean>(true);
  const [hasV2, setHasV2] = useState<boolean>(true);
  const [hasV3, setHasV3] = useState<boolean>(true);

  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    return result
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    setHasV(result.config_device.analog_reading_labels.some(label => label.startsWith('V')));
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('VA'))) setHasV1(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va1'))) setHasV2(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va2'))) setHasV3(false);
    makeSideNames(result)
    return result
  }

  const makeSideNames = (result: DeviceFull) => {
    var names = ['1o'];
    const secs = result.nameplate.side_labels?.filter(l => l.startsWith('S')).length
    const ters = result.nameplate.side_labels?.filter(l => l.startsWith('T')).length
    const quats = result.nameplate.side_labels?.filter(l => l.startsWith('Q')).length
    if (secs === 1) names.push('2o')
    else Array(secs).fill(0).forEach((x, index) => names.push(`2o${index + 1}`))
    if (ters === 1) names.push('3o')
    else Array(ters).fill(0).forEach((x, index) => names.push(`3o${index + 1}`))
    if (quats === 1) names.push('4o')
    else Array(quats).fill(0).forEach((x, index) => names.push(`4o${index + 1}`))
    setSideNames(names);
  }

  return (
    <MainDeviceDashboard
      location={location}
      deviceType={DeviceType.L}
      processingAffectingContexts={processingAffectingContexts}
      processingGeneral={processingGeneral}
      DeviceInfoDialog={LptcmInfoDialog}
      variables={variables2}
      controlInfoBar={{ element: <ControlInfoBar maxSFCount={1}></ControlInfoBar>, tabCode: "002" }}
      controlComponents={[
        {
          element: hasV ? <ElectricParamTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></ElectricParamTCM> : <ElectricParam sideNames={sideNames}></ElectricParam>,
          title: "electric_param"
        }, {
          element: hasV ? <LoadDiagramTCM></LoadDiagramTCM> : <LoadDiagram></LoadDiagram>,
          title: "load_diagram"
        }, {
          element: hasV ? <PowerQualityTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></PowerQualityTCM> : <PowerQuality sideNames={sideNames}></PowerQuality>,
          title: "power_quality"
        }, {
          element: hasV ? <VectorGraphTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></VectorGraphTCM> : <VectorGraph sideNames={sideNames}></VectorGraph>,
          title: "vector_diagram"
        }, {
          element: hasV ? <WaveformsTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></WaveformsTCM> : <Waveforms sideNames={sideNames} hasV={hasV}></Waveforms>,
          title: "waveforms"
        }
      ].map(v => ({ ...v, ...{ tabCode: LPTCMpermissions[(v.title as keyof typeof LPTCMpermissions)] } }))}
      diagnosticComponents={[
        {
          element: hasV ? <GlobalStatusTCM></GlobalStatusTCM> : <GlobalStatus></GlobalStatus>,
          title: "global_status"
        }, {
          element: hasV ? <ExcitationCurrentTCM></ExcitationCurrentTCM> : <ExcitationCurrent></ExcitationCurrent>,
          title: "excitation"
        }, {
          element: <TransformRatio sideNames={sideNames}></TransformRatio>,
          title: "transformation_ratio"
        }, {
          element: hasV ? <HarmonicsTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></HarmonicsTCM> : <Harmonics sideNames={sideNames} hasV={hasV}></Harmonics>,
          title: "harmonics"
        }
      ].map(v => ({ ...v, ...{ tabCode: LPTCMpermissions[(v.title as keyof typeof LPTCMpermissions)] } }))}
      historyComponents={[
        {
          element: <HistoryPage type={DeviceType.L}></HistoryPage>,
          title: "trendAn"
        },
        {
          element: <ReportViewer>
            {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined }) => hasV ? <SingleReportTCM reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} sideNames={sideNames}></SingleReportTCM> : <SingleReport reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} sideNames={sideNames} hasV={hasV} />}
          </ReportViewer>,
          title: "sampleAn"
        },
      ]}
    ></MainDeviceDashboard>
  );
}