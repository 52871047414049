import { makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from "clsx";

const useStyles = (props: LongDataBoxProps) => {
  return makeStyles((theme) => ({
    lineText2: {
      fontSize: "13px",
      color: theme.palette.primary.main,
      fontWeight: 700,
      height: "37px",
      margin: "auto",
      whiteSpace: "nowrap"
    },
    beforeLine2: {
      width: "50px",
      textAlign: "center",
      marginLeft: "10px",
      height: "24px"
    },
    afterLine2: {
      width: "37px",
      textAlign: "center",
      textAlignLast: "end",
      marginRight: "10px"
    },
    lineHolder2: {
      paddingTop: "5px",
      backgroundColor: "white",
      borderRadius: "10px",
      margin: "4px",
      overflow: 'hidden',
      display: "flex",
      justifyContent: "space-between"
    },
    Line2: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down('md')]: {
        overflowY: 'scroll'
      }
    },
    title2: {
      height: "fit-content",
      margin: "0px 9px 0 9px",
      color: theme.palette.primary.main,
      fontSize: "20px",
      borderRadius: "10px 10px 0 0",
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: "normal",
      textAlign: "left",
    },
    centerDiv: {
      width: 200
    }
  }
  ))();
}

export interface LongDataBoxParts {
  title: string
  value: string
}

interface LongDataBoxProps {
  title?: string,
  parts: Array<LongDataBoxParts>
  gridArea?: string,
  empty?: boolean
}

export default function LongDataBox(props: PropsWithChildren<LongDataBoxProps>) {
  const { title, parts, gridArea, empty } = props;
  const classes = useStyles(props);
  const intl = useIntl();
  const partsFinal = empty ?
    [
      { title: '', value: '' },
      { title: intl.formatMessage({ id: 'na' }), value: '' },
      { title: '', value: '' },
    ] : parts;

  return (
    <div className={classes.lineHolder2} style={{ gridArea: gridArea }}>
      {title && <h3 className={classes.title2}><FormattedMessage id={title}></FormattedMessage></h3>}
      {partsFinal && partsFinal.map((value, index) =>
        <div key={`gauge-${value.title}-${index}`} className={classes.Line2}>
          {value.title && <div className={clsx(classes.beforeLine2, classes.lineText2, empty && classes.centerDiv)}>{value.title}</div>}
          {value.value && <div className={clsx(classes.beforeLine2, classes.lineText2)}>{value.value}</div>}
        </div>
      )}
    </div>
  );
}