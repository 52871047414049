import { enUS, esES, ptPT } from '@material-ui/core/locale';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import shape, { Shape } from '@material-ui/core/styles/shape';
import React, { PropsWithChildren, useEffect } from 'react';
import { useIntl } from 'react-intl';
import backgroundImage from "../../assets/general/background.png";
import EngingLogo from "../../assets/general/iconoptimized.svg";
import logoImage from "../../assets/general/logo--new.webp";
import logoBottom from '../../assets/general/logo-full.webp';
import map from '../../assets/general/map.webp';

import backgroundImageEfacec from "../../assets/general/backgroundEfacec.webp";
import EfacecLogo from "../../assets/general/iconEfacecoptimized.svg";
import logoImageEfacec from "../../assets/general/logo--newEfacec.png";
import { ListItemText, makeStyles } from '@material-ui/core';
import logoBottomEfacec from '../../assets/general/logo-fullefacecoptimized.svg';
import efacecFavicon from "../../assets/general/favicon.ico"

const useStyles = () => {
    return makeStyles((theme) => ({
        logoText1: {
            fontFamily: 'Titillium Web',
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            paddingLeft: theme.spacing(3),
            color: theme.palette.primary.contrastText,
            display: "flex",
            '& div:nth-child(1)': {
                fontSize: '27px',
                fontWeight: 600,
            },
            '& div:nth-child(2)': {
                fontSize: '16px',
                fontWeight: 400,
                paddingTop: "15px",
            },
            '& div:nth-child(3)': {
                verticalAlign: 'super',
                fontSize: '19px',
            }
        },
        logoText2: {
            fontFamily: 'Titillium Web',
            lineHeight: 1.6,
            letterSpacing: "0.0075em",
            paddingLeft: 15,
            color: theme.palette.primary.contrastText,
            display: "flex",
            '& div:nth-child(1)': {
                fontSize: '27px',
                fontWeight: 600,
            },
            '& div:nth-child(2)': {
                fontSize: '16px',
                fontWeight: 400,
                paddingTop: "15px",
            },
            '& div:nth-child(3)': {
                verticalAlign: 'super',
                fontSize: '19px',
            }
        },
    }))()
};

interface graphComponents {
    height: number,
}

export interface ShapeExtended extends Shape {
    borderRadius: any,
    appBar: {
        height: number
    },
    drawer: {
        width: number
    },
    drawerClosed: {
        width: number
    },
    graphBox: {
        FourGaugeHeight: graphComponents,
        smallest: graphComponents,
        smaller: graphComponents,
        normal: graphComponents,
        bigger: graphComponents,
        biggest: graphComponents
    }
}

export interface ThemeExtended extends Theme {
    shape: ShapeExtended,
    palette: PaletteExtended,
}

export interface PaletteExtended extends Palette {
    images: {
        greeting: any,
        greetingLogo: any,
        sidebarLogo: any,
        bottomLogo: any,
        sidebarName: any,
        tabName: string,
        contacts: { name: string, value: string }[],
        contactMap: any,
        website: string,
        favicon: any,
    }
}

const palette = {
    primary: {
        light: '#648bb2',
        main: '#376193',
        dark: '#2d577f',
        contrastText: '#fff',
    },
    error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#8B0000",
        contrastText: '#fff',
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: '#fff',
    },
    info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: '#fff',
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: '#fff',
    },
    images: {
        greeting: backgroundImage,
        greetingLogo: logoImage,
        sidebarLogo: EngingLogo,
        bottomLogo: logoBottom,
        sidebarName: <div></div>,
        tabName: "Enging ePreditMntc®",
        contacts: [
            { name: "phone", value: "+351 911 733 155" },
            { name: "phone", value: "+351 913 887 000" },
            { name: "phone", value: "+351 967 063 651" },
            { name: "business", value: "+351 239 169 347" },
            { name: "email", value: "geral@enging.pt" }
        ],
        contactMap: map,
        website: "enging.pt",
        favicon: undefined
    }
}

const efacecPalette = {
    primary: {
        light: '#606161',
        main: '#363636',
        dark: '#262626',
        contrastText: '#fff',
    },
    error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#8B0000",
        contrastText: '#fff',
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: '#fff',
    },
    info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: '#fff',
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: '#fff',
    },
    images: {
        greeting: backgroundImageEfacec,
        greetingLogo: logoImageEfacec,
        sidebarLogo: EfacecLogo,
        bottomLogo: logoBottomEfacec,
        sidebarName: <div></div>,
        tabName: "Diagnosis RM",
        contacts: [
            { name: "phone", value: "+351 229 562 300" },
            { name: "business", value: "+351 229 518 933" },
            { name: "email", value: "efasset@efacec.com" },
            { name: "email", value: "service.energia@efacec.com" },
            { name: "site", value: "www.efacec.pt" },
        ],
        contactMap: ["text", "Sede", "Parque Empresarial Arroteia Poente", "Arroteia - Leça do Balio", "Apartado 1018 . 4466-952 S. Mamede de Infesta . Portugal"],
        website: "efacec.pt",
        favicon: efacecFavicon,
    }
}

const shape2: ShapeExtended = {
    ...shape, ...{
        appBar: {
            height: 71.5
        },
        drawer: {
            width: 230
        },
        drawerClosed: {
            width: 0
        },
        graphBox: {
            FourGaugeHeight: {
                height: 258,
            },
            smallest: {
                height: 300,
            },
            smaller: {
                height: 600,
            },
            normal: {
                height: 650,
            },
            bigger: {
                height: 700,
            },
            biggest: {
                height: 810,
            }
        }
    }
}

export default function CustomTheme(props: PropsWithChildren<{}>) {
    const intl = useIntl()
    const locale = intl.locale === 'pt' || intl.locale === 'PT' ? ptPT : intl.locale === 'es' || intl.locale === 'ES' ? esES : enUS
    const paletteFinal = window.location.hostname.includes("efacec") ? efacecPalette : palette;
    const classes = useStyles();
    const logoText = window.location.hostname.includes("efacec") ? <ListItemText className={classes.logoText2} disableTypography>
        <div>efacec</div>
    </ListItemText> : <ListItemText className={classes.logoText1} disableTypography>
            <div>ePredit</div><div>Mntc</div><div>®</div>
        </ListItemText>
    paletteFinal.images.sidebarName = logoText;

    useEffect(() => {
        const head = document.head || document.getElementsByTagName('head')[0];
        function changeFavicon(src: any) {
            if (src) {
                var link = document.createElement('link'),
                    oldLink = document.getElementById('dynamic-favicon');
                link.id = 'dynamic-favicon';
                link.rel = 'shortcut icon';
                link.href = src;
                if (oldLink) {
                    head.removeChild(oldLink);
                }
                head.appendChild(link);
            }
        }
        changeFavicon(paletteFinal.images.favicon)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const theme = createTheme({
        palette: paletteFinal,
        shape: shape2,
        typography: {
            fontFamily: [
                'Titillium Web',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol" !important',
            ].join(','),
        },
        overrides: {
            MuiPaper: {
                elevation4: {
                    boxShadow: 'unset',
                },
                elevation1: {
                    boxShadow: 'unset',
                },
            },
            MuiListItemAvatar: {
                root: {
                    minWidth: '40px'
                }
            },
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: 'unset'
                }
            },
            MuiToolbar: {
                regular: {
                    minHeight: '71.5px !important'
                },
                gutters: {
                    paddingLeft: '0 !important'
                }
            },
            MuiMenuItem: {
                root: {
                    "&$selected": {
                        "&:hover": {
                            color: 'black',
                            backgroundColor: palette.primary.light,
                        },
                        backgroundColor: palette.primary.main,
                        color: 'white'
                    },
                },
            },
        },
    }, locale);
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

