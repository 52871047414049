
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
//import { si_format } from '../../../utils/format-data';
import { forGauges } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga1 gr1 gr1 gr3 gr3"
          "ga1 gr1 gr1 gr3 gr3"
          "ga2 gr1 gr1 gr3 gr3"
          "ga2 gr2 gr2 gr4 gr4"
          "ga3 gr2 gr2 gr4 gr4"
          "ga3 gr2 gr2 gr4 gr4"`,
        gridTemplateRows: "repeat(6,1fr)",
        gridTemplateColumns: "0.8fr 1fr 1fr 1fr 1fr",
        maxHeight: "650px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "ga1 ga2 ga3"
          "gr1 gr1 gr1"
          "gr2 gr2 gr2"
          "gr3 gr3 gr3"
          "gr4 gr4 gr4"`,
        gridTemplateRows: "9% repeat(4, 22.5%)",
        gridTemplateColumns: "repeat(3, 32.8%)",
        maxHeight: "unset",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
          "ga1"
          "ga2"
          "ga3"
          "gr1"
          "gr2"
          "gr3"
          "gr4"`,
        gridTemplateRows: "repeat(3, 8%) repeat(4, 18.65%)",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

interface ElectricParamProps {
  hasV?: boolean,
}

export interface gaugeFinal {
  name: string,
  value: number | undefined,
  color: string,
  max: number | undefined
}

export interface gaugeMid {
  variable: string,
  title: string,
  array: Array<gaugeFinal>
}

export interface gaugeProps extends Array<gaugeMid> {
}


export default function ElectricParam(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forGauges(deviceReport);
  const mainGridReference = useRef(null);
  //primario e secundario
  return (
    <Grid ref={mainGridReference} container className={classes.SideContainer}>
      {deviceFull && <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1}>

          {gaugeData && gaugeData.map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${type}`} title={gaugeData[type].title}>
            <GaugeGraph empty={(type === 0 || type === 2) && hasV === false} data={gaugeData[type].array}></GaugeGraph>
          </SubTitledCard>)}

          <SubTitledCard gridArea='gr1' title={"voltage"}>
            <DraggableGraph
              empty={hasV === false}
              ys={[[
                deviceMainHistory ? deviceMainHistory[`ppv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`ppv-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`ppv-2`] : undefined,
              ]]}
              ysnames={[[
                `ppv-0-mcm`,
                `ppv-1-mcm`,
                `ppv-2-mcm`
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['voltageUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"current"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`iv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`iv-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`iv-2`] : undefined
              ]]}
              ysnames={[[
                `iv-0-mcm`,
                `iv-1-mcm`,
                `iv-2-mcm`
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['currentUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title={"electricPower"}>
            <DraggableGraph
              empty={hasV === false}
              ys={[[
                deviceMainHistory ? deviceMainHistory[`actpwr-0`] : undefined,
              ]]}
              ysnames={[[
                `actpwr-0`,
              ]]}
              colors={[[
                '#e23434',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['powerUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr4' title={"powerFactor"}>
            <DraggableGraph
              empty={hasV === false}
              ys={[[
                deviceMainHistory ? deviceMainHistory[`cosphi-0`] : undefined,
              ]]}
              ysnames={[[
                `cosphi-0`,
              ]]}
              colors={[[
                '#2aa060',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['factorUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>}
    </Grid >
  );
}
