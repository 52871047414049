
import { createStyles, isWidthDown, makeStyles, withWidth } from '@material-ui/core';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forGauges } from '../../../utils/mcm-functions';


const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    IA: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
        "gr1 gr2"
        "gr3 gr3"`,
        gridTemplateRows: "13% 86.3%",
        gridTemplateColumns: "repeat(2, 49.77%)",
        maxHeight: "1050px",
        height: theme.shape.graphBox.bigger.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
        "gr1"
        "gr2"
        "gr3"`,
        gridTemplateRows: "16% 16% 66.2%",
        gridTemplateColumns: "100%",
        maxHeight: "1050px",
        height: 600,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1"
        "gr2"
        "gr3"`,
        gridTemplateRows: "20% 20% 59%",
        gridTemplateColumns: "100%",
        maxHeight: "1050px",
        height: 990,
        gridGap: "6px",
        padding: "10px"
      }
    },
    IAGCM: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
        "gr1 gr3"
        "gr2 gr3"
        "gr4 gr3"`,
        gridTemplateRows: "repeat(3, 32.75%)",
        gridTemplateColumns: "13% 86.65%",
        maxHeight: "1050px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
        "gr1"
        "gr2"
        "gr3"`,
        gridTemplateRows: "16% 16% 66.2%",
        gridTemplateColumns: "100%",
        maxHeight: "1050px",
        height: 600,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1"
        "gr2"
        "gr3"`,
        gridTemplateRows: "20% 20% 59%",
        gridTemplateColumns: "100%",
        maxHeight: "1050px",
        height: 990,
        gridGap: "6px",
        padding: "10px"
      }
    }
  }),
);


export default withWidth()(function InstantAnalysis(props: React.PropsWithChildren<{ isGCM?: boolean, hasV?: boolean }>) {
  const classes = useStyles();
  const { isGCM, hasV } = props;
  const { deviceReport, deviceWaveforms } = useContext(EntitiesContext);
  const gaugeData = forGauges(deviceReport, true, isGCM);

  return (
    <TitledCard gridTemplate={isGCM ? classes.IAGCM : classes.IA}>
      <SubTitledCard gridArea='gr1' title={isGCM ? 'statorVoltage' : 'voltage'}><GaugeGraph empty={!hasV} horizontal={!isGCM && !isWidthDown('xs', (props as any).width)} data={gaugeData[0].array}></GaugeGraph></SubTitledCard>
      <SubTitledCard gridArea='gr2' title={isGCM ? 'statorCurrent' : 'current'}><GaugeGraph horizontal={!isGCM && !isWidthDown('xs', (props as any).width)} data={gaugeData[1].array}></GaugeGraph></SubTitledCard>
      {isGCM && <SubTitledCard gridArea='gr4' title={'rotorCurrent'}><GaugeGraph data={gaugeData[3]?.array}></GaugeGraph></SubTitledCard>}
      <SubTitledCard title={"graph"} gridArea='gr3'>
        {!isGCM && <DraggableGraph
          ys={hasV ? [[
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[4].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[5].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[6].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ], [
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[1].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[2].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[3].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ]
          ] : [[
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[1].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[2].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[3].map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ]
            ]}
          ysnames={hasV ? [[
            'ppv-0-mcm',
            'ppv-1-mcm',
            'ppv-2-mcm',
          ], [
            'iv-0-mcm',
            'iv-1-mcm',
            'iv-2-mcm'
          ]
          ] : [[
            'iv-0-mcm',
            'iv-1-mcm',
            'iv-2-mcm'
          ]
            ]}
          colors={hasV ? [[
            '#c70000',
            '#1e7b49',
            '#224a77'
          ], [
            '#ec4747',
            '#2ebd70',
            '#4684c9'
          ]] : [[
            '#ec4747',
            '#2ebd70',
            '#4684c9'
          ]]}
          x={deviceWaveforms ? (deviceWaveforms.wPNv as any)[0].map((v: string) => parseFloat(v.replace(",", "."))) : undefined}
          titlex={'secondsUnits'}
          titley={hasV ? ['voltageUnits', 'currentUnits'] : ["currentUnits"]}
          graphType={"linegl"}
          emptyMessage={'no7days'}
          twoAxisWidth={hasV}
          notTimezone
          removeLegendPrefix
        ></DraggableGraph>}
        {isGCM && <DraggableGraph
          ys={[[
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[7]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[8]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[9]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ], [
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[1]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[2]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[3]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ], [
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[4]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[5]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
            deviceWaveforms ? (deviceWaveforms.wPNv as any)[6]?.map((v: string) => parseFloat(v.replace(",", "."))) : undefined,
          ]
          ]}
          ysnames={[[
            'ppv-0-mcm',
            'ppv-1-mcm',
            'ppv-2-mcm',
          ], [
            'iv-0-mcm',
            'iv-1-mcm',
            'iv-2-mcm'
          ], [
            'Iru',
            'Irv',
            'Irw',
          ]
          ]}
          colors={[[
            '#c70000',
            '#1e7b49',
            '#224a77'
          ], [
            '#ec4747',
            '#2ebd70',
            '#4684c9'
          ], [
            '#ec4747',
            '#2ebd70',
            '#4684c9'
          ]]}
          x={deviceWaveforms ? (deviceWaveforms.wPNv as any)[0].map((v: string) => parseFloat(v.replace(",", "."))) : undefined}
          titlex={'secondsUnits'}
          titley={['voltageStatorUnits', 'currentStatorUnits', 'currentRotorUnits']}
          graphType={"linegl"}
          emptyMessage={'no7days'}
          threeAxisWidth
          notTimezone
          removeLegendPrefix
        ></DraggableGraph>}
      </SubTitledCard>
    </TitledCard>
  );
})