
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { default as React, useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateSA1: {
      [theme.breakpoints.up('sm')]: {
        gridTemplateAreas: `
      "gr1 gr2"`,
        gridTemplateColumns: "repeat(2,50%)",
        gridTemplateRows: "100%",
        maxHeight: "650px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1"
          "gr2"`,
        gridGap: "6px",
        padding: "10px",
        height: 620,
        gridTemplateColumns: "100%",
        gridTemplateRows: "repeat(2,49.7%)",
      }
    },
    displayTitle: {
      marginTop: "-6px",
      color: theme.palette.primary.light,
      height: 33,
      fontSize: 20,
      fontStyle: "normal",
      textAlign: "left",
      fontWeight: 700,
      padding: "1px 2px",
      marginLeft: 10
    },
    displayTitleSelected: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
      marginTop: "-6px",
      color: "white",
      height: 33,
      fontSize: 20,
      fontStyle: "normal",
      textAlign: "left",
      fontWeight: 700,
      padding: "1px 2px",
      marginLeft: 10,
      backgroundColor: theme.palette.primary.light
    },
    menuItem: {

    }
  }),
);

export default function SpectralAnalysis(props: React.PropsWithChildren<{ hasV?: boolean }>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceWaveforms } = useContext(EntitiesContext);
  const [graphToShow, setGraphToShow] = React.useState<string>('epva');

  const handleChange = (name: string) => {
    setGraphToShow(name)
    return;
  }

  return (
    <TitledCard gridTemplate={classes.gridtemplateSA1}>
      <SubTitledCard gridArea='gr1' title={"spectrums"} buttons={<>
        <Button
          onClick={() => handleChange('epva')}
          className={graphToShow === 'epva' ? classes.displayTitleSelected : classes.displayTitle}
        >
          EPVA
        </Button>
        <Button
          onClick={() => handleChange('mcsa')}
          className={graphToShow === 'mcsa' ? classes.displayTitleSelected : classes.displayTitle}
        >
          MCSA
        </Button>
      </>
      }>
        <DraggableGraph
          ys={graphToShow === 'mcsa' ? [
            deviceWaveforms && (deviceWaveforms.wDynZ as any)[1] ? [(deviceWaveforms.wDynZ as any)[1].map((v: string) => parseFloat(v.replace(",", ".")))] : [[]],
          ] : [hasV ? [
            deviceWaveforms && (deviceWaveforms.wDynZ as any)[3] ? (deviceWaveforms.wDynZ as any)[3].map((v: string) => parseFloat(v.replace(",", "."))) : [],
            deviceWaveforms && (deviceWaveforms.wDynZ as any)[2] ? (deviceWaveforms.wDynZ as any)[2].map((v: string) => parseFloat(v.replace(",", "."))) : [],
          ] : [
              deviceWaveforms && (deviceWaveforms.wDynZ as any)[2] ? (deviceWaveforms.wDynZ as any)[2].map((v: string) => parseFloat(v.replace(",", "."))) : [],
            ]]}
          ysnames={graphToShow === 'mcsa' ? [[
            'MCSA',
          ]] : [hasV ? [
            'EPVAv',
            `EPVAi`,
          ] : [
              `EPVAi`,
            ]]
          }
          colors={graphToShow === 'mcsa' ? [[
            '#e23434',
          ]] : [hasV ? [
            '#3473ba',
            '#e23434',
          ] : [
              '#e23434',
            ]]}
          x={deviceWaveforms ? (deviceWaveforms.wDynZ as any)[0].map((v: string) => parseFloat(v.replace(",", "."))) : undefined}
          titlex={'freq-des-description'}
          titley={['amplitudeUnitsPercent']}
          graphType={'linegl'}
          emptyMessage={'no7days'}
          yrange={graphToShow === 'mcsa' ? [-100, 1.5] : [0, 5.1]}
          xrange={[0, 140]}
          notTimezone
        />
      </SubTitledCard>
      <SubTitledCard gridArea='gr2' title={"PQSpectrum"}>
        <DraggableGraph
          empty={!hasV}
          ys={deviceWaveforms && (deviceWaveforms.wDynZ as any)[5] && (deviceWaveforms.wDynZ as any)[4] ? [[
            (deviceWaveforms.wDynZ as any)[5]?.map((v: string) => parseFloat(v.replace(",", "."))),
            (deviceWaveforms.wDynZ as any)[4]?.map((v: string) => parseFloat(v.replace(",", ".")))
          ]] : [[[]]]
          }
          ysnames={[[
            `reactpwr-0-mcm`,
            'P'
          ]]}
          colors={[[
            '#e23434',
            '#2aa060',
          ]]}
          x={deviceWaveforms ? (deviceWaveforms.wDynZ as any)[0].map((v: string) => parseFloat(v.replace(",", "."))) : undefined}
          titlex={'freq-des-description'}
          titley={['amplitudeUnitsPercent']}
          graphType={'linegl'}
          emptyMessage={'no7days'}
          yrange={[0, 5.1]}
          xrange={[0, 140]}
          notTimezone
        />
      </SubTitledCard>
    </TitledCard>
  );
}