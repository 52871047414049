import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
// import lptcmHelp from "../../assets/lptcm-help.webp";
import mcmHelp from "../../assets/equipmentPhotos/mcm-help.webp";
import tcmHelp from "../../assets/equipmentPhotos/tcm-help.webp";
import HelpList from "../../components/global/HelpList";
import ScrollableContent from "../../components/global/ScrollableContent";
import SubNavBar from "../../components/global/SubNavBar";
import APIRequestContext from "../../contexts/api-context";
import { DeviceType } from "../../interfaces/Device";
import { getVariablesByRoleAndTypeAndViews } from "../../utils/format-data";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBox: {
      width: "60%",
      margin: "auto",
      padding: 15
    }

  }),
);

// const lptcmArray = getLPTCMVariables()


// const icmArray = getICM_I_Variables().map(val => val.concat('-icm'))

// const buttons = [
//   {
//     name: "mcm",
//     link: "#/help/mcm",
//     icon: mcmHelp,
//     altIcon: mcmHelp
//   }, {
//     name: "lptcm",
//     link: "#/help/lptcm",
//     icon: lptcmHelp,
//     altIcon: lptcmHelp
//   }, {
//     name: "tcm",
//     link: "#/help/tcm",
//     icon: tcmHelp,
//     altIcon: tcmHelp
//   }, {
//     name: "icm",
//     link: "#/help/icm",
//     icon: tcmHelp,
//     altIcon: tcmHelp
//   },
// ]


const buttons = [
  {
    name: "TCM",
    link: "/help/tcm",
    icon: <img src={tcmHelp} style={{ width: 40 }} alt={"tcm_image"}></img>,
    altIcon: <img src={tcmHelp} style={{ width: 40 }} alt={"tcm_image"}></img>,
  },
  {
    name: "MCM",
    link: "/help/mcm",
    icon: <img src={mcmHelp} style={{ width: 40 }} alt={"tcm_image"}></img>,
    altIcon: <img src={mcmHelp} style={{ width: 40 }} alt={"tcm_image"}></img>
  }
]

const routesAlarms: (({ [route: string]: number })) = {
  'mcm': 1,
  "lptcm": 2,
  "tcm": 0,
  "icm": 3
}

export default function HelpPage(props: any) {
  const { location } = props
  const classes = useStyles();
  const [active, setActive] = useState<number>(0);
  const { user } = useContext(APIRequestContext);

  const tcmArray = useMemo(() => getVariablesByRoleAndTypeAndViews(DeviceType.T, user), [user])

  const mcmArray = useMemo(() => getVariablesByRoleAndTypeAndViews(DeviceType.M, user).map(v => v.concat("-mcm")), [user])



  useEffect(() => {
    setActive(location.pathname.split('/')[2] ? routesAlarms[location.pathname.split('/')[2]] : 0);
  }, [location]);

  return (
    <div style={{ transform: "translate(0px, -16px)", height: "100%" }}>
      <SubNavBar buttons={buttons} active={active} setActive={setActive}>

      </SubNavBar>
      <ScrollableContent>
        <div className={classes.mainBox}>
          {active === 0 && <HelpList
            title={'TCM'}
            subtitle={'tcmSubtitle'}
            variablesDescriptions={tcmArray} />}
          {active === 1 && <HelpList
            title={'MCM'}
            subtitle={'mcmSubtitle'}
            variablesDescriptions={mcmArray} />}
          {/* {active === 2 && <HelpList
            title={'lptcm'}
            subtitle={'lptcmSubtitle'}
            variablesDescriptions={lptcmArray} />}
          {active === 3 && <HelpList
            title={'icm'}
            subtitle={'icmSubtitle'}
            variablesDescriptions={icmArray} />} */}
        </div>
      </ScrollableContent>
    </div>
  );
}