
import React, { useContext } from 'react';
import JustGraph from '../../../components/device/middle/JustGraph';
import { EntitiesContext } from "../../../contexts/entities-context";

export default function ExcitationCurrent(props: React.PropsWithChildren<{}>) {
  const { deviceMainHistory } = useContext(EntitiesContext);
  return (
    <JustGraph
          graphType={'linegl'}
          ys={[[
            deviceMainHistory ? deviceMainHistory['iexc-0'] : undefined,
            deviceMainHistory ? deviceMainHistory['iexc-1'] : undefined,
            deviceMainHistory ? deviceMainHistory['iexc-2'] : undefined,
          ]]}
          ysnames={[["iexc-0", "iexc-1", "iexc-2"]]}
          colors={[['#e23434', '#2aa060', '#3473ba']]}
          titley={["currentUnits"]}
          titlex={''}
          title={'excitation'}
          x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
          emptyMessage={'no7days'}
          enableReportLinks
        ></JustGraph>
  );
}






