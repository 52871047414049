import { withWidth } from '@material-ui/core';
import React, { useState } from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { MCMpermissions } from '../../utils/mcm-functions';
import McmInfoDialog from './dialogs/McmInfoDialog';
import ElectricParam from './tabs/ElectricParam';
import GlobalStatus from './tabs/GlobalStatus';
import Harmonics from './tabs/Harmonics';
import InstantAnalysis from './tabs/InstantAnalysis';
import LoadDiagram from './tabs/LoadDiagram';
import MechanicalParam from './tabs/MechanicalParam';
import PowerQuality from './tabs/PowerQuality';
import SingleReport from './tabs/SingleReport';
import SpectralAnalysis from './tabs/SpectralAnalysis';
import VectorGraph from './tabs/VectorGraph';
import Waveforms from './tabs/Waveforms';

interface MCMPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}

const variables = ["ldratio", "ppv-0", "ppv-1", "ppv-2", "iv-0", "iv-1", "iv-2", "unbi-0", "unbppv-0", "thdppv-0", "thdppv-1", "thdppv-2", "thdi-0", "thdi-1", "thdi-2", "iexc-0", "iexc-1", "iexc-2", "iexc-3", "cosphi-0", "actpwr-0",
  'sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'] as const //baixo diag, cima control
const variables2 = (variables as any)
variables2.unshift('timestamp')
type MUV = typeof variables[number];
type time = { 'timestamp': string[] }
export type McmUsableVariables = time & {
  [K in MUV]: Array<number>;
}

export default withWidth()(function MCMPage(props: MCMPageProps) {
  const { location } = props;
  const [hasV, setHasV] = useState<boolean>(true);

  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    return result;
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('V'))) setHasV(false);
    return result;
  }

  return (
    <MainDeviceDashboard
      location={location}
      deviceType={DeviceType.M}
      processingAffectingContexts={processingAffectingContexts}
      processingGeneral={processingGeneral}
      DeviceInfoDialog={McmInfoDialog}
      variables={variables2}
      controlInfoBar={{ element: <ControlInfoBar maxSFCount={5}></ControlInfoBar>, tabCode: "004" }}
      controlComponents={[
        {
          element: <ElectricParam hasV={hasV}></ElectricParam>,
          title: "electric_param"
        }, {
          element: <MechanicalParam></MechanicalParam>,
          title: "mechanical_param"
        }, {
          element: <LoadDiagram></LoadDiagram>,
          title: "load_diagram"
        }, {
          element: <PowerQuality hasV={hasV}></PowerQuality>,
          title: "power_quality"
        }, {
          element: <VectorGraph hasV={hasV}></VectorGraph>,
          title: "vector_diagram"
        }, {
          element: <Waveforms hasV={hasV}></Waveforms>,
          title: "waveforms"
        }
      ].filter(v => hasV || (!hasV && v.title !== "mechanical_param"))
        .map(v => ({ ...v, ...{ tabCode: MCMpermissions[(v.title as keyof typeof MCMpermissions)] } }))}
      diagnosticComponents={[
        {
          element: <GlobalStatus></GlobalStatus>,
          title: "global_status"
        }, {
          element: <SpectralAnalysis hasV={hasV}></SpectralAnalysis>,
          title: "spectral_analysis"
        }, {
          element: <InstantAnalysis hasV={hasV}></InstantAnalysis>,
          title: "instant_analysis"
        }, {
          element: <Harmonics hasV={hasV}></Harmonics>,
          title: "harmonics"
        }
      ].map(v => ({ ...v, ...{ tabCode: MCMpermissions[(v.title as keyof typeof MCMpermissions)] } }))}
      historyComponents={[
        {
          element: <HistoryPage type={DeviceType.M}></HistoryPage>,
          title: "trendAn"
        },
        {
          element: <ReportViewer>
            {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined }) => <SingleReport hasV={hasV} reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} />}
          </ReportViewer>,
          title: "sampleAn"
        },
      ]}
    ></MainDeviceDashboard>);
})