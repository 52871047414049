import { Button, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrganizationList } from '../../../interfaces/Organization';
import { CurrentUser, Roles, User, UserForm } from '../../../interfaces/User';
import WarningIcon from '@material-ui/icons/Warning';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between"
    },
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%",
      margin: 8
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    stepperRoot: {
      padding: `0 0 ${theme.spacing(1)}px 0`
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    formPanel: {
      height: "60vh"
    },
    formPanelSee: {
      height: "60vh",
      overflowY: 'auto',
      display: 'grid',
      textAlign: "center",
      // alignContent: "space-evenly",
    },
    formAction: {
      textAlign: "right"
    },
    noneInfo: {
      textAlign: 'center',
      paddingTop: 44
    }
  }),
);

interface UserListProps {
  org: OrganizationList | undefined,
  users: (UserForm | User)[] | undefined,
  currentUser: CurrentUser,
  editAction: (user: any) => any,
  activeAssuranceMenu: number,
  setActiveAssuranceMenu: React.Dispatch<React.SetStateAction<number>>,
  deleteUser: (user: User) => void,
  handleClose: () => void,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function UserList(props: UserListProps) {
  const { org, users, currentUser, editAction, activeAssuranceMenu, setActiveAssuranceMenu, deleteUser, handleClose, setActiveStep } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (<><div className={classes.formPanelSee}>
    <h3>{org?.name}</h3>
    {users && users
      .filter(usr => usr.organization_id === org?._id && ((usr.role === Roles.ADMIN && currentUser && currentUser.role < Roles.ORGADMIN) || (usr.role !== Roles.ADMIN)))
      .map((user, index) => <div key={`user-${index}`}>
        {user && (user as User).pexp && (new Date((user as User).pexp)) < (new Date()) && <Tooltip style={{ paddingRight: 4, transform: "translateY(6px)" }} title={`${intl.formatMessage({ id: 'admin15DayWarning' })}`}><WarningIcon color={"error"} /></Tooltip>}
        {user && (user as User).fail_attempts !== undefined && (user as User).fail_attempts > 4 && <Tooltip style={{ paddingRight: 4, transform: "translateY(6px)" }} title={`${intl.formatMessage({ id: 'admin5TriesWarning' })}`}><LockIcon color={"error"} /></Tooltip>}
        {user.name} - {user.email}
        {(user as User)._id && user.role === Roles.USER && <Tooltip style={{ minWidth: 40 }} title={`${intl.formatMessage({ id: 'edit' })}`}>
          <Button onClick={(event: any) => editAction(user)}>
            <EditIcon color={"primary"} />
          </Button>
        </Tooltip>}
        {(user as User)._id && user.role === Roles.USER && (index !== activeAssuranceMenu) && <Tooltip style={{ minWidth: 40 }} title={`${intl.formatMessage({ id: 'delete' })}`}>
          <Button onClick={(event: any) => { setActiveAssuranceMenu(index) }}>
            <DeleteIcon color={"primary"} />
          </Button>
        </Tooltip>}
        {(user as User)._id && user.role === Roles.USER && (index === activeAssuranceMenu) && <>
          <Tooltip style={{ minWidth: 40 }} title={`${intl.formatMessage({ id: 'cancel' })}`}>
            <Button onClick={(event: any) => { setActiveAssuranceMenu(-1) }}>
              <CancelIcon htmlColor='red' />
            </Button>
          </Tooltip>
          <Tooltip style={{ minWidth: 40 }} title={`${intl.formatMessage({ id: 'confirm' })}`}>
            <Button onClick={(event: any) => deleteUser(user as unknown as User)}>
              <CheckIcon htmlColor='red' />
            </Button>
          </Tooltip>
        </>}
      </div>)}
  </div>
    <div className={classes.formAction}>
      <Button onClick={handleClose} className={classes.backButton}><FormattedMessage id="cancel" /></Button>
      <Button variant="contained" color="primary" onClick={() => setActiveStep(1)}><FormattedMessage id="next" /></Button>
    </div>
  </>)
}