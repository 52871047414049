import { isWidthDown, withWidth } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/ErrorOutlineTwoTone';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import clsx from 'clsx';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { SocketContext } from '../../contexts/socket-context';

const useStyles = () => {
  return makeStyles((theme) => ({
    connected: {
      color: theme.palette.success.main
    },
    disabled: {
      color: theme.palette.warning.main
    },
    closed: {
      color: theme.palette.error.main
    },
    container: {
      transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: 4,
      minWidth: 57,
      padding: "6px 8px",
      position: "relative",
      alignItems: "center",
      display: "inline-flex",
      justifyContent: "center",
    },
    noShow: {
      display: "none"
    }
  }))();
}

export default withWidth()(function SocketMenu(props: any) {
  const classes = useStyles();
  const { state } = useContext(SocketContext);
  const intl = useIntl();

  const getIcon = () => {
    switch (state) {
      case WebSocket.OPEN: return <Tooltip title={intl.formatMessage({ id: "socketConnected" })} aria-label="add">
        <Badge badgeContent={<ImportExportIcon style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.26)" }} />}>
          <FiberManualRecordIcon height={20} width={18.52} className={classes.connected} />
        </Badge>
      </Tooltip>;
      case WebSocket.CLOSED:
      case WebSocket.CLOSING: return <Tooltip title={intl.formatMessage({ id: "socketDisconnected" })} aria-label="add">
        <Badge badgeContent={<ErrorIcon style={{ fontSize: "1rem", color: "rgba(255, 0, 0, 0.80)" }} />} >
          <FiberManualRecordIcon height={20} width={18.52} className={classes.closed} />
        </Badge>
      </Tooltip>;
      case WebSocket.CONNECTING: return <Tooltip title={intl.formatMessage({ id: "socketReconnecting" })} aria-label="add">
        <Badge badgeContent={<AutorenewIcon style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.80)" }} />} >
          <FiberManualRecordIcon height={20} width={18.52} className={classes.disabled} />
        </Badge>
      </Tooltip>;
      default: return undefined;
    }
  }

  return (
    <div className={clsx(classes.container, isWidthDown('xs', (props).width) && classes.noShow)}>
      {getIcon()}
    </div>
  );
})