import React, { PropsWithChildren } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';


interface CommonTimeProps {
  val: string,
  ms?: boolean,
  separate?: boolean,
  justTime?: boolean
}

export default function CommonTime(props: PropsWithChildren<CommonTimeProps>) {
  const { val, ms, separate, justTime } = props

  return (<React.Fragment>
    {separate && ms && <div><FormattedTime value={new Date(val ? val : 0)} timeStyle={'medium'} /></div>}
    {separate && !ms && <div><><FormattedTime value={new Date(val ? val : 0)} /> </></div>}
    {!separate && ms && <FormattedTime value={new Date(val ? val : 0)} timeStyle={'medium'} />}
    {!separate && !ms && <><FormattedTime value={new Date(val ? val : 0)} /> </>}
    {separate && !justTime && <div><FormattedDate year={"2-digit"} month="numeric" day="numeric" value={new Date(val ? val : 0)} /></div>}
    {!separate && !justTime && <FormattedDate year={"2-digit"} month="numeric" day="numeric" value={new Date(val ? val : 0)} />}
  </React.Fragment>
  );
}