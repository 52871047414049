import { Fade, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, useTheme, withStyles } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import SignalCellularConnectedNoInternet4BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet4Bar';
import SignalCellularOffIcon from '@material-ui/icons/SignalCellularOff';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DeviceList, DeviceListExpanded, DeviceStatus, DeviceType } from '../../interfaces/Device';
import { deviceTypeToRouteString, fullSFSort, sfNewFormat } from '../../utils/format-data';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      animation: `enter 500ms ${theme.transitions.easing.easeInOut}` //animacoes no index.css
    },
    outerTable: {
      marginLeft: "8px",
      marginRight: "8px",
      width: "calc(100% - 16px)",
      maxHeight: "100%",
      overflowY: "auto",
      borderRadius: "10px",
      marginBottom: 20
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    circle: {
      marginRight: "5px",
      height: "15px",
      width: "15px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    },
    ballCell: {
      alignItems: "center",
      display: "flex"
    },
    link: {
      textDecoration: "unset",
      color: "black"
    }
  }),
);

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "12.5%"
    },
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontSize: 12
    },
    body: {
      fontSize: 14,
      color: theme.palette.common.black,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
    },
  }),
)(TableRow);

const StyledTablePagination = withStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: "30px !important"
    }
  }),
)(TablePagination);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof DeviceList;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: true, label: 'name' },
  { id: 'status', numeric: true, disablePadding: false, label: 'status' },
  { id: 'type', numeric: true, disablePadding: false, label: 'type' },
  { id: 'local', numeric: true, disablePadding: false, label: 'local' },
  { id: 'last_report', numeric: true, disablePadding: false, label: 'lastReport' }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DeviceList) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof DeviceList) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={`${index}-${headCell.label}-${headCell.id}`}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={index === 0 ? { width: "50%" } : {}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <FormattedMessage id={headCell.label} />
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  //console.log(a, b, orderBy)
  if (orderBy === "local") {
    if ((b as unknown as DeviceList).local < (a as unknown as DeviceList).local) {
      return -1;
    }
    if ((b as unknown as DeviceList).local > (a as unknown as DeviceList).local) {
      return 1;
    }
    return 0;
  }
  if (orderBy === "last_report") {
    const a1 = (a as unknown as DeviceList)
    const b1 = (b as unknown as DeviceList)
    if (b1.last_report && !a1.last_report) {
      return 1
    } else
      if (a1.last_report && !b1.last_report) {
        return -1
      } else
        if (!b1.last_report && !a1.last_report) {
          return 0
        }
    if ((b1.last_report ? b1.last_report.timestamp : 0) < (a1.last_report ? a1.last_report.timestamp : 0)) {
      return -1;
    } else
      if ((b1.last_report ? b1.last_report.timestamp : 0) > (a1.last_report ? a1.last_report.timestamp : 0)) {
        return 1;
      }
    return 0;
  }
  if (orderBy === "organization_name") {
    //console.log('\n\nstart')
    return fullSFSort((a as unknown as DeviceListExpanded), (b as unknown as DeviceListExpanded))
  }
  if (orderBy === "status") {
    if (((a as unknown as DeviceList).status === DeviceStatus.PRODUCTION && (b as unknown as DeviceList).status === DeviceStatus.INSTALLING)
      || ((a as unknown as DeviceList).status === DeviceStatus.INSTALLING && (b as unknown as DeviceList).status === DeviceStatus.PRODUCTION)) {
      return (a as unknown as DeviceList).status > (b as unknown as DeviceList).status ? 1 : (a as unknown as DeviceList).status === (b as unknown as DeviceList).status ? 0 : -1
    } else {
      return (b as unknown as DeviceList).status > (a as unknown as DeviceList).status ? 1 : (a as unknown as DeviceList).status === (b as unknown as DeviceList).status ? 0 : -1
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    //console.log((a[0] as any).name, (a[0] as any).last_report?.sf, (b[0] as any).name, (b[0] as any).last_report?.sf, order)
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface DashboardTableProps {
  list: Array<any>; //TODO FIX LATER
}


export default function DashboardTable(props: DashboardTableProps) {
  const classes = useStyles();
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const defaultRows = localStorage.getItem("defaultRows");
  if (!defaultRows) localStorage.setItem("defaultRows", "15");
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRows ? parseInt(defaultRows) : 15);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof DeviceList>('organization_name');
  const [open, setOpen] = useState(true);
  const [showing, setShowing] = useState<Array<any>>([]);
  const intl = useIntl();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("defaultRows", event.target.value)
    setPage(0);
  };

  const formatType = (type: string): string => {
    return type === DeviceType['M'] ? 'MCM' :
      type === DeviceType['L'] ? 'LPTCM' :
        type === DeviceType['Ld'] ? 'LPTCM' :
          type === DeviceType['L3'] ? 'LPTCM' :
            type === DeviceType['L4'] ? 'LPTCM' :
              type === DeviceType['L5'] ? 'LPTCM' :
                type === DeviceType['T'] ? 'TCM' :
                  type === DeviceType['I'] ? 'ICM' :
                    type === DeviceType['Gi'] ? 'GCM' :
                      type === DeviceType.Td ? 'TCM' :
                        type === DeviceType["Gs"] ? "GCM" :
                          type === DeviceType["Gc"] ? "GCM" :
                            type === DeviceType["Gm"] ? "GCM" :
                              'NOT DEFINED';
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DeviceList) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setOpen(true)
    return () => {
      setOpen(false);
    }
  }, [])

  useEffect(() => {
    const timeout = setInterval(() => { setPage(x => (x + 1) * rowsPerPage >= list.length ? 0 : x + 1) }, 30000) // 10 min = 600000   /30000 ms
    return () => {
      clearInterval(timeout);
    }
  }, [list.length, rowsPerPage, page, order, orderBy])

  useEffect(() => { setPage(0) }, [list])

  useEffect(() => {
    setShowing(stableSort(list, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }, [list, order, orderBy, page, rowsPerPage, setShowing])

  const circleWithLabel = (sf: sfNewFormat) => {
    return <Tooltip key={`circleDashm-${sf.name}-${sf.level}`} title={intl.formatMessage({ id: (sf.name ? sf.name : "severity_factor") })}><span className={classes.circle} style={{ backgroundColor: sf.color }} /></Tooltip>
  }

  const makeStatus = (status: DeviceStatus) => {
    if (status === DeviceStatus.INACTIVE) return <Tooltip title={`${intl.formatMessage({ id: 'inactive' })}`}><SignalCellularOffIcon htmlColor={'grey'}></SignalCellularOffIcon></Tooltip>
    else if (status === DeviceStatus.PRODUCTION) return <Tooltip title={`${intl.formatMessage({ id: 'sending' })}`}><SignalCellular4BarIcon htmlColor={'#2aa060'} /></Tooltip>
    else if (status === DeviceStatus.NOTSENDING) return <Tooltip title={`${intl.formatMessage({ id: 'notsending' })}`}><SignalCellularConnectedNoInternet0BarIcon htmlColor={'#e23434'} /></Tooltip>
    else if (status === DeviceStatus.INSTALLING) return <Tooltip title={`${intl.formatMessage({ id: 'installing' })}`}><SignalCellularConnectedNoInternet4BarIcon htmlColor={'#2aa060'} /></Tooltip>
    else if (status === DeviceStatus.NOTPROCESSING) return <Tooltip title={`${intl.formatMessage({ id: 'notprocessing' })}`} placement="right">
      <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path color="rgb(219, 200, 26)" fillOpacity=".4" d="M22 8V2L2 22h16V8z"></path>
        <path color="rgb(230, 74, 25)" d="M20 22h2v-2h-2v2zm0-12v8h2v-8h-2z"></path>
      </svg>
    </Tooltip>;
    else if (status === DeviceStatus.DESTROYED) return <Tooltip title={`${intl.formatMessage({ id: 'destroyed' })}`} placement="right">
      <HighlightOffIcon style={{ color: "#bdbdbd" }} />
    </Tooltip>;
  }

  /*                                                                                               ---------------------------FALTA LCOALE PARA TEMPO  */
  return (
    <Fade in={open}>
      <TableContainer component={Paper} className={classes.outerTable}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>
            {showing
              .map((row, index) => {
                const rowTyped = row as unknown as DeviceListExpanded
                return (
                  <StyledTableRow key={`table-row-${index}`}>
                    <StyledTableCell component="th" scope="row">
                      <div className={classes.ballCell}>
                        {rowTyped.sfGroup?.sfs.map((sfOneAtATime, i) => (circleWithLabel(sfOneAtATime)))}
                        <Link className={classes.link} to={`/${deviceTypeToRouteString(rowTyped.type)}/${row._id}`}>{rowTyped.name}</Link>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="right">{makeStatus(rowTyped.status)}</StyledTableCell>
                    <StyledTableCell align="right">{formatType(rowTyped.type)}</StyledTableCell>
                    <StyledTableCell align="right">{rowTyped?.local}</StyledTableCell>
                    <StyledTableCell align="right">{rowTyped.last_report ? new Date(rowTyped.last_report.timestamp).toLocaleString() : <FormattedMessage id='nodata' />}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <StyledTablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, { label: `${intl.formatMessage({ id: 'all' })}`, value: [5, 10, 15, 20, 25].includes(list.length) ? list.length + 1 : list.length }]}
                colSpan={4}
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fade>
  );
}
