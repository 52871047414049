import { Fade } from "@material-ui/core";
import { default as React, PropsWithChildren, useContext, useEffect, useState } from "react";
import { ToastContext } from "./toast-context";
import AlertInfoDialog from "../components/alarms/AlertInfoDialog";
import APIRequestContext from '../contexts/api-context';
import { EntitiesContext } from "../contexts/entities-context";
import { SocketContext } from "../contexts/socket-context";
import { received, receivedExtended } from "../interfaces/Alerts";
import { PageResponse } from "../interfaces/Responses";
import { Roles, User } from "../interfaces/User";
import { WsMessage } from "../interfaces/Websocket";
import { receivedToExtended } from "../pages/alert/AlertReceived";
import { calculateSFLevel } from "../utils/format-data";


export const AlarmContext = React.createContext<{
  alerts: receivedExtended[],
  notAcknowledged: received[],
  setAlerts: (data: Array<receivedExtended>) => void;
  setNotAcknowledged: (data: Array<received>) => void;
  openAlertDialog: (alarm: received, handleVerify?: (alert_id: string, id: string, email: string) => void) => void;
}>({
  alerts: [],
  notAcknowledged: [],
  setAlerts: (data: Array<receivedExtended>) => { },
  setNotAcknowledged: (data: Array<received>) => { },
  openAlertDialog: (alarm, handleVerify) => { },
})


export function AlertComponent(props: PropsWithChildren<{}>) {
  const { children } = props;
  const { apiRequest, user } = useContext(APIRequestContext);
  const { devices } = useContext(EntitiesContext);
  const [alert, setAlert] = useState<received>();
  const [users, setUsers] = useState<User[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [handleVerifyExterior, setHandleVerifyExterior] = useState<any>();
  const [notAcknowledged, setNotAcknowledged] = useState<Array<received>>([]);
  const [alerts, setAlerts] = useState<receivedExtended[]>([])
  const { displayToast } = useContext(ToastContext);
  const { setDeviceReport, setDeviceWaveforms, setDevices, deviceFull, setDeviceFull } = useContext(EntitiesContext);
  const { socket } = useContext(SocketContext);



  useEffect(() => {
    // console.log("waiting for messages", socket)
    if (socket) {
      socket.onmessage = (event) => {
        const data: WsMessage = JSON.parse(event.data);
        // console.log('\n---message:', event, data)
        if (data.type === 'report') {
          const report = data.data;
          // console.log("check", deviceFull?._id, report.device_id)
          if (deviceFull?._id === report.device_id) {
            // console.log(new Date(deviceFull.last_report!.timestamp).getTime(), new Date(report.timestamp).getTime());
            if (!deviceFull.last_report || (new Date(deviceFull.last_report.timestamp).getTime()) < (new Date(report.timestamp).getTime())) {
              //CHANGE LAST REPORT FROM deviceFull
              const c = deviceFull;
              deviceFull.last_report = {
                id: report._id,
                timestamp: (report.timestamp as unknown as Date),
                sf: report.diagnostic.sF,
                at: new Date(),
                level: calculateSFLevel(c.severity_param, report.diagnostic.sF)
              }
              setDeviceFull(c)
              setDeviceReport(report)
              setDeviceWaveforms(report.waveforms)
              document.title = document.title.startsWith('* ') ? document.title : '* ' + document.title
            }

          }
        } else if (data.type === 'dashboard') {
          const report = data.data;
          const d = [...devices];
          //console.log('dashboard(ws)-',devices, report)
          d.forEach((device, index, arr) => {
            if (arr[index]._id === report.device_id) {
              //console.log(new Date(arr[index].last_report!.timestamp).getTime(), new Date(report.timestamp).getTime());
              if (!arr[index].last_report || (new Date(arr[index].last_report!.timestamp)).getTime() < (new Date(report.timestamp).getTime()))
                //console.log("in")
                arr[index].last_report = {
                  id: report._id,
                  timestamp: report.timestamp,
                  sf: report.sf,
                  level: calculateSFLevel(deviceFull ? deviceFull.severity_param : {
                    sF_limit1: 1,
                    sF_limit2: 2,
                    ld_limit1: 1,
                    ld_limit2: 2,
                  }, report.sf),
                  at: report.timestamp
                }
            }
          });
          //console.log('dashboard(ws)[depois]-',d)
          setDevices(d)
          document.title = document.title.startsWith('* ') ? document.title : '* ' + document.title
        } else if (data.type === 'occurrence') {
          const alarm = data.data;
          displayToast("info", alarm.device.name, alarm.alarm.message)
          document.title = document.title.startsWith('* ') ? document.title : '* ' + document.title
          // WATING FOR UPDATE
          var copyAlerts = alerts;
          var copyNotAcknowledged = notAcknowledged;
          copyAlerts.unshift(receivedToExtended(alarm, devices))
          copyNotAcknowledged.unshift(alarm)
          // console.log('occurrence', copyAlerts, copyNotAcknowledged, receivedToExtended(alarm, devices), alarm)
          setAlerts(copyAlerts)
          setNotAcknowledged(copyNotAcknowledged)
        }
      }
    }
    return () => {
      if (socket?.onmessage) {
        socket.onmessage = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, devices, notAcknowledged, setNotAcknowledged, setAlerts, socket, deviceFull])



  const openAlertDialog = (alarm: received, handleVerify?: (alert_id: string, id: string, email: string) => void) => {
    setAlert(alarm)
    if (handleVerify) setHandleVerifyExterior(handleVerify)
    setOpen(true)
  }

  const handleClose = () => {
    setAlert(undefined)
    setOpen(false)
  }

  const updateAlerts = (updated: received) => {
    var c = alerts;
    c.map(alert => {
      var a2 = alert;
      if (updated._id === a2._id) {
        a2.acknowledged_user = updated.acknowledged_user
        a2.acknowledged_email = updated.acknowledged_user ? updated.acknowledged_user.email : ''
      }
      return a2
    })
    setAlerts(c)
  }

  const updateNotAcknowledged = (updated: received) => {
    var c = notAcknowledged;
    c.splice(c.findIndex(c2 => c2._id === updated._id), 1)
    setNotAcknowledged(c)
  }

  const handleVerify = (alert_id: string, id: string, email: string) => {
    if (alert) {
      const tempAlert: received = { ...alert, acknowledged_user: { email: email, id: id } }
      if (handleVerifyExterior) handleVerifyExterior(alert_id, id, email) // idk what this is, i think its nothing
      setAlert(tempAlert);
      updateAlerts(tempAlert);
      updateNotAcknowledged(tempAlert);
      setOpen(false)
    }
  }

  useEffect(() => {// FIX LOGIN GIVES ERROR
    if (user && user.name) {
      var isSubscribed = true
      //console.log("LOGGERZ: alertcomponent")
      if (user.role === Roles.ORGADMIN || user.role === Roles.ADMIN) apiRequest<PageResponse<User>>("GET", "api/v1/user")
        .then(result => { if (isSubscribed) setUsers(result.values); })
        .catch(error => { });
    }
    return () => {
      isSubscribed = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])


  //title={intl.formatMessage({ id: t.title })}
  //text={intl.formatMessage({ id: t.text })}

  return (
    <AlarmContext.Provider value={{ openAlertDialog, alerts, setAlerts, notAcknowledged, setNotAcknowledged }}>
      {children}
      {alert && <Fade>
        <AlertInfoDialog
          devices={devices}
          alarm={alert}
          handleVerify={handleVerify}
          users={users}
          handleClose={handleClose}
          open={open}
        />
      </Fade>}
    </AlarmContext.Provider>);
}