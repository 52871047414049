
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import JustGraph from '../../../components/device/middle/JustGraph';
import { EntitiesContext } from "../../../contexts/entities-context";


export default function TransformRatio(props: React.PropsWithChildren<{ sideNames: string[] }>) {
  const { deviceMainHistory, deviceFull } = useContext(EntitiesContext);
  const { sideNames } = props;
  const sideAddNumber = 4 * (sideNames.length > 2 ? sideNames.length > 3 ? sideNames.length > 4 ? sideNames.length > 5 ? 4 : 3 : 2 : 1 : 0);
  const intl = useIntl()

  const getClosest = (value: any, upOrDown: string, arrayToLook: Array<any>) => {
    var onepercent = arrayToLook[0] - arrayToLook[1];
    //console.log(onepercent)
    if (!onepercent || onepercent < 0) onepercent = 0.01
    else onepercent = onepercent / 8
    let ret = 0;
    if (arrayToLook.length === 0) {
      return 0
    }
    if (upOrDown === 'down') {
      let i = 0;
      while (arrayToLook[i] > value) {
        ret = arrayToLook[i]
        i++;
      }
      //console.log('ret', ret, '\nvalue', value, '\narray', arrayToLook, '\narray[i]', arrayToLook[i])
      if (ret === 0) {
        return value - onepercent;
      } else {
        if (arrayToLook[i]) {
          return arrayToLook[i] - onepercent;
        }
        if (ret < value) {
          return ret - onepercent;
        } else {
          return value - onepercent
        }
      }
    }
    if (upOrDown === 'up') {
      let i = 0;
      while (arrayToLook[i] > value) {
        ret = arrayToLook[i]
        i++;
      }
      if (ret === 0) {
        return value + onepercent;
      } else {
        return ret + onepercent;
      }
    }
  }
  const minArray = (arr: Array<number>) => {
    var f = arr ? arr.find(v => v !== null) : -1;
    f = f ? f : 0
    return arr ? arr.reduce((min, currentValue) => currentValue !== null ? min < currentValue ? min : currentValue : min, f) : -1;
  }
  const maxArray = (arr: Array<number>) => arr ? arr.reduce((max, currentValue) => Math.max(max, currentValue), arr[0]) : -1;

  const d = deviceFull ? deviceFull.nameplate.side ? ((deviceFull.nameplate.side[0].Tap[0][1] - deviceFull.nameplate.side[0].Tap[1][1]) /
    (2 * deviceFull.nameplate.side[1].Un)) : undefined : undefined;   // VER FORMULA ESTIMA 
  // ESTE NAO FUNCIONA COM OS LADOS TROCADOS COMO NO TCM
  const lines = deviceFull && deviceFull.nameplate.side && deviceMainHistory !== undefined ? (deviceFull.nameplate.side[0].Tap.map((tapPos) => {
    return {
      xref: 'x',
      yref: 'y',
      x0: deviceMainHistory.timestamp ? new Date(deviceMainHistory.timestamp[0]) : [],
      y0: tapPos[1] / deviceFull.nameplate.side[1].Un - d!,  // RESTO DA FORMUAL ESTIMA
      x1: deviceMainHistory.timestamp ? new Date(deviceMainHistory.timestamp[deviceMainHistory.timestamp.length - 1]) : [],
      y1: tapPos[1] / deviceFull.nameplate.side[1].Un - d!,  // RESTO DA FORMUAL ESTIMA
      line: {
        color: 'rgb(169, 169, 169)',
        width: 1,
        dash: 'dot'
      }
    }
  })) : undefined;

  const roughAverageDifference = // difference between lines
    deviceFull ? deviceFull.nameplate.side ? (((deviceFull.nameplate.side[0].Tap[0][1] / deviceFull.nameplate.side[1].Un - d!) +
      (deviceFull.nameplate.side[0].Tap[1][1] / deviceFull.nameplate.side[1].Un - d!)) /
      2 - (deviceFull.nameplate.side[0].Tap[0][1] / deviceFull.nameplate.side[1].Un - d!) + 0.004) : undefined : undefined;

  const annot = deviceFull ? deviceFull.nameplate.side ? (deviceFull.nameplate.side[0].Tap.map((tapPos) => {
    return {
      xref: 'paper',
      yref: 'y',
      x: 0,
      y: tapPos[1] / deviceFull.nameplate.side[1].Un - d! - roughAverageDifference!,
      text: '<b>' + intl.formatMessage({ id: 'tap', defaultMessage: '', description: '' }) + ' ' + tapPos[0] + '</b>',
      xanchor: 'left',
      yanchor: 'bottom',
      showarrow: false,
      opacity: 0.30,
      font: {
        size: 16,
        color: 'black'
      }
    }
  })) : undefined : undefined;
  //seria rt-11 e rt-15

  return (
    <JustGraph
      graphType={'linegl'}
      ys={[[
        deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : undefined,
      ]]}
      ysnames={[[(`rt-${7 + sideAddNumber}` as 'rt-7')]]}
      colors={[['red']]}
      titley={["transformRatio"]}
      title={'transformRatio'}
      x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
      yrange={[
        getClosest(minArray(deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : []), 'down', lines ? lines.map((value) => value.y0) : []),
        getClosest(maxArray(deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : []), 'up', lines ? lines.map((value) => value.y0) : [])
      ]}
      shapes={lines}
      annotations={annot}
      titlex={''}
      emptyMessage={'no7days'}
      enableReportLinks
    ></JustGraph>
  );
}



/*
const d = (device.nameplate.side[0].Tap[0][1] - device.nameplate.side[0].Tap[1][1]) /
(2 * device.nameplate.side[1].Un)   // VER FORMULA ESTIMA

const offset = 0;  // TODO CHANGE LATER WHEN MORE THINGS ARE ADDED

const lines = device.nameplate.side[0].Tap.map((tapPos) => {
return {
    xref: 'x',
    yref: 'y',
    x0: data.history['timestamp'][0],
    y0: tapPos[1] / device.nameplate.side[1].Un - d + offset,  // RESTO DA FORMUAL ESTIMA
    x1: data.history['timestamp'][data.history['timestamp'].length - 1],
    y1: tapPos[1] / device.nameplate.side[1].Un - d + offset,  // RESTO DA FORMUAL ESTIMA
    line: {
        color: 'rgb(169, 169, 169)',
        width: 1,
        dash: 'dot'
    }
}
});
const roughAverageDifference = // difference between lines
((device.nameplate.side[0].Tap[0][1] / device.nameplate.side[1].Un - d) +
    (device.nameplate.side[0].Tap[1][1] / device.nameplate.side[1].Un - d)) /
2 - (device.nameplate.side[0].Tap[0][1] / device.nameplate.side[1].Un - d) + 0.004

const annot = device.nameplate.side[0].Tap.map((tapPos) => {
return {
    xref: 'paper',
    yref: 'y',
    x: 0,
    y: tapPos[1] / device.nameplate.side[1].Un - d - roughAverageDifference + offset,
    text: '<b>' + this.translate.instant('tcm.diag.tap') + tapPos[0] + '</b>',
    xanchor: 'left',
    yanchor: 'bottom',
    showarrow: false,
    opacity: 0.30,
    font: {
        size: 16,
        color: 'black'
    }
}
});
device.transform_ratio_graph = {
graph: [
    {
        x: data.history['timestamp'],
        y: t_ratio_current,
        type: 'lines',
        name: this.translate.instant('tcm.diag.transformation-ratio-current'),
        marker: { color: 'red' }
    }
],
layout: {
    yaxis: {
        title:`${this.translate.instant('tcm.diag.transformation-ratio')}`,
        range: [
            this.getClosest(this.minArray(t_ratio_current), 'down', lines.map((value) => value.y0)),
            this.getClosest(this.maxArray(t_ratio_current), 'up', lines.map((value) => value.y0))
        ]
    },
    margin: {
        t: 20,
        l: 70,
        r: 50,
        b: 50,
        pad: 5
    },
    font: { family: 'Titillium Web, "Helvetica Neue", sans-serif' },
    shapes: lines,
    annotations: annot
}
};*/


