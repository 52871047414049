import { sfGroup } from "../utils/format-data";
import { ReportList, ReportWaveforms } from "./Report";

export interface DeviceList {
  _id: string;
  gateway_id: string;
  name: string;
  type: DeviceType;
  last_report?: {
    id: string;
    timestamp: Date;
    sf: Array<number>;
    level: 0 | 1 | 2;
    at: Date;
  };
  severity_param: {
    sF_limit1: number;
    sF_limit2: number;
    ld_limit1: number;
    ld_limit2: number;
  };
  status: DeviceStatus;
  created_at: Date;
  deleted_at?: Date;
  organization_id: string;
  local: string;
  organization_name?: string;
}

export interface DeviceListExpanded extends DeviceList {
  sfGroup: sfGroup
}

export interface DeviceFull {
  _id: string;
  gateway_id: string;
  type: DeviceType;
  name: string;
  acq_type: string;
  timer: number;
  nameplate: DeviceNameplate;
  config_device: DeviceConfig;
  config_diagnostic: DeviceDiagnostic;
  config_report: DeviceReport;
  severity_param: DeviceSeverity;
  config_param?: any;
  calib_param?: any;
  statistics?: DeviceStatistics;
  last_report?: {
    id: string;
    timestamp: Date;
    sf: Array<number>;
    at: Date;
    level: 0 | 1 | 2;
  };
  status: DeviceStatus;
  switch_cma?: boolean;
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
  local: string;
}

export interface DeviceFullExpanded extends DeviceFull {
  sfGroup: sfGroup
}

export enum DeviceType {
  M = "1", // MCM
  L = "2", // LP-TCM
  Ld = "3", // LP-TCMd
  T = "4", // TCM
  DZ = "5",   // dynz ou dynamic impedance 
  OL = "7", // OLTC ou inrush ou corrente de magnetizacao
  I = "8", // ICM
  Gi = "9", // GCM
  Td = "A",//"10", // TCM duplo sec
  OLd = "B",//"11", // OLTCd
  DZd = "D",//"13" // inrushd
  X = "E", // XCM (Rn)
  Gc = "F", // GCM Conventional
  Gs = "G", // GCM Synchronous
  Gm = "H", // GCM Magnets
  L3 = "I", // lptcm triple sec
  L4 = "J", // lptcm quadruple sec
  L5 = "K", // lptcm quintuple sec
}

// 1 - MCM
// 2 - LP-TCM
// 3 - LP-TCMd
// 4 - TCM => Report (=20000pts)
// 5 - TCM OLTC => Report_OLTC (>20000pts)
// 6 - TCM Learning => Comissioning
// 7 - TCM Inrush
// 8 - ICM
// 9 - GCM
// 10 - A - TCMd => Report (=20000pts)
// 11 - B - TCMd OLTC => Report_OLTC (>20000pts)
// 12 - C - TCMd Learning => Comissioning
// 13 - D - TCMd Inrush
// 14 - E - XCM (Rn)

export enum DeviceStatus {
  INSTALLING,
  PRODUCTION,
  NOTSENDING,
  INACTIVE,
  NOTPROCESSING,
  DESTROYED
}

export interface DeviceConfig {
  acquisition_type: number;
  fs_analog: number;
  pts_analog: number;
  analog_reading_labels: Array<string>;
  analog_reading_index: Array<number>;
  config_analog_channels: Array<{
    primary_side: number;
    secondary_side: number;
    precision_class: string;
    sensor_gain: number;
    sensor_fc: number;
    invert: boolean;
  }>;
  fs_digital: number;
  pts_digitals: number;
  digital_reading_labels: Array<string>;
  digital_reading_index: Array<number>;
  time_tap: number;
  time_betacq: number;
  time_tapstabilize: number;
  array_digitals_bars: Array<
    Array<{
      table_Dig: Array<Array<number>>;
      label_Dig: Array<string>;
    }>
  >;
  array_digitals_action: Array<{
    table_Dig: Array<Array<number>>;
    label_Dig: Array<string>;
    action: Array<Array<string>>;
  }>;
  array_digitals_BCD: Array<{
    table_Dig: Array<Array<number>>;
    label_Dig: Array<string>;
  }>;
}

export interface DeviceStatistics {
  sF: Array<number>;
  varLcc: Array<number>;
  lastTap: number;
  loadRatio: Array<number>;
  tapChanges: number;
  tapOccurrence: Array<number>;
}

export interface DeviceNameplate {
  manufacturer: string;
  type: string;
  frequency: number;
  model: string;
  serial_number: string;
  year: string;
  cooltype: string;
  side_labels: Array<string>;
  side: Array<{
    Sn_ONAN: number;
    Sn_ONAF: number;
    Un: number;
    In: number;
    Ucc: number;
    Icc: number;
    Connection: string;
    Clock_index: number;
    Tap_pos: number;
    Tap: Array<Array<number>>;
    TetaZ: number;
  }>;
  reserve1_labels: Array<Array<string>>;
  reserve1: Array<Array<number>>;
}

export interface DeviceDiagnostic {
  max_lvar: Array<number>;
  lim_Iexc: Array<number>;
  lim_Inoise: Array<number>;
  max_Vvar: Array<number>;
  lim_Vmin: Array<number>;
  theta: number;
  ucc: number;
  side_Iexc: number;
  power_flow: number;
  filter_Iexc: boolean;
  fc_Iexc: number;
  tap_posMeas: boolean;
  tap_posAna: boolean;
  tap_Analog: Array<Array<number>>;
  tap_label: string;
  diag_enable: boolean;
  time_LastTap: number;
}

export interface DeviceReport {
  periods_sample: number;
  pts_sample: number;
}

export interface DeviceSeverity {
  sF_limit1: number;
  sF_limit2: number;
  ld_limit1: number;
  ld_limit2: number;
}

export interface DeviceWaveforms {
  time: string[];
  wDynZ?: string[][];
  wI?: string[][];
  wIexc?: string[][];
  wPNv?: string[][];
  wPPv?: string[][];
  wVPIexc?: string[][];
}

export interface DeviceWebsocket extends ReportList {
  waveforms: ReportWaveforms;
  device_id: string;
}

export interface DeviceDashboard {
  device_id: string;
  timestamp: Date;
  _id: string;
  sf: Array<number>
}