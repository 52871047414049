
import { Button, Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import LongDataBox from '../../../components/device/middle/LongDataBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forHarmonics } from '../../../utils/lptcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateH: {
      gridTemplateAreas: `
          "gr1 gr3"
          "gr2 gr4"`,
      gridTemplateRows: "86.5% 12%",
      gridTemplateColumns: "1fr 1fr",
      maxHeight: "799px",
      height: theme.shape.graphBox.smallest.height + 40,
      gridGap: "6px",
      padding: "10px"
    },
    gridtemplateHnoVoltage: {
      gridTemplateAreas: `
          "gr1"
          "gr2"`,
      gridTemplateRows: "89% 10%",
      gridTemplateColumns: "100%",
      height: theme.shape.graphBox.smallest.height + 40,
      gridGap: "6px",
      padding: "10px"
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    }
  }),
);
//MAYBE I CAN KEEP ALL THE GRAPHS AND JUST CHANGE IF THEY DISPLAY OR NOT

export default withWidth()(function Harmonics(props: React.PropsWithChildren<{ hasV: boolean, sideNames: Array<string> }>) {
  const classes = useStyles();
  const { hasV, sideNames } = props;
  const { deviceFull, deviceReport } = useContext(EntitiesContext);
  const [parityPrimaryCurrent, setParityPrimaryCurrent] = useState<boolean>(false);
  const [parityPrimaryVoltage, setParityPrimaryVoltage] = useState<boolean>(false);
  const [paritySecondaryCurrent, setParitySecondaryCurrent] = useState<boolean>(false);
  const [paritySecondaryVoltage, setParitySecondaryVoltage] = useState<boolean>(false);
  const [paritySecondary2Current, setParitySecondary2Current] = useState<boolean>(false);
  const [paritySecondary2Voltage, setParitySecondary2Voltage] = useState<boolean>(false);
  const data = forHarmonics(deviceReport, sideNames);
  const changeParity = (side: number, current: number) => {
    if (side === 0) {
      if (current === 0) {
        setParityPrimaryCurrent(!parityPrimaryCurrent)
      } else {
        setParityPrimaryVoltage(!parityPrimaryVoltage)
      }
    } else if (side === 1) {
      if (current === 0) {
        setParitySecondaryCurrent(!paritySecondaryCurrent)
      } else {
        setParitySecondaryVoltage(!paritySecondaryVoltage)
      }
    } else {
      if (current === 0) {
        setParitySecondary2Current(!paritySecondary2Current)
      } else {
        setParitySecondary2Voltage(!paritySecondary2Voltage)
      }
    }
  };

  return (
    <Grid container className={classes.SideContainer}>
      {data && data.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={hasV ? classes.gridtemplateH : classes.gridtemplateHnoVoltage} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull!.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={[data[side][0][side === 0 ?
                (parityPrimaryCurrent ? 'even' : 'odd') :
                side === 1 ?
                  (paritySecondaryCurrent ? 'even' : 'odd') :
                  (paritySecondary2Current ? 'even' : 'odd')].y]}
              x={data[side][0][side === 0 ?
                (parityPrimaryCurrent ? 'even' : 'odd') :
                side === 1 ?
                  (paritySecondaryCurrent ? 'even' : 'odd') :
                  (paritySecondary2Current ? 'even' : 'odd')].x}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2'>
            <LongDataBox
              parts={[
                { title: 'Ia1:', value: si_format(data[side][0].one[0], 'A') },
                { title: 'Ib1:', value: si_format(data[side][0].one[1], 'A') },
                { title: 'Ic1:', value: si_format(data[side][0].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
          {hasV && <SubTitledCard gridArea='gr3' title='voltage' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={[data[side][0][side === 0 ?
                (parityPrimaryCurrent ? 'even' : 'odd') :
                side === 1 ?
                  (paritySecondaryCurrent ? 'even' : 'odd') :
                  (paritySecondary2Current ? 'even' : 'odd')].y]}
              x={data[side][0][side === 0 ?
                (parityPrimaryCurrent ? 'even' : 'odd') :
                side === 1 ?
                  (paritySecondaryCurrent ? 'even' : 'odd') :
                  (paritySecondary2Current ? 'even' : 'odd')].x}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>}
          {hasV && <SubTitledCard gridArea='gr4'>
            <LongDataBox
              parts={[
                { title: 'Va1:', value: si_format(data[side][1].one[0], 'V') },
                { title: 'Vb1:', value: si_format(data[side][1].one[1], 'V') },
                { title: 'Vc1:', value: si_format(data[side][1].one[2], 'V') },
              ]}
            ></LongDataBox>
          </SubTitledCard>}
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






