
import { Button, Grid, makeStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import TodayIcon from '@material-ui/icons/Today';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'; // TYPES ESTAO NESTE REPOSITORIO E NAO VEM NO PAKCAGE
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import APIRequestContext from '../../../contexts/api-context';
import { EntitiesContext } from "../../../contexts/entities-context";
import { DeviceType } from '../../../interfaces/Device';
import { HistoryWrapperKeysType, HistoryWrapperType, SelectedVariablesType } from '../../../interfaces/helper';
import { VariablesResponse } from '../../../interfaces/Responses';
import { getVariablesByRoleAndTypeAndViews, translatedSideTextToFinalText, variableValueToFixedVariableValue } from '../../../utils/format-data';
import DownloadCsvDialog from '../final/DownloadCsvDialog';
import HistoryCard from '../final/HistoryCard';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "221px"
  },
  formControlSmall: {
    margin: theme.spacing(1),
    verticalAlign: "bottom"
  },
  pageLegend: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  }
}));

interface HistoryPageProps {
  sideLabels?: Array<string>,
  extra?: boolean,
  type: DeviceType,
}

const addHours = (value: Date, hours: number) => {
  var newDate = new Date()
  newDate.setTime(value.getTime() + hours * 60 * 60 * 1000)
  return newDate
}
const dateToLocal = (value: Date): string => {
  return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
}

const startVars = {
  [DeviceType.T]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1', 'unbpnv-0', 'unbpnv-1']],
  [DeviceType.Td]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1', 'unbpnv-0', 'unbpnv-1']],
  [DeviceType.L]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1']],
  [DeviceType.Ld]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1']],
  [DeviceType.L3]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1']],
  [DeviceType.L4]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1']],
  [DeviceType.L5]: [['sf-0'], ['ldratio'], ['unbi-0', 'unbi-1']],
  [DeviceType.I]: [['sf-0'], ['ppv-3'], ['var-ppv-3', 'unbppv-0']],
  "EXTRAI": [['sf-0', 'sf-1'], ['ldratio'], ['var-ppv-3', 'unbppv-0']],
  [DeviceType.Gi]: [['sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'], ['ldratio'], ['thdppv-3', 'thdi-3']],
  [DeviceType.Gc]: [['sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'], ['ldratio'], ['thdppv-3', 'thdi-3']],
  [DeviceType.Gs]: [['sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'], ['ldratio'], ['thdppv-3', 'thdi-3']],
  [DeviceType.Gm]: [['sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'], ['ldratio'], ['thdppv-3', 'thdi-3']],
  [DeviceType.M]: [['sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'], ['ldratio'], ['thdppv-3', 'thdi-3']],
}
// TODO REMAKE SOMEHOW MAYBE TO BE MORE READABLE
export default function HistoryPage(props: React.PropsWithChildren<HistoryPageProps>) {
  //const { deviceFull } = useContext(EntitiesContext);
  const { sideLabels, type, extra } = props;
  const classes = useStyles();
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const intl = useIntl()
  const { apiRequestSplit, user } = useContext(APIRequestContext);
  const { deviceFull } = useContext(EntitiesContext);
  const deviceType = type === DeviceType.T ? sideLabels && sideLabels.length === 3 ? DeviceType.Td : DeviceType.T : type === DeviceType.L ? sideLabels && sideLabels.length === 3 ? DeviceType.Ld : sideLabels && sideLabels.length === 4 ? DeviceType.L3 : sideLabels && sideLabels.length === 5 ? DeviceType.L4 : sideLabels && sideLabels.length === 6 ? DeviceType.L5 : DeviceType.L : type;
  const [dateInterval, setDateInterval] = useState<[Date, Date]>([addHours(new Date(), -(24 * 7)), new Date()]);
  const [previousDateInterval, setPreviousDateInterval] = useState<[Date, Date]>([new Date(), new Date()]);
  const [fullData, setFullData] = useState<{ [key: string]: Array<string | number> }>({});
  const sideLabelsChanged = sideLabels?.map(s => s.localeCompare('1o') === 0 ? 'Prim' : s.localeCompare('2o') === 0 ? 'Sec' : s.localeCompare('2o1') === 0 ? 'Sec1' : s.localeCompare('2o2') === 0 ? 'Sec2' : s.localeCompare('2o3') === 0 ? 'Sec3' : s.localeCompare('2o4') === 0 ? 'Sec4' : s.localeCompare('2o5') === 0 ? 'Sec5' : s.localeCompare('3o') === 0 ? 'Ter' : s.localeCompare('3o1') === 0 ? 'Ter1' : s.localeCompare('3o2') === 0 ? 'Ter2' : s.localeCompare('3o3') === 0 ? 'Ter3' : s.localeCompare('4o1') === 0 ? 'Qua1' : s.localeCompare('4o2') === 0 ? 'Qua2' : s.localeCompare('4o') === 0 ? 'Qua' : s.localeCompare('5o') === 0 ? 'Qui' : s.localeCompare('6o') === 0 ? 'Sen' : 'Unset')
  const vTranslated = useMemo(() => {
    const v = getVariablesByRoleAndTypeAndViews(deviceType, user, extra);
    return v.map((x, index) => {
      return {
        value: x,
        label: translatedSideTextToFinalText(intl.formatMessage({ id: variableValueToFixedVariableValue(x, deviceType) }), sideLabelsChanged),
        index: index
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, deviceType, extra, sideLabelsChanged])
  //console.log(sideLabels, sideLabelsChanged, vTranslated, deviceType, currentUser)
  const deviceTypeForVariables: keyof typeof startVars = (deviceType === DeviceType.I && extra ? "EXTRAI" : deviceType as any);
  const [varsSelected, setVarsSelected] = useState<HistoryWrapperType<SelectedVariablesType>>({
    graph1: [vTranslated.filter(v => startVars[deviceTypeForVariables][0].some(sv => sv === v.value)), [], [], []],
    graph2: [vTranslated.filter(v => startVars[deviceTypeForVariables][1].some(sv => sv === v.value)), [], [], []],
    graph3: [vTranslated.filter(v => startVars[deviceTypeForVariables][2].some(sv => sv === v.value)), [], [], []],
  });
  const [isLoading, setIsLoading] = useState<boolean[]>([false, false, false])

  const setVarsSelectedInSpecificGraph = (graph: HistoryWrapperKeysType) => (value: SelectedVariablesType) => {
    setVarsSelected(vars => { return { ...vars, [graph]: value } })
  }

  const handleClose = () => {
    setOpenDownload(false)
    //if (temp != null) selectOrganization(temp);
  }
  const handleOpen = () => {
    setOpenDownload(true)
    //if (temp != null) selectOrganization(temp);
  }

  const setGraphInVariables = (replace?: boolean) => (data: { [key: string]: Array<string | number> }) => {
    setFullData(oldData => {
      if (replace) return { ...oldData, ...data }
      const timeDifference = (oldData.timestamp ? oldData.timestamp.length : data.timestamp.length) - data.timestamp.length;
      if (timeDifference <= 0) return { ...oldData, ...data }
      else {
        // console.log(timeDifference)
        const toFill = new Array(timeDifference).fill(undefined)
        const newData = { ...data }
        Object.keys(data).forEach((dataKey) => {
          // console.log(dataKey)
          if (dataKey !== "timestamp") newData[dataKey] = toFill.concat(data[dataKey]);
        })
        return { ...oldData, ...newData, ...{ timestamp: oldData.timestamp } }
      }
    }
    )
  }

  const getGraph = (vars: string[], varsSelected: HistoryWrapperType<SelectedVariablesType>, replace?: boolean) => {
    if (vars.length > 0) {
      let varsForRequest = vars;
      varsForRequest = varsForRequest.concat("timestamp");
      //console.log("\n\n\n", varsForRequest)
      setIsLoading([true, true, true])
      apiRequestSplit<VariablesResponse<{ [key: string]: Array<string | number> }>>("GET", "api/v1/report/", {
        query: {
          type: deviceType,
          device_id: deviceFull?._id,
          since: dateInterval[0]?.toISOString(),
          until: dateInterval[1]?.toISOString(),
          variables: varsForRequest
        },
      }, setGraphInVariables(replace)).then(() => { setIsLoading([false, false, false]) }).catch(error => { });
    }
  }

  useEffect(() => {
    //console.log(dateInterval, varsSelected)
    // TODO : NOT UPDATE WHEN SAME VARIABLE IN DIFFERENT GRAPH
    if (previousDateInterval[0].getTime() !== dateInterval[0].getTime() || previousDateInterval[1].getTime() !== dateInterval[1].getTime()) {
      let addedVariables: string[] = [];
      varsSelected.graph1.forEach(v => v.forEach(v2 => { if (!addedVariables.includes(v2.value)) addedVariables.push(v2.value) }))
      varsSelected.graph2.forEach(v => v.forEach(v2 => { if (!addedVariables.includes(v2.value)) addedVariables.push(v2.value) }))
      varsSelected.graph3.forEach(v => v.forEach(v2 => { if (!addedVariables.includes(v2.value)) addedVariables.push(v2.value) }))
      getGraph(addedVariables, varsSelected, true);
    } else {

      let existentValue: string[] = [];
      const allVarsSelected = varsSelected.graph1.flat().concat(varsSelected.graph2.flat().concat(varsSelected.graph3.flat())).filter(m => {
        if (existentValue.includes(m.value)) {
          return false
        } else {
          existentValue.push(m.value); return true
        }
      })
      let addedVariables: string[] = [];
      allVarsSelected.forEach(variable => {
        if (!(fullData[variable.value] && (fullData["timestamp"] && fullData["timestamp"].length === fullData[variable.value].length))) {
          addedVariables.push(variable.value);
        }
      })
      // console.log(existentValue, allVarsSelected, addedVariables)
      if (addedVariables && addedVariables.length > 0) {
        getGraph(addedVariables, varsSelected);
      }

    }
    setPreviousDateInterval(dateInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInterval, varsSelected])



  const yNames1 = useMemo(() => {
    return varsSelected.graph1.map(v => v.map(v2 => v2.value))
  }, [varsSelected.graph1])
  const yPoints1 = useMemo(() => {
    const st = yNames1.flat();
    const ret = st.length === 0 ? [[[], [], [], []]] : yNames1.map(axis => axis.map(graphName => fullData[graphName]));
    const loading = ret.flat().length > 0 && (ret.flat().flat().length === 0 || ret.flat().some(arr => arr && !arr.some(v => v !== undefined))) && isLoading[0]
    // console.log(ret, loading)
    return loading ? [] : ret
  }, [fullData, isLoading, yNames1])
  const xPoints1 = useMemo(() => {
    const st = yNames1.flat();
    return st.length === 0 ? [] : fullData.timestamp;
  }, [fullData.timestamp, yNames1])


  const yNames2 = useMemo(() => {
    return varsSelected.graph2.map(v => v.map(v2 => v2.value))
  }, [varsSelected.graph2])
  const yPoints2 = useMemo(() => {
    const st = yNames2.flat();
    const ret = st.length === 0 ? [[[], [], [], []]] : yNames2.map(axis => axis.map(graphName => fullData[graphName]));
    const loading = ret.flat().length > 0 && (ret.flat().flat().length === 0 || ret.flat().some(arr => arr && !arr.some(v => v !== undefined))) && isLoading[1]
    // console.log(ret, loading)
    return loading ? [] : ret
  }, [fullData, isLoading, yNames2])
  const xPoints2 = useMemo(() => {
    const st = yNames2.flat();
    return st.length === 0 ? [] : fullData.timestamp;
  }, [fullData.timestamp, yNames2])


  const yNames3 = useMemo(() => {
    return varsSelected.graph3.map(v => v.map(v2 => v2.value))
  }, [varsSelected.graph3])
  const yPoints3 = useMemo(() => {
    const st = yNames3.flat();
    const ret = st.length === 0 ? [[[], [], [], []]] : yNames3.map(axis => axis.map(graphName => fullData[graphName]));
    const loading = ret.flat().length > 0 && (ret.flat().flat().length === 0 || ret.flat().some(arr => arr && !arr.some(v => v !== undefined))) && isLoading[2]
    // console.log(ret, loading)
    return loading ? [[]] : ret
  }, [fullData, isLoading, yNames3])
  const xPoints3 = useMemo(() => {
    const st = yNames3.flat();
    return st.length === 0 ? [] : fullData.timestamp;
  }, [fullData.timestamp, yNames3])


  return (
    <div>
      <Grid container>
        <Grid item>
          <DateRangePicker
            className={classes.formControl}
            onChange={setDateInterval}
            value={dateInterval}
            calendarIcon={<TodayIcon />}
            clearIcon={null}
            required
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.formControlSmall}
            onClick={handleOpen}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id='downloadCSV' />
          </Button>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 10 }}>
        <HistoryCard allVariables={vTranslated} varsSelected={varsSelected.graph1} setVarsSelected={setVarsSelectedInSpecificGraph("graph1")} xPoints={xPoints1} yPoints={yPoints1} yNames={(yNames1).map(v => v.map(x => variableValueToFixedVariableValue(x, deviceType)))} key={'1'} sideLabels={sideLabelsChanged}></HistoryCard>
      </div>
      <div style={{ marginBottom: 10 }}>
        <HistoryCard allVariables={vTranslated} varsSelected={varsSelected.graph2} setVarsSelected={setVarsSelectedInSpecificGraph("graph2")} xPoints={xPoints2} yPoints={yPoints2} yNames={yNames2.map(v => v.map(x => variableValueToFixedVariableValue(x, deviceType)))} key={'2'} sideLabels={sideLabelsChanged}></HistoryCard>
      </div>
      <div style={{ marginBottom: 10 }}>
        <HistoryCard allVariables={vTranslated} varsSelected={varsSelected.graph3} setVarsSelected={setVarsSelectedInSpecificGraph("graph3")} xPoints={xPoints3} yPoints={yPoints3} yNames={yNames3.map(v => v.map(x => variableValueToFixedVariableValue(x, deviceType)))} key={'3'} sideLabels={sideLabelsChanged}></HistoryCard>
      </div>
      <DownloadCsvDialog
        dateStart={dateToLocal(dateInterval[0])}
        dateEnd={dateToLocal(dateInterval[1])}
        open={openDownload}
        handleClose={handleClose}
        deviceID={deviceFull ? deviceFull._id : ''}
        deviceName={deviceFull ? deviceFull.name : ''}
        deviceType={deviceType}
        variables={vTranslated}
        sideLabels={sideLabels}
      />
    </div>
  );
}






//<HistoryCard key={'2'} startVars={['ld-ratio']} since={since} until={until}></HistoryCard>
///<HistoryCard key={'3'} startVars={['unbi-1', 'unbi-2', 'unbpnv-1', 'unbpnv-2']} since={since} until={until}></HistoryCard>