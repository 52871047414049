
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplatePQ: {
      gridTemplateAreas: `
          "gr1"
          "gr2"
          "gr3"`,
      gridTemplateColumns: "100%",
      gridTemplateRows: "minmax(32%, 1fr) minmax(32%, 1fr) minmax(32%, 1fr)",
      height: theme.shape.graphBox.biggest.height,
      gridGap: "6px",
      padding: "10px",
    }
  }),
);

interface PowerProps {

}

export default function PowerQuality(props: React.PropsWithChildren<PowerProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceMainHistory } = useContext(EntitiesContext);
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplatePQ} >
          <SubTitledCard gridArea='gr1' title={"unbalance"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`unbppv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`unbi-0`] : undefined,
              ]]}
              ysnames={[[
                `unbppv-0-icm`,
                `unbi-0-icm`,
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['unbalanceUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"thdv"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`thdppv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdppv-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdppv-2`] : undefined,
              ]]}
              ysnames={[[
                (`thdppv-0-icm`),
                (`thdppv-1-icm`),
                (`thdppv-2-icm`),
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title={"thdc"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`thdi-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdi-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdi-2`] : undefined,
              ]]}
              ysnames={[[
                (`thdi-0-icm`),
                (`thdi-1-icm`),
                (`thdi-2-icm`),
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






