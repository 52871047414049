import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface RemoveDialogProps {
  open: boolean,
  msg: string,
  handleAgree: () => void,
  handleClose: () => void
}

export default function AssuranceDialog(props: RemoveDialogProps) {
  const { msg, handleAgree, open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose} aria-describedby="remove-dialog-description" >
      <DialogContent>
        <DialogContentText id="remove-dialog-description">
          <FormattedMessage id='assurance'></FormattedMessage> <br /><b>{msg}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id='disagree'></FormattedMessage>
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          <FormattedMessage id='agree'></FormattedMessage>
        </Button>
      </DialogActions>
    </Dialog>
  );
}