import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeviceList } from '../../../interfaces/Device';
import { OrganizationList } from '../../../interfaces/Organization';
import { CurrentUser, Roles } from '../../../interfaces/User';
import { extendedUser } from '../AdminPageDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between"
    },
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%",
      margin: 8
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    stepperRoot: {
      padding: `0 0 ${theme.spacing(1)}px 0`
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    formPanel: {
      height: "60vh"
    },
    formPanelSee: {
      height: "60vh",
      overflowY: 'auto',
      display: 'grid',
      textAlign: "center",
      // alignContent: "space-evenly",
    },
    formAction: {
      textAlign: "right"
    },
    noneInfo: {
      textAlign: 'center',
      paddingTop: 44
    }
  }),
);

interface UserCreateProps {
  tempUser: extendedUser
  errors: number[]
  handleChange: (value: any, key: string) => void
  devices: DeviceList[] | undefined
  currentUser: CurrentUser
  orgs: OrganizationList[] | undefined
  org: OrganizationList | undefined
  setActiveStep: (value: React.SetStateAction<number>) => void
  handleSubmit: (user: extendedUser) => void
}

export default function UserCreate(props: UserCreateProps) {
  const { tempUser, errors, handleChange, devices, currentUser, orgs, org, setActiveStep, handleSubmit } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (<><div className={classes.formPanel}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          className={classes.formField}
          label={intl.formatMessage({ id: 'name' })}
          value={tempUser.name}
          fullWidth
          {...(errors[0] !== 0 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
          onChange={evt => handleChange(evt.target.value, "name")}
        />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoComplete={"new-email"}
          className={classes.formField}
          label={intl.formatMessage({ id: 'email' })}
          value={tempUser.email}
          fullWidth
          {...(errors[1] === 1 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
          {...(errors[1] === 2 && { error: true, helperText: `${intl.formatMessage({ id: 'badEmail' })}` })}
          onChange={evt => handleChange(evt.target.value, "email")}
        />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          autoComplete={"new-password"}
          className={classes.formField}
          label={intl.formatMessage({ id: 'password' })}
          value={tempUser.password}
          type={'password'}
          fullWidth
          {...(errors[2] === 1 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
          {...(errors[2] === 2 && { error: true, helperText: `${intl.formatMessage({ id: 'mismatch' })}` })}
          {...(errors[2] === 3 && { error: true, helperText: `${intl.formatMessage({ id: 'passwordSpecialMessage' })}` })}
          onChange={evt => handleChange(evt.target.value, "password")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          className={classes.formField}
          label={intl.formatMessage({ id: 'repeatPassword' })}
          value={tempUser.repeatPassword}
          type={'password'}
          fullWidth
          {...(errors[3] === 1 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
          {...(errors[3] === 2 && { error: true, helperText: `${intl.formatMessage({ id: 'mismatch' })}` })}
          {...(errors[3] === 3 && { error: true, helperText: `${intl.formatMessage({ id: 'passwordSpecialMessage' })}` })}
          onChange={evt => handleChange(evt.target.value, "repeatPassword")}
        />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-devices"><FormattedMessage id={'devices'}></FormattedMessage></InputLabel>
          <Select
            multiple
            renderValue={(dev: any) => dev.map((innerDev: string) => devices?.find(d => innerDev === d._id)?.name).join(', ')}
            value={tempUser.devices_id}
            onChange={(evt: any) => handleChange(evt.target.value, "devices_id")}
          >
            {devices && devices.map((x, i) =>
              <MenuItem key={'select-device-' + i} aria-label="None" value={x._id} ><i>{x.name}</i></MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-organization"><FormattedMessage id={'organization'}></FormattedMessage></InputLabel>
          <Select
            disabled={parseInt((currentUser as any)?.role) > 0}
            renderValue={(dev: any) => {
              const orgFound = orgs?.find(d => dev === d._id)
              return orgFound ? orgFound.name : org?.name
            }}
            value={tempUser.organization_id}
            onChange={(evt: any) => handleChange(evt.target.value, "organization_id")}
          >
            {(currentUser as any)?.role === Roles.ADMIN && orgs && orgs.map((x, i) =>
              <MenuItem key={'select-device-' + i} aria-label="None" value={x._id} ><i>{x.name}</i></MenuItem>
            )}
            {(currentUser as any)?.role === Roles.ORGADMIN && org && <MenuItem aria-label="None" value={org?._id} ><i>{org?.name}</i></MenuItem>
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-role"><FormattedMessage id={'userType'}></FormattedMessage></InputLabel>
          <Select
            disabled={parseInt((currentUser as any)?.role) > 0}
            value={tempUser.role}
            onChange={(evt: any) => handleChange(evt.target.value, "role")}
          >
            <MenuItem aria-label="None" value={Roles.ADMIN} ><i>ADMIN</i></MenuItem>
            <MenuItem aria-label="None" value={Roles.ORGADMIN} ><i>ORGADMIN</i></MenuItem>
            <MenuItem aria-label="None" value={Roles.USER} ><i>USER</i></MenuItem>

          </Select>
        </FormControl>
      </Grid>
    </Grid>

  </div>
    <div className={classes.formAction}>
      <Button onClick={() => setActiveStep(0)} className={classes.backButton}><FormattedMessage id="previous" /></Button>
      <Button variant="contained" color="primary" onClick={() => handleSubmit(tempUser)}><FormattedMessage id="create" /></Button>
    </div>
  </>)
}