import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogBox: {
      position: "fixed",
      //inset: props=>`calc(100% - ${74*((props as any).index+1)}px) 0 0 calc(100% - 228px) !important`,
      left: "calc(100% - 235px) !important",
      width: 230,
      height: 80,
      overflow: "hidden",
      "& .MuiBackdrop-root": {
        //backgroundColor: "transparent"
        height: 0
      },
      "& .MuiDialog-paper": {
        boxShadow: "none",
        maxHeight: "calc(100% - 5px)",
        width: 230,
        overflowX: "hidden",
        margin: 5,
        backgroundColor: props => (props as any).colorBG
      },
      "& .MuiDialog-container": {
        height: 80,
      },
      "& .MuiDialogContent-root": {
        overflowY: "hidden",
        overflowX: "hidden",
        padding: "10px 20px",
        color: props => (props as any).colorText
      }
    },
    contentText: {
      display: "grid",
      gridTemplateAreas: `
      "icon title"
      "icon text"`,
      gridTemplateRows: "1fr 0.7fr",
      gridTemplateColumns: "0.1fr 1fr",
      gridGap: 4,
      height: 75
    },
    contentTextNoSub: {
      gridTemplateAreas: `
      "icon title"
      "icon title"`,
    },
    icon: {
      gridArea: "icon",
      margin: 'auto'
    },
    mainText: {
      gridArea: 'title',
      fontWeight: 600,
      maxHeight: 63,
      overflow: 'hidden',
      textAlign: 'center',
      alignSelf: 'center'
    },
    secondaryText: {
      gridArea: 'text',
      textAlign: 'center'
    },
    pointer: {
      cursor: 'pointer'
    }
  }),
);

interface ToastDialogProps {
  type: 'error' | "success" | "info" | "warning",
  title: string,
  text: string,
  index: number,
  action?: () => void,
  close?: () => void,
}

const colors = [
  { type: "error", color: "#de3d31" },
  { type: "success", color: "#459f49" },
  { type: "info", color: "#1e88dd" },
  { type: "warning", color: "#e88a00" }
]
const colorsBG = [
  { type: "error", color: "#180605cc" },
  { type: "success", color: "#071107cc" },
  { type: "info", color: "#030e18cc" },
  { type: "warning", color: "#190f00cc" }
]
const colorsText = [
  { type: "error", color: "#fab3ae" },
  { type: "success", color: "#b7dfb9" },
  { type: "info", color: "#a6d5fa" },
  { type: "warning", color: "#ffd599" }
]

export default function ToastDialog(props: ToastDialogProps) {
  const { type, title, text, index, action, close } = props;
  const open = true
  const color = colors.find(c => c.type === type)?.color
  const colorBG = colorsBG.find(c => c.type === type)?.color
  const colorText = colorsText.find(c => c.type === type)?.color
  const classes = useStyles({ ...props, colorBG, colorText });


  return (
    <Dialog onClick={() => { if (action) action(); if (close) close() }} style={{ top: `calc(100% - ${85 * (index + 1)}px)` }} className={clsx(classes.dialogBox, action && classes.pointer)} open={open} hideBackdrop disableEnforceFocus aria-describedby="remove-dialog-description" >
      <DialogContent className={clsx((!text || text === 'nothing') && classes.contentTextNoSub, classes.contentText)}>
        {type === 'success' && <CheckCircleIcon className={classes.icon} htmlColor={color} />}
        {type === 'error' && <ErrorIcon className={classes.icon} htmlColor={color} />}
        {type === 'info' && <InfoIcon className={classes.icon} htmlColor={color} />}
        {type === 'warning' && <WarningIcon className={classes.icon} htmlColor={color} />}
        <div className={classes.mainText}><FormattedMessage id={title} /></div>
        {text && text !== 'nothing' && <div className={classes.secondaryText}><FormattedMessage id={text} /></div>}
      </DialogContent>
    </Dialog>
  );
}