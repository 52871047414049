import { Fade } from "@material-ui/core";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import ToastDialog from "../components/global/ToastDialog";
import APIRequestContext from "./api-context";


export const ToastContext = React.createContext<{
  displayToast: (type: 'error' | "success" | "info" | "warning", title: string, text: string, duration?: number | undefined, action?: (() => void) | undefined) => void
}>({
  displayToast: (room, title, text, duration?) => { },
})


export interface toastType {
  type: 'error' | "success" | "info" | "warning", title: string, text: string, id: number, enterTime: number, duration: number, action?: () => void
}

export function ToastComponent(props: PropsWithChildren<any>) {
  const { children } = props;
  const [toasts, setToasts] = useState<toastType[]>([]);
  const { user } = useContext(APIRequestContext)
  //const intl = useIntl();
  const displayToast = (type: 'error' | "success" | "info" | "warning", title: string, text: string, duration?: number, action?: () => void) => {
    const tid = Math.round(Math.random() * 100000000);
    const d = duration ? duration : 4000;
    setToasts([...toasts, { type, title, text, id: tid, action, enterTime: (new Date()).getTime(), duration: d }]);
  };

  useEffect(() => {
    if (!user) setToasts([])
  }, [user])

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length > 0) setToasts(toasts.filter(t => ((t.enterTime + t.duration) > (new Date()).getTime())))
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [toasts])

  const handleClose = (id: number) => {
    setToasts(ts => { ts.forEach(t => t.duration = t.id === id ? 0 : t.duration); return ts });
  }

  const updateApp = (evt?: MessageEvent<any>) => {
    displayToast("info", "updateTitle", "updateBody", 999999, () => {
      // console.log(evt?.source, 'should send skip');
      (evt?.source?.postMessage as any)({ type: 'SKIP_WAITING' });
      window.location.reload()
    });
  }

  navigator.serviceWorker.onmessage = (event) => {
    if (event.data.type === 'UPDATE_AVAILABLE') {
      updateApp(event);
    }
  }
  // useEffect(()=>{console.log('children')},[children])
  // useEffect(()=>{console.log('toasts')},[toasts])
  // useEffect(()=>{console.log('setToasts')},[setToasts])
  // useEffect(()=>{console.log('displayToast')},[displayToast])


  //title={intl.formatMessage({ id: t.title })}
  //text={intl.formatMessage({ id: t.text })}

  return (
    <ToastContext.Provider value={{ displayToast }}>
      {children}
      {toasts.length > 0 && toasts.map((t, index) => <Fade key={`toast-${t.id}`}>
        <ToastDialog
          index={index}
          title={t.title}
          type={t.type}
          text={t.text}
          action={t.action}
          close={() => handleClose(t.id)}
        />
      </Fade>)}
    </ToastContext.Provider>);
}