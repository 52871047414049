import { Button, Grid, makeStyles, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clock from '../../assets/navigationIcons/clock.webp';
import CreateAlarmDialog from '../../components/alarms/CreateAlarmDialog';
import EntityTable, { HeadCell } from "../../components/entities/EntityTable";
import AssuranceDialog from "../../components/global/AssuranceDialog";
import APIRequestContext from '../../contexts/api-context';
import { ToastContext } from "../../contexts/toast-context";
import { AlarmConditionOperator, config, configExtended, CreateAlarm, CreateAlarmForSubmission } from "../../interfaces/Alerts";
import { DeviceList } from "../../interfaces/Device";
import { OrganizationList } from "../../interfaces/Organization";
import { PageResponse, SingleResponse } from "../../interfaces/Responses";
import { Roles, User } from "../../interfaces/User";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "200px"
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: "107px"
  },
  pageLegend: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    background: "white",
    "&:hover": {
      background: theme.palette.grey[100],
      boxShadow: "unset"
    }
  }
}));
type Order = 'asc' | 'desc';



export default function AlertConfig(props: any) {
  //const { location } = props
  const [alarms, setAlarms] = useState<configExtended[]>([]);
  const { apiRequest } = useContext(APIRequestContext);
  const [alarmsTemp, setAlarmsTemp] = useState<configExtended[]>([]);
  const [devices, setDevices] = useState<DeviceList[]>([]);// TODO MUDAR PARA O CONTEXTO
  const [users, setUsers] = useState<User[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationList[]>();
  const [search, setSearch] = useState<string>('');
  const [addAlert, setAddAlert] = useState<boolean>(false);
  const [openAssurance, setOpenAssurance] = useState<boolean>(false);
  const classes = useStyles();
  const intl = useIntl();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('created_at');
  const [total, setTotal] = useState<number>(0)
  var currentUser2 = localStorage.getItem("user");
  const currentUser: User = JSON.parse(currentUser2 ? currentUser2 : '');
  const organizationDefaults = { organization_id: '', users_id: [], devices: [], name: '', message: '', color: '#ffc000', send_by_email: true, show_in_dashboard: true, samples_mean: 1, logic: "0", conditions: [{ threshold: 0, operator: AlarmConditionOperator['GREATER'], field: '' }] }
  const { displayToast } = useContext(ToastContext);
  const [filters, setFilters] = useState({
    page: 0,
    limit: 15
  });
  const [selectedAlarm, setSelectedAlarm] = useState<CreateAlarm>(organizationDefaults)

  const toAlarmType = (alarm: configExtended): CreateAlarm => {
    return {
      organization_id: alarm.organization_id,
      users_id: alarm.users_id,
      devices: alarm.devices.map(dev => dev.id),
      name: alarm.name,
      message: alarm.message,
      color: alarm.color,
      send_by_email: alarm.send_by_email,
      show_in_dashboard: alarm.show_in_dashboard,
      samples_mean: alarm.samples_mean,
      logic: alarm.logic.toString(),
      conditions: alarm.conditions,
      id: alarm._id
    }
  }

  const toAlarmConfig = (alarm: CreateAlarm, id: string): configExtended => {
    return {
      organization_id: alarm.organization_id,
      users_id: alarm.users_id,
      devices: alarm.devices.map(dev => { return { id: dev, active: false } }),
      name: alarm.name,
      message: alarm.message,
      color: alarm.color,
      send_by_email: alarm.send_by_email,
      show_in_dashboard: alarm.show_in_dashboard,
      samples_mean: alarm.samples_mean,
      logic: parseInt(alarm.logic),
      conditions: alarm.conditions,
      _id: id,
      operator: alarm.conditions[0].operator === '1' || alarm.conditions[0].operator === 1 ?
        '<' :
        alarm.conditions[0].operator === '2' || (alarm.conditions[0].operator as any) === 2 ?
          '=' :
          '>',
      devicesNames: alarm.devices.map((dev: any) => {
        const d = devices.find(dev2 => dev2._id === dev)
        return d ? d.name : ''
      }),
      owner_id: (currentUser as any).id,
      created_at: (new Date()).toISOString(),
      updated_at: (new Date()).toISOString()
    }
  }

  const toAlarmForSubmission = (alarm: CreateAlarm): CreateAlarmForSubmission => {
    return {
      organization_id: alarm.organization_id,
      users_id: alarm.users_id,
      devices_id: alarm.devices,
      name: alarm.name,
      message: alarm.message,
      color: alarm.color,
      send_by_email: alarm.send_by_email,
      show_in_dashboard: alarm.show_in_dashboard,
      samples_mean: alarm.samples_mean,
      logic: parseInt(alarm.logic),
      conditions: alarm.conditions
    }
  }

  const getAlarms = (page: number, limit: number, orderby: string, ordertype: number, count?: boolean) => {
    let vars = {
      page: page + 1,
      limit: limit,
      orderby: orderby,
      ordertype: ordertype,
      ...(count ? ({ count: count }) : {})
    }
    apiRequest<PageResponse<config> | SingleResponse<string>>("GET", "api/v1/alarm", { query: vars })
      .then(result => {
        if (!count) {
          const als = (result as PageResponse<config>).values.map((alarm: any) => {
            alarm.operator = alarm.conditions[0].operator === 1 || alarm.conditions[0].operator === '1'
              ? '<'
              : alarm.conditions[0].operator === 2 || alarm.conditions[0].operator === '2'
                ? '=' :
                '>';
            alarm.devicesNames = alarm.devices.map((dev: any) => devices.find(dev2 => dev2._id === dev.id)?.name)
            if (alarm.updated_at === null) { alarm.updated_at = alarm.created_at }
            return alarm
          })
          setAlarms(als)
          setAlarmsTemp(als)
        } else {
          setTotal(parseInt((result as SingleResponse<string>).value ? (result as SingleResponse<string>).value : '0'))
        }
      })
      .catch(error => {

      });

  }

  const handleChange = (value: string) => {
    setSearch(value)
  }

  const handleChangeFilters = (value: any, key: string) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleAddAlert = () => {
    setSelectedAlarm(organizationDefaults)
    setAddAlert(true);
  }

  const handleEdit = (id: string) => {
    const f = alarms.find(al => al._id === id);
    if (f) {
      setSelectedAlarm(toAlarmType(f));
      setAddAlert(true);
    }
  }

  const handleDelete = (id: string | undefined) => {
    const f = alarms.find(al => al._id === id);
    if (f) {
      setSelectedAlarm(toAlarmType(f));
      setOpenAssurance(true)
    }
  }

  const closeAssurance = () => {
    setSelectedAlarm(organizationDefaults)
    setOpenAssurance(false)
  }

  const handleDeleteFinal = (id: string | undefined) => {
    if (id) {
      apiRequest<void>("DELETE", "api/v1/alarm/" + id)  //////////PUT BACK
        .then(result => {
          var als = alarms.slice();
          const ind = als.findIndex(al => al._id === id)
          als.splice(ind, 1)
          setAlarms(als)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    }
    setSelectedAlarm(organizationDefaults)
    setOpenAssurance(false)
  }

  const handleSubmit = (updating: boolean, alarm: CreateAlarm) => {
    if (updating) {
      //console.log("updating", alarm, selectedAlarm.id)
      apiRequest<SingleResponse<void>>("PUT", "api/v1/alarm/" + selectedAlarm.id, { jsonData: toAlarmForSubmission(alarm) })
        .then(result => {
          var als = alarms.slice();
          const id = als.findIndex(alar => alar._id === selectedAlarm.id)
          als[id] = (toAlarmConfig(alarm, selectedAlarm.id!))
          setAlarms(als)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    } else {
      apiRequest<SingleResponse<string>>("POST", "api/v1/alarm", { jsonData: toAlarmForSubmission(alarm) })
        .then(result => {
          var als = alarms.slice();
          als.push(toAlarmConfig(alarm, result.value))
          setAlarms(als)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    }
    setAddAlert(false)
    setSelectedAlarm(organizationDefaults)
  }

  const headCells: HeadCell<keyof configExtended | 'actions'>[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'name' },
    { id: 'message', numeric: true, disablePadding: false, label: 'message' },
    { id: 'devicesNames', numeric: true, disablePadding: false, label: 'devices' },
    { id: 'operator', numeric: true, disablePadding: false, label: 'operator' },
    { id: 'updated_at', numeric: true, disablePadding: false, label: 'updatedAt' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'actions' },
  ];

  const handleCloseDialog = (temp?: CreateAlarm) => {
    setAddAlert(false)
    //if (temp != null) selectOrganization(temp);
  }

  useEffect(() => {
    const orderFixed = order === 'asc' ? -1 : 1
    getAlarms(filters.page, filters.limit, orderBy, orderFixed, false)
    getAlarms(filters.page, filters.limit, orderBy, orderFixed, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, devices, order, orderBy])

  useEffect(() => {
    const getDevices = () => {
      apiRequest<PageResponse<DeviceList>>("GET", "api/v1/device")
        .then(result => { setDevices(result.values); })
        .catch(error => { });
    }

    const getUsers = () => {
      apiRequest<PageResponse<User>>("GET", "api/v1/user")
        .then(result => { setUsers(result.values); })
        .catch(error => { });
    }

    const getOrgs = () => {
      apiRequest<PageResponse<OrganizationList>>("GET", "api/v1/organization")
        .then(result => { setOrganizations(result.values); })
        .catch(error => { });
    }
    getDevices()
    getUsers()
    //console.log("LOGGERZ: alertconfig")
    if (currentUser && (currentUser as any).role < Roles.ORGADMIN) getOrgs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest])

  useEffect(() => {
    const s = search.toLocaleLowerCase()
    var als = alarms.filter(alarm => {
      var st = ''
      if (alarm.updated_at) {
        st = alarm.name.toLocaleLowerCase() +
          alarm.message.toLocaleLowerCase() +
          alarm.devicesNames.toString().toLocaleLowerCase() +
          alarm.operator +
          (alarm.updated_at as unknown as string).substring(11, 16) + ' ' +
          (alarm.updated_at as unknown as string).substring(8, 10) + '/' +
          (alarm.updated_at as unknown as string).substring(5, 7) + '/' +
          (alarm.updated_at as unknown as string).substring(2, 4);
      } else {
        st = alarm.name.toLocaleLowerCase() +
          alarm.message.toLocaleLowerCase() +
          alarm.devicesNames.toString().toLocaleLowerCase() +
          alarm.operator +
          alarm.created_at.substring(11, 16) + ' ' +
          alarm.created_at.substring(8, 10) + '/' +
          alarm.created_at.substring(5, 7) + '/' +
          alarm.created_at.substring(2, 4);
      }

      if (st.includes(s)) {
        return true;
      } else {
        return false
      }
    })
    setAlarmsTemp(als!)
  }, [search, alarms])


  return (<div>
    <Grid container>
      <Grid item>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          startIcon={<img src={clock} alt='clock' />}
          onClick={handleAddAlert}
        >
          <FormattedMessage id="addAlert" />
        </Button>
      </Grid>
      <Grid item>
        <TextField
          id="select-search"
          label={<FormattedMessage id="search" />}
          type="text"
          className={classes.formControl}
          InputLabelProps={{ shrink: true }}
          value={search}
          onChange={evt => handleChange(evt.target.value)}
        />
      </Grid>
    </Grid>
    <EntityTable
      dates={[4]}
      dot={0}
      list={alarmsTemp}
      formatColor={(alarm: any) => { return alarm.color }}
      headCells={headCells}
      total={total}
      actions1={handleEdit}
      buttons1={<Tooltip title={intl.formatMessage({ id: 'editAlarm' })}><EditIcon /></Tooltip>}
      actions2={handleDelete}
      buttons2={<Tooltip title={intl.formatMessage({ id: 'deleteAlarm' })}><DeleteIcon /></Tooltip>}
      setRows={(n: number) => { handleChangeFilters(n, 'limit') }}
      setPage={(n: number) => { handleChangeFilters(n, 'page') }}
      page={filters.page}
      rows={filters.limit}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
    ></EntityTable>
    <CreateAlarmDialog
      open={addAlert}
      handleClose={handleCloseDialog}
      handlePositiveClose={handleSubmit}
      alarm={selectedAlarm}
      devices={devices}
      users={users}
      organizations={organizations}
    />
    {openAssurance && <AssuranceDialog
      open={openAssurance}
      msg={intl.formatMessage({ id: 'deleteAlarm' }) + selectedAlarm.name}
      handleAgree={() => handleDeleteFinal(alarms.find(al => selectedAlarm.name === al.name && selectedAlarm.message === al.message)?._id)}
      handleClose={closeAssurance}
    ></AssuranceDialog>}
  </div>
  );
}