
import { isWidthDown, makeStyles, TextField, Typography, withWidth } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AllVariablesType, SelectedVariablesType, XPointsType, YNamesType, YPointsType } from '../../../interfaces/helper';
import TitledCard from '../middle/TitledCard';
import DraggableGraph from './DraggableGraph';


//interface helperInterface { x: any[], v: { ys: any[], ysnames: any[] } }

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "200px"
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: "107px"
  },
  pageLegend: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  },
  multiselect: {
    width: '22%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiAutocomplete-input:first-child': {////    quando nao tem opcoes, isto é 6 em vez de 4 e estraga tudo
      padding: '4px 0 !important'
    },
    '& .MuiFormControl-fullWidth': {  ///        tem padding a mais em cima
      marginTop: 4
    }
  },
  selected: {
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    height: '100%'
  },
  renderTags: {
    maxWidth: "calc(100% - 81px)",
    fontSize: 15
  },
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.grey[400],
      borderColor: 'transparent',
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderColor: 'transparent',
    }
  },
  listbox: {
    backgroundColor: theme.palette.grey[100]
  }
}));


interface HistoryCardProps {
  allVariables: AllVariablesType,
  varsSelected: SelectedVariablesType,
  setVarsSelected: (s: SelectedVariablesType) => void,
  xPoints: XPointsType,
  yPoints: YPointsType,
  yNames: YNamesType,
  key: string,
  sideLabels?: string[]
}

export default withWidth()(function HistoryCard(props: React.PropsWithChildren<HistoryCardProps>) {
  const { allVariables, varsSelected, setVarsSelected, yNames, yPoints, xPoints, key, sideLabels } = props;
  const classes = useStyles();
  const [ID] = useState<string>(Math.round(Math.random() * 100000000).toString());
  const intl = useIntl()
  
  const handleChange = (val: any, graphIndex: number) => {
    if (val.some((v: any) => !v.value)) {
      return
    }
    if (val.length === 0) {   // APAGA TUDO
      const copy = varsSelected.slice()
      copy.splice(graphIndex, 1, val)
      setVarsSelected(copy)
    } else {
      const lastVal = val[val.length - 1].value
      const found = val.filter((v: any) => v.value === lastVal).length
      if (found > 1) {
        val = val.filter((v: any) => v.value !== lastVal)
      }
      const copy = varsSelected.slice()
      copy.splice(graphIndex, 1, val)
      setVarsSelected(copy)
    }
  }

  const renderTags = (selected: any) => {
    let renderTagsValue = selected.map(function (elem: any) {
      return elem.label;
    })
      .join(", ");
    return (
      <Typography
        className={classes.renderTags}
        noWrap={true}
        color="textPrimary"
      >
        {renderTagsValue}
      </Typography>
    );
  }

  const renderInput = (params: any, number?: string) => {
    return (
      <TextField
        {...params}
        variant="standard"
        placeholder={`${intl.formatMessage({ id: 'variables' })} ${number}`}
        margin="normal"
      />
    )
  }

  return (
    <TitledCard
      title={'graph'}
      key={`historycard-${ID}`}
      fillDiv
      forceHeight={isWidthDown('sm', (props as any).width) ? 350 : 506}
      buttons={[
        <Autocomplete
          autoHighlight
          key={`select-v1-${key}-${ID}`}
          multiple
          id={`tags-standard1-${ID}`}
          getOptionSelected={(option, value) => option.value === value.value}
          freeSolo
          value={varsSelected[0]}
          disableCloseOnSelect
          options={allVariables}
          onChange={(e, attr) => handleChange(attr, 0)}
          getOptionLabel={(option) => option.label}
          className={classes.multiselect}
          renderTags={selected => renderTags(selected)}
          renderInput={(params) => renderInput(params, '1')}
          classes={{ option: classes.option, listbox: classes.listbox }}
        />,
        <Autocomplete
          autoHighlight
          key={`select-v2-${key}-${ID}`}
          multiple
          id={`tags-standard2-${ID}`}
          getOptionSelected={(option, value) => option.value === value.value}
          freeSolo
          value={varsSelected[1]}
          disableCloseOnSelect
          options={allVariables}
          onChange={(e, attr) => handleChange(attr, 1)}
          getOptionLabel={(option) => option.label}
          className={classes.multiselect}
          renderTags={selected => renderTags(selected)}
          renderInput={(params) => renderInput(params, '2')}
          classes={{ option: classes.option, listbox: classes.listbox }}
        />,
        <Autocomplete
          autoHighlight
          key={`select-v3-${key}-${ID}`}
          multiple
          id={`tags-standard3-${ID}`}
          getOptionSelected={(option, value) => option.value === value.value}
          freeSolo
          value={varsSelected[2]}
          disableCloseOnSelect
          options={allVariables}
          onChange={(e, attr) => handleChange(attr, 2)}
          getOptionLabel={(option) => option.label}
          className={classes.multiselect}
          renderTags={selected => renderTags(selected)}
          renderInput={(params) => renderInput(params, '3')}
          classes={{ option: classes.option, listbox: classes.listbox }}
        />,
        <Autocomplete
          autoHighlight
          key={`select-v4-${key}-${ID}`}
          multiple
          id={`tags-standard4-${ID}`}
          getOptionSelected={(option, value) => option.value === value.value}
          freeSolo
          value={varsSelected[3]}
          disableCloseOnSelect
          options={allVariables}
          onChange={(e, attr) => handleChange(attr, 3)}
          getOptionLabel={(option) => option.label}
          className={classes.multiselect}
          renderTags={selected => renderTags(selected)}
          renderInput={(params) => renderInput(params, '4')}
          classes={{ option: classes.option, listbox: classes.listbox }}
        />
      ]}
    >
      <DraggableGraph
        x={xPoints}
        ys={yPoints}
        titley={['', '', '', '']}
        titlex={''}
        ysnames={yNames}
        graphType={'linegl'}
        colors={[varsSelected[0] ?                          /// CORES DO GLOBAL STATUS
          varsSelected[0].length > 4 ?
            ['#ffa500', '#ff0000', '#ad000e', '#007701', '#097299'] :
            varsSelected[0].map(v => v.value)[0] === 'sf-0' && varsSelected[0].map(v => v.value)[1] === 'sf-1' ?
              ['#097299', '#ff0000'] :
              varsSelected[0].map(v => v.value)[0] === 'sf-0' ?
                ['red'] :
                undefined :
          undefined, undefined, undefined, undefined]}
        fourAxisWidth
        emptyMessage={'noDataInInterval'}
        heightInherited
        sideNames={sideLabels}
        enableReportLinks
      />
    </TitledCard>
  );
})





