
import { Grid, isWidthDown, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import { GraphPartsInterface } from '../../../interfaces/helper';
import { ThemeExtended } from '../../global/Theme';
import DraggableGraph from '../final/DraggableGraph';
import SubTitledCard from '../middle/SubTitledCard';
import SmallCornerBox, { SmallCornerBoxParts } from '../middle/SmallCornerBox';

const useStyles = makeStyles((theme: ThemeExtended) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            overflowY: 'auto'
        },
        SideContainer: {
            display: "flex",
            height: "100%"
        },
        height: {
            height: theme.shape.graphBox.normal.height,
            [theme.breakpoints.down('sm')]: {
                height: "350px",
            }
        },
        fillwidth: {
            width: '100%'
        }
    }),
);

export interface BigGraphSmallDataProps {
    small: Array<Array<SmallCornerBoxParts>>,
    smallTitles?: Array<string>,
    big: GraphPartsInterface,
    title: string,
    autoHeight?: boolean,
}

export default withWidth()(function BigGraphSmallData(props: React.PropsWithChildren<BigGraphSmallDataProps>) {
    const classes = useStyles();
    const { small, big, smallTitles, title, autoHeight } = props;
    const mainGridReference = useRef(null);
    //primario e secundario
    return (
        <Grid ref={mainGridReference} container className={classes.SideContainer}>
            <Grid key={`titledcard-1`} className={classes.fillwidth} item md={isWidthDown('sm', (props as any).width) ? 12 : 10}>
                <SubTitledCard divclass={classes.height} noShadow={true} gridArea='gr1' title={title}>
                    <DraggableGraph
                        {...big}
                    ></DraggableGraph>
                </SubTitledCard>
            </Grid>
            <Grid key={`titledcard-2`} className={classes.fillwidth} item sm={isWidthDown('sm', (props as any).width) ? 12 : 2}>
                {small.map((value, index) => <SmallCornerBox key={`smallbox-${index}`} height={autoHeight ? "unset" : ""} title={smallTitles ? smallTitles[index] : undefined} parts={value}></SmallCornerBox>)}
            </Grid>
        </Grid >
    );
})
