//import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { PropsWithChildren, useMemo, useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2'; // probably the best, can be changed later
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  main: {
    height: (props: scrollprops) => `calc(100% - ${props.nothing ? '0' : props.coverNavBar ? '-24' : props.dashboardHeight ? "49" : '65'}px) !important`,
    marginTop: (props: scrollprops) => props.keepTop ? 0 : -8,
    marginLeft: (props: scrollprops) => props.coverNavBar ? -8 : 0,
    width: (props: scrollprops) => props.coverNavBar ? "calc(100% + 16px) !important" : "100%",
    "& > div": {
      overflowX: (props: scrollprops) => props.noHorizontal ? ("hidden !important" as any) : "scroll",
    }
  },
  mainScroll: {
    "&:first-child": {
      marginRight: "0px !important",
    }
  },
  sub: {
    paddingTop: 8
  }
}));

interface scrollprops {
  nothing?: any
  onlyY?: any
  disabled?: any
  keepTop?: any
  coverNavBar?: any
  dashboardHeight?: boolean
  noHorizontal?: boolean
}

export default function ScrollableContent(props: PropsWithChildren<scrollprops>) {
  const classes = useStyles(props);
  const { children, onlyY, disabled } = props
  const [hasScroll, setHasScroll] = useState<boolean>();//mobile removes scrollbars and messes up this jank library, this finds if there are scrollbars to adjust the width

  const setRef = (instance: Scrollbars | null) => {
    if (!((instance?.container.firstElementChild as HTMLElement)?.offsetWidth) && !((instance?.container.firstElementChild as HTMLElement)?.clientWidth)) { }
    else if ((instance?.container.firstElementChild as HTMLElement)?.offsetWidth === (instance?.container.firstElementChild as HTMLElement)?.clientWidth) { setHasScroll(false) }
    else { setHasScroll(true) }
  }

  const classFinal = useMemo(() => { return clsx(classes.main, !hasScroll && classes.mainScroll) }, [classes.main, classes.mainScroll, hasScroll])

  if (disabled) return <>{children}</>
  else return (<Scrollbars ref={setRef} autoHeight={onlyY} autoHide className={classFinal} >
    <div className={classes.sub}>
      {children}
    </div>
  </ Scrollbars>)
}