
import { Grid, isWidthDown, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PolarGraph from '../../../components/device/final/PolarGraph';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forVectorGraphsLPTCM } from '../../../utils/lptcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateVG: {
      gridTemplateAreas: `
          "gr1 gr2"`,
      gridTemplateColumns: "0.666fr 0.333fr",
      height: theme.shape.graphBox.smallest.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "gr1" 
          "gr2"`,
        gridTemplateRows: "49.1% 50%",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: 650,
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

export default withWidth()(function VectorGraph(props: React.PropsWithChildren<{
  sideNames: Array<string>//the names of the sides
}>) {
  const classes = useStyles();
  const { deviceFull, deviceReport } = useContext(EntitiesContext);
  const data = forVectorGraphsLPTCM(deviceReport)
  //console.log(data)
  const intl = useIntl()
  const { sideNames } = props;
  return (
    <Grid container className={classes.SideContainer}>
      {data && data.map((value, side) => <Grid key={`titledcard-${side}`} item xs={isWidthDown('sm', (props as any).width) ? 12 : sideNames.length > 2 ? 4 : 6}>
        <TitledCard gridTemplate={classes.gridtemplateVG} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull!.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1'>
            <PolarGraph
              ys={value.map(form => form.angulo)}
              ysnames={side === 0 ? ['IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic']}
              colors={['#f15d5d', '#51c787', '#6299d8']}
              xs={value.map(form => form.amplitude)}
            ></PolarGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title='current'>
            <SmallCornerBox
              parts={[
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: side === 0 ? 'iv-0' : 'iv-4', value: [si_format(value[0].amplitude[1], 'pu', 2, true), si_format(value[0].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'iv-1' : 'iv-5', value: [si_format(value[1].amplitude[1], 'pu', 2, true), si_format(value[1].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'iv-2' : 'iv-6', value: [si_format(value[2].amplitude[1], 'pu', 2, true), si_format(value[2].angulo[1], 'º', 1, true)] },
              ]}
            ></SmallCornerBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






