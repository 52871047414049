import { useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import bcm from '../../assets/equipmentIcons/bcmoptimized.svg';
import distributor from '../../assets/equipmentIcons/distributionoptimized.svg';
import gmotor from '../../assets/equipmentIcons/g_motoroptimized.svg';
import motor from '../../assets/equipmentIcons/motoroptimized.svg';
import powerT from '../../assets/equipmentIcons/powerToptimized.svg';
import pvm from '../../assets/equipmentIcons/pvmoptimized.svg';
import wind from '../../assets/equipmentIcons/windoptimized.svg';
import alert from '../../assets/navigationIcons/alertoptimized.svg';
import dash from '../../assets/navigationIcons/dashoptimized.svg';
import { ReactComponent as Phone } from '../../assets/navigationIcons/phoneoptimized.svg';
import { ReactComponent as Question } from '../../assets/navigationIcons/questionoptimized.svg';
import { EntitiesContext } from "../../contexts/entities-context";
import { SubstationList, SubstationType } from '../../interfaces/Substations';
import { CurrentUser, Roles } from '../../interfaces/User';
import { deviceTypeToSubstationType, substationTypeToRouteString } from '../../utils/format-data';
import MenuPopper from './MenuPopper';
import CustomNavLink, { CustomNavLinkProps } from "./NavLink";
import { ThemeExtended } from './Theme';


const useStyles = () => {
  return makeStyles((theme: ThemeExtended) => ({
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: theme.shape.drawer.width,
      borderRight: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        height: `calc(100vh + ${theme.shape.appBar.height}px)`,
        width: theme.shape.drawer.width,
        backgroundColor: theme.palette.primary.main,
      }
    },
    drawerPaperClose: {
      backgroundColor: theme.palette.primary.main,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.down('sm')]: {
        height: `calc(100vh + ${theme.shape.appBar.height}px)`,
        width: 0,
      },
      '& ul div div h6 a': {
        display: 'none'
      }
    },
    logoAvatar: {
      width: 42,
      height: '100%'
    },
    logoutContainer: {
      bottom: 0,
      left: 0,
      position: "absolute",
      width: "100%",
      backgroundColor: "white",
      [theme.breakpoints.down('sm')]: {
        position: 'unset',
      }
    },
    logoutButton: {
      fontFamily: 'Titillium Web',
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      color: theme.palette.primary.contrastText,
      textAlign: "center",
      fontSize: '20px',
      fontWeight: 600
    },
    coloredListTop: {
      //height: '40px',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      height: '71px',
    },
    logoText: {
      fontFamily: 'Titillium Web',
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      paddingLeft: theme.spacing(3),
      color: theme.palette.primary.contrastText,
      display: "flex",
      '& div:nth-child(1)': {
        fontSize: '27px',
        fontWeight: 600,
      },
      '& div:nth-child(2)': {
        fontSize: '16px',
        fontWeight: 400,
        paddingTop: "15px",
      },
      '& div:nth-child(3)': {
        verticalAlign: 'super',
        fontSize: '19px',
      }
    },
    newGutters: {
      paddingLeft: '14px',
      height: '100%'
    },
    coloredListBottom: {
      paddingTop: '0px',
      paddingBottom: '0px',
      '& li:nth-child(odd)': {
        backgroundColor: theme.palette.primary.dark
      },
      '& li:nth-child(even)': {
        backgroundColor: theme.palette.primary.main
      }
    },
    menuItem: {
      fontSize: "14px",
      textTransform: "uppercase",
      fontWeight: 600,
      textAlign: "center",
      color: theme.palette.primary.main,
      backgroundColor: "white !important",
      '&.Mui-focusVisible': {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "white"

      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        color: "white"
      }
    },
    sublist: {
      position: 'fixed',
      zIndex: 999,
      marginTop: -64,
      "& div": {
        top: "unset !important",
        left: "unset !important"
      }
    },
    sublistopen: {
      marginLeft: theme.shape.drawer.width,
    },
    sublistclosed: {
      marginLeft: 72,
    },
    imageClass: {
      marginLeft: 14,
      width: 30,
      height: 30,
      "& path": {
        fill: theme.palette.primary.main,
      }
    },
    imageClassWhite: {
      marginLeft: 14,
      width: 30,
      height: 30,
      "& path": {
        fill: theme.palette.grey[50],
      }
    },
    bigMenuList: {
      maxHeight: 400,
      overflowY: "auto"
    },
    searchEquipmentText: {
      top: 0,
      position: "absolute",
      textDecorationLine: "underline",
      color: theme.palette.warning.main,
      fontSize: 12
    },
    searchOrgText: {
      top: 15,
      position: "absolute",
      textDecorationLine: "underline",
      color: theme.palette.error.main,
      fontSize: 12
    },
    menuItemSearch: {
      '& Mui-focusVisible': {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "white"
      },
      height: 33,
      fontSize: "14px",
      textTransform: "uppercase",
      fontWeight: 600,
      textAlign: "center",
      color: theme.palette.primary.main,
      backgroundColor: "white !important",
      minWidth: 150
    },
    textFieldSearch: {
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: 150,
      '& input': {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
      }
    }
  }))()
};

const list: Array<CustomNavLinkProps> = [
  { text: "dashboard", icon: <img src={dash} alt='dashboard' height={58} />, link: "/dashboard" },
  { text: "alerts", icon: <img style={{ height: "58px" }} src={alert} alt="Alerts"></img>, link: "/alerts" },
  { text: "TCM", icon: <img style={{ height: "58px" }} src={powerT} alt="TCM"></img>, link: "/tcm" },
  { text: "LPTCM", icon: <img style={{ height: "58px" }} src={distributor} alt="LPTCM"></img>, link: "/lptcm" },
  { text: "MCM", icon: <img style={{ height: "58px" }} src={motor} alt="MCM"></img>, link: "/mcm" },
  { text: "GCM", icon: <img style={{ height: "58px" }} src={gmotor} alt="GCM"></img>, link: "/gcm" },
  { text: "PVM", icon: <img style={{ height: "58px" }} src={pvm} alt="PVM"></img>, link: "/pvm" },
  { text: "WTM", icon: <img style={{ height: "58px" }} src={wind} alt="WTM"></img>, link: "/wtm" },
  { text: "BCM", icon: <img style={{ height: "58px" }} src={bcm} alt="BCM"></img>, link: "/bcm" }
]

var listSpectator: Array<CustomNavLinkProps> = [...list]
listSpectator.splice(1, 1) // tirar os alarmes

export type sublistSubtype = { //LIST OF SUBSTATIONS
  link: string, //info on substation id or org id is here
  text: string, // text on the bar
  isOpen: boolean,  // if this is open show the links array
  links: Array<sublistSubtype>
}

type sublistType = Array<{
  text: string,
  type: SubstationType,
  isOpen: boolean,
  hasDevices: boolean,
  links: sublistSubtype[],
  hasSearch: boolean
}>


const sublistEmpty: sublistType = [
  { text: "MCM", type: SubstationType['MCM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "LPTCM", type: SubstationType['LPTCM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "GCM", type: SubstationType['GCM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "TCM", type: SubstationType['TCM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "PVM", type: SubstationType['PVM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "WTM", type: SubstationType['WTM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
  { text: "BCM", type: SubstationType['BCM'], links: [], isOpen: false, hasDevices: false, hasSearch: false, },
]

export default function Sidebar(props: { open: boolean, setOpen: (open: boolean) => void }) {
  const { open, setOpen } = props;
  const classes = useStyles();
  const { substations, devices, organizations } = useContext(EntitiesContext);
  const [sublist, setSublist] = useState(sublistEmpty);                                                // THE SUBMENU INFORMATION
  const cu = localStorage.getItem("user");
  var currentUser: CurrentUser = JSON.parse(cu ? cu : '');
  var mainList = list;
  if (currentUser?.role < Roles.SPECTATOR) mainList = list;
  else mainList = listSpectator
  const isAdmin = currentUser.role === Roles.ADMIN;
  const theme: ThemeExtended = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();

  const list2: Array<CustomNavLinkProps> = [
    {
      text: "contacts",
      iconSelected: <Phone className={classes.imageClassWhite}></Phone>,
      icon: <Phone className={classes.imageClass}></Phone>,
      link: "/contacts",
      color: theme.palette.primary.main
    },
    {
      text: "help",
      iconSelected: <Question className={classes.imageClassWhite} />,
      icon: <Question className={classes.imageClass} />,
      link: "/help",
      color: theme.palette.primary.main
    }
  ]

  //https://10.255.255.210/#/substation/615b15c593964142fa3df5c0
  useEffect(() => {

    const makeLinksForSubstation = (nodes: SubstationList[], sub_id?: string): sublistSubtype[] => {
      if (!sub_id) {
        const roots = nodes.filter(s => s.substation_id == null);
        const leaves = nodes.filter(s => s.substation_id != null);
        return roots.map(sub => {
          return {
            link: `/substation/${sub._id}`,
            text: sub.name,
            isOpen: false,
            hasSearch: false,
            links: makeLinksForSubstation(leaves, sub._id)
          }
        })
      } else {
        const here = nodes.filter(s => s.substation_id === sub_id);
        const notHere = nodes.filter(s => s.substation_id !== sub_id && s.substation_id !== undefined);
        return here.map(sub => {
          return {
            link: `/substation/${sub._id}`,
            text: sub.name,
            isOpen: false,
            hasSearch: false,
            links: makeLinksForSubstation(notHere, sub._id)
          }
        })
      }
    }

    if (isAdmin) {
      const newSublist: sublistType = sublist?.map(sub => {
        const orgsForSubstation = organizations ? organizations.filter(org => devices.some(dev => deviceTypeToSubstationType(dev.type, substations, dev._id) === sub.type && dev.organization_id === org._id)) : []
        return {
          ...sub,
          ...{
            hasDevices: devices.some(d => deviceTypeToSubstationType(d.type, substations, d._id) === sub.type),
            links: orgsForSubstation.map(org => {
              const linksForOrgAndType = substations ? substations.filter(substation => substation.type === sub.type && substation.organization_id === org._id) : []
              return {
                link: `/${substationTypeToRouteString(sub.type)}/org/${org._id}`,
                text: org.name,
                isOpen: false,
                hasSearch: false,
                links: makeLinksForSubstation(linksForOrgAndType, undefined)
              }
            })
          }
        }
      })
      setSublist(newSublist);
    } else {
      const newSublist: sublistType = sublist?.map(sub => {
        const linksForType = substations ? substations.filter(substation => substation.type === sub.type) : []
        return {
          ...sub,
          ...{ hasDevices: devices.some(d => deviceTypeToSubstationType(d.type, substations, d._id) === sub.type), links: makeLinksForSubstation(linksForType, undefined) }
        }
      })
      setSublist(newSublist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, isAdmin, organizations, substations])

  const setSublistAs = (name: string, value: boolean) => {
    setSublist(x => x.map(sublist => ({ ...sublist, ...{ isOpen: sublist.text === name ? value : false } })))
  }

  // const updateLinks = (index1: number) => (index2: number, variable: keyof sublistSubtype, value: any) => {
  //   const newSub = sublist;
  //   if (newSub[index1] && newSub[index1].links[index2] && newSub[index1].links[index2][variable] !== undefined) {
  //     (newSub[index1].links[index2][variable] as any) = value
  //   }
  //   setSublist(newSub);
  // }

  const updateLinks = (index1: number) => (indexes2: number[], isOpen: boolean) => {
    // console.log(isOpen)
    const newSub = sublist;
    const path = indexes2.slice(0, indexes2.length - 1);
    const final = indexes2.slice(indexes2.length - 1);
    let node: any = newSub[index1];
    path.forEach(i => {
      if (node.links && node.links[i]) {
        if (isOpen) node.isOpen = isOpen;
        // console.log(JSON.parse(JSON.stringify(node)))
        node = node.links[i];
      }
    })
    if (node.links && node.links[final[0]]) {
      // console.log(JSON.parse(JSON.stringify(node)))
      node.isOpen = isOpen;
      node.links = node.links.map((v: sublistSubtype, index: number) => ({
        ...v,
        ...{ isOpen: index === final[0] ? isOpen : false }
      }))
      // console.log(JSON.parse(JSON.stringify(node.links.find((v:any, i:any) => i === final[0]))))
    }
    // console.log(JSON.parse(JSON.stringify(newSub)))
    // console.log("\n")
    setSublist([...newSub]);
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper,
          !open && classes.drawerPaperClose)
      }}
      open={open}
    >
      <List className={classes.coloredListTop}>
        <ListItem button className={classes.newGutters} onClick={() => setOpen(!open)}>
          <ListItemAvatar>
            <Avatar variant="square" className={classes.logoAvatar} src={theme.palette.images.sidebarLogo} />
          </ListItemAvatar>
          {theme.palette.images.sidebarName}
        </ListItem>
      </List>
      <Divider />
      <List className={classes.coloredListBottom}>
        {mainList.filter(l => ((l.text === 'dashboard' || l.text === 'alerts') || sublist.find(v => v.text === l.text)?.hasDevices)).map((item, index) => <React.Fragment key={`navlink-${index}`}>
          <CustomNavLink
            onMouseEnter={(event: any) => { setSublistAs(item.text, true); setAnchorEl(event.currentTarget ?? anchorEl); }}
            onMouseLeave={(event: any) => setSublistAs(item.text, false)}
            key={`navlink-${index}`}
            {...item} >
            {sublist.find(s => s.text === item.text) && <MenuPopper
              anchorElement={anchorEl}
              open={sublist.find(s => s.text === item.text)!.isOpen === true}
              openDrawer={open}
              first={true}
              links={sublist.find(s => s.text === item.text)?.links}
              updateLinks={updateLinks(sublist.findIndex(s => s.text === item.text))}
            />}
          </CustomNavLink>
        </React.Fragment>)}
      </List>
      {/* <MenuItem onClick={() => handleChangePage(sublink.link)} className={classes.menuItem} key={`select-${index}-${index2}`} value={sublink.text}>{sublink.text}</MenuItem> */}

      < List className={classes.logoutContainer} disablePadding >
        {list2.map((item, index) => <CustomNavLink key={`navlink2-${index}`} {...item} />)}
      </List>
    </Drawer >
  );
}