
import { createStyles, Grid, isWidthDown, makeStyles, useTheme, withWidth } from '@material-ui/core';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import RoundGauge from '../../../components/device/final/RoundGauge';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { createMovingAverage } from '../../../utils/format-data';
import { forGlobalStatus } from '../../../utils/icm-functions';


interface GlobalStatusProps {

}


const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    sfBox: {
      gridTemplateAreas: `
          "g0"
          "g1"
          "g2"
          "g3"
          "g4"`,
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      gridTemplateRows: "repeat(3,29%)",
      gridTemplateColumns: "100%",
      [theme.breakpoints.between('xs', 'sm')]: {
        gridTemplateAreas: `
          "g0 g1"`,
        height: 'unset',
        gridGap: "6px",
        padding: "10px",
        gridTemplateRows: "100%",
        gridTemplateColumns: "repeat(2,49.2%)",
      }
    },
    sfGraph: {
      height: '100%'
    }
  }),
);

export default withWidth()(function GlobalStatusI(props: React.PropsWithChildren<GlobalStatusProps>) {
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const classes = useStyles();
  const line1 = deviceFull?.severity_param.sF_limit1
  const line2 = deviceFull?.severity_param.sF_limit2
  const data = forGlobalStatus(deviceReport);
  const theme: ThemeExtended = useTheme();

  const toLocalTimezone = (ISOdate: string): string => {
    //console.log(ISOdate)
    const value = new Date(ISOdate);
    return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
  }

  const lines = deviceFull && deviceMainHistory !== undefined && deviceMainHistory['timestamp'] ? [{
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line1,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line1,
    line: {
      color: '#f7dc99',
      width: 1,
      dash: 'dot'
    }
  }, {
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line2,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line2,
    line: {
      color: '#f88787',
      width: 2,
      dash: 'dot'
    }
  }] : undefined;

  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={isWidthDown('sm', (props as any).width) ? 12 : 2}>
        <TitledCard gridTemplate={classes.sfBox} title={'severityFactors'}>
          {data && data.map((d, index) => <SubTitledCard key={`g${index}`} gridArea={`g${index}`} title={d.name}>
            <RoundGauge data={d} />
          </SubTitledCard>)}
        </TitledCard>
      </Grid>

      <Grid item xs={isWidthDown('sm', (props as any).width) ? 12 : 10}>
        <TitledCard forceHeight={isWidthDown('md', (props as any).width) ? theme.shape.graphBox.smallest.height : theme.shape.graphBox.normal.height} title={'severityFactors'}>
          <DraggableGraph
            graphType='linegl'
            ys={[[
              deviceMainHistory ? deviceMainHistory['sf-0'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-0']) : undefined,
              deviceMainHistory ? deviceMainHistory['sf-1'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-1']) : undefined,
            ]]}
            ysnames={[["sf-0-icm", "avg-sf-0-icm", "sf-1-icm", "avg-sf-1-icm"]]}
            colors={[['#09729924', '#097299', '#ff000024', '#ff0000']]}
            titley={["severityFactor"]}
            x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
            yrange={[0, 3]}
            shapes={lines}
            titlex=''
            emptyMessage='no7days'
            enableReportLinks
          />
        </TitledCard>
      </Grid>
    </Grid>
  );
})






