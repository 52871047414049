import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import APIRequestContext from "../contexts/api-context";
import { DeviceFullExpanded, DeviceList, DeviceListExpanded } from "../interfaces/Device";
import { OrganizationList } from "../interfaces/Organization";
import { ReportList, ReportWaveforms } from "../interfaces/Report";
import { SubstationList } from "../interfaces/Substations";
import { IcmUsableVariables } from "../pages/icm/ICMPage";
import { LptcmUsableVariables } from "../pages/lptcm/LPTCMPage";
import { McmUsableVariables } from "../pages/mcm/MCMPage";
import { TcmUsableVariables } from "../pages/tcm/TCMPage";

export type AllUsableVariables = McmUsableVariables & TcmUsableVariables & LptcmUsableVariables & IcmUsableVariables

export const EntitiesContext = React.createContext<{
  organizations: OrganizationList[] | undefined;
  substations: Array<SubstationList> | undefined;
  devices: Array<DeviceListExpanded>;
  deviceFull: DeviceFullExpanded | undefined;
  deviceReport: ReportList | undefined;
  deviceMainHistory: AllUsableVariables | undefined;
  deviceWaveforms: ReportWaveforms | undefined;
  setOrganizations: (data: OrganizationList[]) => void;
  setSubstations: (data: Array<SubstationList>) => void;
  setDevices: (data: Array<DeviceListExpanded>) => void;
  setDeviceFull: (device: any) => void;
  setDeviceReport: (report: any) => void;
  setDeviceMainHistory: (report: any) => void;
  setDeviceWaveforms: (report: any) => void;
  //setReport e semelhantes    guardar os dados aqui para nao duplicar
  // adicionar a pagina de historico no devicemainhistory
}>({
  organizations: undefined,
  substations: undefined,
  devices: [],
  deviceFull: undefined,
  deviceReport: undefined,
  deviceMainHistory: undefined,
  deviceWaveforms: undefined,
  setOrganizations: (data: Array<OrganizationList>) => { },
  setSubstations: (data: Array<SubstationList>) => { },
  setDevices: (data: Array<DeviceList>) => { },
  setDeviceFull: (device: DeviceFullExpanded) => { },
  setDeviceReport: (report: ReportList) => { },
  setDeviceMainHistory: (reports: AllUsableVariables) => { },
  setDeviceWaveforms: (report: any) => { },
});


export function ContextComponent(props: PropsWithChildren<{}>) {
  const { user } = useContext(APIRequestContext);
  const [devices, setDevices] = useState<Array<DeviceListExpanded>>([]);
  const [deviceFull, setDeviceFull] = useState<DeviceFullExpanded>();
  const [deviceReport, setDeviceReport] = useState<ReportList>();
  const [deviceMainHistory, setDeviceMainHistory] = useState<any>();
  const [deviceWaveforms, setDeviceWaveforms] = useState<ReportWaveforms>();
  const [substations, setSubstations] = useState<Array<SubstationList>>();
  const [organizations, setOrganizations] = useState<Array<OrganizationList>>();


  useEffect(() => {
    setDevices([]);
    setDeviceFull(undefined);
    setDeviceReport(undefined);
    setDeviceMainHistory(undefined);
    setDeviceWaveforms(undefined);
    setSubstations(undefined);
  }, [user])

  return (
    <EntitiesContext.Provider value={{
      organizations, setOrganizations, substations, devices, setDevices, setSubstations, deviceFull, deviceReport, deviceMainHistory, setDeviceFull, setDeviceReport, setDeviceMainHistory, deviceWaveforms, setDeviceWaveforms
    }}>
      {props.children}
    </EntitiesContext.Provider>
  );
}