import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import APIRequestContext from '../../contexts/api-context';
import { SingleResponse, SuccessResponse } from '../../interfaces/Responses';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      margin: `${theme.spacing(1)}px auto`,
      width: "80px"
    },
    actionsContainer: {
      textAlign: "right",
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    mainbutton: {
      width: "fit-content"
    },
  }),
);

interface UserMFAProps {
  noButton?: boolean,
  endFn?: () => any
}


export default function UserMFA(props: UserMFAProps) {
  const { noButton, endFn } = props;
  const classes = useStyles();
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [qrcode, setQrcode] = useState<string>();
  const [open, setOpen] = useState<boolean>(noButton === true);
  const [activeStep, setActiveStep] = React.useState(0);
  const { apiRequest, user } = useContext(APIRequestContext);
  const intl = useIntl();

  const steps = useMemo(() => [intl.formatMessage({ id: '2faStep1' }), intl.formatMessage({ id: '2faStep2' }), intl.formatMessage({ id: '2faStep3' }), intl.formatMessage({ id: '2faStep4' })], [intl]);

  useEffect(() => {
    if (open && user) {
      apiRequest<SingleResponse<string>>("GET", "api/v1/auth/totp")
        .then(res => QRCode.toDataURL(res.value).then(dataURL => setQrcode(dataURL)));
    }
  }, [apiRequest, open, user]);


  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <StepContent>
          <Typography variant='body1'><FormattedMessage id="installGoogle" /><a rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target="_blank">Play Store</a> <FormattedMessage id="or" /> <a rel="noreferrer" href='https://apps.apple.com/app/google-authenticator/id388497605' target="_blank">App Store</a><FormattedMessage id="onYourDevice" /></Typography>
          <Typography variant='body2'><FormattedMessage id="otherApps" /></Typography>
          <div className={classes.actionsContainer}>
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}><FormattedMessage id="next" /></Button>
          </div>
        </StepContent>;
      case 1:
        return <StepContent>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={qrcode} alt={"qr code"} style={{ maxWidth: "500px" }} />
            <Typography variant='body1'><FormattedMessage id="scanqr" /></Typography>
          </div>
          <div className={classes.actionsContainer}>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}><FormattedMessage id="previous" /></Button>
              <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}><FormattedMessage id="next" /></Button>
            </div>
          </div>
        </StepContent>;
      case 2:
        return <StepContent>
          <Typography variant='body1'><FormattedMessage id="insertcode" /></Typography>
          <div style={{ width: "100%", textAlign: "center" }}>
            <TextField
              className={classes.formField}
              label={intl.formatMessage({ id: "code" })}
              value={code}
              onChange={evt => {
                if (evt.target.value.length <= 6 && !isNaN(parseInt(evt.target.value[evt.target.value.length - 1])))
                  setCode(evt.target.value)
              }}
              onKeyPress={evt => catchEnter(evt)}
              error={error}
              helperText={error ? "failed" : undefined}
            />
          </div>

          <div className={classes.actionsContainer}>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}><FormattedMessage id="previous" /></Button>
              <Button disabled={code.length !== 6} variant="contained" color="primary" onClick={handleSubmit} className={classes.button}><FormattedMessage id="submit" /></Button>
            </div>
          </div>
        </StepContent>;
      case 3:
        return <StepContent>
          <div style={{ width: "100%", textAlign: "center" }}>
            <DoneOutlineIcon htmlColor='#4caf50' />
            <Typography variant='body1'><FormattedMessage id="success2fa" /></Typography>
          </div>
          <div className={classes.actionsContainer}>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}><FormattedMessage id="previous" /></Button>
              <Button variant="contained" color="primary" onClick={handleClose} className={classes.button}><FormattedMessage id="close" /></Button>
            </div>
          </div>
        </StepContent>;
      default:
        return 'Please press outside';
    }
  }

  const catchEnter = (e: any) => {
    if (e.charCode === 13) {
      handleSubmit();
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCode("");
    setError(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCode("");
  };

  const handleSubmit = () => {
    apiRequest<SuccessResponse>("POST", "api/v1/auth/totp", { jsonData: { code } })
      .then(res => handleNext())
      .catch(() => setError(true));
  }
  const handleClose = () => {
    if (noButton) {
      if (endFn) endFn()
    } else {
      setOpen(false);
      setActiveStep(0);
      setCode("");
      setError(false)
    }
  };

  return (
    <>
      {noButton ?
        <>
          <DialogTitle><FormattedMessage id='configure2fa' /></DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  {getStepContent(index)}
                </Step>
              ))}
            </Stepper>
          </DialogContent>
        </>
        :
        <>
          <Button
            variant="contained"
            color="default"
            className={classes.mainbutton}
            startIcon={<VpnKeyIcon />}
            onClick={() => setOpen(prev => !prev)}
          >
            <FormattedMessage id='enable2fa' />
          </Button>
          <Dialog maxWidth="md" onClose={handleClose} open={open}>
            <DialogTitle><FormattedMessage id='configure2fa' /></DialogTitle>
            <DialogContent style={{ width: 500 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    {getStepContent(index)}
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  );
}