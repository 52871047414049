
import { Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forGauges } from '../../../utils/tcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%",
      [theme.breakpoints.up('sm')]: {
        flexDirection: "unset"
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column"
      }
    },
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `"ga1 ga2 ga3 gr1 gr2"`,
        gridTemplateRows: "100%",
        gridTemplateColumns: "repeat(3,calc(11%)) repeat(2,calc(33.5% - 12px))",
        maxHeight: "425px",
        height: theme.shape.graphBox.smallest.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "ga1 ga2 ga3"
          "gr1 gr1 gr1"
          "gr2 gr2 gr2"`,
        gridTemplateRows: "25% 36.7% 36.7%",
        gridTemplateColumns: "repeat(3,1fr)",
        maxHeight: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "ga1"
          "ga2"
          "ga3"
          "gr1"
          "gr2"`,
        gridTemplateRows: "repeat(6, 19.53%)",
        gridTemplateColumns: "99.9%",
        gridGap: "6px",
        padding: "6px",
        minHeight: 1040
      }
    },
  }),
);

interface ElectricParamProps {
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
  sideNames: Array<string>//the names of the sides
}

export interface gaugeFinal {
  name: string,
  value: number | undefined,
  color: string,
  max: number | undefined
}

export interface gaugeMid {
  variable: string,
  title: string,
  array: Array<gaugeFinal>
}

export interface gaugeProps extends Array<gaugeMid> {
}


export default withWidth()(function ElectricParam(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  const { hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, sideNames } = props;
  const sides = [hasV1, hasV2, hasV3, hasV4 === true, hasV5 === true, hasV6 === true];
  const intl = useIntl();
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forGauges(deviceReport, sides, intl.formatMessage({ id: 'na' }), deviceFull);
  const mainGridReference = useRef(null);
  const translationAppend = useMemo(() => {
    switch (sideNames.length) {
      case 2:
        return "";
      case 3:
        return "-triple";
      case 4:
        return "-quad";
      case 5:
        return "-quin";
      case 6:
        return "-sext";
    }
  }, [sideNames.length]);
  //primario e secundario
  return (
    <Grid ref={mainGridReference} container className={classes.SideContainer}>
      {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>
          {gaugeData[side] && gaugeData[side].map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${side * 3 + type}`} title={gaugeData[side][type].title}>
            <GaugeGraph bigValue data={gaugeData[side][type].array}></GaugeGraph>
          </SubTitledCard>)}
          <SubTitledCard gridArea='gr1' title={"voltage"}>
            <DraggableGraph
              ys={sides[side] ? [[
                deviceMainHistory ? deviceMainHistory[(`ppv-${side * 4}` as 'ppv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`ppv-${1 + side * 4}` as 'ppv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`ppv-${2 + side * 4}` as 'ppv-1')] : undefined,
              ]] : [[[]]]}
              ysnames={[[
                `ppv-${side * 4}${translationAppend}`,
                `ppv-${1 + side * 4}${translationAppend}`,
                `ppv-${2 + side * 4}${translationAppend}`
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['voltageUnits']}
              graphType={'linegl'}
              emptyMessage={sides[side] ? 'no7days' : 'na'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"current"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[(`iv-${side * 4}` as 'iv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`iv-${1 + side * 4}` as 'iv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`iv-${2 + side * 4}` as 'iv-1')] : undefined
              ]]}
              ysnames={[[
                `iv-${side * 4}${translationAppend}`,
                `iv-${1 + side * 4}${translationAppend}`,
                `iv-${2 + side * 4}${translationAppend}`
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['currentUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})