import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useContext, useEffect } from 'react';
import APIRequestContext from '../../contexts/api-context';
import { EntitiesContext } from "../../contexts/entities-context";
import { DeviceList } from '../../interfaces/Device';
import { OrganizationList } from '../../interfaces/Organization';
import { PageResponse } from '../../interfaces/Responses';
import { SubstationList } from '../../interfaces/Substations';
import { CurrentUser, Roles } from '../../interfaces/User';
import { deviceListToDeviceListExpanded } from '../../utils/format-data';
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { ThemeExtended } from './Theme';

const useStyles = makeStyles((theme: ThemeExtended) => ({
  root: {
    display: 'flex',
    background: `url(${theme.palette.images.bottomLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "right bottom"
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'clip',
    backgroundColor: "#c1c9d17a",
    [theme.breakpoints.down('sm')]: {
      height: "calc(100vh + 72px)",
      overflow: "auto"
    }
  },
  container: {
    height: "100%",
    padding: `${theme.spacing(9)}px ${theme.spacing(1)}px  0px  ${theme.spacing(1)}px`,
    position: "relative"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface LayoutProps {
  setLanguage: any,
  languages: Array<string>,
  language: any,
  children: any
}

export default function Layout(props: LayoutProps) {
  const { setLanguage, languages, language } = props
  const classes = useStyles();
  const { apiRequest, apiRequestAllPages } = useContext(APIRequestContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { devices, setDevices, setSubstations, setOrganizations } = useContext(EntitiesContext);
  const cu = localStorage.getItem("user");
  var currentUser: CurrentUser = JSON.parse(cu ? cu : '');

  useEffect(() => {
    async function loadEntities() {
      if (devices.length === 0) {
        const devices = await apiRequestAllPages<PageResponse<DeviceList>>("GET", "api/v1/device", { query: { orderby: "_id", ordertype: 1 } });
        if (devices != null) {
          setDevices(deviceListToDeviceListExpanded(devices.values));
        }
      }
      const subs = await apiRequest<PageResponse<SubstationList>>("GET", "api/v1/substation");
      if (devices != null) {
        setSubstations(subs.values);
      }
      if (currentUser.role === Roles.ADMIN) {
        const orgs = await apiRequest<PageResponse<OrganizationList>>("GET", "api/v1/organization");
        setOrganizations(orgs.values)
      }
      setLoading(false);
    }
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Navbar setLanguage={setLanguage} openSidebar={open} setOpenSidebar={setOpen} language={language} languages={languages} drawerOpen={open} />
      <Sidebar open={open} setOpen={setOpen}></Sidebar>
      <main className={classes.content}>
        {props.children && <div className={classes.container} >{props.children}</div>}
      </main>
    </div>
  );
}