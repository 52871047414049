import { Button, ClickAwayListener, Grow, ListItemText, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as Alarm } from '../../assets/navigationIcons/alarmoptimized.svg';
import { received } from '../../interfaces/Alerts';
import { PageResponse } from '../../interfaces/Responses';
import { AlarmContext } from '../../contexts/alarm-context';
import APIRequestContext from '../../contexts/api-context';
import CommonTime from './CommonTime';

const useStyles = () => {
  return makeStyles((theme) => ({
    headers: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    item: {
      transition: theme.transitions.create('background-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      }
    },
    circle: {
      marginRight: "5px",
      height: "15px",
      width: "15px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    },
    titleHolder: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14
    },
    mainTitle: {
      fontWeight: 600
    },
    coloredIcon: {
      "& path": {
        fill: theme.palette.primary.main,
      }
    }
  }))();
}

export default function AlertsMenu() {
  const classes = useStyles();
  //const [notSending, setNotSending] = useState<Array<any>>([]);
  const { apiRequest } = useContext(APIRequestContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { openAlertDialog, notAcknowledged, setNotAcknowledged } = useContext(AlarmContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const openAlert = (event: React.MouseEvent<EventTarget>, alert: received) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    openAlertDialog(alert)
    setOpen(false);
  };


  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    apiRequest<PageResponse<received>>("GET", "api/v1/alarm-occurrence", { query: { not_acknowledged: true, ordertype: -1, orderby: "created_at", page: 0, limit: 100 } }).then((a) => {
      if (a.success) setNotAcknowledged(a.values.sort((a, b) => { return (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNotAcknowledged]);


  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={notAcknowledged.length} color="error">
          <Alarm height={20} width={18.52} className={classes.coloredIcon} />
        </Badge>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {notAcknowledged.slice(0, 10).map((x, i) =>
                    <MenuItem onClick={(event) => openAlert(event, x)} key={`notAck` + i} className={classes.item}>
                      <ListItemText
                        primary={<div className={classes.titleHolder}>
                          <div className={classes.circle} style={{ backgroundColor: `${x.alarm.color}` }} />
                          <div className={classes.mainTitle}>
                            {x.alarm.name}
                          </div>&nbsp;
                            <CommonTime val={(x.created_at as unknown as string)} />
                        </div>
                        }
                        secondary={x.device.name}
                        key={x._id} />
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}