import { CircularProgress, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { GraphPartsInterface } from '../../../interfaces/helper';
import Plotly from '../../../utils/custom-plotly';
import { translatedSideTextToFinalText } from '../../../utils/format-data';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fixDiv: {
            width: "100%",
            overflow: "hidden",
            borderRadius: "0px 0px 10px 10px"
        },
        circularProgress: {
            margin: "auto",
            zIndex: 2,
            //position: "fixed"
        },
        none: { display: "none" },
        loader: {
            // height: "450px",
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        text: {
            "& text": {
                fontFamily: "Titillium Web,\"Open Sans\", verdana, arial, sans-serif !important",
                fill: theme.palette.primary.main,
                fillOpacity: 1,
                color: theme.palette.primary.main,
                height: 37,
                margin: "auto",
                fontSize: "13px !important",
                fontWeight: 700,
                whiteSpace: "nowrap",
            }
        }
    }),
);

const preExitIcon = {
    width: 500,
    height: 600,
    path: "M211.1184 372.9564 252.234 414.072 398.034 268.272 252.234 122.472 211.1184 163.5876 286.3512 239.112H4.374V297.432H286.3512L211.1184 372.9564ZM470.934 5.832H62.694C30.3264 5.832 4.374 32.076 4.374 64.152V180.792H62.694V64.152H470.934V472.392H62.694V355.752H4.374V472.392C4.374 504.468 30.3264 530.712 62.694 530.712H470.934C503.01 530.712 529.254 504.468 529.254 472.392V64.152C529.254 32.076 503.01 5.832 470.934 5.832Z",
}
const exitIcon = {
    ...preExitIcon,
    style: "fill:#c7c7c7"
}


export default function DraggableGraph(props: React.PropsWithChildren<GraphPartsInterface>) {
    const classes = useStyles();
    const { YIntegerTicks,
        removeLegendPrefix,
        hoverDecimalPoints,
        emptyMessage,
        xrange,
        fourAxisWidth,
        threeAxisWidth,
        twoAxisWidth,
        notTimezone,
        onlyMarkers,
        sameScaleXandY,
        highlight, x, ys,
        titlex, titley, ysnames,
        colors, graphType, shape,
        yrange, annotations, shapes,
        staticGraph, paddBot, paddLeft,
        paddRight, paddTop, heightInherited,
        forceLegend, sideNames,
        enableReportLinks, empty } = props;
    const intl = useIntl()
    const [undef, setUndef] = useState<boolean>(true);   // LOADING       emptyFinal -> ja fez loading
    const [plotlyID] = useState<string>(Math.round(Math.random() * 100000000).toString());
    const [mainRef, setMainRef] = useState<any>(null);
    const checkoutRef = useRef<any>()
    const [goToReportMode, setGoToReportMode] = useState(false);
    const [relayoutResult, setRelayoutResult] = useState<any>();
    const [relayoutFixed, setRelayoutFixed] = useState<any>();
    const history = useHistory();
    const location = window.location.hash.substring(1);
    const goToReportMessage = useMemo(() => intl.formatMessage({ id: "enableGoToReport" }), [intl]);
    const fn = (layout: any) => setRelayoutResult(layout);
    const theme = useTheme();

    const hookToRef = useCallback(node => {
        if (node !== null) {
            setMainRef(node);
        }
    }, [])

    const toLocalTimezone = (ISOdate: string): string => {
        //console.log(ISOdate)
        const value = new Date(ISOdate);
        return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
    }

    const changeAxis = (data: any, leastY: number): Plotly.Data[] => {
        data.map((d: any) => d.yaxis = `y${d.yaxis - leastY === 0 ? '' : d.yaxis - leastY + 1}`)
        return data
    }

    const emptyFinal = useMemo(() => {
        var emptyFinal =
            ys.some((y) => y) ?   // HAS NOT UNDEFINED
                (ys as number[][][]).reduce((a2, b2) => {
                    if (b2) {
                        return a2.concat((b2 as Array<Array<number>>).reduce((a, b) => {
                            return (a as Array<number>).concat(b !== undefined ? b : [])
                        }, []))
                    } else {
                        return []
                    }
                }, []).length === 0 :
                true
        return empty ? empty : emptyFinal;
    }, [empty, ys])

    const data = useMemo(() => {
        let dataBefore: Plotly.Data[][];
        if (!empty) {
            dataBefore =                    // PREPARE THE DATA
                ys.some((y) => y) && x ?   // HAS NOT UNDEFINED
                    (ys as number[][][]).map((graph, indexAxis) => {  // each axis
                        if (graph) {   // GRAPH PODE SER UNDEFINED NA MESMA
                            //const ax = `y${indexAxis === 0 ? '' : indexAxis + 1}`
                            const ret1 = graph.map((yval, index) => {
                                const name = ysnames[indexAxis] ? ysnames[indexAxis][index] ? translatedSideTextToFinalText(intl.formatMessage({ id: ysnames[indexAxis][index] }), sideNames) : "" : "";
                                return {
                                    x: !notTimezone && x ? x.map((x2, indexX) => yval && yval[indexX] !== null ? toLocalTimezone((x2 as unknown as string)) : null)?.filter(b => b !== null) : x,   //TODO CHECK IF 100%
                                    y: yval && yval.filter ? yval.filter(b => b !== null) : yval,                        //TODO CHECK IF 100%
                                    ...((ys && ys[1] && ys[1].length > 0) || (ys[2] && ys[2].length > 0) || (ys[3] && ys[3].length > 0) ?
                                        ({ yaxis: indexAxis }) :
                                        {}),
                                    type: graphType,
                                    name: removeLegendPrefix ? name.substring(name.search('-') + 1) : name,
                                    marker: { size: 2 },
                                    ...(shape ? ({ line: { shape } }) as any : {}),
                                    ...(colors[indexAxis] ? ({ marker: { size: 2, color: colors[indexAxis][index] } }) : {}),
                                    ...(highlight !== undefined ? ({
                                        marker: {
                                            ...({ line: { color: x.map((xVal, i) => i === highlight ? 'black' : '') } }),
                                            color: x.map((xVal, i) => i === highlight ? '#ff0000' : 'blue'),
                                            size: x.map((xVal, i) => i === highlight ? 15 : 0),
                                        },
                                        mode: 'lines+markers'
                                    }) : {}),
                                    ...(highlight !== undefined && colors[indexAxis] ? ({
                                        marker: {
                                            color: x.map((xVal, i) => i === highlight ? '#ff0000' : colors[indexAxis][index]),
                                            size: x.map((xVal, i) => i === highlight ? 15 : 0),
                                        },
                                        mode: 'lines+markers'
                                    }) : {}),
                                    ...(onlyMarkers && ({ mode: 'markers' }))
                                }
                            });
                            if (ret1[0] && ret1[0].x && ret1[0].y ? (ret1[0].x.length === 0 || ret1[0].y.length === 0) : true) {
                                return []
                            }
                            return ret1
                        } else {
                            return []
                        }
                    }) :
                    [[{ x: [], y: [] }]];    // IS UNDEFINED

        } else {
            dataBefore = [];
        }
        let data: Plotly.Data[] = dataBefore.flat()
        let leastY: number = data.reduce((before, da) => (da as any).yaxis < before ? (da as any).yaxis : before, 3)
        data = changeAxis(data, leastY) // because not having axis Y breaks the whole thing
        return data;
    }, [colors, empty, graphType, highlight, intl, notTimezone, onlyMarkers, removeLegendPrefix, shape, sideNames, x, ys, ysnames])

    const layout = useMemo(() => {
        const width = mainRef ? mainRef.offsetWidth : undefined;
        const height = mainRef ? mainRef.offsetHeight : undefined;
        // console.log(mainRef, width, height)
        if (enableReportLinks && goToReportMode) {
            let button = mainRef?.querySelector('[data-val~="report"]');
            button = button?.firstElementChild.firstElementChild;
            if (button && button.style) button.style.setProperty("fill", "rgba(68, 68, 68, 0.7)", "important");
        }
        else if (enableReportLinks && !goToReportMode) {
            let button = mainRef?.querySelector('[data-val~="report"]');
            button = button?.firstElementChild.firstElementChild;
            if (button && button.style) button.style.setProperty("fill", "rgba(68, 68, 68, 0.3)", "important");
        }
        const layoutemptyFinal = { ///          PREPARE THE LAYOUTS
            xaxis: {
                visible: false
            },
            yaxis: {
                visible: false
            },
            width: width,
            height: height,
            annotations: [
                {
                    text: empty ? intl.formatMessage({ id: 'na' }) : emptyMessage ? intl.formatMessage({ id: emptyMessage }) : intl.formatMessage({ id: 'nodata' }),
                    xref: "paper" as unknown as any,
                    yref: "paper" as unknown as any,
                    showarrow: false,
                    font: {
                        size: 19,
                        color: theme.palette.primary.main
                    }
                }
            ]
        }
        const layoutNothing = { ///          To delete the plot
            xaxis: {
                visible: false
            },
            yaxis: {
                visible: false
            },
            width: width,
            height: height
        }
        const layoutNormal = {
            ...(forceLegend && { showlegend: true }),
            xaxis: {
                title: titlex ? intl.formatMessage({ id: titlex, defaultMessage: '', description: '' }) : '',
                titlefont: {
                    family: 'Arial, monospace',
                    size: 14,
                    color: '#7f7f7f'
                },
                tickfont: {
                    color: 'grey'
                },
                //...(!notTimezone && { categoryorder: x.slice().sort((a, b) => a - b) }),
                //domain: [0, 0.85],
                ...(fourAxisWidth && { domain: [0.07, 0.85] }),
                ...(threeAxisWidth && { domain: [0.07, 0.90] }),
                ...(twoAxisWidth && { domain: [0.07, 1] }),
                ...(xrange ? ({ range: xrange }) as any : {})
            },
            yaxis: {                                                         ////////////////////////////////////////// EIXO Y 1
                title: titley[0] ? intl.formatMessage({ id: titley[0], defaultMessage: '', description: '' }) : '',
                titlefont: {
                    color: threeAxisWidth ? '#376193' : 'grey', family: 'Arial, monospace',
                    size: 14,
                },
                tickfont: { color: threeAxisWidth ? '#376193' : 'grey' },
                ...(ys && ((ys[1] && ys[1].length > 0 && ys[1][0] && ys[1][0].length > 0) || (ys[2] && ys[2].length > 0 && ys[2][0] && ys[2][0].length > 0) || (ys[3] && ys[3].length > 0 && ys[3][0] && ys[3][0].length > 0)) && {
                    anchor: 'free',
                    side: 'left',
                    position: 0.0295,
                }),
                ...(YIntegerTicks && ({ type: 'linear', dtick: 1 })),
                ...(hoverDecimalPoints && ({ hoverformat: `.${hoverDecimalPoints}f` })),
                ...(sameScaleXandY && ({ scaleanchor: 'x' })),
                ...(yrange ? ({ range: yrange }) as any : {})
            },
            ...(ys && ys[1] && ys[1].length > 0 && ys[1][0] && ys[1][0].length > 0 ? ({    ////////////////////////////////////////// EIXO Y 2
                yaxis2: {
                    title: titley[1] ? intl.formatMessage({ id: titley[1], defaultMessage: '', description: '' }) : '',
                    titlefont: {
                        color: '#c70000', family: 'Arial',
                        size: 14,
                    },
                    tickfont: { color: '#c70000' },
                    overlaying: 'y',
                    ...(hoverDecimalPoints && ({ hoverformat: `.${hoverDecimalPoints}f` })),
                    ...(yrange ? ({ range: yrange }) as any : {}),
                }
            }) : {}),
            ...(ys && ys[2] && ys[2].length > 0 && ys[2][0] && ys[2][0].length > 0 ? ({     ////////////////////////////////////////// EIXO Y 3
                yaxis3: {
                    title: titley[2] ? intl.formatMessage({ id: titley[2], defaultMessage: '', description: '' }) : '',
                    titlefont: {
                        color: '#1e7b49', family: 'Arial',
                        size: 14,
                    },
                    tickfont: { color: '#1e7b49' },
                    anchor: 'free',
                    overlaying: 'y',
                    side: 'right',
                    position: 0.905,
                    ...(ys && ((ys[1] && ys[1].length > 0 && ys[1][0] && ys[1][0].length > 0) || (ys[2] && ys[2].length > 0 && ys[2][0] && ys[2][0].length > 0) || (ys[3] && ys[3].length > 0 && ys[3][0] && ys[3][0].length > 0)) && !threeAxisWidth && {
                        position: 0.86,
                    }),
                    ...(hoverDecimalPoints && ({ hoverformat: `.${hoverDecimalPoints}f` })),
                    ...(yrange ? ({ range: yrange }) as any : {}),
                }
            }) : {}),
            ...(ys && ys[3] && ys[3].length > 0 && ys[3][0] && ys[3][0].length > 0 ? ({      ////////////////////////////////////////// EIXO Y 4
                yaxis4: {
                    title: titley[3] ? intl.formatMessage({ id: titley[3], defaultMessage: '', description: '' }) : '',
                    titlefont: {
                        color: '#224a77', family: 'Arial',
                        size: 14,
                    },
                    tickfont: { color: '#224a77' },
                    anchor: 'free',
                    overlaying: 'y',
                    side: 'right',
                    position: 0.86,
                    ...(ys && ((ys[1] && ys[1].length > 0 && ys[1][0] && ys[1][0].length > 0) || (ys[2] && ys[2].length > 0 && ys[2][0] && ys[2][0].length > 0) || (ys[3] && ys[3].length > 0 && ys[3][0] && ys[3][0].length > 0)) && {
                        position: 0.9,
                    }),
                    ...(hoverDecimalPoints && ({ hoverformat: `.${hoverDecimalPoints}f` })),
                    ...(yrange ? ({ range: yrange }) as any : {}),
                }
            }) : {}),
            hovermode: "x",
            width: width,
            height: height,
            margin: {
                t: paddTop ? paddTop : 20,
                l: paddLeft ? paddLeft : twoAxisWidth || threeAxisWidth ? 30 : 60,
                r: paddRight ? paddRight : 10,
                b: titlex ? paddBot ? paddBot : 65 : paddBot ? paddBot : 40,
                pad: 0
            },
            ...(fourAxisWidth && { showlegend: true }),
            ...(twoAxisWidth && { showlegend: true }),
            ...(threeAxisWidth && { showlegend: true }),
            legend: {
                font: {
                    size: 10
                },
                y: 0.94,
                ...(fourAxisWidth && {
                    yanchor: "top",
                    y: 0.96,
                    xanchor: "left",
                    x: 0.93,
                }),
                ...(twoAxisWidth && {
                    yanchor: "top",
                    y: 0.96,
                    xanchor: "left",
                    x: 1,
                }),
                ...(threeAxisWidth && {
                    yanchor: "top",
                    y: 0.96,
                    xanchor: "left",
                    x: 1,
                }),
                bgcolor: "transparent",
            },
            ...(shapes ?
                ({
                    shapes: shapes, ...(annotations ?
                        ({ annotations: annotations }) as any :
                        {})
                }) as any :
                annotations ?
                    ({
                        annotations: annotations
                    }) as any :
                    {})
        }



        //                 CHECK IF ALL OF THE ARRAYS are undefined
        const tempUndefined =
            ys.some((y) => y) ?   // HAS NOT UNDEFINED
                !(ys as number[][][]).map(item => item ? item.some(subitem => subitem !== undefined) : true).some(item => item === true) :
                true
        if (undef !== tempUndefined) setUndef(tempUndefined);
        //const tempUndefined = !ys.map(item => item.some(subitem => {console.log('----',ys,subitem); return subitem !== undefined})).some(item => item === true)
        //ys.map(item => {console.log('-',item); item.map(subitem => {console.log('---',subitem)})})
        //                 CHECK IF ARRAYS ARE emptyFinal  (emptyFinal=no data, undefined=loading)
        let layout: any;
        if (emptyFinal) { layout = layoutemptyFinal } else { layout = layoutNormal }
        if (tempUndefined) layout = layoutNothing;
        return layout
    }, [mainRef, enableReportLinks, goToReportMode, empty, intl, emptyMessage, theme.palette.primary.main, forceLegend, titlex, fourAxisWidth, threeAxisWidth, twoAxisWidth, xrange, titley, ys, YIntegerTicks, hoverDecimalPoints, sameScaleXandY, yrange, paddTop, paddLeft, paddRight, paddBot, shapes, annotations, undef, emptyFinal])

    const config = useMemo(() => {
        const config = {
            responsive: true,
            displayModeBar: "hover",
            staticPlot: staticGraph,
            displaylogo: false,
            ...(enableReportLinks ? {
                modeBarButtonsToAdd: [
                    {
                        name: goToReportMessage,
                        icon: exitIcon,
                        click: function (gd: any) {
                            setGoToReportMode(s => !s)
                        },
                        attr: "dragmode",
                        val: "report"
                    }
                ],
            } : {})
        }
        const configemptyFinal = {
            responsive: false,
            displayModeBar: false,
            staticPlot: true,
            displaylogo: false
        }
        if (emptyFinal) return configemptyFinal
        else return config
    }, [emptyFinal, enableReportLinks, goToReportMessage, staticGraph])




    useEffect(() => {                                                        // ENABLING CLICKING ON THE GRAPH AND CHAngING PAGE
        let p: any = document.getElementById(plotlyID);
        let d: any = p?.getElementsByClassName('nsewdrag')[0]
        // console.log(p, d, goToReportMode, plotlyID)
        if (p.on && location) {
            if (goToReportMode) {
                p.on('plotly_click', (data: any) => {
                    const dateIncomplete = new Date(data.points[0].x).getTime();
                    const newLocation = location.split("/")[0] + "/" + location.split("/")[1] + "/" + location.split("/")[2] + "/history/2/" + dateIncomplete;
                    history.push(newLocation);
                });
                p.on('plotly_hover', function (data: any) {
                    d.style.cursor = 'pointer'
                });
                p.on('plotly_unhover', function (data: any) {
                    d.style.cursor = ''
                });
            }
            else {
                p.removeAllListeners('plotly_click');
                p.removeAllListeners('plotly_hover');
                p.removeAllListeners('plotly_unhover');
            }
        }
    }, [goToReportMode, history, location, plotlyID])

    useEffect(() => {                                                                            // BLOCKING ZOOM OUTS
        let relayoutFixed;
        if (relayoutResult !== undefined) {
            let x1 = relayoutResult["xaxis.range[0]"];
            let x2 = relayoutResult["xaxis.range[1]"];
            if (x1 && x2) {
                if (x && x[0] && x[x.length - 1]) {
                    if (new Date(x1).getTime() < new Date(x[x.length - 1]).getTime()) x1 = x[x.length - 1];
                    if (new Date(x2).getTime() > new Date(x[0]).getTime()) x2 = x[0];
                    if (x1 !== undefined && x2 !== undefined) {
                        relayoutFixed = [x1, x2];
                    } else {
                        relayoutFixed = undefined;
                    }
                }
                setRelayoutFixed(relayoutFixed);
                // console.log("relayourfixed", relayoutFixed, relayoutResult)
            }
        }
    }, [relayoutResult, x, layout])

    useLayoutEffect(() => {                                                                            // DRAWING GRAPH
        let layoutFinal = layout
        // if (layoutFinal) console.log((JSON.parse(JSON.stringify(layoutFinal)) as any))
        if (layoutFinal?.xaxis && !notTimezone) {
            layoutFinal.xaxis.range = relayoutFixed;
            // console.log(layoutFinal?.xaxis?.range)
        }
        // console.log(layoutFinal, relayoutFixed)
        // console.log(ys, data, layoutFinal, config)
        setTimeout(() => {
            if (checkoutRef?.current?.getAttribute('id') === plotlyID) {
                Plotly.react(plotlyID, data, layoutFinal, config);
                let p: any = document.getElementById(plotlyID);
                if (p.removeListener) p.removeAllListeners("plotly_relayout");
                if (p.on && !notTimezone) p.on('plotly_relayout', fn)
            }
        }, 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, data, layout, plotlyID, relayoutFixed]);


    /*
        useEffect(() => {
            console.log(annotations)
        }, [annotations]);
        useEffect(() => {
            console.log(colors)
        }, [colors]);
        useEffect(() => {
            console.log(graphType)
        }, [graphType]);
        useEffect(() => {
            console.log(highlight)
        }, [highlight]);
        useEffect(() => {
            console.log(intl)
        }, [intl]);
        useEffect(() => {
            console.log(paddBot)
        }, [paddBot]);
        useEffect(() => {
            console.log(paddLeft)
        }, [paddLeft]);
        useEffect(() => {
            console.log(paddRight)
        }, [paddRight]);
        useEffect(() => {
            console.log(paddTop)
        }, [paddTop]);
        useEffect(() => {
            console.log(plotlyID)
        }, [plotlyID]);
        useEffect(() => {
            console.log(shape)
        }, [shape]);
        useEffect(() => {
            console.log(shapes)
        }, [shapes]);
        useEffect(() => {
            console.log(staticGraph)
        }, [staticGraph]);
        useEffect(() => {
            console.log(titlex)
        }, [titlex]);
        useEffect(() => {
            console.log(titley)
        }, [titley]);
        useEffect(() => {
            console.log(x)
        }, [x]);
        useEffect(() => {
            console.log(yrange)
        }, [yrange]);
        useEffect(() => {
            console.log(ys)
        }, [ys]);
        useEffect(() => {
            console.log(ysnames)
        }, [ysnames]);
    */

    return (<div ref={hookToRef} style={{ height: heightInherited ? "inherit" : '100%', width: '100%', display: "flex" }}>
        {undef && <div className={classes.loader}><CircularProgress className={classes.circularProgress} color="inherit" /></div>}
        {/* {!undef && <Plot className={classes.fixDiv} data={data} layout={layout} config={config}></Plot>} */}
        <div ref={checkoutRef} id={plotlyID} className={clsx(classes.fixDiv, emptyFinal && classes.text, undef && classes.none)}></div>
    </div>
    );
}