export interface SubstationList {
  _id: string,
  organization_id: string,
  name: string,
  devices_id: string[],
  type: SubstationType,
  substation_id?: string;
  created_at: string,
  updated_at?: string,
}

export enum SubstationType {
  MCM,
  LPTCM,
  LPTCMd,
  TCM,
  PVM, // solar park
  WTM, // wind farm
  ICM,
  GCM,
  TCMd,
  XCM,
  BCM // battery storage
}
