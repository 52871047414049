import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import TitledCard from "../../components/device/middle/TitledCard";
import PasswordChanger from "../../components/user/PasswordChanger";
import UserMFA from "../../components/user/UserMFA";
import APIRequestContext from '../../contexts/api-context';
import { EntitiesContext } from "../../contexts/entities-context";
import { DeviceList } from "../../interfaces/Device";
import { OrganizationList } from "../../interfaces/Organization";
import { PageResponse } from "../../interfaces/Responses";
import { CurrentUser, Roles } from '../../interfaces/User';
import { deviceListToDeviceListExpanded } from "../../utils/format-data";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "repeat(2, 57px)",
      gridGap: 30,
      padding: 25
    },
    grid2: {
      gridTemplateColumns: "1fr",
      gridGap: 5,
      padding: 25
    },
    item: {
      "& p": {
        marginBottom: 0
      },
      "& p:first-of-type": {
        fontSize: 16,
        fontWeight: 600
      }
    },
    button: {
      width: "fit-content"
    },
    boxLeft: {
      padding: "15px 0px 15px 15px"
    },
    boxRight: {
      padding: "15px 15px 15px 15px"
    }
  }),
);

export default function UserDetailsPage() {
  const classes = useStyles();
  var currentUser: CurrentUser = JSON.parse(localStorage.getItem("user") ? localStorage.getItem("user")! : '');
  const { devices, setDevices } = useContext(EntitiesContext);
  const { apiRequest } = useContext(APIRequestContext);
  const [orgs, setOrgs] = useState<OrganizationList[]>([]);
  const [openPasswordChanger, setOpenPasswordChanger] = useState<boolean>(false);



  const handleChangePassword = (pass: string) => {
    apiRequest<null>("PUT", "api/v1/auth/chgpass", { jsonData: { password: pass } })
      .then(result => { setOpenPasswordChanger(false) })
      .catch(error => { setOpenPasswordChanger(false) }); // ERROR WARNING
  }

  useEffect(() => {
    const getDevices = async () => {
      apiRequest<PageResponse<DeviceList>>("GET", "api/v1/device")
        .then(result => { setDevices(deviceListToDeviceListExpanded(result.values)); })
        .catch(error => { });
    }

    const getOrgs = async () => {
      apiRequest<PageResponse<OrganizationList>>("GET", "api/v1/organization")
        .then(result => { setOrgs(result.values); })
        .catch(error => { });
    }
    if (devices.length === 0) getDevices();
    getOrgs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      <Grid item className={classes.boxLeft} md={9}>
        <TitledCard title="userDetails" gridTemplate={classes.grid}>
          <div className={classes.item}>
            <p><FormattedMessage id="name" /></p>
            <p>{currentUser.name}</p>
          </div>
          <div className={classes.item}>
            <p><FormattedMessage id="email" /></p>
            <p>{currentUser.email}</p>
          </div>
          <div className={classes.item}>
            <p><FormattedMessage id="organization" /></p>
            <p>{orgs.find(org => org._id === currentUser.organization_id)?.name}</p>
          </div>
          <div className={classes.item}>
            <p><FormattedMessage id="userType" /></p>
            <p><FormattedMessage id={currentUser.role === Roles.ADMIN ? 'admin' :
              currentUser.role === Roles.ORGADMIN ? 'orgadmin' :
                currentUser.role === Roles.USER ? 'user' : 'spectator'} /></p>
          </div>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<EditIcon />}
            onClick={() => { setOpenPasswordChanger(true) }}
          >
            <FormattedMessage id='changePassword' />
          </Button>
          <UserMFA />
        </TitledCard>
      </Grid>

      <Grid item className={classes.boxRight} sm={3}>
        <TitledCard title="devices" gridTemplate={classes.grid2}>
          {currentUser.assigned_devices.map((dev, index) => <div key={`device-${index}`}>{devices.find(device => device._id === dev)?.name}</div>)}
        </TitledCard>
      </Grid>
      {openPasswordChanger && <PasswordChanger
        open={openPasswordChanger}
        handleAgree={handleChangePassword}
        handleClose={() => setOpenPasswordChanger(false)}
      />}
    </Grid >
  );
}