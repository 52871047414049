
import { Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forGaugesLPTCM } from '../../../utils/lptcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateEP1: {
      gridTemplateAreas: `"ga1 gr1"`,
      gridTemplateRows: "100%",
      gridTemplateColumns: "calc(15% - 3px) calc(85% - 3px)",
      maxWidth: "100%",
      maxHeight: "100%",
      height: theme.shape.graphBox.smallest.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateRows: "200px 1fr",
        maxHeight: "unset",
        height: "unset",
      }
    },
  }),
);

interface ElectricParamProps {
  sideNames: Array<string>//the names of the sides
}

export interface gaugeFinal {
  name: string,
  value: number | undefined,
  color: string,
  max: number | undefined
}

export interface gaugeMid {
  title: string
  array: Array<gaugeFinal>
}

export interface gaugeProps extends Array<gaugeMid> {
}


export default withWidth()(function ElectricParam(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  const { sideNames } = props;
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forGaugesLPTCM(deviceReport, deviceFull);
  const translationAppend = useMemo(() => {
    switch (sideNames.length) {
      case 2:
        return "";
      case 3:
        return "-triple";
      case 4:
        return "-quad";
      case 5:
        return "-quin";
      case 6:
        return "-sext";
    }
  }, [sideNames.length]);
  //primario e secundario
  return (
    <Grid container className={classes.SideContainer}>
      {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>
          {gaugeData[side] && gaugeData[side].map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${side * 3 + type}`} title={gaugeData[side][type].title}>
            <GaugeGraph smallNumber data={gaugeData[side][type].array}></GaugeGraph>
          </SubTitledCard>)}
          <SubTitledCard gridArea='gr1' title={"current"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[(`iv-${side * 4}` as 'iv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`iv-${1 + side * 4}` as 'iv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`iv-${2 + side * 4}` as 'iv-1')] : undefined
              ]]}
              ysnames={[[
                (`iv-${side * 4}${translationAppend}`),
                (`iv-${1 + side * 4}${translationAppend}`),
                (`iv-${2 + side * 4}${translationAppend}`)
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['currentUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})
