
import { createStyles, Grid, isWidthDown, makeStyles, useTheme, withWidth } from '@material-ui/core';
import React, { useContext, useRef } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import RoundGauge from '../../../components/device/final/RoundGauge';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { createMovingAverage } from '../../../utils/format-data';
import { forGlobalStatus } from '../../../utils/mcm-functions';


const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    sfBox: {
      gridTemplateAreas: `
          "g0"
          "g1"
          "g2"
          "g3"
          "g4"`,
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      gridTemplateRows: "repeat(5,19.2%)",
      gridTemplateColumns: "100%",
      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateAreas: `
          "g0 g1 g2 g3 g4"`,
        height: 'unset',
        gridGap: "6px",
        padding: "10px",
        gridTemplateRows: "100%",
        gridTemplateColumns: "repeat(5,19.2%)",
      }
    },
    sfGraph: {
      height: '100%'
    }
  }),
);


export default withWidth()(function GlobalStatus(props: React.PropsWithChildren<{ isGCM?: boolean }>) {
  const classes = useStyles();
  const { isGCM } = props;
  const { deviceFull, deviceMainHistory, deviceReport } = useContext(EntitiesContext);
  const mainGridReference = useRef(null);
  const data = forGlobalStatus(deviceReport, deviceFull, isGCM);
  const theme: ThemeExtended = useTheme();
  const line1 = 1
  const line2 = 2

  const toLocalTimezone = (ISOdate: string): string => {
    //console.log(ISOdate)
    const value = new Date(ISOdate);
    return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
  }

  const lines = deviceFull && deviceMainHistory !== undefined && deviceMainHistory['timestamp'] ? [{
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line1,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line1,
    line: {
      color: '#f7dc99',
      width: 1,
      dash: 'dot'
    }
  }, {
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line2,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line2,
    line: {
      color: '#f88787',
      width: 2,
      dash: 'dot'
    }
  }] : undefined;

  return (
    <Grid ref={mainGridReference} container className={classes.SideContainer}>
      <Grid item xs={isWidthDown('sm', (props as any).width) ? 12 : 2}>
        <TitledCard gridTemplate={classes.sfBox} title={'severityFactors'}>
          {data && data.map((d, index) => <SubTitledCard key={`g${index}`} gridArea={`g${index}`} title={d.name}>
            <RoundGauge height={85} data={d} />
          </SubTitledCard>)}
        </TitledCard>
      </Grid>

      <Grid item xs={isWidthDown('sm', (props as any).width) ? 12 : 10}>
        <TitledCard forceHeight={theme.shape.graphBox.normal.height} title={'severityFactors'}>
          <DraggableGraph
            ys={[[
              deviceMainHistory ? deviceMainHistory['sf-0'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-0']) : undefined,
              deviceMainHistory ? deviceMainHistory['sf-1'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-1']) : undefined,
              deviceMainHistory ? deviceMainHistory['sf-2'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-2']) : undefined,
              deviceMainHistory ? deviceMainHistory['sf-3'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-3']) : undefined,
              deviceMainHistory ? deviceMainHistory['sf-4'] : undefined,
              deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-4']) : undefined,
            ]]}
            ysnames={[[
              isGCM ? `sf-0-gcm` : `sf-0-mcm`, isGCM ? "avg-sf-0-gcm" : "avg-sf-0-mcm",
              isGCM ? `sf-1-gcm` : `sf-1-mcm`, isGCM ? "avg-sf-1-gcm" : "avg-sf-1-mcm",
              isGCM ? `sf-2-gcm` : `sf-2-mcm`, isGCM ? "avg-sf-2-gcm" : "avg-sf-2-mcm",
              isGCM ? `sf-3-gcm` : `sf-3-mcm`, isGCM ? "avg-sf-3-gcm" : "avg-sf-3-mcm",
              isGCM ? `sf-4-gcm` : `sf-4-mcm`, isGCM ? "avg-sf-4-gcm" : "avg-sf-4-mcm"
            ]]}
            colors={[[
              '#ffa50024', '#ffa500',
              '#ff000024', '#ff0000',
              '#ad000e24', '#ad000e',
              '#00770124', '#007701',
              '#09729924', '#097299',
            ]]}
            x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
            yrange={[0, 3]}
            shapes={lines}
            titlex={''}
            titley={['severity_factor_units']}
            graphType={'linegl'}
            emptyMessage={'no7days'}
            hoverDecimalPoints={2}
            enableReportLinks
          />
        </TitledCard>
      </Grid>
    </Grid>

  );
})






