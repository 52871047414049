
import { Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forWaveforms } from '../../../utils/tcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateWV: {
      [theme.breakpoints.up('sm')]: {
        gridTemplateAreas: `
          "gr1 gr2 gr3"`,
        gridTemplateRows: "100%",
        gridTemplateColumns: "1fr 330px 330px",
        maxHeight: theme.shape.graphBox.smallest.height,
        height: theme.shape.graphBox.smallest.height,
        gridGap: "6px",
        padding: "10px",
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        gridTemplateAreas: `
          "gr1 gr1"
          "gr2 gr3"`,
        gridTemplateRows: "repeat(2,49.6%)",
        gridTemplateColumns: "repeat(2,49.6%)",
        maxHeight: theme.shape.graphBox.biggest.height,
        height: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px",
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "gr1"
          "gr2"
          "gr3"`,
        gridTemplateRows: "300px 244px 244px",
        gridTemplateColumns: "100%",
        maxHeight: theme.shape.graphBox.biggest.height,
        height: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px",
      }
    },
  }),
);

export default withWidth()(function Waveforms(props: React.PropsWithChildren<{
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
  sideNames: Array<string>//the names of the sides
}>) {
  const classes = useStyles();
  const { deviceFull, deviceWaveforms } = useContext(EntitiesContext);
  const { hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, sideNames } = props;
  const voltageSide = [hasV1, hasV2, hasV3, hasV4 === true, hasV5 === true, hasV6 === true];
  // console.log(voltageSide)
  const data = forWaveforms(deviceWaveforms, deviceFull)
  return (
    <Grid container className={classes.SideContainer}>
      {data && data.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateWV} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull?.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1'>
            {value[0] && <DraggableGraph
              x={value[0].x as number[]}
              ys={[value[0].ys] as number[][][]}
              titlex={'msUnits'}
              titley={['amplitudeUnits']}
              ysnames={[side === 0 ? ['pnv-0', 'pnv-1', 'pnv-2', 'iv-0', 'iv-1', 'iv-2'] : ['pnv-4', 'pnv-5', 'pnv-6', 'iv-4', 'iv-5', 'iv-6']]}
              colors={[["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8']]}
              graphType={'linegl'}
              notTimezone
              removeLegendPrefix
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title='voltagePark'>
            {value[1] && <DraggableGraph
              x={value[1].x as number[]}
              ys={voltageSide[side] ? [value[1].ys] as number[][][] : [[[]]]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#3473ba"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title='currentPark'>
            {value[2] && <DraggableGraph
              x={value[2].x as number[]}
              ys={[value[2].ys] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#e23434"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






