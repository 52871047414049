var Plotly = require('plotly.js/lib/core');

// plotly d3 is mandatory in any graph so i used it separately
Plotly.register([
    require('plotly.js/lib/bar'),
    require('plotly.js/lib/scattergl'),
    require('plotly.js/lib/scatterpolar'),
    require('plotly.js/lib/indicator'),
]);

export default Plotly
//module.exports = Plotly;