import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: 5
    }
  }),
);

interface colorProps {
  val: string,
  onClick: any,
  title: string,
  options: string[]
}

export default function ColorCirclePicker(props: PropsWithChildren<colorProps>) {
  const { val, onClick, title, options } = props
  const classes = useStyles();

  const handleClick = (color: string) => {
    onClick(color)
  }

  return (
    <div>
      <Typography>
        <FormattedMessage id={title}></FormattedMessage>
      </Typography>
      {options.map(color => {
        if (color === val) {
          return <IconButton className={classes.button} key={'icon-' + color} onClick={() => handleClick(color)}><RadioButtonCheckedIcon fontSize={"large"} htmlColor={color}></RadioButtonCheckedIcon></IconButton>
        } else {
          return <IconButton className={classes.button} key={'icon-' + color} onClick={() => handleClick(color)}><RadioButtonUncheckedIcon fontSize={"large"} htmlColor={color}></RadioButtonUncheckedIcon></IconButton>
        }
      })}

    </div>
  );
}
/// TROCAR A PARTE DO TCM  PARA CHILDREN