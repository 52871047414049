import { AppBar, createStyles, Fade, IconButton, InputBase, isWidthUp, Theme, Toolbar, Tooltip, useTheme, withWidth } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/SearchRounded';
import ViewListIcon from '@material-ui/icons/ViewListRounded';
import ViewModuleIcon from '@material-ui/icons/ViewModuleRounded';
import FirstPageIcon from '@material-ui/icons/FirstPageRounded';
import LastPageIcon from '@material-ui/icons/LastPageRounded';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import DashboardCard from '../../components/dashboard/DashboardCard';
import DashboardTable from '../../components/dashboard/DashboardTable';
import ScrollableContent from '../../components/global/ScrollableContent';
import { ThemeExtended } from '../../components/global/Theme';
import { EntitiesContext } from "../../contexts/entities-context";
import { SocketContext } from '../../contexts/socket-context';
import { DeviceList, DeviceListExpanded, DeviceType } from '../../interfaces/Device';
import { OrganizationList } from '../../interfaces/Organization';
import { SubstationType } from '../../interfaces/Substations';
import { fullSFSort } from '../../utils/format-data';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      justifyContent: "flex-end",
      marginBottom: theme.spacing(1)
    },
    fullHeigh: {
      height: "100%",
      paddingTop: 8
    },
    fullWidth: {
      width: "100%"
    },
    detailsPanel: {
      marginLeft: theme.spacing(1),
      height: 'calc(100% - 48px)'
    },
    gridPanel: {
      height: 'fit-content',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(auto-fill,minmax(275px,1fr))',
      gridColumnGap: 'var(--spacing)',
      width: '100%',
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%"
    },
    gridPanelCards: {
      height: "calc(100vh - 137px)",
      maxHeight: "calc(100vh - 137px)",
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(auto-fill,minmax(275px,1fr))',
      // gridTemplateRows: 'repeat(auto-fill, minmax(270px,1fr))',
      gridTemplateRows: "auto auto auto",
      gridColumnGap: 'var(--spacing)',
      flexGrow: 0,
      flexBasis: "100%",
      gridAutoFlow: "column",
      overflowX: "auto",
    },
    panel: {
      height: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        height: 'unset'
      },
      width: '100%',
      overflow: "hidden",
      padding: "0 19px"
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.dark,
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.contrastText, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: 8,
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    cycle: {
      color: theme.palette.primary.dark
    },
    carouselButtonsHolder: {
      position: "absolute",
      padding: 5,
      left: "calc(50vw - 144px)",
      [theme.breakpoints.down('sm')]: {
        position: "unset",
        left: "unset",
        display: "flex"
      },
    },
  }),
);

interface DashboardProps {
  location: {
    pathname: string
  }
}

enum DisplayStyle {
  CARD = 0, TABLE = 1
}

export default withWidth()(function DashboardPage(props: DashboardProps) {
  const classes = useStyles();
  const defaultDashboard = localStorage.getItem("defaultDashboard");
  const [displayStyle, setDisplayStyle] = useState<DisplayStyle>(defaultDashboard ? parseInt(defaultDashboard) : DisplayStyle.CARD); // 0 mixed,1 table,2cards
  const [search, setSearch] = useState<string>('');
  const [devicesShowing, setDevicesShowing] = useState<Array<DeviceListExpanded>>([]);
  const [wsOn, setWsOn] = useState<boolean>(false);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [restartTimer, setRestartTimer] = useState<boolean>(false);
  const { devices, substations, organizations } = useContext(EntitiesContext);
  const { joinRoom, leaveRoom } = useContext(SocketContext);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const ID = props.location.pathname.split('/')[2]
  const theme: ThemeExtended = useTheme();
  const intl = useIntl();
  //const reference = useRef<HTMLDivElement>(null)
  //const { displayToast } = useContext(ToastContext);
  const history = useHistory();

  const typeFilterFn = (dev: DeviceList) => {
    if (props.location.pathname.includes("lptcm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      if (dev.type !== DeviceType["L"] && dev.type !== DeviceType.Ld && dev.type !== DeviceType.L3 && dev.type !== DeviceType.L4 && dev.type !== DeviceType.L5) {
        return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['PVM'] || s.type === SubstationType['WTM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM PVM
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if ((subs.includes(dev._id))) {
          return false
        }
      }
    } else if (props.location.pathname.includes("tcm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      if (dev.type !== DeviceType["T"] && dev.type !== DeviceType.Td) {
        return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['PVM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM PVM
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if ((subs.includes(dev._id))) {
          return false
        }
      }
    }
    else if (props.location.pathname.includes("mcm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      if (dev.type !== DeviceType["M"]) {
        return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['PVM'] || s.type === SubstationType['WTM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM PVM para tirar
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if ((subs.includes(dev._id))) {
          return false
        }
      }
    } else if (props.location.pathname.includes("gcm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) {
          //console.log(dev, props.location.pathname,1)
          return false
        }
      }
      if (!(dev.type === DeviceType["Gi"] || dev.type === DeviceType["Gm"] || dev.type === DeviceType["Gc"] || dev.type === DeviceType["Gs"])) {
        //console.log(dev, props.location.pathname,2)
        return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['WTM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM WTM para tirar
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if ((subs.includes(dev._id))) {
          //console.log(dev, props.location.pathname,3)
          return false
        }
      }
    } else if (props.location.pathname.includes("substation")) {
      const devices = substations?.filter(s => s._id === ID || s.substation_id === ID).map(v => v.devices_id).flat()
      if (devices && !(devices.includes(dev._id))) {
        return false
      }
    } else if (props.location.pathname.includes("pvm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['PVM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM PVM
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if (!(subs.includes(dev._id))) {
          return false
        }
      } else {
        return false
      }
    } else if (props.location.pathname.includes("wtm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['WTM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM WTM
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if (!(subs.includes(dev._id))) {
          return false
        }
      } else {
        return false
      }
    } else if (props.location.pathname.includes("bcm")) {
      if (props.location.pathname.includes("/org/")) {
        const orgid = props.location.pathname.split('/')[3]
        if (dev.organization_id !== orgid) return false
      }
      const sub = substations?.filter(s => s.type === SubstationType['BCM'])  // TODOS OS DEVICES QUE FAZEM PARTE DE UM BCM
      if (sub) {
        const subs = sub.map(s => s.devices_id).flat()
        if (!(subs.includes(dev._id))) {
          return false
        }
      } else {
        return false
      }
    }
    return true
  }

  const cycleLayout = () => {
    //displayToast('success','failedLoading','failedLoading')
    const newValue = (displayStyle + 1) % 2
    setDisplayStyle(newValue);
    localStorage.setItem("defaultDashboard", newValue.toString());
  }

  useEffect(() => {
    const titleHelp = substations?.find(s => s._id === ID)?.name
    const solution = props.location.pathname.split('/')[1]
    const organization = props.location.pathname.split('/')[3]
    let org: OrganizationList | undefined;
    if (organization) org = organizations?.find(org => org._id === organization)
    if (ID && !organization) document.title = titleHelp ? 'Sub: ' + titleHelp + " - " + theme.palette.images.tabName : theme.palette.images.tabName
    else if (ID && organization) document.title = org ? 'Org: ' + org!.name + " - " + theme.palette.images.tabName : theme.palette.images.tabName
    else if (solution) document.title = `${solution.toLocaleUpperCase()} - ${theme.palette.images.tabName}`
    return () => {
      document.title = theme.palette.images.tabName
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID, props.location.pathname, substations])

  useEffect(() => {
    const timeout = setInterval(() => { setCarouselIndex(x => x + 1) }, 30000) // 10 min = 600000   30000ms = 30 sec
    return () => {
      clearInterval(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID, props.location.pathname, substations, displayStyle, search, devicesShowing, devices, props, carouselIndex, restartTimer])

  useEffect(() => {
    if (joinRoom && !wsOn) {
      joinRoom('join_dashboard');
      setWsOn(true);
    }
    return () => {
      if (leaveRoom && wsOn) {
        leaveRoom('leave_dashboard');
        setWsOn(false);
      }
    }
  }, [joinRoom, leaveRoom, wsOn])

  useEffect(() => {
    //console.log('dashboardpage',devices)
    const searchFilterFn = (dev: DeviceList) => {
      if (dev.name.toLowerCase().includes(search.toLowerCase())) {
        return true
      } else {
        return false
      }
    }
    const filteredDevs2 = devices.filter(typeFilterFn);
    if (filteredDevs2.length === 0 && !props.location.pathname.includes("dashboard")) {
      history.push('/dashboard')
    }
    const filteredDevs = filteredDevs2.filter(searchFilterFn);
    filteredDevs.forEach(d => d.name = d.name.replace(/\xA0/g, ' ')); //i dont know why it comes like this, but it breaks formatting
    const sortedDevs = filteredDevs ? filteredDevs.sort(fullSFSort) : [];
    //console.log(filteredDevs, sortedDevs)
    const width = window.innerWidth
    if (displayStyle === DisplayStyle.CARD) {
      let newX = (width < 960 ? width - 16 : width - 88) - 38;
      let newY = (window.innerHeight - 129) - 8;
      let x = Math.floor((newX / (269 + 16)));
      let y = Math.floor(newY / (263));//reality is 283+30 for bottom extra padding
      const maxIndex = Math.ceil(sortedDevs.length / (x * y));
      setLastIndex(maxIndex);
      const newIndex = ((carouselIndex % maxIndex) + maxIndex) % maxIndex;
      setDevicesShowing(sortedDevs.slice((x * y) * newIndex, (x * y) * (newIndex + 1)))
    } else if (displayStyle === DisplayStyle.TABLE) {
      setDevicesShowing(sortedDevs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, displayStyle, search, props, carouselIndex]);

  /*
  case 'red': return '#e23434';
  case 'yellow': return '#ffc000';
  case 'green': return '#2aa060';
  case 'blue': return '#3473ba';
  default: return '#00B1FF';*/

  return (
    <div className={classes.fullHeigh} /*ref={reference}*/>
      <div className={classes.fullWidth}>
        <AppBar elevation={0} position="static" >
          <Toolbar variant="dense" className={classes.toolbar}>

            {displayStyle === DisplayStyle.CARD && devicesShowing.length > 0 && lastIndex > 1 && <span className={classes.carouselButtonsHolder}>
              <IconButton onClick={() => { setCarouselIndex(x => 0) }} ><FirstPageIcon /></IconButton>
              <IconButton onClick={() => { setCarouselIndex(x => x - 1) }} ><NavigateBefore /></IconButton>
              <IconButton onClick={() => { setCarouselIndex(x => x + 1) }} ><NavigateNext /></IconButton>
              <IconButton onClick={() => { setCarouselIndex(x => lastIndex - 1) }} ><LastPageIcon /></IconButton>
            </span>}

            {displayStyle === DisplayStyle.CARD && <>
              <Tooltip title={intl.formatMessage({ id: "listMode" })}>
                <IconButton
                  onClick={cycleLayout}
                  className={classes.cycle}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>
            </>}
            {displayStyle === DisplayStyle.TABLE && <>
              <Tooltip title={intl.formatMessage({ id: "cardMode" })}>
                <IconButton
                  onClick={cycleLayout}
                  className={classes.cycle}
                >
                  <ViewModuleIcon />
                </IconButton>
              </Tooltip>
            </>}

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={intl.formatMessage({ id: "search" })}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={event => { setSearch(event.target.value) }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>

          </Toolbar>
        </AppBar>
      </div>

      <ScrollableContent dashboardHeight noHorizontal={isWidthUp('md', (props as any).width)}>
        <div className={classes.panel} >
          <div className={classes.gridPanel} >
            {displayStyle === DisplayStyle.CARD && devicesShowing.length > 0 && devicesShowing.map((dev, index) => (
              <DashboardCard restartTimer={setRestartTimer} key={`card-${index}-${dev._id}`} device={dev} subslist={substations} ></DashboardCard>
            ))}
          </div>
          {displayStyle === DisplayStyle.TABLE && devicesShowing.length > 0 && <Fade><DashboardTable list={devicesShowing}></DashboardTable></Fade>}
        </div>
      </ScrollableContent>
    </div >

  );
})