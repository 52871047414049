
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import LongDataBox from '../../../components/device/middle/LongDataBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forHarmonics } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateH: {
      gridTemplateAreas: `
          "gr1 gr3"
          "gr2 gr4"`,
      gridTemplateRows: "16fr 1fr",
      gridTemplateColumns: "49.85% 49.85%",
      maxHeight: "799px",
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1"
          "gr2"
          "gr3" 
          "gr4"`,
        gridTemplateRows: "44.7% 4.4% 44.7% 4.4%",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: "900px",
        gridGap: "6px",
        padding: "10px",
      }
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    }
  }),
);
//MAYBE I CAN KEEP ALL THE GRAPHS AND JUST CHANGE IF THEY DISPLAY OR NOT

interface HarmonicsProps {
  hasV?: boolean
}

export default function Harmonics(props: React.PropsWithChildren<HarmonicsProps>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceReport } = useContext(EntitiesContext);
  const [parityPrimaryCurrent, setParityPrimaryCurrent] = useState<boolean>(false);
  const [parityPrimaryVoltage, setParityPrimaryVoltage] = useState<boolean>(false);
  const [paritySecondaryCurrent, setParitySecondaryCurrent] = useState<boolean>(false);
  const [paritySecondaryVoltage, setParitySecondaryVoltage] = useState<boolean>(false);
  const data = forHarmonics(deviceReport);
  const changeParity = (side: number, current: number) => {
    if (side === 0) {
      if (current === 0) {
        setParityPrimaryCurrent(!parityPrimaryCurrent)
      } else {
        setParityPrimaryVoltage(!parityPrimaryVoltage)
      }
    } else {
      if (current === 0) {
        setParitySecondaryCurrent(!paritySecondaryCurrent)
      } else {
        setParitySecondaryVoltage(!paritySecondaryVoltage)
      }
    }
  };
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateH} >
          <SubTitledCard gridArea='gr1' title='voltage' buttons={
            <Button className={classes.button} onClick={() => { changeParity(0, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              empty={!hasV}
              ys={[data[0][0][(parityPrimaryCurrent ? 'even' : 'odd')].y]}
              x={data[0][0][(parityPrimaryCurrent ? 'even' : 'odd')].x}
              ysnames={[['ppv-0-icm', 'ppv-1-icm', 'ppv-2-icm']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2'>
            <LongDataBox
              empty={!hasV}
              parts={[
                { title: 'Vuv1:', value: si_format(data[0][0].one[0], 'V') },
                { title: 'Vvw1:', value: si_format(data[0][0].one[1], 'V') },
                { title: 'Vwu1:', value: si_format(data[0][0].one[2], 'V') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(0, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={[data[0][1][(parityPrimaryVoltage ? 'even' : 'odd')].y]}
              x={data[0][1][(parityPrimaryVoltage ? 'even' : 'odd')].x}
              ysnames={[['iv-0-icm', 'iv-1-icm', 'iv-2-icm']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr4'>
            <LongDataBox
              parts={[
                { title: 'Iu1:', value: si_format(data[0][1].one[0], 'A') },
                { title: 'Iv1:', value: si_format(data[0][1].one[1], 'A') },
                { title: 'Iw1:', value: si_format(data[0][1].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






