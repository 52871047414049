import { makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    TitleCardBox: {
        backgroundColor: "white",
        borderRadius: 10,
        margin: "4px"
        //height: "100%" //THIS WORKED before 26/4/2021
    },
    titlecardtitle: {
        padding: "12px 0 4px 25px",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: 1.53,
        color: theme.palette.primary.main,
        display: 'flex'
    },
    titlecardmain: {
        display: "grid",
        gridTemplateColumns: ((props) => (props as any).fillDiv && "100%")
    },
    pt: {
        paddingTop: "0 !important"
    }
}
))

interface TitleCardProps {
    title?: string,
    number?: string,
    gridTemplate?: any
    measure?: string
    forceHeight?: number | string
    buttons?: any
    fillDiv?: boolean
    className?: string
}

export default function TitledCard(props: PropsWithChildren<TitleCardProps>) {
    const { title, number, children, gridTemplate, measure, forceHeight, buttons, className } = props;
    const classes = useStyles(props)

    return (
        <div className={clsx(classes.TitleCardBox, className)}>
            {title && <div className={classes.titlecardtitle}><FormattedMessage id={title}></FormattedMessage>{number ? `-${number}` : ''} {measure ? `(${measure})` : ''} {buttons}</div>}
            <div className={classes.titlecardmain + ' ' + (title ? classes.pt : '') + ' ' + gridTemplate} style={forceHeight ? { height: forceHeight } : {}} >{children}</div>
        </div>
    );
}