import { createStyles, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React from "react";
import TitledCard from "../../components/device/middle/TitledCard";
import { ThemeExtended } from "../../components/global/Theme"
import LanguageIcon from '@material-ui/icons/Language';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    image: {
      padding: 20,
      width: "calc(100% - 12px)"
    },
    icons: {
      color: theme.palette.primary.main,
      fontSize: "2.5rem",
      paddingTop: 11
    },
    grid: {
      gridTemplateColumns: "66px 1fr",
      gridTemplateRows: "auto",
      gridGap: 2,
      padding: 7,
      paddingBottom: 13,
    },
    grid2: {
      padding: 7,
      paddingBottom: 13,
    },
    text: {
      paddingTop: 10,
      margin: 0,
      lineHeight: "30px",
      position: "relative",
      display: "block",
      height: "auto",
      whiteSpace: "nowrap",
      color: theme.palette.primary.main,
    },    
    boxLeft: {
      padding: "15px 0px 15px 15px"
    },
    boxRight: {
      padding: "15px 15px 15px 15px"
    }
  }),
);

export default function ContactsPage() {
  const classes = useStyles();
  const theme: ThemeExtended = useTheme();

  const getIcon = (name: string): any => {
    switch (name) {
      case "phone": return <PhoneIcon className={classes.icons}></PhoneIcon>;
      case "business": return <BusinessIcon className={classes.icons}></BusinessIcon>;
      case "email": return <EmailIcon className={classes.icons}></EmailIcon>;
      case "site": return <LanguageIcon className={classes.icons}></LanguageIcon>;
      default: <PhoneIcon className={classes.icons}></PhoneIcon>

    }
  }

  return (
    <Grid container>
      <Grid item className={classes.boxLeft} sm={3}>
        <TitledCard gridTemplate={classes.grid}>
          {theme.palette.images.contacts.map((val: any) => <React.Fragment key={val.value}>{getIcon(val.name)}<div className={classes.text}>{val.value}</div></React.Fragment>
          )}
        </TitledCard>
      </Grid>

      <Grid item className={classes.boxRight} md={9}>
        <TitledCard gridTemplate={classes.grid2}>
          {theme.palette.images.contactMap && theme.palette.images.contactMap[0] === "text" && theme.palette.images.contactMap.map((contact: any, index: number) => {
            if (index === 0) {
              return <React.Fragment key={`$contact${index}`} />
            } else if (index === 1) {
              return <Typography paragraph variant={'h4'} key={`$contact${index}`}>{contact}</Typography>
            } else {
              return <Typography variant={'h6'} key={`$contact${index}`}>{contact}</Typography>
            }
          }
          )}
          {theme.palette.images.contactMap && theme.palette.images.contactMap[0] !== "text" && <img className={classes.image} src={theme.palette.images.contactMap} alt="Map with locations"></img>}
        </TitledCard>
      </Grid>
    </Grid >
  );
}