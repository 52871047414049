import { DeviceFull, DeviceType } from '../interfaces/Device';
import { ReportList, ReportWaveforms } from '../interfaces/Report';
import { gaugeProps } from '../pages/tcm/tabs/ElectricParam';
import { chunkArray, formatForPhasor, makeArrowsFromDataPolar } from './format-data';

export const forGaugesLPTCM = (report: ReportList | undefined, device: DeviceFull | undefined): Array<gaugeProps> => {
    const n = device?.nameplate?.side_labels ? device.nameplate.side_labels.length : 0
    const sidesArray = Array(n).fill(1)
    const maxes = sidesArray.map((value, side) => {
        return Math.max(report?.electricParam?.iv ? report.electricParam.iv[0 + side * 4] : 0,
            report?.electricParam?.iv ? report.electricParam.iv[1 + side * 4] : 0,
            report?.electricParam?.iv ? report.electricParam.iv[2 + side * 4] : 0,
            report?.electricParam?.iv ? report.electricParam.iv[3 + side * 4] : 0)
    })
    const two = sidesArray.map((value, side) => {
        return {
            variable: 'A',
            title: 'current',
            array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
                { name: `I${side === 0 ? 'A' : 'a'}`, value: report?.electricParam?.iv && report.electricParam.iv[0 + side * 4], color: '#ec4747', max: maxes[side] },
                { name: `I${side === 0 ? 'B' : 'b'}`, value: report?.electricParam?.iv && report.electricParam.iv[1 + side * 4], color: '#2ebd70', max: maxes[side] },
                { name: `I${side === 0 ? 'C' : 'c'}`, value: report?.electricParam?.iv && report.electricParam.iv[2 + side * 4], color: '#4684c9', max: maxes[side] },
                { name: 'I', value: report?.electricParam?.iv && report.electricParam.iv[3 + side * 4], color: '#f9c937', max: maxes[side] }
            ]
        }
    })
    return two.map((value, side) => [two[side]])
}

export const forVectorGraphsLPTCM = (device: ReportList | undefined) => { // IA (amplitude depois fase) IB IC ia ib ic
    if (device === undefined) {
        return undefined
    }
    var formattedPhasors = formatForPhasor(device.electricParam.phasors)
    // console.log(formattedPhasors)
    formattedPhasors = {
        amplitudes: formattedPhasors.amplitudes?.map(arr => arr.slice(0, 3).reduce((a, b) => a + b, 0) === 0 ? arr.slice(3) : arr.slice(3, 6).reduce((a, b) => a + b, 0) === 0 ? arr.slice(0, 3) : arr),
        degrees: formattedPhasors.degrees?.map(arr => arr.slice(0, 3).reduce((a, b) => a + b, 0) === 0 ? arr.slice(3) : arr.slice(3, 6).reduce((a, b) => a + b, 0) === 0 ? arr.slice(0, 3) : arr)
    }
    //console.log(formattedPhasors)
    const max = formattedPhasors.amplitudes?.map(arr => arr.reduce((a, b) => a > b ? a : b));
    //console.log(max, formattedPhasors.amplitudes)
    const arrowedPhasors = formattedPhasors.amplitudes?.map((sideAmps, side) => {
        return sideAmps.map((amps, valIndex) => {
            return makeArrowsFromDataPolar(amps, formattedPhasors!.degrees![side][valIndex], max && max[side] ? max[side] : 1)
        })
    })
    return arrowedPhasors;
}
//many functions from here are the same as tcm
export const forWaveforms = (waves: ReportWaveforms | undefined, deviceFull: DeviceFull | undefined, lptcm?: boolean): Array<Array<{ x: Array<number | string>, ys: Array<Array<number | string>> }>> => {
    if (waves === undefined || deviceFull === undefined) {
        return [[{ x: [], ys: [[]] }]]
    }
    let has2Secondary = 0
    if (deviceFull.type === DeviceType.Td || deviceFull.type === DeviceType.Ld) has2Secondary = 1;
    else if (deviceFull.type === DeviceType.L3) has2Secondary = 2;
    else if (deviceFull.type === DeviceType.L4) has2Secondary = 3;
    else if (deviceFull.type === DeviceType.L5) has2Secondary = 4;
    const wPNv = chunkArray(waves.wPNv, 3); // SAO SO zeros se nao houver tensoes
    const wI = chunkArray(waves.wI, 3);
    //let wVPIexc = waves.wVPIexc.slice(0, 4)
    //console.log(has2Secondary,deviceFull)
    //2-8-12
    //3-10-16
    //4-12-20
    let wVPI = chunkArray(waves.wVPIexc.slice(4, 8 + 2 * has2Secondary), 2);
    let wVPV = chunkArray(waves.wVPIexc.slice(8 + 2 * has2Secondary, 12 + 4 * has2Secondary), 2); // 16 partes com 3 lados

    let currents = wVPI.map((each) => {
        return {
            x: each ? each[0] : undefined, y: each ? each[1] : undefined
        }
    });
    let voltages = wVPV.map((each) => {
        return {
            x: each ? each[0] : undefined, y: each ? each[1] : undefined
        }
    });
    // console.log(currents, voltages)
    let main = deviceFull.nameplate.side.map((each, index) => {
        if (!wI || !wPNv || !wI[index] || !wPNv[index]) return { x: [], ys: [[]] }
        const wPNv2 = wPNv[index];
        const wI2 = wI[index];
        return {
            x: [...wI2[0].keys()].map(value => value / deviceFull.config_device.fs_analog * 1000),
            ys: lptcm ?
                [wI2[0], wI2[1], wI2[2]] as Array<Array<number>> :
                [wPNv2[0], wPNv2[1], wPNv2[2], wI2[0], wI2[1], wI2[2]] as Array<Array<number>>
        };
    });
    const range = main.map((d, index) => index)
    return range.map(id => [
        { x: main[id].x, ys: main[id].ys },
        { x: voltages[id] ? voltages[id]?.x : [], ys: [voltages[id] ? voltages[id].y : []] },
        { x: currents[id] ? currents[id].x : [], ys: [currents[id] ? currents[id].y : []] },
    ])

}

export const forHarmonics = (device: ReportList | undefined, side_labels: string[] | undefined): Array<Array<{ one: Array<number>, even: { x: Array<number>, y: Array<Array<number>> }, odd: { x: Array<number>, y: Array<Array<number>> } }>> => {
    if (device === undefined) {
        return [[{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }], [{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }]]
    }
    const evenx = Array(20).fill(1).map((value, index) => (index + 1) * 2) //20
    const oddx = Array(19).fill(1).map((value, index) => (index + 1) * 2 + 1) //19
    const [current, voltage] = prepareHarmonicsData(device.pwrQuality.thdI, device.pwrQuality.thdPNv, side_labels)
    return current.map((x, index) => [  //    SIDE 
        {   // CURRENT
            even: {
                x: evenx,
                y: current[index].map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
            },
            odd: {
                x: oddx,
                y: current[index].map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
            },
            one: current[index] && current[index][0] && current[index][1] && current[index][2] ? [current[index][0][0], current[index][1][0], current[index][2][0]] : [0, 0, 0]
        },
        {   // VOLTAGE
            even: {
                x: evenx,
                y: voltage[index].map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
            },
            odd: {
                x: oddx,
                y: voltage[index].map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
            },
            one: voltage[index] && voltage[index][0] && voltage[index][1] && voltage[index][2] ? [voltage[index][0][0], voltage[index][1][0], voltage[index][2][0]] : [0, 0, 0]
        }
    ])
}

const prepareHarmonicsData = (I: Array<number>, V: Array<number>, side_labels: string[] | undefined): Array<Array<Array<Array<number>>>> => {
    const length = side_labels ? side_labels.length : 2
    if (I === undefined) return Array(length).fill([]);
    const sliceAmount = length * 4
    const harms1 = I.slice(sliceAmount);
    const arr1 = chunkArray(harms1, 150)
    const harmonics_current = arr1.map(a => chunkArray(a, 50))
    if (V === undefined) return [harmonics_current, []]
    const harms2 = V.slice(sliceAmount);
    const arr2 = chunkArray(harms2, 150)
    const harmonics_voltage = arr2.map(a => chunkArray(a, 50))
    return [harmonics_current, harmonics_voltage]
}

const LPTCMvariables = {  // TO BE CONFIRMED
    primary: [[
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'phasors-7',
        'phasors-9',
        'phasors-11'
    ], [
        'sf-3',
        'sf-4'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'rt-7'
    ], [
        'pwrloss-3',
        'varlcc-3',
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-4',
        'rt-5',
        'rt-6',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
    ]]
}

const LPTCMDvariables = {  // TO BE CONFIRMED
    primary: [[
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'phasors-7',
        'phasors-9',
        'phasors-11'
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'phasors-13',
        'phasors-15',
        'phasors-17'
    ], [
        'sf-5',
        'sf-6'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'rt-11'
    ], [
        'pwrloss-3',
        'varlcc-3',
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-8',
        'rt-9',
        'rt-10',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
        'auxdig-16',
        'auxdig-17',
        'auxdig-18',
        'auxdig-19',
        'auxdig-20',
        'auxdig-21',
    ]]
}


const LPTCMTvariables = {  // TO BE CONFIRMED
    primary: [[
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'phasors-7',
        'phasors-9',
        'phasors-11'
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'phasors-13',
        'phasors-15',
        'phasors-17'
    ], [
        'sf-5',
        'sf-6'
    ]],
    quaternary: [[
        'iv-12',
        'iv-13',
        'iv-14',
        'iv-15',
        'vari-12',
        'vari-13',
        'vari-14',
        'vari-15',
        'thdi-12',
        'thdi-13',
        'thdi-14',
        'thdi-15',
        'unbi-3',
        'phasors-19',
        'phasors-21',
        'phasors-23'
    ], [
        'sf-7',
        'sf-8'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'rt-15'
    ], [
        'pwrloss-3',
        'varlcc-3',
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-12',
        'rt-13',
        'rt-14',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
        'auxdig-16',
        'auxdig-17',
        'auxdig-18',
        'auxdig-19',
        'auxdig-20',
        'auxdig-21',
        'auxdig-22',
        'auxdig-23',
        'auxdig-24',
        'auxdig-25',
        'auxdig-26',
        'auxdig-27',
        'auxdig-28',
        'auxdig-29',
        'auxdig-30',
    ]]
}

const LPTCMQvariables = {  // TO BE CONFIRMED
    primary: [[
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'phasors-7',
        'phasors-9',
        'phasors-11'
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'phasors-13',
        'phasors-15',
        'phasors-17'
    ], [
        'sf-5',
        'sf-6'
    ]],
    quaternary: [[
        'iv-12',
        'iv-13',
        'iv-14',
        'iv-15',
        'vari-12',
        'vari-13',
        'vari-14',
        'vari-15',
        'thdi-12',
        'thdi-13',
        'thdi-14',
        'thdi-15',
        'unbi-3',
        'phasors-19',
        'phasors-21',
        'phasors-23'
    ], [
        'sf-7',
        'sf-8'
    ]],
    quinary: [[
        'iv-16',
        'iv-17',
        'iv-18',
        'iv-19',
        'vari-16',
        'vari-17',
        'vari-18',
        'vari-19',
        'thdi-16',
        'thdi-17',
        'thdi-18',
        'thdi-19',
        'unbi-4',
        'phasors-25',
        'phasors-27',
        'phasors-29'
    ], [
        'sf-9',
        'sf-10'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'rt-19'
    ], [
        'pwrloss-3',
        'varlcc-3',
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-16',
        'rt-17',
        'rt-18',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
        'auxdig-16',
        'auxdig-17',
        'auxdig-18',
        'auxdig-19',
        'auxdig-20',
        'auxdig-21',
        'auxdig-22',
        'auxdig-23',
        'auxdig-24',
        'auxdig-25',
        'auxdig-26',
        'auxdig-27',
        'auxdig-28',
        'auxdig-29',
        'auxdig-30',
    ]]
}

const LPTCMCvariables = {  // TO BE CONFIRMED
    primary: [[
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'phasors-7',
        'phasors-9',
        'phasors-11'
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'phasors-13',
        'phasors-15',
        'phasors-17'
    ], [
        'sf-5',
        'sf-6'
    ]],
    quaternary: [[
        'iv-12',
        'iv-13',
        'iv-14',
        'iv-15',
        'vari-12',
        'vari-13',
        'vari-14',
        'vari-15',
        'thdi-12',
        'thdi-13',
        'thdi-14',
        'thdi-15',
        'unbi-3',
        'phasors-19',
        'phasors-21',
        'phasors-23'
    ], [
        'sf-7',
        'sf-8'
    ]],
    quinary: [[
        'iv-16',
        'iv-17',
        'iv-18',
        'iv-19',
        'vari-16',
        'vari-17',
        'vari-18',
        'vari-19',
        'thdi-16',
        'thdi-17',
        'thdi-18',
        'thdi-19',
        'unbi-4',
        'phasors-25',
        'phasors-27',
        'phasors-29'
    ], [
        'sf-9',
        'sf-10'
    ]],
    senary: [[
        'iv-20',
        'iv-21',
        'iv-22',
        'iv-23',
        'vari-20',
        'vari-21',
        'vari-22',
        'vari-23',
        'thdi-20',
        'thdi-21',
        'thdi-22',
        'thdi-23',
        'unbi-5',
        'phasors-31',
        'phasors-33',
        'phasors-35'
    ], [
        'sf-11',
        'sf-12'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'rt-23'
    ], [
        'pwrloss-3',
        'varlcc-3',
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-20',
        'rt-21',
        'rt-22',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
        'auxdig-16',
        'auxdig-17',
        'auxdig-18',
        'auxdig-19',
        'auxdig-20',
        'auxdig-21',
        'auxdig-22',
        'auxdig-23',
        'auxdig-24',
        'auxdig-25',
        'auxdig-26',
        'auxdig-27',
        'auxdig-28',
        'auxdig-29',
        'auxdig-30',
    ]]
}

export const LPTCMpermissions = {
    "electric_param": "006",
    "load_diagram": "00d",
    "power_quality": "013",
    "vector_diagram": "018",
    "waveforms": "01d",
    "global_status": "022",
    "excitation": "027",
    "transformation_ratio": "02a",
    "harmonics": "031",
}

export const getLPTCMTVariablesAdmin = (): Array<string> => {
    let one: any;
    let two: any;
    let three: any;
    let four: any;
    let five: any;
    one = LPTCMTvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
    one = one.reduce((a: any, b: any) => [...a, ...b], [])
    two = LPTCMTvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
    two = two.reduce((a: any, b: any) => [...a, ...b], [])
    three = LPTCMTvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
    three = three.reduce((a: any, b: any) => [...a, ...b], [])
    four = LPTCMTvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
    four = four.reduce((a: any, b: any) => [...a, ...b], [])
    five = LPTCMTvariables.quaternary.map((arr, indexArr) => arr.map((val, index) => val))
    five = five.reduce((a: any, b: any) => [...a, ...b], [])
    return [...one, ...two, ...four, ...five, ...three]
}

export const getLPTCMQVariablesAdmin = (): Array<string> => {
    let one: any;
    let two: any;
    let three: any;
    let four: any;
    let five: any;
    let six: any;
    one = LPTCMQvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
    one = one.reduce((a: any, b: any) => [...a, ...b], [])
    two = LPTCMQvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
    two = two.reduce((a: any, b: any) => [...a, ...b], [])
    three = LPTCMQvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
    three = three.reduce((a: any, b: any) => [...a, ...b], [])
    four = LPTCMQvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
    four = four.reduce((a: any, b: any) => [...a, ...b], [])
    five = LPTCMQvariables.quaternary.map((arr, indexArr) => arr.map((val, index) => val))
    five = five.reduce((a: any, b: any) => [...a, ...b], [])
    six = LPTCMQvariables.quinary.map((arr, indexArr) => arr.map((val, index) => val))
    six = six.reduce((a: any, b: any) => [...a, ...b], [])
    return [...one, ...two, ...four, ...five, ...six, ...three]
}

export const getLPTCMCVariablesAdmin = (): Array<string> => {
    let one: any;
    let two: any;
    let three: any;
    let four: any;
    let five: any;
    let six: any;
    let seven: any;
    one = LPTCMCvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
    one = one.reduce((a: any, b: any) => [...a, ...b], [])
    two = LPTCMCvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
    two = two.reduce((a: any, b: any) => [...a, ...b], [])
    three = LPTCMCvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
    three = three.reduce((a: any, b: any) => [...a, ...b], [])
    four = LPTCMCvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
    four = four.reduce((a: any, b: any) => [...a, ...b], [])
    five = LPTCMCvariables.quaternary.map((arr, indexArr) => arr.map((val, index) => val))
    five = five.reduce((a: any, b: any) => [...a, ...b], [])
    six = LPTCMCvariables.quinary.map((arr, indexArr) => arr.map((val, index) => val))
    six = six.reduce((a: any, b: any) => [...a, ...b], [])
    seven = LPTCMCvariables.senary.map((arr, indexArr) => arr.map((val, index) => val))
    seven = seven.reduce((a: any, b: any) => [...a, ...b], [])
    return [...one, ...two, ...four, ...five, ...six, ...seven, ...three]
}

export const getLPTCMVariablesAdmin = (isTriple?: boolean): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    if (isTriple) {
        one = LPTCMDvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
        one = one.reduce((a: any, b: any) => [...a, ...b], [])
        two = LPTCMDvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
        two = two.reduce((a: any, b: any) => [...a, ...b], [])
        three = LPTCMDvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
        three = three.reduce((a: any, b: any) => [...a, ...b], [])
        four = LPTCMDvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
        four = four.reduce((a: any, b: any) => [...a, ...b], [])
        return [...one, ...two, ...four, ...three]
    } else {
        one = LPTCMvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
        one = one.reduce((a: any, b: any) => [...a, ...b], [])
        two = LPTCMvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
        two = two.reduce((a: any, b: any) => [...a, ...b], [])
        three = LPTCMvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
        three = three.reduce((a: any, b: any) => [...a, ...b], [])
        return [...one, ...two, ...three]
    }
}

export const getLPTCMVariables = (isTriple?: boolean): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    if (isTriple) {
        one = LPTCMDvariables.primary[0].map((val, index) => { return val })
        two = LPTCMDvariables.secondary[0].map((val, index) => { return val })
        three = LPTCMDvariables.general[0].map((val, index) => { return val })
        four = LPTCMDvariables.tertiary[0].map((val, index) => { return val })
        return [...one, ...two, ...four, ...three]
    } else {
        one = LPTCMvariables.primary[0].map((val, index) => { return val })
        two = LPTCMvariables.secondary[0].map((val, index) => { return val })
        three = LPTCMvariables.general[0].map((val, index) => { return val })
        return [...one, ...two, ...three]
    }
}

export const getLPTCMTVariables = (): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    var five: any;
    one = LPTCMTvariables.primary[0].map((val, index) => { return val })
    two = LPTCMTvariables.secondary[0].map((val, index) => { return val })
    three = LPTCMTvariables.general[0].map((val, index) => { return val })
    four = LPTCMTvariables.tertiary[0].map((val, index) => { return val })
    five = LPTCMTvariables.quaternary[0].map((val, index) => { return val })
    return [...one, ...two, ...four, ...five, ...three]
}

export const getLPTCMQVariables = (): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    var five: any;
    var six: any;
    one = LPTCMQvariables.primary[0].map((val, index) => { return val })
    two = LPTCMQvariables.secondary[0].map((val, index) => { return val })
    three = LPTCMQvariables.general[0].map((val, index) => { return val })
    four = LPTCMQvariables.tertiary[0].map((val, index) => { return val })
    five = LPTCMQvariables.quaternary[0].map((val, index) => { return val })
    six = LPTCMQvariables.quinary[0].map((val, index) => { return val })
    return [...one, ...two, ...four, ...five, ...six, ...three]
}

export const getLPTCMCVariables = (): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    var five: any;
    var six: any;
    var seven: any;
    one = LPTCMCvariables.primary[0].map((val, index) => { return val })
    two = LPTCMCvariables.secondary[0].map((val, index) => { return val })
    three = LPTCMCvariables.general[0].map((val, index) => { return val })
    four = LPTCMCvariables.tertiary[0].map((val, index) => { return val })
    five = LPTCMCvariables.quaternary[0].map((val, index) => { return val })
    six = LPTCMCvariables.quinary[0].map((val, index) => { return val })
    seven = LPTCMCvariables.senary[0].map((val, index) => { return val })
    return [...one, ...two, ...four, ...five, ...six, ...seven, ...three]
}