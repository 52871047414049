import { Accordion, AccordionDetails, AccordionSummary, Fade, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as SimpleB } from '../../assets/equipmentIcons/simpleBoptimized.svg';
import { ReactComponent as SimpleM } from '../../assets/equipmentIcons/simpleMoptimized.svg';
import { ReactComponent as SimpleP } from '../../assets/equipmentIcons/simplePoptimized.svg';
import { ReactComponent as SimpleT } from '../../assets/equipmentIcons/simpleToptimized.svg';
import { ReactComponent as SimpleW } from '../../assets/equipmentIcons/simpleWoptimized.svg';
import gcm from '../../assets/equipmentPhotos/gcm.webp';
import icm from '../../assets/equipmentPhotos/icm.webp';
import lptcm from '../../assets/equipmentPhotos/lp_tcm.webp';
import mcm from '../../assets/equipmentPhotos/mcm.webp';
import TCM2 from '../../assets/equipmentPhotos/tcm2.webp';
import { DeviceList, DeviceListExpanded, DeviceStatus, DeviceType } from '../../interfaces/Device';
import { SubstationList, SubstationType } from '../../interfaces/Substations';
import { deviceTypeToRouteString, sfNewFormat, si_format } from '../../utils/format-data';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aroundBox: {
      borderRadius: "20px",
      backgroundColor: "white",
      maxWidth: "400px",
      minWidth: "250px",
      width: "unset",
      marginLeft: "8px",
      marginRight: "8px",
      marginBottom: "6px",
      "& .MuiAccordion-root.Mui-expanded": {
        margin: 0,
      },
      height: "fit-content"
      //maxHeight: 243,
      //zIndex: 9999  
    },
    aroundBoxSmall: {
      borderRadius: "20px",
      backgroundColor: "white",
      maxWidth: "400px",
      minWidth: "250px",
      width: "unset",
      marginLeft: "8px",
      marginRight: "8px",
      marginBottom: 0,
      "& .MuiAccordion-root.Mui-expanded": {
        margin: 0,
      },
      height: "fit-content"
      //maxHeight: 243,
      //zIndex: 9999  
    },
    topCard: {
      display: "flex",
      paddingBottom: "25px"
    },
    topCardSmall: {
      display: "flex",
      paddingBottom: "12px"
    },
    middleCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center"
    },
    bottomCard: {
      borderRadius: "20px !important",
      '&::before': {
        backgroundColor: "unset !important"
      }
    },
    title: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      minWidth: "min-content",
      borderRadius: "20px 0 20px 0"
    },
    image: {
      width: "80px"
    },
    imageSmall: {
      width: "55px"
    },
    icon: {
      width: '54px',
      height: '40px',
      margin: "11px 15px 0px 15px",
      overflow: "overlay",
      "& path": {
        fill: theme.palette.primary.main,
        transform: "scale(0.35)",
      }
    },
    titleFont: {
      color: "white",
      lineHeight: "18px",
      maxWidth: "max-content",
      fontSize: "17px",
      width: "auto",
      paddingRight: "15px",
      paddingTop: "15px", //8 se for grande
      paddingLeft: "15px",
      fontWeight: "bold",
      margin: 'auto'
    },
    titleFontHidden: {
      whiteSpace: 'nowrap',
      visibility: 'hidden',
      position: 'absolute',
      color: "white",
      lineHeight: "18px",
      maxWidth: "max-content",
      fontSize: "17px",
      width: "auto",
      paddingRight: "25px",
      paddingTop: "15px", //8 se for grande
      paddingLeft: "25px",
      fontWeight: "bold"
    },
    titleFontLarge: {
      paddingTop: "8px", //8 se for grande
    },
    status: {
      padding: "0 26px 11px",
      width: "100%",
      display: "flex"
    },
    location: {
      padding: "0 26px 11px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    locationSmall: {
      padding: "0 26px 8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
    justLocation: {
      display: "flex",
      flexDirection: "column"
    },
    mainFont: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: "14px",
      paddingLeft: "5px",
      paddingRight: "10px",
      textTransform: 'uppercase'
    },
    secondaryFont: {
      color: theme.palette.primary.main,
      fontWeight: "normal",
      fontSize: "14px",
      paddingLeft: "5px",
      paddingRight: "10px",
      maxWidth: 118
    },
    statusBar: {
      transform: "unset",
      borderRadius: "20px 20px 20px 20px",
      height: "21px",
      width: "100%",
      display: "flex",
      placeContent: "flex-end",
      alignItems: "flex-start",
      fontWeight: "normal",
      color: "#fff",
      float: "unset",
      flexShrink: "unset",
      minWidth: "70px"
    },
    statusFont: {
      paddingRight: "11px",
      fontFamily: "'Titillium Web'",
      alignSelf: "center",
      textTransform: "uppercase",
      fontSize: 14
    },
    detailsFont: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      margin: "0 auto",
      fontSize: "small"
    },
    link: {
      textDecoration: "unset"
    },
    ControlHolder: {
      alignItems: "center",
      display: "flex"
    },
    ControlText: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginLeft: "8px",
      fontSize: "13.5px"
    },
    ControlTextSmall: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginLeft: "8px",
      fontSize: "13.5px",
      marginBottom: 0,
      marginTop: 0
    },
    ControlTextMain: {
      color: theme.palette.primary.main,
      fontSize: "18px",
      //padding: "9px 20px 8px",
      fontWeight: 700,
      lineHeight: 1.53,
      margin: 0
    },
    bulb: {
      height: "20px",
      width: "20px",
      borderRadius: "50%",
    },
    smallBulb: {
      height: "15px",
      width: "15px",
      borderRadius: "50%",
    },
    mainHolder: {
      display: "block",
      marginLeft: 10,
      marginRight: 10
    },
    mainHolderSmall: {
      display: "block",
      marginLeft: 15,
      marginRight: 15
    }
  }),
);

interface DeviceListProps {
  device: DeviceListExpanded;
  subslist?: SubstationList[];
  expanded?: boolean,
  restartTimer?: React.Dispatch<React.SetStateAction<boolean>>
}

enum DeviceIcon {
  P,
  W,
  T,
  M,
  B
}














export default function DashboardCard(props: DeviceListProps) {
  const classes = useStyles();
  const { device, subslist, expanded, restartTimer } = props;
  const intl = useIntl();
  const titleRef = useRef<HTMLSpanElement>(null);
  const realTitleRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const wrapCutoff = 240;
  const [fontSize, setFontSize] = useState({ fontSize: '17px' });
  const linkstring = deviceTypeToRouteString(device.type)

  const [open, setOpen] = useState(true)

  const getStatusText = (s: DeviceStatus, sf?: sfNewFormat): string => {
    if (s === DeviceStatus.PRODUCTION) {
      if (sf) {
        if (sf.level === 2) return intl.formatMessage({ id: "critical" })
        else if (sf.level === 1) return intl.formatMessage({ id: "warning" })
        else return intl.formatMessage({ id: "sending" })
      } else return intl.formatMessage({ id: "sending" })
    }
    if (s === DeviceStatus.NOTSENDING) {
      return intl.formatMessage({ id: "notsending" })
    }
    if (s === DeviceStatus.INACTIVE) {
      return intl.formatMessage({ id: "inactive" })
    }
    if (s === DeviceStatus.NOTPROCESSING) {
      return intl.formatMessage({ id: "notprocessing" })
    }
    if (s === DeviceStatus.DESTROYED) {
      return intl.formatMessage({ id: "destroyed" })
    }
    return intl.formatMessage({ id: "installing" })
  }

  const diagnosticInfo = (device: DeviceListExpanded): React.ReactNode => {
    return <>

      {device.sfGroup?.sfs.map((sf, index) => <div key={`controlholder-${index}`} className={classes.ControlHolder}>
        <div style={{ backgroundColor: `${sf.color}` }} className={classes.bulb}></div>
        <p className={classes.ControlText}><FormattedMessage id={(sf.name ? sf.name : "severity_factor")} />:</p>
        <p className={classes.ControlText}>{si_format(sf.value, '%', 2, true)}</p>
      </div>)}

    </>
  }

  const makeSecondaryFontSize = (name: string) => {
    if (name.length > 18) {
      return 13
    } else if (name.length > 25) {
      return 12
    } else {
      return 14
    }
  }

  const getStylizedIcon = (device: DeviceList, sublist?: SubstationList[]): DeviceIcon => {
    var ret = undefined;
    sublist?.forEach((sub) => {
      if (sub.devices_id.some(d => d === device._id)) {
        if (sub.type === SubstationType.PVM) {
          ret = DeviceIcon.P;
        }
        if (sub.type === SubstationType.WTM) {
          ret = DeviceIcon.W;
        }
        if (sub.type === SubstationType.BCM) {
          ret = DeviceIcon.B;
        }
      }
    })
    if (ret) return ret
    else if (device.type === DeviceType.L || device.type === DeviceType.Ld || device.type === DeviceType.T || device.type === DeviceType.Td) {
      return DeviceIcon.T;
    }
    else if (device.type === DeviceType.M || device.type === DeviceType.Gi || device.type === DeviceType.Gc || device.type === DeviceType.Gs || device.type === DeviceType.Gm) {
      return DeviceIcon.M;
    }
    return DeviceIcon.T;
  }
  const icon = getStylizedIcon(device, subslist);

  useEffect(() => {
    setOpen(true)
    return () => {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    const resizeListener = () => {
      if (realTitleRef?.current?.offsetWidth) setCardWidth(realTitleRef.current.offsetWidth)
    };
    window.addEventListener('resize', resizeListener);

    if (titleRef?.current?.offsetWidth) setWidth(titleRef.current.offsetWidth)

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [titleRef.current?.offsetWidth, device])

  useEffect(() => {
    //card width 189.48 - -1px        214 - -0px               24.52 diff
    const calculateFontSize = (width: number): { fontSize: any } => {
      const l = width
      var sub = cardWidth - 189.48;
      sub = sub < 0 ? 0 : sub > 24.52 ? 24.52 : sub;
      sub = (sub / 24.52) * 1.5 - 1.5;
      var result = 0;
      if (l < wrapCutoff) {
        if (l < 185) {
          // console.log("pos1", width, result, sub, device.name);                             /////////////    1
          return { fontSize: '20px' };
        }
        const zero = 637.350256410228;
        const um = -5.468461538461280;
        const dois = 0.012051282051282;
        result = (zero + um * l + dois * Math.pow(l, 2))
        if (result > 20) {
          // console.log("pos2", width, result, sub, device.name);                             /////////////    2
          return { fontSize: `${20 + sub * 2}px` }
        }
        result = result + sub * 2
        // console.log("pos3", width, result, sub, device.name);                             /////////////    3
        return { fontSize: result.toString() + 'px' }
      }
      const zero = 33.9268773292;
      const um = -0.0564074774;
      const dois = 0.0000267292;
      result = (zero + um * l + dois * Math.pow(l, 2))
      if (result > 20) {
        // console.log("pos4", width, result, sub, device.name);                             /////////////    4
        return { fontSize: `${20 + sub}px` }
      }
      result = result + sub
      // console.log("pos5", width, result, sub, device.name);                             /////////////    5
      return { fontSize: result.toString() + 'px' }
    }
    //console.log(device.name, estimatedSize(device.name), calculateFontSize(device.name).fontSize)
    setFontSize(calculateFontSize(width))
  }, [cardWidth, width])

  // console.log(device.name, width, fontSize, cardWidth)
  // console.log(device.status, device.sfGroup.sfs ? device.sfGroup.sfs[device.sfGroup.maxIndex] : undefined,getStatusText(device.status, device.sfGroup.sfs ? device.sfGroup.sfs[device.sfGroup.maxIndex] : undefined))

  return (
    <Fade timeout={500} in={open}>
      <div className={expanded ? classes.aroundBoxSmall : classes.aroundBox} style={{ height: expanded ? 260 : "fit-content" }}>
        <Link className={classes.link} to={`/${linkstring}/${device._id}`}>
          <div className={expanded ? classes.topCardSmall : classes.topCard}>
            <div className={classes.title}>
              <Typography ref={realTitleRef} className={clsx(classes.titleFont, width > wrapCutoff && classes.titleFontLarge)} style={fontSize}>{device.name}</Typography>
              <Typography ref={titleRef} className={classes.titleFontHidden}>{device.name}</Typography>
            </div>
            {/* <img className={classes.icon} src={getStylizedIcon(device, subslist)} alt={device.type}/> */}
            {icon === DeviceIcon.M && <SimpleM className={classes.icon} />}
            {icon === DeviceIcon.T && <SimpleT className={classes.icon} />}
            {icon === DeviceIcon.P && <SimpleP className={classes.icon} />}
            {icon === DeviceIcon.W && <SimpleW className={classes.icon} />}
            {icon === DeviceIcon.B && <SimpleB className={classes.icon} />}
          </div>
          <div className={classes.middleCard}>
            <div className={classes.status}>
              <Typography className={classes.mainFont}><FormattedMessage id="status" /></Typography>
              <div className={classes.statusBar} style={{ backgroundColor: device.status === DeviceStatus.NOTSENDING || device.status === DeviceStatus.INACTIVE || device.status === DeviceStatus.NOTPROCESSING || device.status === DeviceStatus.DESTROYED ? "grey" : device.sfGroup.sfs[device.sfGroup.maxIndex].color }}>
                <Typography className={classes.statusFont}>{getStatusText(device.status, device.sfGroup.sfs ? device.sfGroup.sfs[device.sfGroup.maxIndex] : undefined)}</Typography>
              </div>
            </div>
            <div className={expanded ? classes.locationSmall : classes.location}>
              <div className={classes.justLocation}>
                <Typography className={classes.mainFont}><FormattedMessage id="local" /></Typography>
                <Typography className={classes.secondaryFont} style={{ fontSize: makeSecondaryFontSize(device.local) }}>{device.local}</Typography>
              </div>
              <img className={expanded ? classes.imageSmall : classes.image} src={
                device.type === DeviceType['T'] || device.type === DeviceType['Td'] ?
                  TCM2 :
                  device.type === DeviceType['L'] || device.type === DeviceType['Ld'] ?
                    lptcm :
                    device.type === DeviceType['I'] ?
                      icm :
                      device.type === DeviceType['M'] ?
                        mcm :
                        device.type === DeviceType['Gi'] || device.type === DeviceType['Gc'] || device.type === DeviceType['Gs'] || device.type === DeviceType['Gm'] ?
                          gcm :
                          TCM2} alt="TCM"
              ></img>
            </div>
          </div>

        </Link>
        <Accordion className={classes.bottomCard}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => { if (restartTimer) restartTimer(x => !x) }}
          >
            <Typography className={classes.detailsFont}><FormattedMessage id="showDetails" /></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.mainHolder}>
              <h3 className={classes.ControlTextMain}><FormattedMessage id={"diagnostic"} /></h3>
              {diagnosticInfo(device)}
            </div>
          </AccordionDetails>
        </Accordion>
      </div >
    </Fade>
  );
}
