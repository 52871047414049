import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlarmCondition, CreateAlarm } from '../../interfaces/Alerts';
import { DeviceList, DeviceType } from '../../interfaces/Device';
import { OrganizationList } from '../../interfaces/Organization';
import { Roles, User } from '../../interfaces/User';
import { getVariablesByRoleAndTypeAndViews } from '../../utils/format-data';
import EntityDialog from '../entities/EntityDialog';
import ColorCirclePicker from '../global/ColorCirclePicker';
import ConditionsBit from './ConditionsBit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%"
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    alignOperator: {
      marginLeft: 3,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    buttonsMultiple: {
      width: 40,
      color: 'black',
      '&.Mui-selected': {
        '&:hover': {
          backgroundColor: '#4d74a4',
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }),
);

export const typeToText = (t: DeviceType) => {
  if (t === DeviceType.T) return 'tcm'
  else if (t === DeviceType.L) return 'lptcm'
  else if (t === DeviceType.I) return 'icm'
  else if (t === DeviceType.M) return 'mcm'
  else if (t === DeviceType.Gi) return 'gcm'
}

interface CreateAlarmDialogProps {
  open: boolean;
  handleClose: (alarm?: CreateAlarm) => void;
  handlePositiveClose: (updating: boolean, alarm: CreateAlarm) => void;
  alarm: CreateAlarm
  devices: DeviceList[];
  users: User[];
  organizations?: OrganizationList[];
}

export type VariablesGroupType = {
  [key in DeviceType]?: VariablesType
}
export type VariablesType = { normal: string[], special?: string[] }

export default function CreateAlarmDialog(props: CreateAlarmDialogProps) {
  const { open, handleClose, devices, users, alarm, handlePositiveClose, organizations } = props;
  const [devicesFiltered, setDevicesFiltered] = React.useState(devices);
  const [usersFiltered, setUsersFiltered] = React.useState(users);
  const classes = useStyles();
  const [errors, setErrors] = React.useState<Array<number>>([0, 0, 0, 0, 0]);
  const intl = useIntl();
  const colors = ['#e23434', '#ffc000', '#2aa060', '#3473ba'];
  const updating = !(props.alarm?.name === '' && props.alarm?.message === '')
  var currentUser2 = localStorage.getItem("user");
  const currentUser: User = JSON.parse(currentUser2 ? currentUser2 : '');
  if (!alarm.organization_id) alarm.organization_id = currentUser.organization_id
  const [tempAlarm, setTempAlarm] = React.useState<CreateAlarm>(alarm);
  const deviceFound = devices.find(d => d._id === (alarm.devices.length > 0 ? alarm.devices[0] : d._id))
  const initialType = deviceFound ?
    deviceFound.type === DeviceType.Ld ?
      DeviceType.L :
      deviceFound.type === DeviceType.Td ?
        DeviceType.T :
        deviceFound.type :
    DeviceType.T;
  const [type, setType] = React.useState(initialType)
  //console.log('aaaa',devices,initialType, type)
  const allVars: VariablesGroupType = {
    [DeviceType.T]: {
      normal: getVariablesByRoleAndTypeAndViews(DeviceType.T, currentUser),
      special: getVariablesByRoleAndTypeAndViews(DeviceType.Td, currentUser),
    },
    [DeviceType.L]: {
      normal: getVariablesByRoleAndTypeAndViews(DeviceType.L, currentUser),
      special: getVariablesByRoleAndTypeAndViews(DeviceType.Ld, currentUser),
    },
    [DeviceType.I]: {
      normal: getVariablesByRoleAndTypeAndViews(DeviceType.I, currentUser, true),
    },
    [DeviceType.M]: {
      normal: getVariablesByRoleAndTypeAndViews(DeviceType.M, currentUser),
    },
    [DeviceType.Gi]: {
      normal: getVariablesByRoleAndTypeAndViews(DeviceType.Gi, currentUser),
    }
  }
  const solutions = [DeviceType.T, DeviceType.L, DeviceType.I, DeviceType.M, DeviceType.Gi].filter(tipo => devices.some(dispositivo => dispositivo.type === tipo))



  useEffect(() => {
    const deviceFound = devices.find(d => d._id === (alarm.devices.length > 0 ? alarm.devices[0] : d._id))
    const initialType = deviceFound ?
      deviceFound.type === DeviceType.Ld ?
        DeviceType.L :
        deviceFound.type === DeviceType.Td ?
          DeviceType.T :
          deviceFound.type :
      DeviceType.T;
    setType(initialType)
    setTempAlarm(alarm)
    const temp = [...errors];
    for (const key in alarm) {
      if (verifyInput(alarm[key as keyof CreateAlarm], key)) {
        temp[getErrorKey(key)] = 0;
      }
    }
    setErrors(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    //console.log(type, '\n\n', tempAlarm.organization_id, '\n\n', devices, '\n\n', devices.filter(de => de.type === type && de.organization_id === tempAlarm.organization_id))

    //remake
    if (type === DeviceType.L) {
      if (tempAlarm.organization_id) setDevicesFiltered(devices.filter(de => (de.type === type || de.type === DeviceType.Ld) && de.organization_id === tempAlarm.organization_id))
      else setDevicesFiltered(devices.filter(de => (de.type === type || de.type === DeviceType.Ld)))

    } else if (type === DeviceType.T) {
      if (tempAlarm.organization_id) setDevicesFiltered(devices.filter(de => (de.type === type || de.type === DeviceType.Td) && de.organization_id === tempAlarm.organization_id))
      else setDevicesFiltered(devices.filter(de => (de.type === type || de.type === DeviceType.Td)))

    } else if (tempAlarm.organization_id) setDevicesFiltered(devices.filter(de => de.type === type && de.organization_id === tempAlarm.organization_id))

    else setDevicesFiltered(devices.filter(de => de.type === type))
  }, [devices, type, tempAlarm.organization_id])

  useEffect(() => {
    if (tempAlarm.organization_id) setUsersFiltered(users.filter(us => ((us.organization_id === tempAlarm.organization_id || us.role < Roles.ORGADMIN) && ((us.role < Roles.ORGADMIN && currentUser.role < Roles.ORGADMIN) || us.role > Roles.ADMIN))))
    else setUsersFiltered(users)
  }, [users, tempAlarm.organization_id, currentUser.role])

  const setTypeHelper = (anything: any) => {
    setTempAlarm({ ...tempAlarm, 'devices': [] });
    setType(anything)
  }

  const handleChange = (value: any, key: string) => {
    var deletedevices = false;
    var deleteusers = false;
    var addorg = undefined
    if (key === 'devices') {
      const deviceFound = devices.find(d => d._id === value[0])
      if (deviceFound?.type === DeviceType.Td) {//needs reorganizing
        setType(DeviceType.T)
      } else if (deviceFound?.type === DeviceType.Ld) {
        setType(DeviceType.L)
      } else {
        setType(deviceFound ? deviceFound.type : DeviceType.T)
      }
      if (!tempAlarm.organization_id) addorg = deviceFound?.organization_id
    } else if (key === 'organization_id') {
      const o = value
      const orgFound = tempAlarm.devices.map(ds => devices.find(d => d._id === ds)).some(b => b?.organization_id !== o)
      deletedevices = orgFound
      const userFound = tempAlarm.users_id.map(uid => users.find(d => d._id === uid)).some(b => b?.organization_id !== tempAlarm.organization_id)
      deleteusers = userFound
    } else if (key === 'users_id') {
      const userFound = users.find(d => d._id === value[0])
      if (!tempAlarm.organization_id) addorg = userFound?.organization_id
    }
    const idx = getErrorKey(key);
    const alsoCheckIdx = key === "devices" ? getErrorKey("conditions") : key === "conditions" ? getErrorKey("devices") : undefined
    var val = 1
    if (idx !== -1) {
      if (errors[idx] > 0) {
        if (verifyInput(value, key)) {
          const temp = errors;
          temp[idx] = 0;
          setErrors(temp)
        }
      } else {
        if (!verifyInput(value, key)) {
          //special case for devices/fields to be refactored later
          if (key === "devices") {
            if (!isCompatible(value)) val = 2
          } else if (key === "conditions") {
            if (!isCompatible(undefined, value)) val = 2
          }
          const temp = errors;
          temp[idx] = val;
          setErrors(temp)
        }
      }
    }
    val = 1
    if (alsoCheckIdx && alsoCheckIdx !== -1) { // CASE WHERE ONE FIXES THE OTHER OR ONE MESSES THE OTHER
      if (errors[alsoCheckIdx] > 0) {
        if (verifyInput(value, key)) {
          const temp = errors;
          temp[alsoCheckIdx] = 0;
          setErrors(temp)
        }
      } else {
        if (!verifyInput(value, key)) {
          //special case for devices/fields to be refactored later
          if (key === "devices") {
            if (!isCompatible(value)) val = 2
          } else if (key === "conditions") {
            if (!isCompatible(undefined, value)) val = 2
          }
          const temp = errors;
          temp[alsoCheckIdx] = val;
          setErrors(temp)
        }
      }
    }
    //console.log({ ...tempAlarm, [key]: value, 'organization_id': addorg })
    if (addorg) setTempAlarm({ ...tempAlarm, [key]: value, 'organization_id': addorg });
    else if (deletedevices && deleteusers) setTempAlarm({ ...tempAlarm, [key]: value, 'devices': [], 'users_id': [] });
    else if (deletedevices) setTempAlarm({ ...tempAlarm, [key]: value, 'devices': [] });
    else if (deleteusers) setTempAlarm({ ...tempAlarm, [key]: value, 'users_id': [] });
    else setTempAlarm({ ...tempAlarm, [key]: value });
  };

  const handleSubmit = () => {
    let has_error = false;
    const temp = [...errors];
    for (const key in tempAlarm) {
      if (!verifyInput(tempAlarm[key as keyof CreateAlarm], key)) {
        has_error = true;
        temp[getErrorKey(key)] = 1;
      }
    }
    setErrors(temp);
    // REMOVER VAZIOS
    if (!has_error) {
      //console.log('sending', updating, cleanEmpty(tempAlarm));
      handlePositiveClose(updating, cleanEmpty(tempAlarm))
    }
  }

  const cleanEmpty = (al: CreateAlarm): CreateAlarm => {
    const temp = al
    if (!temp.organization_id) {
      temp.organization_id = (currentUser as any).organization_id
    }
    temp.conditions = temp.conditions.filter(c => c.field !== '')
    return temp
  }

  const getErrorKey = (key: string) => {
    switch (key) {
      case 'name': return 0;
      case 'message': return 1;
      case 'devices': return 2;
      case 'users_id': return 3;
      case 'conditions': return 4;
      case 'organization_id': return 5;
      default: return -1;
    }
  }

  const isCompatible = (device?: string, field?: Array<{ threshold: number, operator: number, field: string }>) => {
    //console.log("iscompatible, device, field", device, field)  // MISSING CASE WHEN REMOVING A THE OPPOSITE FIXES THE COMPATIBILITY
    if (device) {
      if (type === DeviceType.T) { // TYPE IS TRANSFORMER POWER
        if (tempAlarm.conditions.some(c => (allVars[DeviceType.T]?.special?.includes(c.field)) && (!allVars[DeviceType.T]?.normal?.includes(c.field)))) { // FIELDS ARE OF SECONDARY AND NOT PRIMARY
          const deviceFound = devices.filter(d => device.includes(d._id))
          //console.log(deviceFound)
          if (deviceFound && (deviceFound.some(d => d.type === DeviceType.L || d?.type === DeviceType.T))) { //SELECTED DEVICES DONT HAVE DOUBLE SECONDARY
            //console.log("false")
            return false;
          }
        }
      }
      if (type === DeviceType.L) { // TYPE IS TRANSFORMER DSITRIBUTOR
        if (tempAlarm.conditions.some(c => (allVars[DeviceType.L]?.special?.includes(c.field)) && (!allVars[DeviceType.L]?.normal?.includes(c.field)))) { // FIELDS ARE OF SECONDARY AND NOT PRIMARY
          const deviceFound = devices.filter(d => device.includes(d._id))
          //console.log(deviceFound)
          if (deviceFound && (deviceFound.some(d => d.type === DeviceType.L || d?.type === DeviceType.T))) { //SELECTED DEVICES DONT HAVE DOUBLE SECONDARY
            //console.log("false")
            return false;
          }
        }
      }
    } else if (field) {
      if (allVars && allVars[DeviceType.T] && allVars[DeviceType.T]!.special) {
        if (field.some(v => !allVars[DeviceType.T]!.normal?.includes(v.field))) { // es triple variable
          const devicesExtended = tempAlarm.devices.map(ds => devices.find(d => d._id === ds));
          if (devicesExtended.some(d => d?.type === DeviceType.T || d?.type === DeviceType.L)) {
            //console.log("false")
            return false
          }
        }
      }
    }
    //console.log("true")
    return true
  }

  const verifyInput = (value: any, key: string) => {
    switch (key) {
      case 'name': return value.length > 0;
      case 'message': return value.length > 0;
      case 'devices': return value.length > 0 && isCompatible(value);
      case 'users_id': return value.length > 0;
      case 'conditions': return verifyConditions(value);
      case 'organization_id': return verifyOrg(value)
      default: return true;
    }
  }

  const verifyConditions = (value: Array<{ threshold: number, operator: number, field: string }>) => {
    if (!isCompatible(undefined, value)) { return false }
    return value.reduce((a, b) => a || b.field.length > 0, false)
  }

  const verifyOrg = (value: string) => {
    return true
  }

  return (
    <EntityDialog
      open={open}
      title={updating ? intl.formatMessage({ id: 'editAlarm' }) : intl.formatMessage({ id: 'addAlarm' })}
      handleClose={() => handleClose()}
      handleSubmit={() => handleSubmit()}>
      <>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              className={classes.formField}
              label={intl.formatMessage({ id: 'name' })}
              value={tempAlarm.name}
              fullWidth
              {...(errors[0] !== 0 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
              onChange={evt => handleChange(evt.target.value, "name")}
            />
          </Grid>
          <Grid style={{ alignSelf: "center" }} item xs={4}>
            <FormControl className={classes.formControlSmall}>
              <InputLabel htmlFor="select-average"><FormattedMessage id={'averageSamples'}></FormattedMessage></InputLabel>
              <Select
                inputProps={{ name: 'average', id: 'select-average' }}
                value={tempAlarm.samples_mean}
                onChange={evt => handleChange(evt.target.value, "samples_mean")}
              >
                {[...Array(10)].map((x, i) =>
                  <MenuItem key={'select-number-' + i} aria-label="None" value={i + 1} ><i>{(i + 1).toString()}</i></MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              className={classes.formField}
              label={intl.formatMessage({ id: 'description' })}
              value={tempAlarm.message}
              fullWidth
              autoComplete='off'
              type='string'
              {...(errors[1] !== 0 && { error: true, helperText: `${intl.formatMessage({ id: 'generalMissing' })}` })}
              onChange={evt => handleChange(evt.target.value, "message")}
            />
          </Grid>
          <Grid item xs={4}>
            <ColorCirclePicker
              val={tempAlarm.color}
              onClick={(color: any) => handleChange(color, "color")}
              title={'color'}
              options={colors}
            />
          </Grid>
        </Grid>








        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl className={classes.formControlSmall + ' ' + classes.alignSelects}>
              <InputLabel htmlFor="select-solution"><FormattedMessage id={'solutions'}></FormattedMessage></InputLabel>
              <Select
                inputProps={{ name: 'solutions', id: 'select-solutions' }}
                value={type}
                onChange={(evt, type) => { setTypeHelper((evt.target.value as any)) }}
              >
                {solutions
                  .map((x, i) =>
                    <MenuItem key={'select-solutions-' + i} value={x} ><FormattedMessage id={typeToText(x)} /></MenuItem>
                  )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Typography><FormattedMessage id={'notifications'}></FormattedMessage></Typography>
            <FormControlLabel
              control={<Checkbox
                checked={tempAlarm.send_by_email}
                onChange={evt => handleChange(evt.target.checked, "send_by_email")}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                color="primary"
              />}
              label="Email"
            />


          </Grid>
        </Grid>







        <Grid container spacing={2}>
          <Grid item xs={8}>

            <FormControl className={classes.formControlSmall + ' ' + classes.alignSelects}>
              <InputLabel htmlFor="select-devices"><FormattedMessage id={'devices'}></FormattedMessage></InputLabel>
              <Select
                multiple
                inputProps={{ name: 'devices', id: 'select-devices' }}
                value={tempAlarm.devices.map(dev => dev)}
                {...(errors[2] !== 0 && { error: true })}
                onChange={evt => handleChange(evt.target.value, "devices")}
              >
                {devicesFiltered
                  .map((x, i) =>
                    <MenuItem key={'select-devices-' + i} value={x._id} >{(x.name).toString()}</MenuItem>
                  )}
              </Select>
              {errors[2] !== 0 && errors[2] !== 2 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"generalMissing"} /></FormHelperText>}
              {errors[2] !== 0 && errors[2] === 2 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"incompatible"} /></FormHelperText>}
              {devicesFiltered.length === 0 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"noDevicesWithSelectedOptions"} /></FormHelperText>}
            </FormControl>

          </Grid>

          <Grid item xs={4}>

            <FormControl component="fieldset" className={classes.formControlSmall}>
              <ToggleButtonGroup
                value={tempAlarm.logic}
                exclusive
                onChange={(evt, val) => { handleChange(val, "logic") }}
                aria-label="text alignment"
              >
                <ToggleButton className={classes.buttonsMultiple} value="0" aria-label="left aligned">
                  <FormattedMessage id={'and'} />
                </ToggleButton>
                <ToggleButton className={classes.buttonsMultiple} value="1" aria-label="centered">
                  <FormattedMessage id={'or'} />
                </ToggleButton>
              </ToggleButtonGroup>


            </FormControl>


          </Grid>

        </Grid>








        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl className={classes.formControlSmall + ' ' + classes.alignSelects}>
              <InputLabel htmlFor="select-users"><FormattedMessage id={'users'}></FormattedMessage></InputLabel>
              <Select
                multiple
                inputProps={{ name: 'users', id: 'select-users' }}
                value={tempAlarm.users_id}
                {...(errors[3] !== 0 && { error: true })}
                onChange={evt => handleChange(evt.target.value, "users_id")}
              >
                {usersFiltered.map((x, i) =>
                  <MenuItem key={'select-users-' + i} value={x._id} >{(x.name).toString()}</MenuItem>
                )}
              </Select>
              {errors[3] !== 0 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"generalMissing"} /></FormHelperText>}
            </FormControl>
          </Grid>

          {currentUser && (currentUser as any).role < Roles.ORGADMIN && <Grid item xs={4}>
            <FormControl disabled={updating} className={classes.formControlSmall + ' ' + classes.alignSelects}>
              <InputLabel htmlFor="select-org"><FormattedMessage id={'organization'}></FormattedMessage></InputLabel>
              <Select
                inputProps={{ name: 'org', id: 'select-org' }}
                value={tempAlarm.organization_id}
                {...(errors[3] !== 0 && { error: true })}
                onChange={evt => handleChange(evt.target.value, "organization_id")}
              >
                {organizations && organizations!.map((x, i) =>
                  <MenuItem key={'select-org-' + i} value={x._id} >{(x.name).toString()}</MenuItem>
                )}
                {!organizations && <MenuItem value={''}></MenuItem>}
              </Select>
              {errors[5] !== 0 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"generalMissing"} /></FormHelperText>}
            </FormControl>
          </Grid>}
        </Grid>





        <Grid className={classes.alignConditions} container spacing={2}>
          <Typography><FormattedMessage id={'conditions'}></FormattedMessage></Typography>
          {errors[4] !== 0 && errors[4] !== 2 && <FormHelperText style={{ color: "red" }}>&nbsp;<FormattedMessage id={"generalMissing"} /></FormHelperText>}
          {errors[4] !== 0 && errors[4] === 2 && <FormHelperText style={{ color: "red" }}><FormattedMessage id={"incompatible"} /></FormHelperText>}
        </Grid>
        <Grid className={classes.alignConditions} container spacing={2}>
          <ConditionsBit
            val={tempAlarm.conditions}
            onClick={(conds: AlarmCondition[]) => { handleChange(conds, "conditions") }}
            type={type}
            variables={allVars}
          />
        </Grid>



      </>
    </EntityDialog >
  );
}