import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { HashRouter } from "react-router-dom";
import locale_en from "../assets/locales/en.json";
import locale_es from "../assets/locales/es.json";
import locale_pt from "../assets/locales/pt.json";
import CustomTheme from "../components/global/Theme";
import { AlertComponent } from "../contexts/alarm-context";
import { APIProvider } from "../contexts/api-context";
import { ContextComponent } from "../contexts/entities-context";
import { SocketProvider } from '../contexts/socket-context';
import { ToastComponent } from "../contexts/toast-context";
import { TranslationProvider } from "../contexts/translation-context";
import RoutesComponent from "./RoutesComponent";

interface dataKeys {
  [key: string]: any;
}
const data: dataKeys = {
  'es': locale_es,
  'pt': locale_pt,
  'en': locale_en
};
console.log(`
     :*@@#@#=.                                                                             
 .:#@@@@@@#@@@#-.                                    ..                             ..     
 @@@@@@@@@@*%@@@@%#   :%%%%%%%%%%=*%%%.   =%%%   =#%@@@@##- =%%%:*%%%=    %%%:  -#%@@@@%#= 
@@@@@@#+  =*:@@@@@@   :@@@#++++++:#@@@@+. =@@@ -@@@#+==+#*  =@@@:#@@@@*.  @@@::@@@%+==+**  
@@@%@:       @@@@@@   :@@@#===.   #@@@@@@+=@@@.@@@#    ====.=@@@:#@@@@@@# @@@:%@@@    -===:
@@%#@:       @@@@@@   :@@@#+++.   #@@++@@@%@@@.@@@#   +%@@@-=@@@:#@@#-@@@#@@@:@@@@   -%@@@=
*%@@@@*-  :+*@@@@@@   :@@@*------.#@@= .=@@@@@ =@@@#=..=@@@-=@@@:#@@#  =@@@@@:-@@@*+:.-%@@=
 @@@@@@@@@##%###%#=   :@@@@@@@@@@=#@@=   :#@@@  .*#@@@@@@#*.=@@@:#@@#    *@@@:  =#@@@@@@%*:
 -+@@@@@@@@@@@@#-.                                                                        
    .=%@@@@@#- `)
export default function App() {


  return (

    <APIProvider>
      <SocketProvider>
        <TranslationProvider>
          <CustomTheme>
            <ContextComponent>
              <ToastComponent>
                <AlertComponent>

                  <HashRouter>
                    <CssBaseline />
                    <RoutesComponent data={data}></RoutesComponent>
                  </HashRouter>


                </AlertComponent>
              </ToastComponent>
            </ContextComponent>
          </CustomTheme>
        </TranslationProvider>
      </SocketProvider>
    </APIProvider>

  );
}