import { gaugeFinal2, gaugeMid2 } from '../components/device/final/RoundGauge';
import { DeviceFull } from '../interfaces/Device';
import { ReportList, ReportWaveforms } from '../interfaces/Report';
import { gaugeProps } from '../pages/mcm/tabs/ElectricParam';
import { chunkArray, makeArrowsFromDataPolar } from './format-data';


export const mcmOrder = ['supply', 'stator', 'rotor', 'eccentricity', 'load']

export const forGauges = (report: ReportList | undefined, change?: boolean, withRotor?: boolean): gaugeProps => {
    const maxes1 = Math.max(report && report.electricParam.ppv ? report.electricParam.ppv[0] : 0,
        report && report.electricParam.ppv ? report.electricParam.ppv[1] : 0,
        report && report.electricParam.ppv ? report.electricParam.ppv[2] : 0,
        report && report.electricParam.ppv ? report.electricParam.ppv[3] : 0
    )
    const maxes2 = Math.max(report && report.electricParam.iv ? report.electricParam.iv[0] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[1] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[2] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[3] : 0
    )
    const maxes3 = Math.max(report && report.electricParam.apparPwr ? report.electricParam.apparPwr[0] : 0,
        report && report.electricParam.actPwr ? report.electricParam.actPwr[0] : 0,
        report && report.electricParam.reactPwr ? report.electricParam.reactPwr[0] : 0
    )
    const maxes4 = Math.max(report && report.electricParam.iv ? report.electricParam.iv[4] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[5] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[6] : 0,
        report && report.electricParam.iv ? report.electricParam.iv[7] : 0
    )
    const one = {
        variable: 'V',
        title: 'voltage',
        array: [//ppv  report.data.electricParam.ppv
            { name: `Vuv`, value: report?.electricParam?.ppv && report.electricParam.ppv[0], color: change ? '#c70000' : '#ec4747', max: maxes1 },
            { name: `Vvw`, value: report?.electricParam?.ppv && report.electricParam.ppv[1], color: change ? '#1e7b49' : '#2ebd70', max: maxes1 },
            { name: `Vwu`, value: report?.electricParam?.ppv && report.electricParam.ppv[2], color: change ? '#224a77' : '#4684c9', max: maxes1 },
            { name: 'V', value: report?.electricParam?.ppv && report.electricParam.ppv[3], color: change ? '#cd9c08' : '#f9c937', max: maxes1 }
        ]
    }
    const two = {
        variable: 'A',
        title: 'current',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: `Iu`, value: report?.electricParam?.iv && report.electricParam.iv[0], color: '#ec4747', max: maxes2 },
            { name: `Iv`, value: report?.electricParam?.iv && report.electricParam.iv[1], color: '#2ebd70', max: maxes2 },
            { name: `Iw`, value: report?.electricParam?.iv && report.electricParam.iv[2], color: '#4684c9', max: maxes2 },
            { name: 'I', value: report?.electricParam?.iv && report.electricParam.iv[3], color: '#f9c937', max: maxes2 }
        ]
    }

    const three = {
        variable: '',
        title: 'power',
        array: [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
            { name: 'S', value: report?.electricParam?.apparPwr && report.electricParam.apparPwr[0], color: '#9e2d99', max: maxes3 },
            { name: 'P', value: report?.electricParam?.actPwr && report.electricParam.actPwr[0], color: '#a9662f', max: maxes3 },
            { name: 'Q', value: report?.electricParam?.reactPwr && report.electricParam.reactPwr[0], color: '#edb146', max: maxes3 },
            { name: 'PF', value: report?.electricParam?.cosPhi && report.electricParam.cosPhi[0], color: '#e2d5c1', max: 1 }
        ]
    }
    const four = {
        variable: 'A',
        title: 'current',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: `Iru`, value: report?.electricParam?.iv && report.electricParam.iv[4], color: '#ec4747', max: maxes4 },
            { name: `Irv`, value: report?.electricParam?.iv && report.electricParam.iv[5], color: '#2ebd70', max: maxes4 },
            { name: `Irw`, value: report?.electricParam?.iv && report.electricParam.iv[6], color: '#4684c9', max: maxes4 },
            { name: 'Ir', value: report?.electricParam?.iv && report.electricParam.iv[7], color: '#f9c937', max: maxes4 }
        ]
    }

    return withRotor ? [one, two, three, four] : [one, two, three]
}

export const forMechanicalGauges = (report: ReportList | undefined, deviceFull: DeviceFull | undefined, addEfficiency?: boolean): gaugeMid2[] => {
    const maxes1 = 1.5 * (deviceFull?.nameplate?.side ? deviceFull?.nameplate?.side[0].Ucc : 1)
    const maxes2 = (deviceFull?.nameplate?.side ? deviceFull?.nameplate?.side[0].Sn_ONAF : 1) * 1500 / (6.283 * (deviceFull?.nameplate?.side ? deviceFull?.nameplate?.side[0].Ucc : 1) / 60)//TBD
    const maxes3 = 1500 * (deviceFull?.nameplate?.side ? deviceFull?.nameplate?.side[0].Sn_ONAF : 1)
    const one = {
        variable: 'V',
        title: 'velocity',
        array: [//ppv  report.data.electricParam.ppv
            { name: ``, value: report?.diagnostic?.iexc && report.diagnostic.iexc[1], colors: ['#3473ba'], max: maxes1, unit: 'rpm', extendedNumber: true },
        ]
    }
    const two = {
        variable: 'A',
        title: 'torque',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: ``, value: report?.diagnostic?.iexc && report.diagnostic.iexc[0], colors: ['#2aa060'], max: maxes2, unit: 'Nm' },
        ]
    }

    const three = {
        variable: '',
        title: 'mechPower',
        array: [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
            { name: '', value: report?.diagnostic?.iexc && report.diagnostic.iexc[2], colors: ['#e23434'], max: maxes3, unit: 'W' },
        ]
    }

    if (addEfficiency) {
        const four = {
            variable: '',
            title: 'performance',
            array: [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
                { name: '', value: report?.diagnostic?.iexc && report.diagnostic.iexc[3], colors: ['#f9c937'], max: 100, unit: '%' },
            ]
        }
        return [one, two, three, four]
    }

    return [one, two, three]
}

export const forPowerQualityGauges = (report: ReportList | undefined, noVoltage?: boolean, keepBoxes?: boolean): gaugeProps => {
    const maxes1 = Math.max(report?.pwrQuality?.unbPPv ? report.pwrQuality.unbPPv[0] : 0,
        report?.pwrQuality?.unbI ? report.pwrQuality.unbI[0] : 0,
    )
    const maxes2 = report?.pwrQuality?.thdPPv ? Math.max(report.pwrQuality.thdPPv[0],
        report.pwrQuality.thdPPv[1],
        report.pwrQuality.thdPPv[2],
        report.pwrQuality.thdPPv[3]
    ) : 0
    const maxes3 = report?.pwrQuality?.thdPPv ? Math.max(report.pwrQuality.thdI[0],
        report.pwrQuality.thdI[1],
        report.pwrQuality.thdI[2],
        report.pwrQuality.thdI[3]
    ) : 0
    //console.log(maxes1,report?.pwrQuality.unbI[0],report?.pwrQuality.unbPPv[0])
    let one = {
        variable: '',
        title: 'unbalance',
        array: [//ppv  report.data.electricParam.ppv
            { name: `unbi-0-mcm`, value: report?.pwrQuality?.unbI && report.pwrQuality.unbI[0], color: '#ec4747', max: maxes1 },
            { name: `unbppv-0-mcm`, value: report?.pwrQuality?.unbPPv && report.pwrQuality.unbPPv[0], color: '#2ebd70', max: maxes1 },
        ]
    }
    if (noVoltage) one.array.pop()
    const two = {
        variable: '',
        title: 'thdv',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: `thdppv-0-mcm`, value: report?.pwrQuality?.thdPPv && report.pwrQuality.thdPPv[0], color: '#ec4747', max: maxes2 },
            { name: `thdppv-1-mcm`, value: report?.pwrQuality?.thdPPv && report.pwrQuality.thdPPv[1], color: '#2ebd70', max: maxes2 },
            { name: `thdppv-2-mcm`, value: report?.pwrQuality?.thdPPv && report.pwrQuality.thdPPv[2], color: '#4684c9', max: maxes2 },
            { name: 'thdppv-3-mcm', value: report?.pwrQuality?.thdPPv && report.pwrQuality.thdPPv[3], color: '#f9c937', max: maxes2 }
        ]
    }

    const three = {
        variable: '',
        title: 'thdc',
        array: [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
            { name: 'thdi-0-mcm', value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[0], color: '#ec4747', max: maxes3 },
            { name: 'thdi-1-mcm', value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[1], color: '#2ebd70', max: maxes3 },
            { name: 'thdi-2-mcm', value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[2], color: '#4684c9', max: maxes3 },
            { name: 'thdi-3-mcm', value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[3], color: '#f9c937', max: maxes3 }
        ]
    }
    if (noVoltage && !keepBoxes) {
        return [one, three]
    }
    return [one, two, three]
}

export const forVectorGraphs = (device: ReportList | undefined): Array<{ amplitude: Array<number>, angulo: Array<number> }> | undefined => {// IA (amplitude depois fase) IB IC 
    if (device === undefined) {
        return undefined
    }
    var formattedPhasors = {
        amplitudes: device.electricParam.phasors?.filter((v, i) => i % 2 === 0 && i < 12),
        degrees: device.electricParam.phasors?.filter((v, i) => i % 2 !== 0 && i < 12)
    }
    const max = formattedPhasors.amplitudes?.reduce((a, b) => a > b ? a : b);
    const arrowedPhasors = formattedPhasors.amplitudes?.map((value, indx) => makeArrowsFromDataPolar(value, formattedPhasors!.degrees[indx], max ? max : 1))
    return arrowedPhasors;
}

export const forWaveforms = (waves: ReportWaveforms | undefined, deviceFull: DeviceFull | undefined): Array<{ x: Array<number | string>, ys: Array<Array<number | string>> }> => {
    if (waves === undefined || deviceFull === undefined) {
        return [{ x: [], ys: [[]] }]
    }
    const wPNv = chunkArray(waves.wPPv, 3); // SAO SO zeros se nao houver tensoes
    const wI = chunkArray(waves.wI, 3);
    const wPNv2 = wPNv[0];
    const wI2 = wI[0];
    if (!wI || !wPNv || !wI2 || !wPNv2) return [
        { x: [], ys: [[]] },
        { x: [], ys: [[]] },
        { x: [], ys: [[]] },
    ]
    let main = {
        x: [...wI2[0].keys()].map(value => value / deviceFull.config_device.fs_analog * 1000),
        ys: [wPNv2[0], wPNv2[1], wPNv2[2], wI2[0], wI2[1], wI2[2]] as Array<Array<number>>
    };
    return [
        { x: main.x, ys: main.ys },
        { x: waves.wIexc[2], ys: [waves.wIexc[3]] },
        { x: waves.wIexc[0], ys: [waves.wIexc[1]] },
    ]
}

export const forGlobalStatus = (device?: ReportList, deviceList?: DeviceFull, isGCM?: boolean): gaugeFinal2[] => {
    var max = 3;
    var sfLimits = [1, 2];
    var ldLimits = [1, 2];
    if (deviceList?.severity_param && (deviceList.severity_param.sF_limit1 || deviceList.severity_param.sF_limit1 === 0) && (deviceList.severity_param.sF_limit2 || deviceList.severity_param.sF_limit2 === 0)) {
        sfLimits = [deviceList.severity_param.sF_limit1, deviceList.severity_param.sF_limit2]
    }
    if (deviceList?.severity_param && (deviceList.severity_param.ld_limit1 || deviceList.severity_param.ld_limit1 === 0) && (deviceList.severity_param.ld_limit2 || deviceList.severity_param.ld_limit2 === 0)) {
        ldLimits = [deviceList.severity_param.ld_limit1, deviceList.severity_param.ld_limit2]
    }
    if (ldLimits[1] > max) max = ldLimits[1] * 1.3

    const one = { name: !isGCM ? `supply` : 'voltage', value: device?.diagnostic?.sF ? device.diagnostic.sF[0] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: max, steps: [[0, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], max]], unit: '%' }

    const two = { name: `stator`, value: device?.diagnostic?.sF ? device.diagnostic.sF[1] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: max, steps: [[0, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], max]], unit: '%' }

    const three = { name: `rotor`, value: device?.diagnostic?.sF ? device.diagnostic.sF[2] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: max, steps: [[0, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], max]], unit: '%' }

    const four = { name: `eccentricity`, value: device?.diagnostic?.sF ? device.diagnostic.sF[3] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: max, steps: [[0, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], max]], unit: '%' }

    const five = { name: !isGCM ? `load` : 'powerVariation', value: device?.diagnostic?.sF ? device.diagnostic.sF[4] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: max, steps: [[0, ldLimits[0]], [ldLimits[0], ldLimits[1]], [ldLimits[1], max]], unit: '%' }

    return [one, two, three, four, five]
}

export const forHarmonics = (device: ReportList | undefined): Array<Array<{ one: Array<number>, even: { x: Array<number>, y: Array<Array<number>> }, odd: { x: Array<number>, y: Array<Array<number>> } }>> => {
    if (device === undefined) {
        return [[{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }], [{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }]]
    }
    const evenx = Array(20).fill(1).map((value, index) => (index + 1) * 2) //20
    const oddx = Array(19).fill(1).map((value, index) => (index + 1) * 2 + 1) //19
    const voltage = device.pwrQuality.thdPPv ? prepareHarmonicsData(device.pwrQuality.thdPPv) : [[], [], []]
    const current = device.pwrQuality.thdI ? prepareHarmonicsData(device.pwrQuality.thdI) : [[], [], []]
    return [
        [  //    SIDE PRIMARY
            {   // VOLTAGE
                even: {
                    x: evenx,
                    y: voltage.map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
                },
                odd: {
                    x: oddx,
                    y: voltage.map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
                },
                one: [voltage[0][0], voltage[1][0], voltage[2][0]]
            },
            {   // Currrent
                even: {
                    x: evenx,
                    y: current.map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
                },
                odd: {
                    x: oddx,
                    y: current.map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
                },
                one: [current[0][0], current[1][0], current[2][0]]
            }
        ]
    ]
}

const prepareHarmonicsData = (arr: Array<number>): Array<Array<number>> => {
    const harms1 = arr.slice(4);
    const harmonics_current = [
        harms1.slice(0, 50),
        harms1.slice(50, 100),
        harms1.slice(100, 150),
    ];
    return harmonics_current;
}

const MCMvariables = {
    general: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',
        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'varpnv-0',
        'varpnv-1',
        'varpnv-2',
        'varpnv-3',
        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',
        'unbpnv-0',
        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',
        'unbppv-0',
        'actpwr-0',
        'cosphi-0',
        'apparpwr-0',
        'reactpwr-0',
        'phasors-0',
        'phasors-1',
        'phasors-2',
        'phasors-3',
        'phasors-4',
        'phasors-5',
        'phasors-6',
        'phasors-7',
        'phasors-8',
        'phasors-9',
        'phasors-10',
        'phasors-11',
        'phasors-12',
        'phasors-13',
        'sf-0',
        'sf-1',
        'sf-2',
        'sf-3',
        'sf-4',
        'freq',
        'ldratio',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
    ], [
        'pnv-4',
        'pnv-5',
        'pnv-6',
        'iv-4',
        'iv-5',
        'iv-6',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
    ]]
}

export const MCMpermissions = {
    "electric_param": "007",
    "mechanical_param": "00a",
    "load_diagram": "00e",
    "power_quality": "014",
    "vector_diagram": "019",
    "waveforms": "01e",
    "global_status": "023",
    "spectral_analysis": "02c",
    "instant_analysis": "02e",
    "harmonics": "032",
}

export const getVariablesAdmin = (): Array<string> => {
    var three = MCMvariables.general.map((arr, indexArr) => arr.map((val, index) => val)).reduce((a: any, b: any) => [...a, ...b], [])
    return [...three]
}

export const getVariables = (): Array<string> => {
    var three = MCMvariables.general[0].map((val, index) => { return val })
    return [...three]
}
