import React, { useState } from "react";
import { VariablesResponse } from "../interfaces/Responses";
import { CurrentUser } from "../interfaces/User";
const url = process.env.NODE_ENV === "production" ? window.location.host + window.location.pathname : "10.255.255.210/";
//const url = "10.255.255.210";
//mock "localhost:3333"
//vm "10.255.255.210"
export const serverURL = "https://" + url;
export const socketURL = "wss://" + url + "ws";


const APIRequestContext = React.createContext<{
  user?: CurrentUser;
  login: (user: CurrentUser) => void;
  logout: () => void;
  apiRequest: <T>(
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    }
  ) => Promise<T>;
  apiRequestSplit: <T>(
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    },
    setVariable?: (s: { [key: string]: Array<string | number> }) => void
  ) => Promise<T>;
  apiRequestAllPages: <T>(
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    }
  ) => Promise<T>;
}>({
  user: undefined,
  login: (user) => { },
  logout: () => { },
  apiRequest: (method, route) => new Promise(() => { }),
  apiRequestSplit: (method, route) => new Promise(() => { }),
  apiRequestAllPages: (method, route) => new Promise(() => { }),
});

export default APIRequestContext;

export function APIProvider(props: React.PropsWithChildren<{}>) {
  const [user, setLoggedUser] = useState<CurrentUser | undefined>();
  let currentSplitID = "";

  const divideDateRangeIntoOtherRange = (date1: Date, date2: Date): Date[][] => {
    const max = 345600000 // 3days  //864000000 // 10 days
    const maxmax = 1296000000 // 15 days
    const newdate1 = new Date(date1)
    const newdate2 = new Date(date2)
    const divs = newdate2.getTime() - new Date(newdate1).getTime()
    if (divs < maxmax) return [[newdate1, newdate2]]
    else {
      var ret = []
      var start = newdate1.getTime();
      var end = newdate2.getTime();
      var i = 1;
      while (start + i * max < end) {
        ret.push([new Date(start + (i - 1) * max), new Date(start + (i) * max)]);
        i = i + 1
      }
      ret.push([new Date(start + (i - 1) * max), newdate2])
      return ret
    }
  }


  const getQueryString = (query: { [key: string]: any }) => {
    const queryString = Object.entries(query)
      .filter(([key, value]) => query[key] != null && query[key] !== "")
      .map(([key, value]) => value.forEach ? value.map((v2: any) => `${key}=${v2}`).join('&') : `${key}=${value}`)
      .join("&");
    if (queryString !== "") return "?" + queryString;
    else return "";
  };

  const login = (user: CurrentUser) => {
    localStorage.setItem("user", JSON.stringify(user));
    setLoggedUser(user);
  };

  const logout = () => {
    localStorage.removeItem("user");
    setLoggedUser(undefined);
    // setDevices([]);
    // setDeviceFull(undefined);
    // setDeviceReport(undefined);
    // setDeviceMainHistory(undefined);
    // setDeviceWaveforms(undefined);
    // setSubstations(undefined);
  };


  const apiRequest = async<T extends unknown>(
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    }
  ): Promise<T> => {
    return new Promise<T>(async (resolve, reject) => {
      let serviceUrl = serverURL + route;
      const options: RequestInit = { method };
      if (params !== undefined) {
        if (params.query !== undefined) {
          serviceUrl += getQueryString(params.query);
        }
        if (params.jsonData !== undefined) {
          options.headers = {
            ...options.headers,
            "Content-Type": "application/json",
          };
          options.body = JSON.stringify(params.jsonData);
        } else if (params.formData !== undefined) {
          options.body = params.formData;
        }
      }
      const currentUser = localStorage.getItem("user");
      if (currentUser !== null) {
        options.headers = {
          ...options.headers,
          Authorization: JSON.parse(currentUser).token,
        };
      }

      try {
        const res = await fetch(serviceUrl, options);
        if (res.ok) {
          try {
            resolve((await res.json()) as T);
          } catch (e) {
            resolve((undefined as unknown) as T);
          }
        } else {
          if (res.status === 401) {
            localStorage.removeItem("user");
            logout()
          }
          reject(`error ${method} ${route} #${res.status}# *${await res.text()}*`);
        }
      } catch (e: any) {
        reject(`network error ${method} ${route} #${e.status}#`);
      }

    });
  };


  const apiRequestSplit = async<T extends unknown>( // KEEPS THE BODY OF THE LAST MESSAGE AND THE VALUES ARE CONCATENATED
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    },
    setVariable?: (s: { [key: string]: Array<string | number> }) => void
  ): Promise<T> => {
    if (params?.query && params.query.since && params.query.until) {
      const thisID = Math.round(Math.random() * 100000000).toString();
      currentSplitID = (thisID);
      //console.log(params.query.since, params.query.until)
      const divisions = divideDateRangeIntoOtherRange(params.query.since, params.query.until)
      //console.log(divisions)
      var newParams: any = params;
      const resultAppended: { [key: string]: Array<string | number> } = {};
      var answer: any = {}
      for (const div of divisions) {
        if (thisID !== currentSplitID) { return new Promise((resolve, reject) => reject("")) }
        newParams.query.since = div[0]
        newParams.query.until = div[1]
        answer = await apiRequest(method, route, newParams)
        if ((answer as any).values) {
          for (let [key, value] of Object.entries((answer as VariablesResponse<{ [key: string]: Array<string | number> }>).values)) {
            if (resultAppended[key]) {
              resultAppended[key] = value.concat(resultAppended[key]);
            } else {
              resultAppended[key] = value;
            }
          }
        }
        if (setVariable) setVariable(resultAppended);
      }
      //console.log(resultAppended)
      answer.values = resultAppended
      return new Promise((resolve) => resolve(answer));
    }
    else return apiRequest(method, route, params);
  }

  const apiRequestAllPages = async<T extends unknown>(
    method: string,
    route: string,
    params?: {
      query?: { [key: string]: any };
      jsonData?: Object;
      formData?: FormData;
    }
  ): Promise<T> => {
    return new Promise<T>(async (resolve, reject) => {
      const options: RequestInit = { method };
      if (params !== undefined) {
        if (params.jsonData !== undefined) {
          options.headers = {
            ...options.headers,
            "Content-Type": "application/json",
          };
          options.body = JSON.stringify(params.jsonData);
        } else if (params.formData !== undefined) {
          options.body = params.formData;
        }
      }
      const currentUser = localStorage.getItem("user");
      if (currentUser !== null) {
        options.headers = {
          ...options.headers,
          Authorization: JSON.parse(currentUser).token,
        };
      }

      try {
        const serviceUrlCount = serverURL + route + getQueryString({ count: true });
        const countRes = await fetch(serviceUrlCount, options);
        if (countRes.ok) {
          const count = (await countRes.json())?.value
          if (count !== undefined || count !== null) {
            const limit = 50;
            let page = 1;
            const maxPage = Math.ceil(count / limit);
            let addedResult = undefined;
            while (page <= maxPage) {
              const pageServiceUrl = serverURL + route + getQueryString({ ...params?.query, limit: limit, page: page });
              page++;
              const res = await fetch(pageServiceUrl, options);
              if (res.ok) {
                try {
                  const resultJson = (await res.json());
                  if (addedResult === undefined) {
                    addedResult = resultJson;
                  } else {
                    if (addedResult?.values && resultJson?.values) {
                      addedResult.values = addedResult.values.concat(resultJson.values)
                    }
                  }
                } catch (e) {
                  addedResult = undefined;
                }
              } else {
                if (res.status === 401) {
                  localStorage.removeItem("user");
                  logout()
                }
                reject(`error ${method} ${route} #${res.status}#`);
              }
            }
            resolve(addedResult);
          }
        }
      } catch (e: any) {
        reject(`network error ${method} ${route} #${e.status}#`);
      }

    });
  };

  React.useEffect(() => {
    const currentUserStr = localStorage.getItem("user");
    if (currentUserStr != null) {
      const currentUser = JSON.parse(currentUserStr);
      setLoggedUser(currentUser);
    }
  }, []);

  return (
    <APIRequestContext.Provider value={{ user, login, logout, apiRequest, apiRequestSplit, apiRequestAllPages }}>
      {props.children}
    </APIRequestContext.Provider>
  );
}