
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useRef } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import RoundGauge from '../../../components/device/final/RoundGauge';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forLoadStatistics } from '../../../utils/format-data';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateLD1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
      "gr1 ga1"
      "gr1 ga2"
      "gr1 ga3"`,
        gridTemplateRows: "repeat(3, 32.64%)",
        gridTemplateColumns: "85% 14.65%",
        maxHeight: "650px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
      "gr1 gr1"
      "ga1 ga2"`,
        gridTemplateRows: "59.6% 39.6%",
        gridTemplateColumns: "1fr 0.5fr",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
      "gr1"
      "ga1"
      "ga2"`,
        gridTemplateRows: "59.5% 19.5% 19.5%",
        gridTemplateColumns: "99.9%",
        maxHeight: "950px",
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

export default function LoadDiagram(props: React.PropsWithChildren<any>) {
  const classes = useStyles();
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const mainGridReference = useRef(null);
  const data = forLoadStatistics(deviceFull, deviceReport);
  const gauge = data[0];
  const small = data.slice(1);
  //primario e secundario
  return (
    <Grid ref={mainGridReference} container className={classes.SideContainer}>
      {deviceFull && <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateLD1}>

          <SubTitledCard gridArea='gr1' title={"loadDiagram"}>
            <DraggableGraph
              ys={[[deviceMainHistory ? deviceMainHistory['ldratio'] : undefined]]}
              ysnames={[["ldratio"]]}
              colors={[["#e23434"]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['loadUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>

          <SubTitledCard gridArea='ga1' title={'statistics'}>
            <SmallCornerBox height={"100%"} parts={small}></SmallCornerBox>
          </SubTitledCard>

          <SubTitledCard flexCenter gridArea='ga2' title={'currentLoad'}>
            <RoundGauge data={{ name: '', value: (gauge.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[0, 80], [80, 100], [100, 125]], max: 125, unit: '%' }}></RoundGauge>
          </SubTitledCard>

        </TitledCard>

      </Grid>}
    </Grid >
  );
}
