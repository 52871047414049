
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import RoundGauge from '../../../components/device/final/RoundGauge';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
//import { si_format } from '../../../utils/format-data';
import { forMechanicalGauges } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {
        gridTemplateAreas: `
      "ga1 gr1 gr1 gr3 gr3"
      "ga1 gr1 gr1 gr3 gr3"
      "ga2 gr1 gr1 gr3 gr3"
      "ga2 gr2 gr2 gr4 gr4"
      "ga3 gr2 gr2 gr4 gr4"
      "ga3 gr2 gr2 gr4 gr4"`,
        gridTemplateRows: "repeat(6,1fr)",
        gridTemplateColumns: "0.8fr repeat(4,1fr)",
        maxHeight: "650px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "ga1 ga2 ga3"
          "gr1 gr1 gr1"
          "gr2 gr2 gr2"
          "gr3 gr3 gr3"
          "gr4 gr4 gr4"`,
        gridGap: "6px",
        padding: "10px",
        height: 2000,
        gridTemplateColumns: "repeat(3, 32.8%)",
        gridTemplateRows: "9% repeat(4, 22.48%)",
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
          "ga1"
          "ga2"
          "ga3"
          "gr1"
          "gr2"
          "gr3"
          "gr4"`,
        gridGap: "6px",
        padding: "10px",
        height: 2000,
        gridTemplateColumns: "100%",
        gridTemplateRows: "repeat(3, 8%) repeat(4, 18.57%)",
      }
    },
  }),
);

interface ElectricParamProps {
}


export default function MechanicalParam(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forMechanicalGauges(deviceReport, deviceFull);
  const mainGridReference = useRef(null);
  //primario e secundario
  return (
    <Grid ref={mainGridReference} container className={classes.SideContainer}>
      {deviceFull && <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1}>

          {gaugeData && gaugeData.map((value2: any, type: number) => <SubTitledCard flexCenter gridArea={`ga${type + 1}`} key={`subtitledcard-${type}`} title={gaugeData[type].title}>
            {gaugeData[type].array && gaugeData[type].array.map((d, index) => <RoundGauge height={130} key={`gauge-${index}`} data={d}></RoundGauge>)}
          </SubTitledCard>)}

          <SubTitledCard gridArea='gr1' title={"velocity"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`iexc-1`] : undefined,
              ]]}
              ysnames={[[
                `iexc-1`
              ]]}
              colors={[[
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['velocityUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"torque"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`iexc-0`] : undefined
              ]]}
              ysnames={[[
                `iexc-0`
              ]]}
              colors={[[
                '#2aa060'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['torqueUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title={"mechPower"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`iexc-2`] : undefined,
              ]]}
              ysnames={[[
                `iexc-2`,
              ]]}
              colors={[[
                '#e23434',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['powerUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr4' title={"performance"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`iexc-3`] : undefined,
              ]]}
              ysnames={[[
                `iexc-3`,
              ]]}
              colors={[[
                '#f9c937',
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['performanceUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>}
    </Grid >
  );
}
