import React, { useState } from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { swapSides } from '../../utils/format-data';
import { TCMpermissions } from '../../utils/tcm-functions';
import TcmInfoDialog from '../tcm/dialogs/TcmInfoDialog';
import ControlTapChanger from '../tcm/tabs/ControlTapChanger';
import DiagTapChanger from '../tcm/tabs/DiagTapChanger';
import DynamicImpedance from '../tcm/tabs/DynamicImpedance';
import ElectricParam from '../tcm/tabs/ElectricParam';
import ExcitationCurrent from '../tcm/tabs/ExcitationCurrent';
import GlobalStatus from '../tcm/tabs/GlobalStatus';
import Harmonics from '../tcm/tabs/Harmonics';
import Inrush from '../tcm/tabs/Inrush';
import LoadDiagram from '../tcm/tabs/LoadDiagram';
import PowerQuality from '../tcm/tabs/PowerQuality';
import ShortCircuit from '../tcm/tabs/ShortCircuit';
import SingleReport from '../tcm/tabs/SingleReport';
import TransformRatio from '../tcm/tabs/TransformRatio';
import VectorGraph from '../tcm/tabs/VectorGraph';
import Waveforms from '../tcm/tabs/Waveforms';

interface TCMDPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}

const variables = ["ldratio", "ppv-0", "ppv-1", "ppv-2", "ppv-4", "ppv-5", "ppv-6", "ppv-8", "ppv-9", "ppv-10", "iv-0", "iv-1", "iv-2", "iv-4", "iv-5", "iv-6", "iv-8", "iv-9", "iv-10", "unbi-0", "unbi-1", "unbi-2", "unbpnv-0", "unbpnv-1", "unbpnv-2", "thdpnv-0", "thdpnv-1", "thdpnv-2", "thdpnv-4", "thdpnv-5", "thdpnv-6", "thdpnv-8", "thdpnv-9", "thdpnv-10", "thdi-0", "thdi-1", "thdi-2", "thdi-3", "thdi-4", "thdi-5", "thdi-6", "thdi-7", "thdi-8", "thdi-9", "thdi-10", "thdi-11", "tappos",
  'sf-0', 'iexc-0', 'iexc-1', 'iexc-2', 'varlcc-3', 'iv-3', 'iv-7', 'rt-11', 'rt-15'] as const //baixo diag, cima control
type TUV = typeof variables[number];
type time = { 'timestamp': string[] }
const variables2 = (variables as any)
variables2.unshift('timestamp')
export type TcmUsableVariables = time & {
  [K in TUV]: Array<number>;
}

export default function TCMDPage(props: TCMDPageProps) {
  const { location } = props
  const [hasV1, setHasV1] = useState<boolean>(true);
  const [hasV2, setHasV2] = useState<boolean>(true);
  const [hasV3, setHasV3] = useState<boolean>(true);
  const [sideNames, setSideNames] = useState<Array<string>>(['1o', '2o']);
  const [swappedSides, setSwappedSides] = useState<boolean>(false);

  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    if (result.config_diagnostic?.power_flow === 2) {
      result = swapSides(result);
    }
    return result
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    if (result.config_diagnostic?.power_flow === 2) {
      setSwappedSides(true);
    }
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('VA'))) setHasV1(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va1'))) setHasV2(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va2'))) setHasV3(false);
    makeSideNames(result)
    return result
  }

  const makeSideNames = (result: DeviceFull) => {
    var names = ['1o'];
    const secs = result.nameplate.side_labels.filter(l => l.startsWith('S')).length
    const ters = result.nameplate.side_labels.filter(l => l.startsWith('T')).length
    const quats = result.nameplate.side_labels.filter(l => l.startsWith('Q')).length
    if (secs === 1) names.push('2o')
    else Array(secs).fill(0).forEach((x, index) => names.push(`2o${index + 1}`))
    if (ters === 1) names.push('3o')
    else Array(ters).fill(0).forEach((x, index) => names.push(`3o${index + 1}`))
    if (quats === 1) names.push('4o')
    else Array(quats).fill(0).forEach((x, index) => names.push(`4o${index + 1}`))
    setSideNames(names);
  }

  return (<MainDeviceDashboard
    sideNames={sideNames}
    location={location}
    deviceType={DeviceType.Td}
    processingAffectingContexts={processingAffectingContexts}
    processingGeneral={processingGeneral}
    DeviceInfoDialog={TcmInfoDialog}
    variables={variables2}
    controlInfoBar={{ element: <ControlInfoBar maxSFCount={1}></ControlInfoBar>, tabCode: "001" }}
    controlComponents={[
      {
        element: <ElectricParam sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></ElectricParam>,
        title: "electric_param"
      }, {
        element: <LoadDiagram></LoadDiagram>,
        title: "load_diagram"
      }, {
        element: <ControlTapChanger></ControlTapChanger>,
        title: "tap_changer"
      }, {
        element: <PowerQuality sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></PowerQuality>,
        title: "power_quality"
      }, {
        element: <VectorGraph sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></VectorGraph>,
        title: "vector_diagram"
      }, {
        element: <Waveforms sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></Waveforms>,
        title: "waveforms"
      }
    ].map(v => ({ ...v, ...{ tabCode: TCMpermissions[(v.title as keyof typeof TCMpermissions)] } }))}
    diagnosticComponents={[
      {
        element: <GlobalStatus></GlobalStatus>,
        title: "global_status"
      }, {
        element: <ExcitationCurrent></ExcitationCurrent>,
        title: "excitation"
      }, {
        element: <DiagTapChanger></DiagTapChanger>,
        title: "tap_changer_diag"
      }, {
        element: <TransformRatio sideNames={sideNames} swappedSides={swappedSides}></TransformRatio>,
        title: "transformation_ratio"
      }, {
        element: <ShortCircuit hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></ShortCircuit>,
        title: "short_circuit"
      }, {
        element: <Harmonics sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3}></Harmonics>,
        title: "harmonics"
      }, {
        element: <DynamicImpedance isTCMD></DynamicImpedance>,
        title: "dynamic_impedance"
      }, {
        element: <Inrush isTCMD></Inrush>,
        title: "inrush"
      }
    ].map(v => ({ ...v, ...{ tabCode: TCMpermissions[(v.title as keyof typeof TCMpermissions)] } }))}
    historyComponents={[
      {
        element: <HistoryPage type={DeviceType.Td} sideLabels={sideNames}></HistoryPage>,
        title: "trendAn"
      },
      {
        element: <ReportViewer>
          {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined }) => <SingleReport reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} sideNames={sideNames} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} />}
        </ReportViewer>,
        title: "sampleAn"
      },
    ]}
  ></MainDeviceDashboard>
  );
}