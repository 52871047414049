import { Button, ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
//import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Person } from '../../assets/navigationIcons/personoptimized.svg';
import APIRequestContext, { serverURL } from '../../contexts/api-context';
import { EntitiesContext } from "../../contexts/entities-context";
import { ToastContext } from "../../contexts/toast-context";
import { OrganizationList } from '../../interfaces/Organization';
import { ListResponse, SingleResponse } from '../../interfaces/Responses';
import { Roles, User, UserForm } from '../../interfaces/User';
import AdminPageDialog from '../admin/AdminPageDialog';

const useStyles = () => {
  return makeStyles((theme) => ({
    headers: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    item: {
      transition: theme.transitions.create('background-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      }
    },
    button: {
      width: '100%',
      justifyContent: 'right'
    },
    coloredIcon: {
      "& path": {
        fill: theme.palette.primary.main,
      }
    }
  }))();
}

export default function UsersMenu() {
  //const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notSending, setNotSending] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notAcknowledged, setNotAcknowledged] = useState<Array<any>>([]);
  const { apiRequest } = useContext(APIRequestContext);
  const [org, setOrg] = useState<OrganizationList>();
  const [orgs, setOrgs] = useState<OrganizationList[]>();
  const [users, setUsers] = useState<Array<User | UserForm>>();
  const { logout, user } = useContext(APIRequestContext)
  const { devices } = useContext(EntitiesContext)
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { displayToast } = useContext(ToastContext);
  const classes = useStyles();
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const getOrgDetails = (id: string) => {
      apiRequest<SingleResponse<OrganizationList>>("GET", "api/v1/organization/" + id)
        .then(result => {
          if (result.success) setOrg(result.value)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    }
    const getOrgList = () => {
      apiRequest<ListResponse<OrganizationList>>("GET", "api/v1/organization")
        .then(result => {
          if (result.success) setOrgs(result.values)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    }
    const getUsersDetails = () => {
      apiRequest<ListResponse<User>>("GET", "api/v1/user")
        .then(result => {
          if (result.success) setUsers(result.values)
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    }
    if (user) getOrgDetails(user.organization_id)
    else setOrg(undefined)
    if (user && user.role < Roles.USER) getUsersDetails()
    //console.log("LOGGERZ: usermenu")
    if (user?.role === Roles.ADMIN) getOrgList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logoutmiddle = () => {
    setOpen(false);
    logout()
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleSubmit = (user: UserForm) => {
    apiRequest<SingleResponse<any>>("POST", "api/v1/user", { jsonData: user })
      .then(result => {
        if (result.success) setUsers(users?.concat(user))
        //SUCESSO mesangens
      })
      .catch(error => {
        //FALHA
        displayToast("error", "failedAction", "nothing")
      });
    setOpenDialog(false);
  }

  const openAdminDialog = () => {
    setOpenDialog(true);
  }

  const goToAdmin = () => {
    window.open(serverURL + 'admin/')
  }

  const goToUserPage = () => {
    history.push("/userDetails");
  }

  /*
    <IconButton color="inherit" onClick={handleClick}>
          <Badge badgeContent={notAcknowledged.length + notSending.length} color="primary">
            <AccountCircleIcon color="primary" />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: "400px",
              maxWidth: "200px",
              marginTop: 43
            },
          }}
        >
          <Button className={classes.button} onClick={logout}>
            <Typography className={classes.headers} variant="caption" display="block">
              <FormattedMessage id={"logout"} />
            </Typography>
          </Button>
  
          <Button className={classes.button} href='#/userDetails'>
            <Typography className={classes.headers} variant="caption" display="block">
              <FormattedMessage id={"userDetails"} />
            </Typography>
          </Button>
  
        </Menu>*/
  //<Divider />
  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Person height={20} width={20} className={classes.coloredIcon} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {user && user?.role < Roles.SPECTATOR && <MenuItem component='a' onClick={goToUserPage} ><FormattedMessage id={"userDetails"} /></MenuItem>}
                  {user && user?.role < Roles.USER && <MenuItem component='a' onClick={openAdminDialog}><FormattedMessage id={"orgAdminPage"} /></MenuItem>}
                  {user && user?.role < Roles.ORGADMIN && <MenuItem component='a' onClick={goToAdmin}><FormattedMessage id={"adminPage"} /></MenuItem>}
                  <MenuItem onClick={logoutmiddle}><FormattedMessage id={"logout"} /></MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {user && user?.role < Roles.USER && <AdminPageDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handlePositiveClose={handleSubmit}
        users={users}
        setUsers={setUsers}
        org={org}
        orgs={orgs}
        devices={devices}
      />}
    </>
  );
}