
import React, { useContext } from 'react';
import JustGraph from '../../../components/device/middle/JustGraph';
import { EntitiesContext } from "../../../contexts/entities-context";


export default function ControlTapChanger(props: React.PropsWithChildren<any>) {
  const { deviceMainHistory } = useContext(EntitiesContext);
  return (
    <JustGraph
          graphType={'linegl'}
          ys={[[
            deviceMainHistory ? deviceMainHistory['tappos'] : undefined,
          ]]}
          ysnames={[["tappos"]]}
          colors={[['#ff892f']]}
          titley={["tapPos"]}
          titlex={''}
          title={'tapPos'}
          x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
          shape={'hv'}
          emptyMessage={'no7days'}
          YIntegerTicks
          enableReportLinks
        ></JustGraph>
  );
}






