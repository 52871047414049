import { Button, createStyles, Grid, isWidthDown, makeStyles, withWidth } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableGraph from "../../../components/device/final/DraggableGraph";
import GaugeGraph from "../../../components/device/final/GaugeGraph";
import PolarGraph from "../../../components/device/final/PolarGraph";
import RoundGauge from "../../../components/device/final/RoundGauge";
import LongDataBox from "../../../components/device/middle/LongDataBox";
import SmallCornerBox from "../../../components/device/middle/SmallCornerBox";
import SubTitledCard from "../../../components/device/middle/SubTitledCard";
import TitledCard from "../../../components/device/middle/TitledCard";
import { ThemeExtended } from "../../../components/global/Theme";
import APIRequestContext from "../../../contexts/api-context";
import { EntitiesContext } from "../../../contexts/entities-context";
import { ReportList, ReportWaveforms } from "../../../interfaces/Report";
import { forLoadStatistics, forSeverityStatistics, getPermissionObjectByDevice, makePermissionsObject, prepareForTransformRatioGauge, si_format } from "../../../utils/format-data";
import { fixForPowerQualityGauges, forExcitationGauges, forGauges, forHarmonics, forPowerQualityGauges, forVectorGraphs, forWaveforms } from "../../../utils/tcm-functions";



const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    mainDiv: {
      marginTop: 10,
      marginBottom: 10,
      '& > .MuiGrid-item': {
        padding: 5
      }
    },
    gridtemplateMoreTriple: {
      gridTemplateAreas: `
      "ga1" 
      "ga2"
      "ga3" 
      "ga21"
      "ga22" 
      "ga23"
      "gr1"
      "gr2" 
      "gr3"
      "gw1"
      "gw2"
      "gw3"
      "gh1"
      "gh2"
      "gh3"
      "gh4"`,
      gridTemplateRows: "repeat(9, 1fr) 300px 250px 320px 38px 320px 38px",
      gridTemplateColumns: "repeat(1,1fr)",
      gridGap: "6px",
      padding: "10px",
      width: "100%"
    },
    gridtemplateTriple: {
      [theme.breakpoints.up('lg')]: {  //triple
        gridTemplateAreas: `
          "ga1 ga1 ga2 ga2 ga3 ga3"
          "ga21 ga21 ga22 ga22 ga23 ga23"
          "gr1 gr1 gr2 gr2 gr3 gr3"
          "gw1 gw1 gw1 gw1 gw1 gw1"
          "gw2 gw2 gw2 gw3 gw3 gw3"
          "gh1 gh1 gh1 gh1 gh1 gh1"
          "gh2 gh2 gh2 gh2 gh2 gh2"
          "gh3 gh3 gh3 gh3 gh3 gh3"
          "gh4 gh4 gh4 gh4 gh4 gh4"`,
        gridTemplateRows: "repeat(3, 196px) 300px 300px 320px 38px 320px 38px",
        gridTemplateColumns: "repeat(6,1fr)",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateAreas: `
          "ga1 ga1 ga2 ga2"
          "ga3 ga3 ga21 ga21"
          "ga22 ga22 ga23 ga23"
          "gr1 gr1 gr1 gr1"
          "gr2 gr2 gr3 gr3"
          "gw1 gw1 gw1 gw1"
          "gw2 gw2 gw3 gw3"
          "gh1 gh1 gh1 gh1"
          "gh2 gh2 gh2 gh2"
          "gh3 gh3 gh3 gh3"
          "gh4 gh4 gh4 gh4"`,
        gridTemplateRows: "repeat(5, 196px) 300px 250px 320px 38px 320px 38px",
        gridTemplateColumns: "repeat(4,1fr)",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
          "ga1" 
          "ga2"
          "ga3" 
          "ga21"
          "ga22" 
          "ga23"
          "gr1"
          "gr2" 
          "gr3"
          "gw1"
          "gw2"
          "gw3"
          "gh1"
          "gh2"
          "gh3"
          "gh4"`,
        gridTemplateRows: "repeat(9, 1fr) 300px 250px 320px 38px 320px 38px",
        gridTemplateColumns: "repeat(1,1fr)",
        gridGap: "6px",
        padding: "10px",
        width: "100%"
      },
    },
    gridtemplateDouble: {
      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: `
          "ga1 ga1 ga2 ga2 ga3 ga3"
          "ga21 ga21 ga21 ga22 ga22 ga22"
          "gr1 gr1 gr2 gr2 gr3 gr3"
          "gw1 gw1 gw1 gw1 gw1 gw1"
          "gw2 gw2 gw2 gw3 gw3 gw3"
          "gh1 gh1 gh1 gh1 gh1 gh1"
          "gh2 gh2 gh2 gh2 gh2 gh2"
          "gh3 gh3 gh3 gh3 gh3 gh3"
          "gh4 gh4 gh4 gh4 gh4 gh4"`,
        gridTemplateRows: "repeat(3, 196px) 300px 300px 320px 38px 320px 38px",
        gridTemplateColumns: "repeat(6,1fr)",
        gridGap: "6px",
        padding: "10px"
      },
    },
    gridtemplateSix: {
      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: `
      "g1 g2 g3 g4 g5 g6"`,
        gridTemplateRows: "repeat(1, 1fr)",
        gridTemplateColumns: "repeat(6, 1fr)",
        maxHeight: "650px",
        height: 179,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateAreas: `
      "g1 g2" 
      "g3 g4" 
      "g5 g6"`,
        gridTemplateRows: "repeat(3, 1fr)",
        gridTemplateColumns: "repeat(2, 1fr)",
        maxHeight: "650px",
        height: 516,
        gridGap: "6px",
        padding: "10px"
      },
    },
    gridtemplateFive: {
      [theme.breakpoints.up('md')]: {
        gridTemplateAreas: `
      "g1 g2 g3 g4 g5"`,
        gridTemplateRows: "repeat(1, 1fr)",
        gridTemplateColumns: "repeat(5, 1fr)",
        maxHeight: "650px",
        height: 179,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
       "g1 g3"
       "g2 g2"
       "g4 g5"`,
        gridTemplateRows: "repeat(3, 1fr)",
        gridTemplateColumns: "repeat(2, 1fr)",
        maxHeight: "650px",
        height: 516,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
       "g1"
       "g2"
       "g3"
       "g4" 
       "g5"`,
        gridTemplateRows: "repeat(6, 1fr)",
        gridTemplateColumns: "repeat(1, 1fr)",
        maxHeight: "850px",
        height: 680,
        gridGap: "6px",
        padding: "10px"
      },
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    }
  })
)

interface SingleReportProps {
  reportSelected: ReportList | undefined;
  waveformsSelected: ReportWaveforms | undefined;
  sideNames: string[];
  hasV1?: boolean;
  hasV2?: boolean;
  hasV3?: boolean;
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
}

export default withWidth()(function SingleReport(props: SingleReportProps) {
  const { reportSelected, waveformsSelected, sideNames, hasV1, hasV2, hasV3, hasV4, hasV5, hasV6 } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const { user } = useContext(APIRequestContext);
  const classes = useStyles();
  const intl = useIntl();
  const permissions = makePermissionsObject(user?.views, getPermissionObjectByDevice(deviceFull?.type), user);
  let sideNamesFixed: string[] = sideNames ? sideNames : ['1o', '2o'];
  const sides = [hasV1 ? true : false, hasV2 ? true : false, hasV3 ? true : false, hasV4 === true, hasV5 === true, hasV6 === true].slice(0, sideNamesFixed.length)
  let xsLength: (6 | 4 | 3 | 2)[] = sides.map(x => (Math.floor(12 / sides.length) as (6 | 4 | 3 | 2)));
  if ((xsLength as number[]).reduce((a, b) => a + b) < 12) { xsLength[0] = (xsLength[0] + 1 as (6 | 4 | 3 | 2)); xsLength[1] = (xsLength[1] + 1 as (6 | 4 | 3 | 2)) }
  const elecParamData = forGauges(reportSelected, sides, intl.formatMessage({ id: 'na' }), deviceFull);
  const loadGauge = forLoadStatistics(deviceFull, reportSelected)[0];
  const qualityData = fixForPowerQualityGauges(forPowerQualityGauges(reportSelected, deviceFull), intl, sideNames, hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, true)
  const vectorData = forVectorGraphs(reportSelected);
  const waveData = forWaveforms(waveformsSelected, deviceFull)
  const sfGauge = forSeverityStatistics(deviceFull, reportSelected)[0];
  const excitationData = forExcitationGauges(reportSelected).map(g => { g.array.map(ar => ar.name = intl.formatMessage({ id: ar.name })); return g });
  const transRatioData = prepareForTransformRatioGauge(deviceFull, reportSelected);
  const harmonicsData = forHarmonics(reportSelected, deviceFull);
  let sfLimits = [1, 2];
  if ((deviceFull?.severity_param.sF_limit1 || deviceFull?.severity_param.sF_limit1 === 0) && (deviceFull?.severity_param.sF_limit2 || deviceFull?.severity_param.sF_limit2 === 0)) sfLimits = [deviceFull?.severity_param.sF_limit1, deviceFull?.severity_param.sF_limit2];
  const [parityCurrent, setParityCurrent] = useState<boolean[]>([false, false, false, false, false, false]);
  const [parityVoltage, setParityVoltage] = useState<boolean[]>([false, false, false, false, false, false]);
  const changeParity = (side: number, current: number) => {
    if (current === 0) {
      setParityCurrent(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    } else {
      setParityVoltage(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    }
  };
  // console.log(qualityData,forPowerQualityGauges(reportSelected, deviceFull))

  return (<Grid className={classes.mainDiv} container>



    <Grid item xs={12}>
      <TitledCard gridTemplate={classes.gridtemplateFive} title={"globalIndicators"}>

        <SubTitledCard flexCenter gridArea='g1' title={'loadJust'}>
          <RoundGauge empty={!permissions["load_diagram"]} data={{ name: '', value: (loadGauge!.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[0, 80], [80, 100], [100, 125]], max: 125, unit: '%' }}></RoundGauge>
        </SubTitledCard>

        <SubTitledCard gridArea={"g2"} title={'excitation'}>
          <GaugeGraph empty={!permissions["transformation_ratio"]} data={excitationData![0].array}>
          </GaugeGraph>
        </SubTitledCard>

        <SubTitledCard flexCenter gridArea='g3' title={'tapPos'}>
          <RoundGauge
            empty={!permissions["tap_changer"]}
            data={{
              name: '',
              value: reportSelected?.tapChanger.tapPos,
              colors: ["#2aa060"],
              steps: [[0, 80]],
              max: deviceFull && deviceFull.nameplate.side[0].Tap[deviceFull.nameplate.side[0].Tap.length - 1] ? deviceFull.nameplate.side[0].Tap[deviceFull.nameplate.side[0].Tap.length - 1][0] : 125,
              min: deviceFull && deviceFull.nameplate.side[0].Tap[0] ? deviceFull.nameplate.side[0].Tap[0][0] : 0,
              extendedNumber: true
            }}></RoundGauge>
        </SubTitledCard>

        {<SubTitledCard flexCenter gridArea='g4' title={'severityFactor'}>
          <RoundGauge empty={!permissions["global_status"]} data={{ name: '', value: (sfGauge!.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[-1, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], 3]], max: 3, unit: '%' }}></RoundGauge>
        </SubTitledCard>}

        <SubTitledCard flexCenter gridArea={"g5"} title={'transformRatio'}>
          <RoundGauge empty={!permissions["transformation_ratio"]} data={transRatioData![0]}>
          </RoundGauge>
        </SubTitledCard>

      </TitledCard>
    </Grid>


    {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-${side}`} item xs={xsLength[side]}>
      <TitledCard gridTemplate={qualityData![side].length < 3 ? classes.gridtemplateDouble : qualityData![side].length < 4 ? classes.gridtemplateTriple : classes.gridtemplateMoreTriple} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>

        {elecParamData![side] && elecParamData![side].map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${side * 3 + type}`} title={elecParamData![side][type].title}>
          <GaugeGraph empty={!permissions["electric_param"]} bigValue data={elecParamData![side][type].array}></GaugeGraph>
        </SubTitledCard>)}

        {qualityData![side] && qualityData![side].map((value2: any, type: number) => <SubTitledCard removeFirstNWords={isWidthDown('xl', (props as any).width) && (sideNamesFixed.length > 2) ? 2 : 0} gridArea={`ga2${type + 1}`} key={`subtitledcard-${type}`} title={qualityData![side][type] ? qualityData![side][type].title : ''}>
          {qualityData![side][type] && <GaugeGraph empty={!permissions["power_quality"]} smallNumber bigValue bigText={qualityData![side][type].array[0].value !== undefined} data={qualityData![side][type].array}></GaugeGraph>}
        </SubTitledCard>)}


        <SubTitledCard gridArea='gr1'>
          <PolarGraph
            empty={!permissions["vector_diagram"]}
            verySmall={sideNamesFixed.length > 2}
            ys={sides[side] ?
              (vectorData && vectorData[side]) ? vectorData[side].map(form => form.angulo) : [[]] :
              (vectorData && vectorData[side]) ? vectorData[side].map(form => form.angulo).filter((v, ind) => ind > 2) : [[]]
            }
            ysnames={side === 0 ?
              sides[side] ? ['VA', 'VB', 'VC', 'IA', 'IB', 'IC'] : ['IA', 'IB', 'IC'] :
              sides[side] ? ['Va', 'Vb', 'Vc', 'Ia', 'Ib', 'Ic'] : ['Ia', 'Ib', 'Ic']
            }
            colors={['#e23434', '#2aa060', '#3473ba', '#f15d5d', '#51c787', '#6299d8']}
            xs={sides[side] ?
              (vectorData && vectorData[side]) ? vectorData[side].map(form => form.amplitude) : [] :
              (vectorData && vectorData[side]) ? vectorData[side].map(form => form.amplitude).filter((v, ind) => ind > 2) : []
            }
          ></PolarGraph>
        </SubTitledCard>

        <SubTitledCard gridArea='gr2' title='voltage'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            small
            parts={sides[side] ? [
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: side === 0 ? 'VA' : 'Va', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][0].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][0].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'VB' : 'Vb', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][1].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][1].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'VC' : 'Vc', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][2].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][2].angulo[1] : 0, 'º', 1, true)] },
            ] : [
                { title: 'empty', value: [intl.formatMessage({ id: 'na' }), '', ''] },
              ]}
          ></SmallCornerBox>
        </SubTitledCard>

        <SubTitledCard gridArea='gr3' title='current'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            small
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: side === 0 ? 'IA' : 'Ia', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][3].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][3].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'IB' : 'Ib', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][4].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][4].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'IC' : 'Ic', value: [si_format((vectorData && vectorData[side]) ? vectorData[side][5].amplitude[1] : 0, 'pu', 2, true), si_format((vectorData && vectorData[side]) ? vectorData[side][5].angulo[1] : 0, 'º', 1, true)] },
            ]}
          ></SmallCornerBox>
        </SubTitledCard>



        {<>
          <SubTitledCard gridArea='gw1'>
            {<DraggableGraph
              empty={!permissions["waveforms"]}
              x={(waveData && waveData[side] && waveData[side][0] ? waveData[side][0].x : [] as number[])}
              ys={[waveData && waveData[side] && waveData[side][0] ? waveData[side][0].ys : [[]]] as number[][][]}
              titlex={'msUnits'}
              titley={['amplitudeUnits']}
              ysnames={[side === 0 ? ['pnv-0', 'pnv-1', 'pnv-2', 'iv-0', 'iv-1', 'iv-2'] : ['pnv-4', 'pnv-5', 'pnv-6', 'iv-4', 'iv-5', 'iv-6']]}
              colors={[["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8']]}
              graphType={'linegl'}
              notTimezone
              removeLegendPrefix
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gw2' title='voltagePark'>
            {<DraggableGraph
              empty={!permissions["waveforms"]}
              x={waveData && waveData[side] && waveData[side][1] ? waveData[side][1].x as number[] : []}
              ys={sides[side] ? [waveData && waveData[side] && waveData[side][1] ? waveData[side][1].ys : [[]]] as number[][][] : [[[]]]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#3473ba"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gw3' title='currentPark'>
            {<DraggableGraph
              empty={!permissions["waveforms"]}
              x={waveData && waveData[side] && waveData[side][2] ? waveData[side][2].x as number[] : []}
              ys={[waveData && waveData[side] && waveData[side][2] ? waveData[side][2].ys : [[]]] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#e23434"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
              emptyMessage={'na'}
            ></DraggableGraph>}
          </SubTitledCard>
        </>}

        {<>
          <SubTitledCard gridArea='gh1' title='voltage' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              empty={!permissions["harmonics"] || !sides[side]}
              ys={harmonicsData[side] ?
                [harmonicsData[side][1][parityVoltage[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={harmonicsData[side] ? harmonicsData[side][1][parityVoltage[side] ? 'even' : 'odd'].x : []}
              ysnames={side === 0 ? [['VA', 'VB', 'VC', 'IA', 'IB', 'IC']] : [['Va', 'Vb', 'Vc', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gh2'>
            <LongDataBox
              empty={!permissions["harmonics"]}
              parts={sides[side] ?
                [
                  { title: 'Va1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[0], 'V') },
                  { title: 'Vb1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[1], 'V') },
                  { title: 'Vc1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[2], 'V') },
                ] :
                [
                  { title: '', value: '' },
                  { title: intl.formatMessage({ id: 'na' }), value: '' },
                  { title: '', value: '' },
                ]}
            ></LongDataBox>
          </SubTitledCard>
          <SubTitledCard gridArea='gh3' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              empty={!permissions["harmonics"]}
              ys={harmonicsData[side] ?
                [harmonicsData[side][0][parityCurrent[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={harmonicsData[side] ? harmonicsData[side][0][parityCurrent[side] ? 'even' : 'odd'].x : []}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gh4'>
            <LongDataBox
              empty={!permissions["harmonics"]}
              parts={[
                { title: 'Ia1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[0], 'A') },
                { title: 'Ib1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[1], 'A') },
                { title: 'Ic1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
        </>}


      </TitledCard>


    </Grid>)}
  </Grid>)
})