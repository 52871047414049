
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forPowerQualityGauges } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplatePQ1: {
      [theme.breakpoints.up('md')]: {
        gridTemplateAreas: `
        "ga1 ga2 ga3 gr1 gr1 gr1"
        "gr2 gr2 gr2 gr3 gr3 gr3"`,
        gridTemplateRows: "repeat(2,49.5%)",
        gridTemplateColumns: "repeat(6,1fr)",
        height: theme.shape.graphBox.normal.height,
        maxHeight: "650px",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
        "ga1 ga2 ga3"
        "gr1 gr1 gr1"
        "gr2 gr2 gr2"
        "gr3 gr3 gr3"`,
        gridGap: "6px",
        padding: "10px",
        height: '1000px',
        gridTemplateRows: "repeat(4,24.6%)",
        gridTemplateColumns: "repeat(3,1fr)",
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
          "ga1"
          "ga2"
          "ga3"
          "gr1"
          "gr2"
          "gr3"`,
        gridTemplateRows: "9% repeat(2, 14.5%) repeat(3, 20%)",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: '1400px',
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

export default function PowerQuality(props: React.PropsWithChildren<{ hasV?: boolean }>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceFull, deviceMainHistory, deviceReport } = useContext(EntitiesContext);
  const intl = useIntl();
  let gaugeData = forPowerQualityGauges(deviceReport, !hasV, true);
  gaugeData.map(g => g.array.map(ar => ar.name = intl.formatMessage({ id: ar.name })))
  return (
    <Grid container className={classes.SideContainer}>
      {deviceFull && <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplatePQ1}>

          {gaugeData && gaugeData.map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${type}`} title={gaugeData[type].title}>
            <GaugeGraph empty={type === 1 && !hasV} smallNumber bigText data={gaugeData[type].array}></GaugeGraph>
          </SubTitledCard>)}

          <SubTitledCard gridArea='gr1' title={"unbalance"}>
            <DraggableGraph
              ys={[hasV ? [
                deviceMainHistory ? deviceMainHistory[`unbi-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`unbppv-0`] : undefined,
              ] : [
                  deviceMainHistory ? deviceMainHistory[`unbi-0`] : undefined,
                ]
              ]}
              ysnames={[[
                `unbi-0-mcm`,
                `unbppv-0-mcm`,
              ]]}
              colors={[[
                '#e23434',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['unbalanceUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"thdv"}>
            <DraggableGraph
              empty={!hasV}
              ys={[[
                deviceMainHistory ? deviceMainHistory[`thdppv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdppv-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdppv-2`] : undefined,
              ]]}
              ysnames={[[
                `thdppv-0-mcm`,
                `thdppv-1-mcm`,
                `thdppv-2-mcm`,
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title={"thdc"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`thdi-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdi-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`thdi-2`] : undefined,
              ]]}
              ysnames={[[
                `thdi-0-mcm`,
                `thdi-1-mcm`,
                `thdi-2-mcm`,
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>
      }
    </Grid >
  );
}






