import { AppBar, Box, Button, createStyles, LinearProgress, makeStyles, Tab, Tabs, Theme, Tooltip, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Control } from '../../../assets/navigationIcons/controloptimized.svg';
import { ReactComponent as Diagnostic } from '../../../assets/navigationIcons/diagnosticoptimized.svg';
import { ReactComponent as History } from '../../../assets/navigationIcons/historyoptimized.svg';
import CommonTime from '../../../components/global/CommonTime';
import ScrollableContent from '../../../components/global/ScrollableContent';
import SubNavBar from '../../../components/global/SubNavBar';
import APIRequestContext from '../../../contexts/api-context';
import { EntitiesContext } from "../../../contexts/entities-context";
import { SocketContext } from '../../../contexts/socket-context';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceType } from '../../../interfaces/Device';
import { ReportList } from '../../../interfaces/Report';
import { ListResponse, SingleResponse, VariablesResponse } from '../../../interfaces/Responses';
import { Roles } from '../../../interfaces/User';
import { deviceFullToDeviceFullExpanded, deviceTypeToRouteString } from '../../../utils/format-data';
import permissionBetter from '../../../utils/view-permissions';
import { ThemeExtended } from '../../global/Theme';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AppBarDevice: {
      color: "#000000de",
      marginBottom: 6
    },
    deviceTitle: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
      borderRadius: "10px"
    },
    deviceTitleName: {
      color: "white",
      paddingLeft: "0px",
      marginRight: "7px",
      maxWidth: "33vw",
      overflowX: "clip",
      [theme.breakpoints.down('sm')]: {
        fontSize: "19px",
        whiteSpace: 'normal'
      },
      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'unset',
        wordBreak: 'break-all',
        fontSize: "15px",
        maxWidth: 85
      },
      fontSize: "32px",
      fontWeight: 700,
      width: "fit-content",
      margin: "unset",
      textAlign: "unset",
      paddingRight: "0",
      lineHeight: "19px",
      whiteSpace: "nowrap",
      textTransform: 'none'
    },
    deviceTitleBubble: {
      height: "20px",
      width: "20px",
      borderRadius: "20px",
      marginRight: "10px",
      border: "2px solid #fff",
    },
    deviceTitleInfo: {
    },
    deviceTitleLocation: {
      marginTop: "-4px",
      fontWeight: 400,
      width: "auto",
      paddingRight: "10px",
      color: "#fff",
      marginBottom: "0",
      whiteSpace: "nowrap",
      maxWidth: 120,
      overflow: "hidden",
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    deviceTitleDate: {
      width: "auto",
      paddingRight: "10px",
      color: "#fff",
      marginBottom: "0",
      marginTop: "-4px",
      fontWeight: 600,
      fontSize: "15px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down('sm')]: {
        whiteSpace: "unset",
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    tabs: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      whiteSpace: "nowrap",
      fontWeight: 600,
      fontSize: "14px",
      textTransform: "unset",
      margin: 'auto'
    },
    tabContainer: {
      borderBottom: "1px solid rgba(0,0,0,.12)"
    },
    boxImage: {
      display: "block",
      width: "30px",
      marginLeft: "10px",
      overflow: "visible",
      "& path": {
        fill: theme.palette.primary.main
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginLeft: 0
      }
    },
    boxImageWhite: {
      display: "block",
      width: "30px",
      marginLeft: "10px",
      overflow: "visible",
      "& path": {
        fill: theme.palette.grey[50]
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginLeft: 0
      }
    },
    progressBeforeLoad: {
      marginTop: 25
    }
  }
  )
)

export const routes: { [route: string]: number } = {
  'control': 0,
  "diagnostic": 1,
  "history": 2,
  //"report": 3
}

// const tcmVariables = ["ldratio", "ppv-0", "ppv-1", "ppv-2", "ppv-4", "ppv-5", "ppv-6", "ppv-8", "ppv-9", "ppv-10", "iv-0", "iv-1", "iv-2", "iv-4", "iv-5", "iv-6", "iv-8", "iv-9", "iv-10", "unbi-0", "unbi-1", "unbi-2", "unbpnv-0", "unbpnv-1", "unbpnv-2", "thdpnv-0", "thdpnv-1", "thdpnv-2", "thdpnv-4", "thdpnv-5", "thdpnv-6", "thdpnv-8", "thdpnv-9", "thdpnv-10", "thdi-0", "thdi-1", "thdi-2", "thdi-3", "thdi-4", "thdi-5", "thdi-6", "thdi-7", "thdi-8", "thdi-9", "thdi-10", "thdi-11", "tappos",
//   'sf-0', 'iexc-0', 'iexc-1', 'iexc-2', 'varlcc-3', 'iv-3', 'iv-7', 'rt-7'] as const //baixo diag, cima control
// type TUV = typeof tcmVariables[number];
// type time = { 'timestamp': string[] }
// const variables2 = (tcmVariables as any)
// variables2.unshift('timestamp')
// export type DeviceUsableVariables = time & {
//   [K in TUV]: Array<number>;
// }

interface DeviceDialogProps {
  open: boolean;
  device?: DeviceFull;
  handleClose: () => void;
  handleSubmit?: () => void;
  sideNames?: string[]
}

interface tabComponentProps {
  element: JSX.Element,
  title: string,
  tabCode?: string,
}

interface MainDeviceDashboardProps {
  location: any,
  deviceType: DeviceType,
  processingAffectingContexts: (arg0: DeviceFull) => DeviceFull,
  processingGeneral: (arg0: DeviceFull) => DeviceFull,
  DeviceInfoDialog: React.ComponentType<DeviceDialogProps>,
  controlComponents: tabComponentProps[],
  controlInfoBar?: { element: JSX.Element, tabCode: string },
  diagnosticComponents: tabComponentProps[],
  historyComponents: tabComponentProps[],
  variables: any,
  sideNames?: string[]
}

export default function MainDeviceDashboard(props: MainDeviceDashboardProps) {
  const classes = useStyles();
  const { location, deviceType, processingAffectingContexts, processingGeneral, DeviceInfoDialog, controlComponents, controlInfoBar, diagnosticComponents, historyComponents, variables, sideNames } = props
  const deviceTypeName = deviceTypeToRouteString(deviceType);
  const ID = location.pathname.split('/')[2]
  const [active, setActive] = useState<number>(0);
  const { deviceFull, deviceReport, setDeviceWaveforms, setDeviceFull, setDeviceReport, setDeviceMainHistory } = useContext(EntitiesContext);
  const [loadedDevice, setLoadedDevice] = useState<boolean>(false);
  const [failedDevice, setFailedDevice] = useState<boolean>(false);
  const [loadedReport, setLoadedReport] = useState<boolean>(false);
  const [failedReport, setFailedReport] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadedReportHistory, setLoadedReportHistory] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [failedReportHistory, setFailedReportHistory] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadedWaveforms, setLoadedWaveforms] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [failedWaveforms, setFailedWaveforms] = useState<boolean>(false);
  const { apiRequest } = useContext(APIRequestContext);
  const [controlTabIndex, setControlTabIndex] = React.useState(0);
  const [diagnosticTabIndex, setDiagnosticTabIndex] = React.useState(0);
  const [historyTabIndex, setHistoryTabIndex] = React.useState(0);
  const { joinRoom, leaveRoom } = useContext(SocketContext);
  const [inRoom, setInRoom] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const { user } = useContext(APIRequestContext)
  const intl = useIntl();
  const history = useHistory();
  const theme: ThemeExtended = useTheme();
  const buttons = [
    {
      name: "control",
      link: `/${deviceTypeName}/@id/control`,
      icon: <Control className={classes.boxImage} />,
      altIcon: <Control className={classes.boxImageWhite} />,
    },
    {
      name: "diagnostic",
      link: `/${deviceTypeName}/@id/diagnostic`,
      icon: <Diagnostic className={classes.boxImage} />,
      altIcon: <Diagnostic className={classes.boxImageWhite} />,
    },
    {
      name: "history",
      link: `/${deviceTypeName}/@id/history`,
      icon: <History className={classes.boxImage} />,
      altIcon: <History className={classes.boxImageWhite} />,
    },
    /*{
      name: "report",
      link: "#/tcmd/@id/report",
      icon: report1,
      altIcon: report2
    }*/
  ]

  const controlBarFiltered = useMemo(() => {
    if (user?.role === Roles.ADMIN) return controlInfoBar ? controlInfoBar.element : undefined;
    return controlInfoBar && user?.views.includes(controlInfoBar?.tabCode) ? controlInfoBar.element : undefined
  }, [controlInfoBar, user])
  const controlComponentsFiltered = useMemo(() => {
    if (user?.role === Roles.ADMIN) return controlComponents
    // console.log(controlComponents.filter(c => c.tabCode && user?.views.includes(c.tabCode)),controlComponents,user)
    return controlComponents.filter(c => c.tabCode && user?.views.includes(c.tabCode))
  }, [controlComponents, user]);
  const diagnosticComponentsFiltered = useMemo(() => {
    if (user?.role === Roles.ADMIN) return diagnosticComponents
    return diagnosticComponents.filter(c => c.tabCode && user?.views.includes(c.tabCode))
  }, [diagnosticComponents, user]);
  const variablesFiltered = useMemo(() => {
    if (user?.role === Roles.ADMIN) return variables
    const allVars = permissionBetter.filter(v => v.solution === deviceType).map(v => v.vr).flat()
    return variables.filter((v: string) => allVars.find(vari => v.startsWith(vari)) !== undefined || v.localeCompare("timestamp") === 0);
  }, [deviceType, user?.role, variables]);

  useEffect(() => {
    if (deviceFull) document.title = deviceFull.name + " - " + theme.palette.images.tabName
    return () => {
      document.title = theme.palette.images.tabName
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceFull])

  useEffect(() => {
    var isInRoomNow = inRoom;
    const roomID = ID
    // console.log("~~~~~~~~ try join_room", roomID, !isInRoomNow)
    if (!isInRoomNow && roomID) {
      if (joinRoom) joinRoom(roomID)
      setInRoom(true)
      isInRoomNow = true;
    }
    return () => {
      //console.log("~~~~~~~~ try leave_room", roomID, !isInRoomNow, !document.location.href.includes('cm/'),document.location.href)
      if (isInRoomNow && roomID && !document.location.href.includes('cm/')) {
        if (leaveRoom) leaveRoom(roomID)
        setInRoom(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID])

  useEffect(() => {
    const getDeviceFull = () => {
      apiRequest<SingleResponse<DeviceFull>>("GET", "api/v1/device/" + ID)
        .then(result => {
          var df = processingAffectingContexts(result.value);
          df = processingGeneral(df)
          setLoadedDevice(true)
          setFailedDevice(false)
          setDeviceFull(deviceFullToDeviceFullExpanded(df));
          if (result.value.last_report === undefined || result.value.last_report.id === undefined) {
            setFailedReport(true); setLoadedReport(false); setFailedWaveforms(true); setLoadedWaveforms(false)
          } else {
            getLastReport(result.value.last_report?.id, result.value._id, result.value.type)
          }
        })
        .catch(error => {
          setFailedDevice(true);
          setLoadedDevice(false);
          if (error.includes && error.includes('#403#')) {
            history.push('/dashboard')
          }
        });
    }

    const getLastReport = (LRID: string | undefined, devid?: string, deviceType?: any) => {
      apiRequest<ListResponse<any>>("GET", "api/v1/report/", { query: { ids: LRID, type: deviceType } })
        .then(result => { setDeviceReport(result.values[0]); setLoadedReport(true); setFailedReport(false); getWaveforms(result.values[0].graph_id) })
        .catch(error => { findPreviousReport(devid) });
    }

    const findPreviousReport = (deviceid: string | undefined) => {
      //console.log("triggered",deviceid)
      apiRequest<ListResponse<ReportList>>("GET", "api/v1/report/", { query: { orderby: "timestamp", ordertype: "-1", only_one: true, device_id: deviceid, since: new Date(Date.now() - (259200000 * 10)).toISOString(), until: (new Date()).toISOString() } })
        .then(result => {
          setDeviceReport(result.values[0]);
          setLoadedReport(true);
          setFailedReport(false);
          getWaveforms(result.values[0].graph_id);
          setDeviceFull((s: DeviceFull) => {
            const devF = {
              ...s, ...({
                last_report: {
                  id: result.values[0]._id,
                  timestamp: (result.values[0].timestamp as unknown as Date),
                  sf: result.values[0].diagnostic.sF,
                  at: (result.values[0].timestamp as unknown as Date),
                  level: 0,
                }
              })
            }
            return deviceFullToDeviceFullExpanded((devF as DeviceFull))
          });
        })
        .catch(error => {
          setFailedReport(true); setLoadedReport(false); setFailedWaveforms(true); setLoadedWaveforms(false)
        });
    }

    const getWaveforms = (WFID: string) => {
      apiRequest<SingleResponse<any>>("GET", "api/v1/graph/" + WFID)
        .then(result => { setDeviceWaveforms(result.value.waveforms); setLoadedWaveforms(true); setFailedWaveforms(false); })
        .catch(error => { setFailedWaveforms(true); setLoadedWaveforms(false) });
    }

    if (ID !== deviceFull?._id) {
      setDeviceMainHistory({})
      getDeviceFull();
      //3 é o control, diagnostic etc, 4 é parametros eletricos, qualidade, etc
      //ROUTES FIX
      setActive(routes[location.pathname.split('/')[3]] ? routes[location.pathname.split('/')[3]] : 0);
      if (routes[location.pathname.split('/')[3]] === 0) setControlTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : 0)
      else if (routes[location.pathname.split('/')[3]] === 1) setDiagnosticTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : 0)
      else if (routes[location.pathname.split('/')[3]] === 2) setHistoryTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : 0)
    } else {
      processingGeneral(deviceFull!);
      setLoadedDevice(true)
      setFailedDevice(false)
      const lr = deviceFull ? deviceFull.last_report ? deviceFull.last_report.id : '' : '';
      if (!deviceReport || !deviceReport._id || deviceReport._id !== lr) getLastReport(lr)
      else setLoadedReport(true)
      //3 é o control, diagnostic etc, 4 é parametros eletricos, qualidade, etc
      //ROUTES FIX
      setActive(routes[location.pathname.split('/')[3]] ? routes[location.pathname.split('/')[3]] : 0);
      if (routes[location.pathname.split('/')[3]] === 0) setControlTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : controlTabIndex)
      else if (routes[location.pathname.split('/')[3]] === 1) setDiagnosticTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : diagnosticTabIndex)
      else if (routes[location.pathname.split('/')[3]] === 2) setHistoryTabIndex(location.pathname.split('/')[4] ? parseInt(location.pathname.split('/')[4]) - 1 : historyTabIndex)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, ID, setDeviceFull, setDeviceMainHistory, setDeviceReport, setDeviceWaveforms]);

  useEffect(() => {
    const addDays = (date: Date, days: number) => {
      return new Date(date.getTime() + days * 86400000)
    }
    const getReportHistory = (device_id: string, lastDate?: string) => {
      let ld = lastDate ? new Date(lastDate) : new Date(new Date().getTime() + 60000)
      ld.setHours(ld.getHours() + 1)
      const lastDate2 = lastDate ? ld : new Date()
      apiRequest<VariablesResponse<any>>("GET", "api/v1/report/", { query: { type: deviceType, device_id: device_id, since: addDays(lastDate2, -8).toISOString(), until: lastDate2.toISOString(), variables: variablesFiltered } })
        .then(result => { setDeviceMainHistory(result.values); setLoadedReportHistory(true); setFailedReportHistory(false) })
        .catch(error => { setFailedReportHistory(true); setLoadedReportHistory(false) });
    }
    getReportHistory(ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID, deviceReport, setDeviceMainHistory, variablesFiltered]);

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (<div style={{ height: "100%", transform: "translate(0px, -16px)", }}>
    {loadedDevice && <SubNavBar buttons={buttons} active={active} setActive={setActive}>
      <div className={classes.deviceTitle}>
        <Tooltip title={intl.formatMessage({ id: 'seeDetails' })}>
          <Button onClick={() => setOpenInfoDialog(true)}>
            <p className={classes.deviceTitleName}>
              {deviceFull?.name}
            </p>
          </Button>
        </Tooltip>
        <div style={{ backgroundColor: deviceFull?.sfGroup.sfs[deviceFull?.sfGroup.maxIndex].color }} className={classes.deviceTitleBubble}></div>
        <div className={classes.deviceTitleInfo}>
          <p className={classes.deviceTitleLocation}>
            {deviceFull?.local}
          </p>
          <p className={classes.deviceTitleDate}>
            <CommonTime val={deviceReport?.timestamp ? (deviceReport?.timestamp as unknown as string) : '0'}></CommonTime>
          </p>
        </div>
      </div>
    </SubNavBar>}

    {failedDevice && <p><FormattedMessage id={'failedLoadingDevice'} /></p>}

    {!failedDevice && !failedReport && !loadedReport && <LinearProgress className={loadedDevice ? "" : classes.progressBeforeLoad} />}

    {loadedReport && active === routes['control'] && <ScrollableContent>
      {controlBarFiltered}
      <AppBar className={classes.AppBarDevice} position="static">
        <Tabs indicatorColor={"primary"} className={classes.tabContainer} value={controlTabIndex} variant="scrollable" aria-label="control tabs example">
          {controlComponentsFiltered.map((cc, index) => <Tab key={`tabs-${index}`} className={classes.tabs} onClick={() => { history.push(`/${deviceTypeName}/${location.pathname.split('/')[2]}/control/${index + 1}`) }} label={<FormattedMessage id={cc.title} />} {...a11yProps(index)} />
          )
          }
        </Tabs>
      </AppBar>
      {controlComponentsFiltered.map((cc, index) => <TabPanel key={`tabscontent-${index}`} value={controlTabIndex} index={index}>
        {cc.element}
      </TabPanel>)
      }
    </ScrollableContent>
    }
    {loadedReport && active === routes['diagnostic'] && <ScrollableContent>
      <AppBar className={classes.AppBarDevice} position="static">
        <Tabs indicatorColor={"primary"} className={classes.tabContainer} value={diagnosticTabIndex} variant="scrollable" aria-label="diagnostic tabs">
          {diagnosticComponentsFiltered.map((cc, index) => <Tab key={`tabs2-${index}`} className={classes.tabs} onClick={() => { history.push(`/${deviceTypeName}/${location.pathname.split('/')[2]}/diagnostic/${index + 1}`) }} label={<FormattedMessage id={cc.title} />} {...a11yProps(index)} />)
          }
        </Tabs>
      </AppBar>
      {diagnosticComponentsFiltered.map((cc, index) => <TabPanel key={`tabscontent2-${index}`} value={diagnosticTabIndex} index={index}>
        {cc.element}
      </TabPanel>)}
    </ScrollableContent>
    }
    {loadedReport && active === routes['history'] && <ScrollableContent>
      <AppBar className={classes.AppBarDevice} position="static">
        <Tabs indicatorColor={"primary"} className={classes.tabContainer} value={historyTabIndex} variant="scrollable" aria-label="history tabs">
          {historyComponents.map((cc, index) => <Tab key={`tabs2-${index}`} className={classes.tabs} onClick={() => { history.push(`/${deviceTypeName}/${location.pathname.split('/')[2]}/history/${index + 1}`) }} label={<FormattedMessage id={cc.title} />} {...a11yProps(index)} />)
          }
        </Tabs>
      </AppBar>
      {historyComponents.map((cc, index) => <TabPanel key={`tabscontent3-${index}`} value={historyTabIndex} index={index}>
        {cc.element}
      </TabPanel>)}
    </ScrollableContent>
    }

    {failedReport && <p><FormattedMessage id={'failedLoadingReport'} /></p>}

    <DeviceInfoDialog
      sideNames={sideNames}
      open={openInfoDialog}
      device={deviceFull}
      handleClose={() => { setOpenInfoDialog(false) }} />
  </div>
  );
}