
import { createStyles, Grid, makeStyles, withWidth } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import sf0 from '../../../assets/equipmentIcons/sf0optimized.svg';
import sf1 from '../../../assets/equipmentIcons/sf1optimized.svg';
import sf2 from '../../../assets/equipmentIcons/sf2optimized.svg';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import RoundGauge from '../../../components/device/final/RoundGauge';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { createMovingAverage, forSeverityStatistics } from '../../../utils/format-data';



const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    height: {
      height: theme.shape.graphBox.smaller.height
    },
    graph: {
      width: "100%"
    },
    gridtemplateGS1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
      "ga1 gr1"
      "ga3 gr1"
      "ga2 gr1"`,
        gridTemplateRows: "repeat(3, 32.64%)",
        gridTemplateColumns: "14.65% 85%",
        maxHeight: "650px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
      "gr1 gr1 gr1"
      "ga1 ga2 ga3"`,
        gridTemplateRows: "59.6% 39.6%",
        gridTemplateColumns: "1fr 0.5fr",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
      "gr1"
      "ga1"
      "ga2"
      "ga3"`,
        gridTemplateRows: "53.3% 15% 15% 15%",
        gridTemplateColumns: "99.9%",
        height: "1050px",
        maxHeight: "1050px",
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);


export default withWidth()(function GlobalStatus(props: React.PropsWithChildren<{}>) {
  const classes = useStyles();
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const line1 = deviceFull?.severity_param.sF_limit1
  const line2 = deviceFull?.severity_param.sF_limit2
  const data = forSeverityStatistics(deviceFull, deviceReport);
  const sfs = [sf0, sf1, sf2];
  const [sfStatus, setSfStatus] = useState<number>(0)
  const gauge = data[0];
  const small = data.slice(1);
  var sfLimits = [1, 2];
  if ((deviceFull?.severity_param.sF_limit1 || deviceFull?.severity_param.sF_limit1 === 0) && (deviceFull?.severity_param.sF_limit2 || deviceFull?.severity_param.sF_limit2 === 0)) sfLimits = [deviceFull?.severity_param.sF_limit1, deviceFull?.severity_param.sF_limit2];

  useEffect(() => {
    if (deviceReport?.diagnostic?.sF && deviceReport?.diagnostic?.sF[0] < 1) {
      setSfStatus(0)
    } else if (deviceReport?.diagnostic?.sF && deviceReport?.diagnostic?.sF[0] < 2) {
      setSfStatus(1)
    } else if (deviceReport?.diagnostic?.sF) {
      setSfStatus(2)
    } else {
      setSfStatus(0)
    }
  }, [deviceReport])

  const toLocalTimezone = (ISOdate: string): string => {
    //console.log(ISOdate)
    const value = new Date(ISOdate);
    return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
  }

  const lines = deviceFull && deviceMainHistory !== undefined && deviceMainHistory['timestamp'] ? [{
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line1,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line1,
    line: {
      color: '#f7dc99',
      width: 1,
      dash: 'dot'
    }
  }, {
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line2,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line2,
    line: {
      color: '#f88787',
      width: 2,
      dash: 'dot'
    }
  }] : undefined;

  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateGS1}>
          <SubTitledCard gridArea='gr1' title={"severityFactor"}>
            <DraggableGraph
              graphType={'linegl'}
              ys={[[
                deviceMainHistory ? deviceMainHistory['sf-0'] : undefined,
                deviceMainHistory ? createMovingAverage(deviceMainHistory['sf-0']) : undefined,
              ]]}
              ysnames={[["sf-0", "average"]]}
              colors={[['#b0b0b0b3', '#FF0000']]}
              titley={["severityFactor"]}
              x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
              yrange={[-1, 3]}
              shapes={lines}
              titlex={''}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>

          <SubTitledCard flexCenter gridArea='ga1'>
            <SmallCornerBox height={"100%"} title={''} parts={[{ title: 'image', value: sfs[sfStatus] }]}></SmallCornerBox>
          </SubTitledCard>
          <SubTitledCard gridArea='ga2'>
            <SmallCornerBox height={"100%"} title={'statistics'} parts={small}></SmallCornerBox>
          </SubTitledCard>
          <SubTitledCard flexCenter gridArea='ga3' title={'currentSeverity'}>
            <RoundGauge height={125} data={{ name: '', value: (gauge.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[-1, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], 3]], max: 3, unit: '%' }}></RoundGauge>
          </SubTitledCard>

        </TitledCard>

      </Grid>
    </Grid >
  );
})