import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between"
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
    formAction: {
      marginTop: "10px",
      textAlign: "right"
    },
    submitButton: {
      '&:hover':{
        backgroundColor: theme.palette.primary.light
      },
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    }
  }),
);

interface DeviceDialogProps {
  open: boolean;
  title: string | JSX.Element;
  handleClose: () => void;
  handleSubmit?: () => void;
  submitTextUntranslated?: string;
  cancelTextUntranslated?: string;
}

export default function DeviceDialog(props: React.PropsWithChildren<DeviceDialogProps>) {
  const { open, title, handleClose, handleSubmit, submitTextUntranslated, cancelTextUntranslated } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitle}>
            <div>{title}</div>
            <IconButton size="small" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {props.children}
          <div className={classes.formAction}>
            <Button onClick={handleClose} className={classes.cancelButton}><FormattedMessage id={`${cancelTextUntranslated ? cancelTextUntranslated : 'cancel'}`} /></Button>
            {handleSubmit && <Button variant="contained" className={classes.submitButton} onClick={handleSubmit}><FormattedMessage id={`${submitTextUntranslated ? submitTextUntranslated : 'submit'}`} /></Button>}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}