import { Dialog, DialogContent, DialogTitle, IconButton, StepButton, StepLabel, Stepper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import APIRequestContext from '../../contexts/api-context';
import { ToastContext } from "../../contexts/toast-context";
import { DeviceList } from '../../interfaces/Device';
import { OrganizationList } from '../../interfaces/Organization';
import { CurrentUser, Roles, User, UserForm } from '../../interfaces/User';
import { verifyPassword } from '../../utils/format-data';
import UserCreate from './tabs/UserCreate';
import UserEdit from './tabs/UserEdit';
import UserList from './tabs/UserList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between"
    },
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%",
      margin: 8
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    stepperRoot: {
      padding: `0 0 ${theme.spacing(1)}px 0`
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    formPanel: {
      height: "60vh"
    },
    formPanelSee: {
      height: "60vh",
      overflowY: 'auto',
      display: 'grid',
      textAlign: "center",
      // alignContent: "space-evenly",
    },
    formAction: {
      textAlign: "right"
    },
    noneInfo: {
      textAlign: 'center',
      paddingTop: 44
    }
  }),
);

interface AdminPageDialogProps {
  open: boolean;
  handleClose: () => void;
  handlePositiveClose: (user: UserForm) => void;
  org?: OrganizationList;
  users?: Array<User | UserForm>;
  setUsers?: (users: Array<UserForm | User>) => void;
  orgs?: OrganizationList[];
  devices?: DeviceList[];
}

const emptyUser = {
  organization_id: '',
  devices_id: [],
  name: '',
  email: '',
  role: 2,
  password: '',
  repeatPassword: ''
}

const emptyUserEdit = {
  organization_id: '',
  devices_id: [],
  name: '',
  email: '',
  role: 2,
  password: '',
  repeatPassword: '',
  _id: '',
  active: true,
  created_at: new Date(),
}

const steps = ['seeUsers', 'createUser', 'editUser'];

export interface extendedUser extends UserForm {
  repeatPassword: string;
}

export default function AdminPageDialog(props: AdminPageDialogProps) {
  var currentUser2 = localStorage.getItem("user");
  const currentUser = JSON.parse(currentUser2 ? currentUser2 : '') as CurrentUser;
  const { open, handleClose, users, org, handlePositiveClose, orgs, devices, setUsers } = props;
  const classes = useStyles();
  const [errors, setErrors] = React.useState<Array<number>>([0, 0, 0, 0, 0]);
  emptyUser.organization_id = (currentUser as any)?.organization_id
  const [tempUser, setTempUser] = React.useState<extendedUser>(emptyUser);
  const [tempUserEdit, setTempUserEdit] = React.useState<extendedUser>(emptyUserEdit);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeAssuranceMenu, setActiveAssuranceMenu] = React.useState<number>(-1)
  const [activeEdit, setActiveEdit] = React.useState<number>(-1)
  const { apiRequest } = useContext(APIRequestContext);
  const { displayToast } = useContext(ToastContext);

  useEffect(() => {
    setTempUser(emptyUser)
    const temp = [...errors];
    setErrors(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleChange = (value: any, key: string) => {
    const idx = getErrorKey(key);
    if (idx !== -1) {
      const ver = verifyInput(value, key)
      if (errors[idx] > 0) {
        if (ver < 1) {
          const temp = errors;
          if (idx === 2 && errors[3] === 2) {
            temp[3] = 0
          }
          if (idx === 3 && errors[2] === 2) {
            temp[2] = 0
          }
          temp[idx] = ver;
          setErrors(temp)
        } else {
          const temp = errors;
          temp[idx] = ver;
          setErrors(temp)
        }
      } else {
        const temp = errors;
        if (idx === 2 && errors[3] === 2) {
          temp[3] = 0
        }
        if (idx === 3 && errors[2] === 2) {
          temp[2] = 0
        }
        if (ver > 0) {
          temp[idx] = ver;
          setErrors(temp)
        }
      }

    }
    setTempUser({ ...tempUser, [key]: value });
  };

  const handleEditChange = (value: any, key: string) => {
    const idx = getErrorKey(key);
    if (idx !== -1) {
      const ver = verifyEditInput(value, key)
      if (errors[idx] > 0) {
        if (ver < 1) {
          const temp = errors;
          if (idx === 2 && errors[3] === 2) {
            temp[3] = 0
          }
          if (idx === 3 && errors[2] === 2) {
            temp[2] = 0
          }
          temp[idx] = ver;
          setErrors(temp)
        } else {
          const temp = errors;
          temp[idx] = ver;
          setErrors(temp)
        }
      } else {
        const temp = errors;
        if (idx === 2 && errors[3] === 2) {
          temp[3] = 0
        }
        if (idx === 3 && errors[2] === 2) {
          temp[2] = 0
        }
        if (ver > 0) {
          temp[idx] = ver;
          setErrors(temp)
        }
      }

    }
    setTempUserEdit({ ...tempUserEdit, [key]: value });
  };

  const handleSubmit = (user: extendedUser) => {
    let has_error = false;
    const temp = [...errors];
    for (const key in tempUser) {
      const ver = verifyInput(tempUser[key as keyof extendedUser], key)
      if (!(ver === 0)) {
        has_error = true;
        temp[getErrorKey(key)] = ver;
      }
    }
    setErrors(temp);
    // REMOVER VAZIOS
    if (!has_error) {
      const { repeatPassword, ...ret } = tempUser
      handlePositiveClose(ret)
    }
  }

  const validateEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const getErrorKey = (key: string) => {
    switch (key) {
      case 'name': return 0;
      case 'email': return 1;
      case 'password': return 2;
      case 'repeatPassword': return 3;
      default: return -1;
    }
  }

  const verifyInput = (value: any, key: string) => {
    switch (key) {
      case 'name': return value.length > 0 ? 0 : 1;
      case 'email': return value.length > 0 ? (validateEmail(value) ? 0 : 2) : 1;
      case 'password': return value.length > 0 ? verifyPassword(value) ? (value.localeCompare(tempUser?.repeatPassword) === 0 ? 0 : 2) : 3 : 1;
      case 'repeatPassword': return value.length > 0 ? verifyPassword(value) ? (value.localeCompare(tempUser?.password) === 0 ? 0 : 2) : 3 : 1;
      default: return 0;
    }
  }

  const verifyEditInput = (value: any, key: string) => {
    // console.log(verifyPassword(value), value, tempUserEdit.repeatPassword, value.localeCompare(tempUserEdit?.repeatPassword), (value.length > 0 ? verifyPassword(value) ? (value.localeCompare(tempUserEdit?.repeatPassword) === 0 ? 0 : 2) : 3 : 1))
    switch (key) {
      case 'name': return value.length > 0 ? 0 : 1;
      case 'email': return value.length > 0 ? (validateEmail(value) ? 0 : 2) : 1;
      case 'password': return value.length > 0 ? verifyPassword(value) ? (value.localeCompare(tempUserEdit?.repeatPassword) === 0 ? 0 : 2) : 3 : 1;
      case 'repeatPassword': return value.length > 0 ? verifyPassword(value) ? (value.localeCompare(tempUserEdit?.password) === 0 ? 0 : 2) : 3 : 1;
      default: return 0;
    }
  }

  const onClickStep = (any: number) => { setActiveStep(any) }

  const deleteUser = (user: User) => {
    apiRequest<void>("DELETE", "api/v1/user/" + user._id)  //////////PUT BACK
      .then((result: any) => {
        var als = users ? users.slice() : [];
        const ind = als.findIndex(al => (al as User)._id && (al as User)._id === user._id);
        als.splice(ind, 1)
        if (setUsers) setUsers(als)
        displayToast('success', "userDeleted", "nothing")
        //SUCESSO mesangens
      })
      .catch(error => {
        //FALHA
        displayToast("error", "failedAction", "nothing")
      });
  }

  const updateUser = (user: User | UserForm) => {
    if ('_id' in user) {
      let newUser: any = user;
      if (currentUser.role !== Roles.ADMIN) newUser = { devices_id: user.devices_id, password: (user as any).password }
      apiRequest<void>("PUT", "api/v1/user/" + user._id, { jsonData: newUser })  //////////PUT BACK
        .then((result: any) => {
          var als = users ? users.slice() : [];
          const ind = als.findIndex(al => (al as User)._id && (al as User)._id === user._id);
          als[ind] = user
          if (setUsers) setUsers(als)
          displayToast('success', "userUpdated", "nothing")
          //SUCESSO mesangens
        })
        .catch(error => {
          //FALHA
          displayToast("error", "failedAction", "nothing")
        });
    } else {
      displayToast("error", "failedAction", "nothing")
    }
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <UserList
          org={org}
          users={users}
          currentUser={currentUser}
          editAction={(user: any) => {
            setActiveEdit(users ? users.findIndex(usr => usr.email === user.email) : -1);
            setTempUserEdit(user);
            setActiveStep(2)
          }}
          activeAssuranceMenu={activeAssuranceMenu}
          setActiveAssuranceMenu={setActiveAssuranceMenu}
          deleteUser={deleteUser}
          handleClose={handleClose}
          setActiveStep={setActiveStep}
        ></UserList>;
      case 1:
        return <UserCreate
          tempUser={tempUser}
          errors={errors}
          handleChange={handleChange}
          devices={devices}
          currentUser={currentUser}
          orgs={orgs}
          org={org}
          setActiveStep={setActiveStep}
          handleSubmit={handleSubmit}
        ></UserCreate>;
      case 2:
        return <UserEdit
          activeEdit={activeEdit}
          users={users}
          tempUserEdit={tempUserEdit}
          errors={errors}
          handleEditChange={handleEditChange}
          devices={devices}
          currentUser={currentUser}
          org={org}
          orgs={orgs}
          setActiveStep={setActiveStep}
          updateUser={updateUser}
          setTempUserEdit={setTempUserEdit}
          activeStep={activeStep}
        ></UserEdit>;
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogTitle}>
          <div><FormattedMessage id="orgAdminPage" /></div>
          <IconButton size="small" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel classes={{ root: classes.stepperRoot }} >
          {steps.map((label, index) => (
            <StepButton disabled={false} onClick={() => onClickStep(index)} key={label}>
              <StepLabel><FormattedMessage id={label} /></StepLabel>
            </StepButton>
          ))}
        </Stepper>
        <div>
          {getStepContent(activeStep)}
        </div>
      </DialogContent>
    </Dialog >
  );
}