import React from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { ICMpermissions } from '../../utils/icm-functions';
import IcmInfoDialog from './dialogs/IcmInfoDialog';
import ElectricParam from './tabs/ElectricParam';
import GlobalStatus from './tabs/GlobalStatus';
import Harmonics from './tabs/Harmonics';
import PowerQuality from './tabs/PowerQuality';
import SingleReport from './tabs/SingleReport';
import VectorGraph from './tabs/VectorGraph';
import Waveforms from './tabs/Waveforms';
import ElectricParamI from './tabsWithCurrent/ElectricParam';
import GlobalStatusI from './tabsWithCurrent/GlobalStatus';
import HarmonicsI from './tabsWithCurrent/Harmonics';
import LoadDiagram from './tabsWithCurrent/LoadDiagram';
import PowerQualityI from './tabsWithCurrent/PowerQuality';
import VectorGraphI from './tabsWithCurrent/VectorGraph';
import WaveformsI from './tabsWithCurrent/Waveforms';

interface ICMPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}

const variables = ["ldratio", "ppv-0", "ppv-1", "ppv-2", "unbppv-0", "thdppv-0", "thdppv-1", "thdppv-2",
  'sf-0', 'sf-1',//baixo diag, cima control
  'iv-0', 'iv-1', 'iv-2', 'thdi-0', 'thdi-1', 'thdi-2', 'unbi-0'] as const // extra com correntes
type IUV = typeof variables[number];
type time = { 'timestamp': string[] }
const variables2 = (variables as any)
variables2.unshift('timestamp')
export type IcmUsableVariables = time & {
  [K in IUV]: Array<number>;
}

export default function ICMPage(props: ICMPageProps) {
  const { location } = props
  const [hasI, setHasI] = React.useState(false);


  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    return result;
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    setHasI(result.config_device.analog_reading_labels.some(label => label.startsWith('I')))
    return result;
  }

  return (
    <MainDeviceDashboard
      location={location}
      deviceType={DeviceType.I}
      processingAffectingContexts={processingAffectingContexts}
      processingGeneral={processingGeneral}
      DeviceInfoDialog={IcmInfoDialog}
      variables={variables2}
      controlInfoBar={{ element: <ControlInfoBar maxSFCount={hasI ? 2 : 1}></ControlInfoBar>, tabCode: "006" }}
      controlComponents={(hasI ? [
        {
          element: <ElectricParamI />,
          title: "electric_param"
        }, {
          element: <LoadDiagram />,
          title: "load_diagram"
        }, {
          element: <PowerQualityI />,
          title: "power_quality"
        }, {
          element: <VectorGraphI />,
          title: "vector_diagram"
        }, {
          element: <WaveformsI />,
          title: "waveforms"
        }
      ] : [
          {
            element: <ElectricParam></ElectricParam>,
            title: "electric_param",
          }, {
            element: <PowerQuality></PowerQuality>,
            title: "power_quality",
          }, {
            element: <VectorGraph></VectorGraph>,
            title: "vector_diagram",
          }, {
            element: <Waveforms></Waveforms>,
            title: "waveforms",
          }
        ]).map(v => ({ ...v, ...{ tabCode: ICMpermissions[(v.title as keyof typeof ICMpermissions)] } }))}
      diagnosticComponents={(!hasI ? [
        {
          element: <GlobalStatus></GlobalStatus>,
          title: "global_status"
        }, {
          element: <Harmonics></Harmonics>,
          title: "harmonics"
        }
      ] : [
          {
            element: <GlobalStatusI />,
            title: "global_status",
          }, {
            element: <HarmonicsI />,
            title: "harmonics",
          }
        ]).map(v => ({ ...v, ...{ tabCode: ICMpermissions[(v.title as keyof typeof ICMpermissions)] } }))}
      historyComponents={[
        {
          element: <HistoryPage type={DeviceType.I} extra={hasI}></HistoryPage>,
          title: "trendAn"
        },
        {
          element: <ReportViewer>
            {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined }) => <SingleReport reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} hasI={hasI} />}
          </ReportViewer>,
          title: "sampleAn"
        },
      ]}
    ></MainDeviceDashboard>
  );
}