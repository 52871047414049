
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forWaveformsICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateWV: {
      gridTemplateAreas: `
          "gr1 gr2"
          "gr1 gr3"`,
      gridTemplateColumns: `1fr ${(theme.shape.graphBox.normal.height / 2) - 14}px`,
      gridTemplateRows: "repeat(2, 49.5%)",
      height: theme.shape.graphBox.normal.height,
      maxHeight: "750px",
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1" 
        "gr2"
        "gr3"`,
        gridTemplateColumns: "100%",
        gridTemplateRows: '1fr 0.4fr 0.4fr',
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px",
      }
    },
  }),
);

interface WaveProps {

}

export default function WaveformsI(props: React.PropsWithChildren<WaveProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceFull, deviceWaveforms } = useContext(EntitiesContext);
  const data = forWaveformsICM(deviceWaveforms, deviceFull, true)
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateWV}>
          <SubTitledCard gridArea='gr1' title="waveforms">
            {data[0][0] && <DraggableGraph
              x={(data[0][0].x as number[])}
              ys={[data[0][0].ys.slice(0, 6)] as number[][][]}
              titlex={'msUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0-icm', 'pnv-1-icm', 'pnv-2-icm', 'iv-0-icm', 'iv-1-icm', 'iv-2-icm']]}
              colors={[["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8']]}
              graphType={'linegl'}
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard>
          {data[0][1] && <SubTitledCard gridArea='gr2' title='voltagePark'>
            {data[0][1] && <DraggableGraph
              x={data[0][1].x as number[]}
              ys={[data[0][1].ys] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#3473ba"]]}
              graphType={'linegl'}
              staticGraph
              sameScaleXandY
              onlyMarkers
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard>}
          {data[0][2] && <SubTitledCard gridArea='gr3' title='currentPark'>
            {data[0][2] && <DraggableGraph
              x={data[0][2].x as number[]}
              ys={[data[0][2].ys] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['iv-0']]}
              colors={[["#e23434"]]}
              graphType={'linegl'}
              staticGraph
              sameScaleXandY
              onlyMarkers
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard>}
        </TitledCard>

      </Grid>
    </Grid >
  );
}






