import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { naSmallCornerBoxParts } from '../../../utils/format-data';

const useStyles = makeStyles((theme) => ({
    lineText: {
        fontSize: "13px",
        color: theme.palette.primary.main,
        fontWeight: 700,
        height: "37px",
        margin: "auto",
        whiteSpace: "nowrap"
    },
    beforeLine: {
        width: "50px",
        textAlign: "center",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            width: "25px",
        }
    },
    beforeLineSmall: {
        width: "25px",
        textAlign: "center",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            width: "25px",
        }
    },
    beforeLineNoTitle: {
        marginLeft: 17,
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 0
        // }
    },
    beforeLineNoTitleSmall: {
        marginLeft: 0,
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: 0
        // }
    },
    afterLine: {
        width: "37px",
        textAlign: "center",
        textAlignLast: "end",
        marginRight: "10px"
    },
    afterLineEmpty: {
        textAlign: "center",
        textAlignLast: "end",
    },
    afterLineSmall: {
        width: "46px",
        textAlign: "center",
        textAlignLast: "end",
        marginRight: "10px"
    },
    afterLineWide: {
        width: "80px",
        textAlign: "center",
        textAlignLast: "end",
        marginRight: "10px"
    },
    lineHolder: {
        display: "grid",
        paddingTop: props => (props as any).title ? "10px" : "16px",
        backgroundColor: "white",
        borderRadius: "10px",
        margin: "4px",
        overflow: 'hidden',
        height: props => (props as any).height ? (props as any).height : "100%",
        width: props => (props as SmallCornerBoxProps).parts.reduce((a, b) => a?.concat(b ? b.title! : ""), "") === "image" ? "100%" : undefined
    },
    Line: {
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
        height: "fit-content",
        margin: "0px 9px 0 9px",
        color: theme.palette.primary.main,
        fontSize: "20px",
        borderRadius: "10px 10px 0 0",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        paddingBottom: 10
    }
}
));

export interface SmallCornerBoxParts {
    title?: string,
    value: Array<string | number> | string | number | undefined,
}

interface SmallCornerBoxProps {
    title?: string,
    parts: Array<SmallCornerBoxParts>
    gridArea?: string,
    height?: string,
    wide?: boolean,
    small?: boolean,
    empty?: boolean,
}

export default function SmallCornerBox(props: PropsWithChildren<SmallCornerBoxProps>) {
    const { title, parts, gridArea, wide, small, empty } = props;
    const classes = useStyles({ ...props })
    const ref = useRef(null);
    const [height, setHeight] = useState<string>("15%")
    const titleFinal = empty ? "" : title;
    const partsFinal = empty ? naSmallCornerBoxParts("na") : parts;

    useEffect(() => {
        setHeight(ref ? ref.current ? `${(ref!.current as any)!.offsetHeight - 32} px` : "15%" : "15%")
    }, [ref])

    return (
        <div ref={ref} className={classes.lineHolder} style={{ gridArea: gridArea }}>
            {titleFinal && <h3 className={classes.title}><FormattedMessage id={titleFinal}></FormattedMessage></h3>}
            {partsFinal && partsFinal.map((value, index) =>
                value.title !== 'image' ?
                    <div key={`gauge-${value.title}-${index}`} className={classes.Line}>
                        {value.title && <div className={clsx(!empty && !small && classes.beforeLine, !empty && small && classes.beforeLineSmall, classes.lineText, !empty && !title && !small && classes.beforeLineNoTitle, !empty && !title && small && classes.beforeLineNoTitleSmall)}><FormattedMessage id={value.title}></FormattedMessage></div>}
                        {value.value && (value.value as string[]).map && (value.value as string[]).map((st, index2) => <div key={`gauge-${index2}-${index}`} className={(!st && index2 === 2 ? classes.afterLineEmpty : wide ? classes.afterLineWide : small ? classes.afterLineSmall : classes.afterLine) + ' ' + classes.lineText}>{st}</div>)}
                        {value.value && !(value.value as string[]).map && <div className={(wide ? classes.afterLineWide : small ? classes.afterLineSmall : classes.afterLine) + ' ' + classes.lineText}>{value.value}</div>}
                    </div> :
                    <img key={`gauge-${value.title}-${index}`} height={height} style={{ justifySelf: "center" }} src={value.value as string} alt={'device'}></img>
            )}
        </div>
    );
}