import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { naGaugeFinal2, si_format } from '../../../utils/format-data';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixDiv: {
      width: "100%",
      overflow: "hidden",
      borderRadius: "10px 10px 10px 10px",
      //height: "275px"
    },
    circularProgress: {
      margin: "auto"
    },
    main: {
      width: "100%", display: "flex", justifyContent: "center"
    },
    text: {
      fontSize: "13px",
      color: theme.palette.primary.main,
      fontWeight: 700,
      height: "37px",
      margin: "auto"
    }
  }),
);

export interface gaugeFinal2 {
  name: string,
  value: number | undefined,
  colors: string[],
  max: number | undefined,
  unit?: string,
  steps?: Array<Array<number>>,
  extendedNumber?: boolean,
  min?: number,
}

export interface gaugeMid2 {
  variable: string,
  title: string,
  array: Array<gaugeFinal2>
}

export interface roundGaugeProps {
  data: gaugeFinal2,
  height?: number,
  empty?: boolean,
}

export default function RoundGauge(props: React.PropsWithChildren<roundGaugeProps>) {
  const classes = useStyles();
  const divReference = useRef(null);
  const intl = useIntl();
  const { data, height, empty } = props;
  const dataFinal = empty ? naGaugeFinal2("") : data;
  const [normalizedRadius, setNormalizedRadius] = useState(0);
  const [circumference, setCircumference] = useState(0);
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [cy, setCy] = useState(0);
  const [yText, setYText] = useState(0);
  const minimum = dataFinal.min ? dataFinal.min : 0;
  const notAvailable = dataFinal.value === undefined;

  const findColor = (steps: number[][], value: number, colors: string[]): string => {
    //console.log(steps,value)
    var ret: string = '';
    steps.some((s, index) => {
      if (value >= s[0]) {
        if (value <= s[1]) {
          ret = colors[index];
        } else if (index === dataFinal.steps!.length - 1) {
          ret = colors[index];
        }
      } else {
        ret = ret ? ret : '#4684c9';
      }
      return ''
    });
    return ret
  }

  const c: string = dataFinal.colors.length > 1 ?
    dataFinal.steps ?
      dataFinal.value ?
        findColor(dataFinal.steps, dataFinal.value, dataFinal.colors) :
        dataFinal.colors[0] :
      dataFinal.colors[0] :
    dataFinal.colors[0]
  const valueAfterMin = dataFinal.value ? dataFinal.min ? dataFinal.value - dataFinal.min : dataFinal.value : 0;
  const maxAfterMin = dataFinal.max ? dataFinal.min ? dataFinal.max - dataFinal.min : dataFinal.max : 100;
  var progress = (valueAfterMin / maxAfterMin) * 100 / 2

  // console.log("1", `going ${progress * 2}%,  min:${data.min}   value+min: ${valueAfterMin}   valuee:${data.value}   max+min:${maxAfterMin} `)
  progress = progress > 50 ?
    50 :
    progress <= 0 ?
      0.001 :
      progress ?
        progress :
        minimum
  // console.log("2", progress)
  const stroke = 14;
  const formattedNumber = si_format(dataFinal.value, undefined, 2, true)
  useEffect(() => {
    const ratio = 1.6444144;
    const innerHeight = (divReference?.current as any)?.parentElement?.offsetHeight;
    const innerWidth = (divReference?.current as any)?.parentElement?.offsetWidth;
    const isLandscape = innerWidth && innerHeight ? innerWidth > innerHeight : -1;
    const tooWide = (innerWidth) > (innerHeight * 2);

    let radius = 0
    if (isLandscape === -1 || height) {
      radius = height ? height : 120;
    } else if (isLandscape === true) {//264-25    179-5
      if (tooWide) {
        radius = (innerWidth / ratio) - (-37.1176 + 0.4353 * innerWidth)
      } else {
        radius = (innerWidth / ratio) - (-37.1176 + 0.2753 * innerWidth)
      }
    } else {
      radius = innerHeight - 50
    }

    // console.log(`radius:${radius}, isLandscape:${isLandscape}, stroke:${stroke}, tooWide:${tooWide}, height:${height}`)
    // console.log(innerHeight, "x", innerWidth)

    const normalizedRadius = radius - stroke * 2;

    const circumference = normalizedRadius * 2 * Math.PI;

    const strokeDashoffset = circumference - progress / 100 * circumference;

    const x = (normalizedRadius + stroke + 5);

    const cy = Math.floor(10 - (x / 22 - 3.4))

    const yText = 0.19 * x + 1.5

    setNormalizedRadius(normalizedRadius)
    setCircumference(circumference)
    setStrokeDashoffset(strokeDashoffset)
    setCy(cy)
    setYText(yText)

  }, [height, progress])

  return (
    <div className={classes.main} ref={divReference}>
      {notAvailable && <div className={classes.text}><FormattedMessage id={"na"}></FormattedMessage></div>}
      {!notAvailable && <svg
        height={normalizedRadius + stroke + 5}
        width={normalizedRadius * 2 + stroke}
        transform-box="fill-box"
      >
        <text x={"50%"} y={"70%"} fill={c} fontSize={yText} fontWeight='600' dominantBaseline="middle" textAnchor="middle">
          {(dataFinal.value || (dataFinal.value === 0)) ?
            parseFloat(formattedNumber) > 1 ?
              dataFinal.extendedNumber ?
                si_format(parseFloat(formattedNumber), dataFinal.unit, 0, undefined, true) :
                si_format(parseFloat(formattedNumber), dataFinal.unit) :
              si_format(parseFloat(formattedNumber), dataFinal.unit, 2, true) : intl.formatMessage({ id: "na" })}
        </text>
        <circle
          transform-origin={`50% 50%`}
          stroke="#ebebeb"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          transform={`rotate(180)`}
          style={{ strokeDashoffset: circumference / 2 }}
          r={normalizedRadius}
          cx={"50%"}
          cy={`${cy}%`}
          strokeLinecap="round"
        />
        <circle
          transform-origin={`50% 50%`}
          stroke={c}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          transform={`rotate(180)`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={"50%"}
          cy={`${cy}%`}
          strokeLinecap="round"
        />

      </svg>}

    </div>
  );
}