
import { Button, Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import LongDataBox from '../../../components/device/middle/LongDataBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forHarmonics } from '../../../utils/tcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateH: {
      gridTemplateAreas: `
          "gr1 gr3"
          "gr2 gr4"`,
      gridTemplateRows: "86.5% 12%",
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: "repeat(2,49.9%)",
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: "repeat(2,49.6%)",
      },
      maxHeight: "799px",
      height: theme.shape.graphBox.smallest.height + 40,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
        "gr1"
        "gr2"
        "gr3"
        "gr4"`,
        gridTemplateRows: "repeat(2, 43.9% 5%)",
        gridTemplateColumns: "100%",
        maxHeight: "799px",
        height: theme.shape.graphBox.biggest.height + 40,
        gridGap: "6px",
        padding: "10px",
      }
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    }
  }),
);
//MAYBE I CAN KEEP ALL THE GRAPHS AND JUST CHANGE IF THEY DISPLAY OR NOT

export default withWidth()(function Harmonics(props: React.PropsWithChildren<{
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
  sideNames: Array<string>//the names of the sides
}>) {
  const classes = useStyles();
  const { deviceFull, deviceReport } = useContext(EntitiesContext);
  const [parityCurrent, setParityCurrent] = useState<boolean[]>([false, false, false, false, false, false]);
  const [parityVoltage, setParityVoltage] = useState<boolean[]>([false, false, false, false, false, false]);
  const data = forHarmonics(deviceReport, deviceFull);
  const { hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, sideNames } = props;
  const sides = [hasV1, hasV2, hasV3, hasV4 === true, hasV5 === true, hasV6 === true];
  const intl = useIntl();
  const changeParity = (side: number, current: number) => {
    if (current === 0) {
      setParityCurrent(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    } else {
      setParityVoltage(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    }
  };
  return (
    <Grid container className={classes.SideContainer}>
      {data && data.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateH} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull!.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1' title='voltage' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={sides[side] ?
                [data[side][1][parityVoltage[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={data[side][1][parityVoltage[side] ? 'even' : 'odd'].x}
              ysnames={side === 0 ? [['VA', 'VB', 'VC', 'IA', 'IB', 'IC']] : [['Va', 'Vb', 'Vc', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
              emptyMessage={sides[side] ? undefined : 'na'}
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2'>
            <LongDataBox
              parts={sides[side] ?
                [
                  { title: 'Va1:', value: si_format(data[side][1].one[0], 'V') },
                  { title: 'Vb1:', value: si_format(data[side][1].one[1], 'V') },
                  { title: 'Vc1:', value: si_format(data[side][1].one[2], 'V') },
                ] :
                [
                  { title: '', value: '' },
                  { title: intl.formatMessage({ id: 'na' }), value: '' },
                  { title: '', value: '' },
                ]}
            ></LongDataBox>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={data[side] ?
                [data[side][0][parityCurrent[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={data[side][0][parityCurrent[side] ? 'even' : 'odd'].x}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr4'>
            <LongDataBox
              parts={[
                { title: 'Ia1:', value: si_format(data[side][0].one[0], 'A') },
                { title: 'Ib1:', value: si_format(data[side][0].one[1], 'A') },
                { title: 'Ic1:', value: si_format(data[side][0].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






