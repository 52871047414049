import { Button, Chip, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Tooltip, useTheme } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import React, { ConsumerProps, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import APIRequestContext from '../../../contexts/api-context';
import { EntitiesContext } from "../../../contexts/entities-context";
import { ReportList, ReportWaveforms } from '../../../interfaces/Report';
import { ListResponse, SingleResponse } from '../../../interfaces/Responses';
import CommonTime from '../../global/CommonTime';
import { ThemeExtended } from '../../global/Theme';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 2,
      marginRight: 2,
      width: 109,
      "&:hover": {
        cursor: "pointer"
      },
      "& input": {
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    formControlSmall: {
      margin: theme.spacing(1),
      verticalAlign: "bottom"
    },
    notButton: {
      border: "1px #00000073 solid !important",
      borderRadius: 5,
      padding: "4px 10px",
      color: "#0000008f !important",
      display: "inline-flex",
      alignItems: "center",
      placeContent: "center",
      fontSize: 18,
      height: 33,
      marginBottom: 6,
      justifyContent: "space-between",
      fontWeight: 600,
      '& .react-date-picker__inputGroup__input': {
        color: "#0000008f !important",
        fontWeight: 600,
        fontSize: 18,
      }
    },
    chip: {
      margin: 2,
      '& > .MuiChip-label': {
        fontSize: "1.05em",
        fontWeight: 600,
      }
    },
    containerChips: {
      marginLeft: 30,
      marginRight: 30
    },
    dialogText: {
      '& > .MuiTypography-root': {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        fontSize: "1.7em",
        fontWeight: 500,
        color: "#0000008f",

      }
    },
    circular: {
      display: "flex",
      justifyContent: "center"
    },
    time: {
      paddingLeft: 5
    }
  }),
);

interface ReportViewerProps {
  reportSelected: ReportList | undefined;
  waveformsSelected: ReportWaveforms | undefined;
  isGCM?: boolean;
}

export default function ReportViewer(props: ConsumerProps<ReportViewerProps>) {
  const { deviceReport } = useContext(EntitiesContext);
  const { apiRequest } = useContext(APIRequestContext);
  const location = useLocation();
  const classes = useStyles();
  const [type, setType] = useState(deviceReport?.type);
  const [dateSelection, setDateSelection] = useState<Date | null>(new Date());
  const [previous, setPrevious] = useState<ReportList>();
  const [next, setNext] = useState<ReportList>();
  const [reportSelected, setReportSelected] = useState<ReportList | undefined>();
  const [waveformsSelected, setWaveformsSelected] = useState<ReportWaveforms | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportsInDay, setReportsInDay] = useState<ReportList[] | undefined>(undefined)
  const [dateListing, setDateListing] = useState<(Date | null | string)>(null)
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const intl = useIntl();

  const addTime = (time: Date | string, minutesToAdd: number): string => {
    return new Date((new Date(time)).getTime() + minutesToAdd * 60000).toISOString();
  }

  const getReportById = (id: string) => {
    // console.log("getreportbyid")
    setLoading(true);
    apiRequest<ListResponse<ReportList>>("GET", "api/v1/report/", { query: { type: deviceReport?.type, ids: id } })
      .then(result => {
        if (result.values[0]) {
          setReportSelected(result.values[0]);
          setType(result.values[0].type);
          setDateSelection(new Date(result.values[0].timestamp));
        }
      })
      .catch(error => { setReportSelected(deviceReport); setType(deviceReport?.type) });
  }
  //timestamp deviceId e type estao num index
  const getClosestReport = (time: string | Date) => {
    // console.log("getclosestreport")
    if (type) {
      setLoading(true);
      apiRequest<ListResponse<ReportList>>("GET", "api/v1/report", { query: { device_id: location.pathname.split('/')[2], type: type, until: addTime(time, 1), only_one: true, ordertype: -1, orderby: "timestamp" } })
        .then(result => {
          setReportSelected(result.values[0]);
          setType(result.values[0].type);
          setDateSelection(new Date(result.values[0].timestamp));
        })
        .catch(error => { });
    }
  }

  const getReportsInDay = (time: string | Date) => {
    if (type) {
      let timeEnd = (new Date(time));
      let timeStart = (new Date(time));
      timeEnd.setHours(24, 0, 0);
      timeStart.setHours(0, 0, 0);
      apiRequest<ListResponse<ReportList>>("GET", "api/v1/report", { query: { device_id: location.pathname.split('/')[2], type: type, until: timeEnd.toISOString(), since: timeStart.toISOString(), ordertype: 1, orderby: "timestamp" } })
        .then(result => { setReportsInDay(result.values) })
        .catch(error => { });
    }
  }

  const handlePressChip = (r: ReportList) => {
    getReportById(r._id)
    setOpen(false);
    setReportsInDay(undefined)
  }

  const handleClose = (b: any) => {
    setOpen(false);
    setReportsInDay(undefined)
  }

  // const handleClosestReportButton = () => {
  //   //console.log(dateSelection)
  //   if (dateSelection) getClosestReport(dateSelection);
  // }

  const handleNextReportButton = () => {
    // setLoading(true);
    if (next) {
      setReportSelected(next);
      setDateSelection(new Date(next.timestamp))
    }
  }

  const handlePreviousReportButton = () => {
    // setLoading(true);
    if (previous) {
      setReportSelected(previous);
      setDateSelection(new Date(previous.timestamp))
    }
  }

  const beforeSetDateSelection = (date: Date | null) => {
    if (date) {
      setDateListing(date);
      getReportsInDay(date);
      setOpen(true);
    }
  }

  useEffect(() => {
    const idOrTime = (location.pathname.split('/')[5]);
    const date = new Date(parseInt(idOrTime));
    if (idOrTime && date.getTime()) {
      getClosestReport(date);
    } else if (idOrTime && !date.getTime()) {
      getReportById(idOrTime);
    } else {
      setReportSelected(deviceReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getReportAfter = (time: string | Date) => {
      if (type) {
        apiRequest<ListResponse<ReportList>>("GET", "api/v1/report", { query: { device_id: location.pathname.split('/')[2], type: type, since: addTime(time, 0.1), only_one: true, ordertype: 1, orderby: "timestamp" } })
          .then(result => { setNext(result.values[0]) })
          .catch(error => { });
      }
    }
    const getReportBefore = (time: string | Date) => {
      if (type) {
        apiRequest<ListResponse<ReportList>>("GET", "api/v1/report", { query: { device_id: location.pathname.split('/')[2], type: type, until: addTime(time, -0.1), only_one: true, ordertype: -1, orderby: "timestamp" } })
          .then(result => { setPrevious(result.values[0]) })
          .catch(error => { });
      }
    }
    const getReportWaveforms = (waveID: string) => {
      if (waveID) {
        apiRequest<SingleResponse<any>>("GET", "api/v1/graph/" + waveID)
          .then(result => { setLoading(false); setWaveformsSelected(result.value.waveforms) })
          .catch(error => { });
      } else {
        setLoading(false);
        setWaveformsSelected(undefined)
      }
    }

    if (reportSelected && reportSelected.timestamp) {
      getReportWaveforms(reportSelected.graph_id);
      getReportAfter(reportSelected?.timestamp);
      getReportBefore(reportSelected?.timestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRequest, reportSelected, type])

  return (<Grid container>
    <Grid item xs={12}>

      {previous && <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.formControlSmall}
        onClick={handlePreviousReportButton}
        startIcon={<NavigateBeforeIcon />}
      >
        <>
          {/* <FormattedMessage id='goTo' /> */}
          <CommonTime justTime val={previous.timestamp}></CommonTime>
        </>
      </Button>}

      <Tooltip placement="top" title={intl.formatMessage({ id: "findClosest" })}>
        <div className={clsx(classes.formControlSmall, classes.notButton)}>
          {loading ? <CircularProgress size={18} /> : <AssessmentIcon htmlColor={theme.palette.primary.main} />}
          <span className={classes.time}>
            <CommonTime justTime val={reportSelected ? reportSelected.timestamp : "0"} ></CommonTime>
            <DatePicker
              className={classes.formControl}
              onChange={beforeSetDateSelection}
              value={dateSelection ? dateSelection : undefined}
              calendarIcon={null}
              clearIcon={null}
              required
            ></DatePicker>
          </span>
        </div>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogText} id="dialog-title">
          {`${intl.formatMessage({ id: "selectSample" })} ${(new Date(dateListing ? dateListing : "")).toLocaleDateString()}`}
        </DialogTitle>
        <DialogContent>
          <div className={classes.containerChips}>

            {reportsInDay && reportsInDay.length > 0 && reportsInDay.map((r) => <Chip key={`chip${r.timestamp}`} className={classes.chip} clickable onClick={() => handlePressChip(r)} label={new Date(r.timestamp).toLocaleTimeString()}></Chip>)}

            {reportsInDay && reportsInDay.length === 0 && <FormattedMessage id={"noReportsInDay"}></FormattedMessage>}

            {!reportsInDay && <div className={classes.circular}><CircularProgress></CircularProgress></div>}

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}><FormattedMessage id={"close"}></FormattedMessage></Button>
        </DialogActions>
      </Dialog>

      {next && <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.formControlSmall}
        onClick={handleNextReportButton}
        endIcon={<NavigateNextIcon />}
      >
        <>
          {/* <FormattedMessage id='goTo' /> */}
          <CommonTime justTime val={next.timestamp}></CommonTime>
        </>
      </Button>}
    </Grid>

    <Grid item xs={12}>
      {props.children({ reportSelected, waveformsSelected })}
    </Grid>
  </Grid>
  )
}