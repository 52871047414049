import { IntlShape } from 'react-intl';
import { gaugeFinal2 } from '../components/device/final/RoundGauge';
import { DeviceFull } from '../interfaces/Device';
import { ReportList, ReportWaveforms } from '../interfaces/Report';
import { gaugeMid, gaugeProps } from '../pages/tcm/tabs/ElectricParam';
import { chunkArray, makeArrowsFromDataPolar } from './format-data';

export const icmOrder = ['voltagesf', 'currentsf']

export const forGaugesICM = (report: ReportList | undefined, hasntI?: boolean, intl?: IntlShape): gaugeProps => {
    const max1 = Math.max(report?.electricParam?.ppv ? report.electricParam.ppv[0] : 0,
        report?.electricParam?.ppv ? report.electricParam.ppv[1] : 0,
        report?.electricParam?.ppv ? report.electricParam.ppv[2] : 0,
        report?.electricParam?.ppv ? report.electricParam.ppv[3] : 0)
    const max2 = Math.max(report?.electricParam?.iv ? report.electricParam.iv[0] : 0,
        report?.electricParam?.iv ? report.electricParam.iv[1] : 0,
        report?.electricParam?.iv ? report.electricParam.iv[2] : 0,
        report?.electricParam?.iv ? report.electricParam.iv[3] : 0)
    const two = {
        variable: 'V',
        title: 'voltage',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: "Vuv", value: report?.electricParam?.ppv ? report?.electricParam?.ppv[0] : 0, color: '#ec4747', max: max1 },
            { name: "Vvw", value: report?.electricParam?.ppv ? report?.electricParam?.ppv[1] : 0, color: '#2ebd70', max: max1 },
            { name: "Vwu", value: report?.electricParam?.ppv ? report?.electricParam?.ppv[2] : 0, color: '#4684c9', max: max1 },
            { name: "V", value: report?.electricParam?.ppv ? report?.electricParam?.ppv[3] : 0, color: '#f9c937', max: max1 }
        ]
    }
    const three = {
        variable: 'A',
        title: 'current',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: "Iu", value: report?.electricParam?.iv ? report?.electricParam?.iv[0] : 0, color: '#ec4747', max: max2 },
            { name: "Iv", value: report?.electricParam?.iv ? report?.electricParam?.iv[1] : 0, color: '#2ebd70', max: max2 },
            { name: "Iw", value: report?.electricParam?.iv ? report?.electricParam?.iv[2] : 0, color: '#4684c9', max: max2 },
            { name: "I", value: report?.electricParam?.iv ? report?.electricParam?.iv[3] : 0, color: '#f9c937', max: max2 }
        ]
    }
    const empty: gaugeMid = {
        title: "current", variable: "", array: [
            { "name": intl ? intl.formatMessage({ id: "na" }) : "", "color": "#f9c937", "max": 0, "value": undefined }
        ]
    }
    return [two, (hasntI ? empty : three)]
}

export const forGlobalStatus = (device?: ReportList): gaugeFinal2[] => {
    const one = { name: `voltagesf`, value: device?.diagnostic.sF ? device.diagnostic.sF[0] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: 3, steps: [[0, 1], [1, 2], [2, 3]], unit: '%' }

    const two = { name: `currentsf`, value: device?.diagnostic.sF ? device.diagnostic.sF[1] : 0, colors: ["#2aa060", "#ffc000", "#e23434"], max: 3, steps: [[0, 1], [1, 2], [2, 3]], unit: '%' }

    return [one, two]
}

export const forVectorGraphsICM = (device: ReportList | undefined, hasI: boolean): Array<{ amplitude: Array<number>, angulo: Array<number> }> | undefined => {// IA (amplitude depois fase) IB IC 
    if (device === undefined) {
        return undefined
    }
    var formattedPhasors = {
        amplitudes: !hasI ?
            device.electricParam.phasors?.filter((v, i) => i % 2 === 0 && i < 6) :
            device.electricParam.phasors?.filter((v, i) => i % 2 === 0 && i < 12),
        degrees: !hasI ?
            device.electricParam.phasors?.filter((v, i) => i % 2 !== 0 && i < 6) :
            device.electricParam.phasors?.filter((v, i) => i % 2 !== 0 && i < 12),
    }
    const max = formattedPhasors.amplitudes?.reduce((a, b) => a > b ? a : b);
    const arrowedPhasors = formattedPhasors.amplitudes?.map((value, indx) => makeArrowsFromDataPolar(value, formattedPhasors!.degrees[indx], max ? max : 1))
    return arrowedPhasors;
}

export const forWaveformsICM = (
    waves: ReportWaveforms | undefined,
    deviceFull: DeviceFull | undefined,
    hasI?: boolean): Array<Array<{ x: Array<number | string>, ys: Array<Array<number | string>> }>> => {
    if (waves === undefined || deviceFull === undefined) {
        return [[{ x: [], ys: [[]] }]]
    }
    //const wPNv = chunkArray(waves.wPNv, 3); // SAO SO zeros se nao houver tensoes
    const wI = chunkArray(waves.wI, 3);
    const wPPv = chunkArray(waves.wPPv, 3);
    const wVPIexc = chunkArray(waves.wVPIexc, 2);

    let exc = wVPIexc.map((each) => {
        return {
            x: each[0], y: each[1]
        }
    });
    let main = [
        {
            x: [...wPPv[0][0].keys()].map(value => value / deviceFull.config_device.fs_analog * 1000),
            ys: [wPPv[0][0], wPPv[0][1], wPPv[0][2]] as Array<Array<number>>
        },
        {
            x: [...wI[0][0].keys()].map(value => value / deviceFull.config_device.fs_analog * 1000),
            ys: [wI[0][0], wI[0][1], wI[0][2]] as Array<Array<number>>
        },
    ];
    if (hasI) {
        return [
            [
                { x: [...main[0].x, ...main[1].x], ys: [...main[0].ys, ...main[1].ys] },
                { x: exc[0].x, ys: [exc[0].y] },
                { x: exc[1] ? exc[1].x : [], ys: [exc[1] ? exc[1].y : []] },
                //{ x: currents[0].x, ys: [currents[0].y] },
            ]
            //, [
            //    { x: main[1].x, ys: main[1].ys },
            //    { x: voltages[1].x, ys: [voltages[1].y] },
            //{ x: currents[1].x, ys: [currents[1].y] },
            //]
        ]
    } else {
        return [
            [
                { x: main[0].x, ys: main[0].ys },
                { x: exc[0].x, ys: [exc[0].y] },
                //{ x: currents[0].x, ys: [currents[0].y] },
            ]
            //, [
            //    { x: main[1].x, ys: main[1].ys },
            //    { x: voltages[1].x, ys: [voltages[1].y] },
            //{ x: currents[1].x, ys: [currents[1].y] },
            //]
        ]
    }
}

export const forHarmonicsICM = (device: ReportList | undefined): Array<Array<{ one: Array<number>, even: { x: Array<number>, y: Array<Array<number>> }, odd: { x: Array<number>, y: Array<Array<number>> } }>> => {
    if (device === undefined) {
        return [[{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }], [{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }]]
    }
    const evenx = Array(20).fill(1).map((value, index) => (index + 1) * 2) //20
    const oddx = Array(19).fill(1).map((value, index) => (index + 1) * 2 + 1) //19
    const voltage = prepareHarmonicsDataICM(device.pwrQuality.thdPPv)
    const current = prepareHarmonicsDataICM(device.pwrQuality.thdI)
    return [
        [  //    SIDE PRIMARY
            {   // VOLTAGE
                even: {
                    x: evenx,
                    y: voltage.map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
                },
                odd: {
                    x: oddx,
                    y: voltage.map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
                },
                one: [voltage[0][0], voltage[1][0], voltage[2][0]]
            },
            {   // Currrent
                even: {
                    x: evenx,
                    y: current.map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
                },
                odd: {
                    x: oddx,
                    y: current.map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
                },
                one: [current[0][0], current[1][0], current[2][0]]
            }
        ]
    ]
}

const prepareHarmonicsDataICM = (thdPPv: Array<number>): Array<Array<number>> => {
    if (!thdPPv) return [[],[],[]]
    const harms1 = thdPPv.slice(4);
    const harmonics_current = [
        harms1.slice(0, 50),
        harms1.slice(50, 100),
        harms1.slice(100, 150),
    ];
    return harmonics_current;
}

const ICMvariables_I = {
    general: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',
        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',
        'unbpnv-0',
        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',
        'unbppv-0',
        'actpwr-0',
        'cosphi-0',
        'apparpwr-0',
        'reactpwr-0',
        'phasors-0',
        'phasors-1',
        'phasors-2',
        'phasors-3',
        'phasors-4',
        'phasors-5',
        'phasors-6',
        'phasors-7',
        'phasors-8',
        'phasors-9',
        'phasors-10',
        'phasors-11',
        'phasors-12',
        'phasors-13',
        'sf-0',
        'sf-1',
        'freq',
        'ldratio',
    ], [
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
    ]]
}

const ICMvariables = {
    general: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',
        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',
        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',
        'unbpnv-0',
        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',
        'unbppv-0',
        'phasors-0',
        'phasors-1',
        'phasors-2',
        'phasors-3',
        'phasors-4',
        'phasors-5',
        'phasors-12',
        'sf-0',
        'freq',
    ], [
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
    ]]
}

export const ICMpermissions = {
    "electric_param": "009",
    "load_diagram": "010",
    "power_quality": "016",
    "vector_diagram": "01b",
    "waveforms": "020",
    "global_status": "025",
    "harmonics": "034",
}

export const getICMVariablesAdmin = (): Array<string> => {
    var three = ICMvariables.general.map((arr, indexArr) => arr.map((val, index) => val)).reduce((a: any, b: any) => [...a, ...b], [])
    return [...three]
}

export const getICMVariables = (): Array<string> => {
    var three = ICMvariables.general[0].map((val, index) => { return val })
    return [...three]
}

export const getICM_I_VariablesAdmin = (): Array<string> => {
    var three = ICMvariables_I.general.map((arr, indexArr) => arr.map((val, index) => val)).reduce((a: any, b: any) => [...a, ...b], [])
    return [...three]
}

export const getICM_I_Variables = (): Array<string> => {
    var three = ICMvariables_I.general[0].map((val, index) => { return val })
    return [...three]
}
