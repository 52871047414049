import React, { useState } from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { LPTCMpermissions } from '../../utils/lptcm-functions';
import LptcmInfoDialog from '../lptcm/dialogs/LptcmInfoDialog';
import ElectricParam from '../lptcm/tabs/ElectricParam';
import ExcitationCurrent from '../lptcm/tabs/ExcitationCurrent';
import GlobalStatus from '../lptcm/tabs/GlobalStatus';
import Harmonics from '../lptcm/tabs/Harmonics';
import LoadDiagram from '../lptcm/tabs/LoadDiagram';
import PowerQuality from '../lptcm/tabs/PowerQuality';
import SingleReport from '../lptcm/tabs/SingleReport';
import TransformRatio from '../lptcm/tabs/TransformRatio';
import VectorGraph from '../lptcm/tabs/VectorGraph';
import Waveforms from '../lptcm/tabs/Waveforms';
import ElectricParamTCM from '../tcm/tabs/ElectricParam';
import ExcitationCurrentTCM from '../tcm/tabs/ExcitationCurrent';
import GlobalStatusTCM from '../tcm/tabs/GlobalStatus';
import HarmonicsTCM from '../tcm/tabs/Harmonics';
import LoadDiagramTCM from '../tcm/tabs/LoadDiagram';
import PowerQualityTCM from '../tcm/tabs/PowerQuality';
import SingleReportTCM from '../tcm/tabs/SingleReport';
import VectorGraphTCM from '../tcm/tabs/VectorGraph';
import WaveformsTCM from '../tcm/tabs/Waveforms';

interface LPTCMDPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}

const variables = ["ldratio",
  "ppv-0", "ppv-1", "ppv-2", "ppv-4", "ppv-5", "ppv-6", "ppv-8", "ppv-9", "ppv-10", "ppv-12", "ppv-13", "ppv-14",
  "iv-0", "iv-1", "iv-2", "iv-4", "iv-5", "iv-6", "iv-8", "iv-9", "iv-10", "iv-12", "iv-13", "iv-14",
  "unbi-0", "unbi-1", "unbi-2",
  "unbpnv-0", "unbpnv-1", "unbpnv-2", "unbi-3",
  "thdpnv-0", "thdpnv-1", "thdpnv-2", "thdpnv-4", "thdpnv-5", "thdpnv-6", "thdpnv-8", "thdpnv-9", "thdpnv-10", "thdpnv-12", "thdpnv-13", "thdpnv-14",
  "thdi-0", "thdi-1", "thdi-2", "thdi-3", "thdi-4", "thdi-5", "thdi-6", "thdi-7", "thdi-8", "thdi-9", "thdi-10", "thdi-11", "thdi-12", "thdi-13", "thdi-14", "thdi-15",
  "tappos",
  'sf-0',
  'iexc-0', 'iexc-1', 'iexc-2',
  'varlcc-3',
  'iv-3', 'iv-7',
  'rt-19'] as const //baixo diag, cima control
type TUV = typeof variables[number];
type time = { 'timestamp': string[] }
const variables2 = (variables as any)
variables2.unshift('timestamp')
export type LptcmdUsableVariables = time & {
  [K in TUV]: Array<number>;
}

export default function LPTCMDPage(props: LPTCMDPageProps) {
  const { location } = props
  const [hasV, setHasV] = React.useState(false);
  const [sideNames, setSideNames] = useState<Array<string>>(['1o', '2o', '3o', '4o']);
  const [hasV1, setHasV1] = useState<boolean>(true);
  const [hasV2, setHasV2] = useState<boolean>(true);
  const [hasV3, setHasV3] = useState<boolean>(true);
  const [hasV4, setHasV4] = useState<boolean>(true);
  const [hasV5, setHasV5] = useState<boolean>(true);

  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    return result
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    setHasV(result.config_device.analog_reading_labels.some(label => label.startsWith('V')));
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('VA'))) setHasV1(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va1'))) setHasV2(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va2'))) setHasV3(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va3'))) setHasV4(false);
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('Va4'))) setHasV5(false);
    makeSideNames(result)
    return result
  }

  const makeSideNames = (result: DeviceFull) => {
    var names = ['1o'];
    const secs = result.nameplate.side_labels?.filter(l => l.startsWith('S')).length
    const ters = result.nameplate.side_labels?.filter(l => l.startsWith('T')).length
    const quats = result.nameplate.side_labels?.filter(l => l.startsWith('Q')).length
    const cincs = result.nameplate.side_labels?.filter(l => l.startsWith('C')).length
    if (secs === 1) names.push('2o')
    else Array(secs).fill(0).forEach((x, index) => names.push(`2o${index + 1}`))
    if (ters === 1) names.push('3o')
    else Array(ters).fill(0).forEach((x, index) => names.push(`3o${index + 1}`))
    if (quats === 1) names.push('4o')
    else Array(quats).fill(0).forEach((x, index) => names.push(`4o${index + 1}`))
    if (cincs === 1) names.push('5o')
    else Array(cincs).fill(0).forEach((x, index) => names.push(`5o${index + 1}`))
    setSideNames(names);
  }

  return (
    <MainDeviceDashboard
      sideNames={sideNames}
      location={location}
      deviceType={DeviceType.L4}
      processingAffectingContexts={processingAffectingContexts}
      processingGeneral={processingGeneral}
      DeviceInfoDialog={LptcmInfoDialog}
      variables={variables2}
      controlInfoBar={{ element: <ControlInfoBar maxSFCount={1}></ControlInfoBar>, tabCode: "05a" }}
      controlComponents={[
        {
          element: hasV ?
            <ElectricParamTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></ElectricParamTCM> :
            <ElectricParam sideNames={sideNames}></ElectricParam>,
          title: "electric_param"
        }, {
          element: hasV ?
            <LoadDiagramTCM></LoadDiagramTCM> :
            <LoadDiagram></LoadDiagram>,
          title: "load_diagram"
        }, {
          element: hasV ?
            <PowerQualityTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></PowerQualityTCM> :
            <PowerQuality sideNames={sideNames}></PowerQuality>,
          title: "power_quality"
        }, {
          element: hasV ?
            <VectorGraphTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></VectorGraphTCM> :
            <VectorGraph sideNames={sideNames}></VectorGraph>,
          title: "vector_diagram"
        }, {
          element: hasV ?
            <WaveformsTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></WaveformsTCM> :
            <Waveforms sideNames={sideNames} hasV={hasV}></Waveforms>,
          title: "waveforms"
        }
      ].map(v => ({ ...v, ...{ tabCode: LPTCMpermissions[(v.title as keyof typeof LPTCMpermissions)] } }))}
      diagnosticComponents={[
        {
          element: hasV ?
            <GlobalStatusTCM></GlobalStatusTCM> :
            <GlobalStatus></GlobalStatus>,
          title: "global_status"
        }, {
          element: hasV ?
            <ExcitationCurrentTCM></ExcitationCurrentTCM> :
            <ExcitationCurrent></ExcitationCurrent>,
          title: "excitation"
        }, {
          element: <TransformRatio sideNames={sideNames}></TransformRatio>,
          title: "transformation_ratio"
        }, {
          element: hasV ?
            <HarmonicsTCM hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></HarmonicsTCM> :
            <Harmonics sideNames={sideNames} hasV={hasV}></Harmonics>,
          title: "harmonics"
        }
      ].map(v => ({ ...v, ...{ tabCode: LPTCMpermissions[(v.title as keyof typeof LPTCMpermissions)] } }))}
      historyComponents={[
        {
          element: <HistoryPage type={DeviceType.L4} sideLabels={sideNames}></HistoryPage>,
          title: "trendAn"
        },
        {
          element: <ReportViewer>
            {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined }) => hasV ?
              <SingleReportTCM reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} hasV1={hasV1} hasV2={hasV2} hasV3={hasV3} hasV4={hasV4} hasV5={hasV5} sideNames={sideNames}></SingleReportTCM> :
              <SingleReport reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} sideNames={sideNames} hasV={hasV} />}
          </ReportViewer>,
          title: "sampleAn"
        },
      ]}
    ></MainDeviceDashboard>
  );
}