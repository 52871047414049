import { Grow, MenuItem, MenuList, Paper, Popper, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/SearchRounded';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sublistSubtype } from './Sidebar';
import { ThemeExtended } from './Theme';


const useStyles = () => {
  return makeStyles((theme: ThemeExtended) => ({
    // sublist: {
    //   // position: 'fixed',
    //   // zIndex: 999,
    //   // marginTop: -60,
    //   "& > div": {
    //     top: "unset !important",
    //     left: "unset !important",
    //     transform: "translate3d(0px, 5px, 0px) !important",//workaraound the first element was 1 pixel wrong
    //   },
    // },
    // sublistopen: {
    //   marginLeft: theme.shape.drawer.width,
    // },
    // sublistclosed: {
    //   marginLeft: 72,
    // },
    menuItem: {
      fontSize: "14px",
      textTransform: "uppercase",
      fontWeight: 600,
      textAlign: "center",
      color: theme.palette.primary.main,
      backgroundColor: "white !important",
      '&.Mui-focusVisible': {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "white"

      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        color: "white"
      }
    },
    popper: {
      marginTop: -1
    },
    textFieldSearch: {
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: 150,
      width: "100%",
      '& input': {
        paddingTop: "0 !important",
        paddingBottom: "0 !important"
      }
    },
    list: {
      marginTop: -8,
      maxHeight: 450,
      overflow: "auto",
      // scrollbarColor: `${theme.palette.primary.main} transparent`,
      // scrollbarWidth: "thin",
      // "&::-webkit-scrollbar-track":
      // {
      //   backgroundColor: "transparent",
      // },
      // "&::-webkit-scrollbar":
      // {
      //   width: 2,
      //   backgroundColor: "transparent",
      // },
      // "&::-webkit-scrollbar-thumb":
      // {
      //   backgroundColor: theme.palette.primary.main,
      //   border: "1px solid #555555",
      // },
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none"
      },
    }
  }))()
};

type MenuPopperProps = {
  open: boolean,
  links?: sublistSubtype[],
  updateLinks: (indexes2: number[], isOpen: boolean) => void,
  openDrawer?: boolean,
  style?: any,
  first?: boolean,
  anchorElement?: any
}

export default function MenuPopper(props: PropsWithChildren<MenuPopperProps>) {
  const { open, style, links, updateLinks, anchorElement } = props;
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();

  const handleChangePage = (link: string, event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // console.log(link)
    event?.preventDefault();
    event?.stopPropagation();
    history.push(link)
  }

  const updateLinksExtended = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, value: boolean) => {
    // if (value === true)console.log(`update-${links ? links[index]?.text : "nothing"}-${value}-time:(${new Date()})`)
    // if (value === false) return
    setAnchorEl(event.currentTarget ?? anchorEl);
    updateLinks([index], value)
  }

  const updateLinksNext = (indexHere: number) => (indexes2: number[], isOpen: boolean) => {
    // if (isOpen === true)console.log(`next-update-${indexes2}-${isOpen}`)
    // if (isOpen === false) return
    const final = indexes2;
    final.unshift(indexHere);
    updateLinks(final, isOpen);
  }

  const removeAccentAndLowerCase = (value: string) => {
    return value.toLowerCase().replace("á", "a").replace("à", "a").replace("ç", "c").replace("ñ", "n").replace("â", "a")
  }

  const linksSorted = useMemo(() => links?.sort((a, b) => a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())), [links]);


  return (
    <>
      {anchorElement && <div style={style}>
        <Popper
          open={open}
          anchorEl={anchorElement ?? { clientWidth: 1, clientHeight: 1, getBoundingClientRect: () => { return { bottom: 1, height: 1, left: 0, right: 1, top: 1, width: 1 } } }}
          role={undefined}
          transition
          placement={"right-start"}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
            // style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            // style={{ transformOrigin: 'right' }}
            >
              <Paper>
                <MenuList className={classes.list} id="menu-list-grow">
                  {links && links.length > 15 && <TextField
                    className={classes.textFieldSearch}
                    autoFocus
                    hiddenLabel
                    size={'small'}
                    value={search}
                    InputProps={{ endAdornment: <SearchIcon />, }}
                    onChange={(value) => setSearch(value.target.value)}
                    id="filled-basic"
                    variant="filled"
                    onKeyDown={(event) => { event.stopPropagation() }} />}
                  {linksSorted && linksSorted.filter(v => !search || removeAccentAndLowerCase(v.text).includes(removeAccentAndLowerCase(search))).map((link, index) => <MenuItem
                    onClick={(event) => handleChangePage(link.link, event)}
                    className={classes.menuItem}
                    key={`select-${index}-${link.text}`}
                    value={link.text}
                    onMouseEnter={(event) => updateLinksExtended(event, index, true)}
                    onMouseLeave={(event) => updateLinksExtended(event, index, false)}
                  >
                    {link.text}
                    {link.isOpen && link.links && link.links.length > 0 && <MenuPopper
                      open={link.isOpen === true}
                      openDrawer={open}
                      first={false}
                      links={link.links}
                      updateLinks={updateLinksNext(index)}
                      anchorElement={anchorEl}
                    />}
                  </MenuItem>)}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div >}
    </>
  )
}