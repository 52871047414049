import { IntlShape } from 'react-intl';
import { DeviceFull, DeviceType } from '../interfaces/Device';
import { ReportList, ReportWaveforms } from '../interfaces/Report';
import { gaugeMid, gaugeProps } from '../pages/tcm/tabs/ElectricParam';
import { chunkArray, formatForPhasor, makeArrowsFromDataPolar } from './format-data';

export const forGauges = (report: ReportList | undefined, hasSide: boolean[], textNA: string, device: DeviceFull | undefined): Array<gaugeProps> => {
    const n = device?.nameplate?.side_labels ? device.nameplate.side_labels.length : 0
    //console.log(n,report?.electricParam.iv.length)
    const sidesArray = Array(n).fill(1)
    const maxes1 = sidesArray.map((value, side) => {
        return report?.electricParam?.ppv ? Math.max(report.electricParam.ppv[0 + side * 4],
            report.electricParam.ppv[1 + side * 4],
            report.electricParam.ppv[2 + side * 4],
            report.electricParam.ppv[3 + side * 4]
        ) : 0
    })
    const maxes2 = sidesArray.map((value, side) => {
        return report?.electricParam?.iv ? Math.max(report.electricParam.iv[0 + side * 4],
            report.electricParam.iv[1 + side * 4],
            report.electricParam.iv[2 + side * 4],
            report.electricParam.iv[3 + side * 4]
        ) : 0
    })
    const maxes3 = sidesArray.map((value, side) => {
        return Math.max(report?.electricParam?.apparPwr ? report.electricParam.apparPwr[0 + side] : 0,
            report?.electricParam?.actPwr ? report.electricParam.actPwr[0 + side] : 0,
            report?.electricParam?.reactPwr ? report.electricParam.reactPwr[side] : 0
        )
    })
    const one = sidesArray.map((value, side) => {
        return {
            variable: 'V',
            title: 'voltage',
            array: hasSide[side] ?
                [//ppv  report.data.electricParam.ppv
                    { name: `V${side === 0 ? 'AB' : 'ab'}`, value: report?.electricParam.ppv && report.electricParam.ppv[0 + side * 4], color: '#ec4747', max: maxes1[side] },
                    { name: `V${side === 0 ? 'BC' : 'bc'}`, value: report?.electricParam.ppv && report.electricParam.ppv[1 + side * 4], color: '#2ebd70', max: maxes1[side] },
                    { name: `V${side === 0 ? 'CA' : 'ca'}`, value: report?.electricParam.ppv && report.electricParam.ppv[2 + side * 4], color: '#4684c9', max: maxes1[side] },
                    { name: 'V', value: report?.electricParam.ppv && report.electricParam.ppv[3 + side * 4], color: '#f9c937', max: maxes1[side] }
                ] : [
                    { name: textNA, value: undefined, color: '#f9c937', max: maxes1[side] }
                ]
        }
    })
    const two = sidesArray.map((value, side) => {
        return {
            variable: 'A',
            title: 'current',
            array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
                { name: `I${side === 0 ? 'A' : 'a'}`, value: report?.electricParam.iv && report.electricParam.iv[0 + side * 4], color: '#ec4747', max: maxes2[side] },
                { name: `I${side === 0 ? 'B' : 'b'}`, value: report?.electricParam.iv && report.electricParam.iv[1 + side * 4], color: '#2ebd70', max: maxes2[side] },
                { name: `I${side === 0 ? 'C' : 'c'}`, value: report?.electricParam.iv && report.electricParam.iv[2 + side * 4], color: '#4684c9', max: maxes2[side] },
                { name: 'I', value: report?.electricParam.iv && report.electricParam.iv[3 + side * 4], color: '#f9c937', max: maxes2[side] }
            ]
        }
    })
    const three = sidesArray.map((value, side) => {
        return {
            variable: '',
            title: 'power',
            array: hasSide[side] ? [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
                { name: 'S', value: report?.electricParam.apparPwr && report.electricParam.apparPwr[0 + side], color: '#9e2d99', max: maxes3[side] },
                { name: 'P', value: report?.electricParam.actPwr && report.electricParam.actPwr[0 + side], color: '#a9662f', max: maxes3[side] },
                { name: 'Q', value: report?.electricParam.reactPwr && report.electricParam.reactPwr[0 + side], color: '#edb146', max: maxes3[side] },
                { name: 'PF', value: report?.electricParam.cosPhi && report.electricParam.cosPhi[0 + side], color: '#e2d5c1', max: 1 }
            ] : [
                    { name: textNA, value: undefined, color: '#f9c937', max: maxes1[side] }
                ]
        }
    })
    return one.map((value, side) => [one[side], two[side], three[side]])
}

export const forVectorGraphs = (device: ReportList | undefined) => {
    if (device === undefined) {
        return undefined
    }
    const formattedPhasors = formatForPhasor(device.electricParam.phasors)
    // console.log(formattedPhasors)
    const max = formattedPhasors.amplitudes?.map(vec => vec.reduce((p, c) => c < p ? p : c, 0))
    // console.log(max)
    const arrowedPhasors = formattedPhasors.amplitudes?.map((sideAmps, side) => {
        return sideAmps.map((amps, valIndex) => {
            return makeArrowsFromDataPolar(amps, formattedPhasors!.degrees![side][valIndex], max ? max[side] : 0.5)
        })
    })
    return arrowedPhasors;
}


export const forPowerQualityGauges = (report: ReportList | undefined, device: DeviceFull | undefined): gaugeProps => {
    // report.pwrQuality.thdI.length>8 
    const sides = device?.nameplate.side_labels ?
        device?.nameplate.side_labels.length > 2 ?
            device?.nameplate.side_labels.length > 3 ?
                [1, 2, 3, 4] :
                [1, 2, 3] :
            [1, 2] :
        [1, 2];
    const appendtext = device && device.nameplate.side_labels.length > 3 ? "-quad" : "";
    const maxes1 = sides.map((value, side) => Math.max(report && report.pwrQuality.unbPPv ? report.pwrQuality.unbPPv[0 + side] : 0,
        report && report.pwrQuality.unbI ? report.pwrQuality.unbI[0 + side] : 0,
    ))
    const maxes2 = sides.map((value, side) => Math.max(report && report.pwrQuality.thdPNv ? report.pwrQuality.thdPNv[0 + side * 4] : 0,
        report && report.pwrQuality.thdPNv ? report.pwrQuality.thdPNv[1 + side * 4] : 0,
        report && report.pwrQuality.thdPNv ? report.pwrQuality.thdPNv[2 + side * 4] : 0,
        report && report.pwrQuality.thdPNv ? report.pwrQuality.thdPNv[3 + side * 4] : 0
    ))
    const maxes3 = sides.map((value, side) => Math.max(report && report.pwrQuality.thdI ? report.pwrQuality.thdI[0 + side * 4] : 0,
        report && report.pwrQuality.thdI ? report.pwrQuality.thdI[1 + side * 4] : 0,
        report && report.pwrQuality.thdI ? report.pwrQuality.thdI[2 + side * 4] : 0,
        report && report.pwrQuality.thdI ? report.pwrQuality.thdI[3 + side * 4] : 0
    ))
    //console.log(maxes1,report?.pwrQuality.unbI[0],report?.pwrQuality.unbPPv[0])
    let one = sides.map((value, side) => ({
        variable: '',
        title: 'unbalance',
        array: [//ppv  report.data.electricParam.ppv
            { name: `unbi-${0 + side}${appendtext}`, value: report?.pwrQuality.unbI && report?.pwrQuality.unbI[0 + side], color: '#ec4747', max: maxes1[side] },
            { name: `unbppv-${0 + side}${appendtext}`, value: report?.pwrQuality.unbPPv && report?.pwrQuality.unbPPv[0 + side], color: '#2ebd70', max: maxes1[side] },
        ]
    }))

    const two = sides.map((value, side) => ({
        variable: '',
        title: 'thdv',
        array: [ //iv    [side, side]   ->  [{name,value},...]    report.data.electricParam.iv
            { name: `thdpnv-${0 + side * 4}${appendtext}`, value: report?.pwrQuality.thdPNv && report.pwrQuality.thdPNv[0 + side * 4], color: '#ec4747', max: maxes2[side] },
            { name: `thdpnv-${1 + side * 4}${appendtext}`, value: report?.pwrQuality.thdPNv && report.pwrQuality.thdPNv[1 + side * 4], color: '#2ebd70', max: maxes2[side] },
            { name: `thdpnv-${2 + side * 4}${appendtext}`, value: report?.pwrQuality.thdPNv && report.pwrQuality.thdPNv[2 + side * 4], color: '#4684c9', max: maxes2[side] },
            { name: `thdpnv-${3 + side * 4}${appendtext}`, value: report?.pwrQuality.thdPNv && report.pwrQuality.thdPNv[3 + side * 4], color: '#f9c937', max: maxes2[side] }
        ]
    }))

    const three = sides.map((value, side) => ({
        variable: '',
        title: 'thdc',
        array: [ //device.apparPwr[side],    device.actPwr[side],    device.reactPwr[side]    cosPhi (value)
            { name: `thdi-${0 + side * 4}${appendtext}`, value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[0 + side * 4], color: '#ec4747', max: maxes3[side] },
            { name: `thdi-${0 + side * 4}${appendtext}`, value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[1 + side * 4], color: '#2ebd70', max: maxes3[side] },
            { name: `thdi-${2 + side * 4}${appendtext}`, value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[2 + side * 4], color: '#4684c9', max: maxes3[side] },
            { name: `thdi-${3 + side * 4}${appendtext}`, value: report?.pwrQuality?.thdI && report.pwrQuality.thdI[3 + side * 4], color: '#f9c937', max: maxes3[side] }
        ]
    }))
    // console.log(one, two, three)
    return sides.length > 2 ? sides.length > 3 ? [one[0], two[0], three[0], one[1], two[1], three[1], one[2], two[2], three[2], one[3], two[3], three[3]] : [one[0], two[0], three[0], one[1], two[1], three[1], one[2], two[2], three[2]] : [one[0], two[0], three[0], one[1], two[1], three[1]]
}

export const fixForPowerQualityGauges = (v: gaugeProps, intl: IntlShape, sideNames?: string[], hasV1?: boolean, hasV2?: boolean, hasV3?: boolean, hasV4?: boolean, hasV5?: boolean, hasV6?: boolean, keepEmpty?: boolean) => {
    const empty: gaugeMid = { title: "thdv", variable: "", array: [{ "name": intl.formatMessage({ id: "na" }), "color": "#f9c937", "max": 0, "value": undefined }] }
    // console.log(JSON.stringify(v))
    const length = sideNames ? sideNames.length : 2
    // console.log(length)
    const hasVS = [hasV1 === true, hasV2 === true, hasV3 === true, hasV4 === true, hasV5 === true, hasV6 === true].slice(0, length)

    v.map(g => g.array.map(ar => ar.name = intl.formatMessage({ id: ar.name }).split('-')[1]))
    sideNames?.forEach((side, i) => {
        if (!hasVS[i] && v[i * 3]) v[i * 3].array.pop();
    })

    const qualityDataFixed = sideNames?.map((side, index) => hasVS[index] ? [v[3 * index], (v[3 * index + 1] ? v[3 * index + 1] : empty), v[3 * index + 2]] : keepEmpty ? [v[3 * index], empty, v[3 * index + 2]] : [v[3 * index], v[3 * index + 2]])

    return qualityDataFixed
}

export const forExcitationGauges = (report: ReportList | undefined): gaugeProps => {
    const maxes1 = Math.max(report?.diagnostic?.iexc ? report.diagnostic.iexc[0] : 0, report?.diagnostic?.iexc ? report.diagnostic.iexc[1] : 0, report?.diagnostic?.iexc ? report.diagnostic.iexc[2] : 0)
    //console.log(maxes1,report?.pwrQuality.unbI[0],report?.pwrQuality.unbPPv[0])
    const one = {
        variable: '',
        title: 'excitation',
        array: report?.diagnostic?.iexc ? [//ppv  report.data.electricParam.ppv
            { name: `iexc-0`, value: report?.diagnostic.iexc[0], color: '#ec4747', max: maxes1 },
            { name: `iexc-1`, value: report?.diagnostic.iexc[1], color: '#2ebd70', max: maxes1 },
            { name: `iexc-2`, value: report?.diagnostic.iexc[2], color: '#4684c9', max: maxes1 },
        ] : [
                { name: "na", value: undefined, color: '#f9c937', max: undefined }
            ]
    }

    return [one]
}

export const forWaveforms = (waves: ReportWaveforms | undefined, deviceFull: DeviceFull | undefined, lptcm?: boolean): Array<Array<{ x: Array<number | string>, ys: Array<Array<number | string>> }>> => {
    if (waves === undefined || deviceFull === undefined) {
        return [[{ x: [], ys: [[]] }]]
    }
    let has2Secondary = 0
    if (deviceFull.type === DeviceType.Td || deviceFull.type === DeviceType.Ld) has2Secondary = 1;
    else if (deviceFull.type === DeviceType.L3) has2Secondary = 2;
    else if (deviceFull.type === DeviceType.L4) has2Secondary = 3;
    else if (deviceFull.type === DeviceType.L5) has2Secondary = 4;
    const wPNv = chunkArray(waves.wPNv, 3); // SAO SO zeros se nao houver tensoes
    const wI = chunkArray(waves.wI, 3);
    //let wVPIexc = waves.wVPIexc.slice(0, 4)
    //console.log(has2Secondary,deviceFull)
    //2-8-12
    //3-10-16
    //4-12-20
    let wVPI = chunkArray(waves.wVPIexc?.slice(4, 8 + 2 * has2Secondary), 2);
    let wVPV = chunkArray(waves.wVPIexc?.slice(8 + 2 * has2Secondary, 12 + 4 * has2Secondary), 2); // 16 partes com 3 lados

    let currents = wVPI.map((each) => {
        return {
            x: each ? each[0] : undefined, y: each ? each[1] : undefined
        }
    });
    let voltages = wVPV.map((each) => {
        return {
            x: each ? each[0] : undefined, y: each ? each[1] : undefined
        }
    });
    // console.log(currents, voltages)
    let main = deviceFull.nameplate.side.map((each, index) => {
        if (!wI || !wPNv || !wI[index] || !wPNv[index]) return { x: [], ys: [[]] }
        const wPNv2 = wPNv[index];
        const wI2 = wI[index];
        return {
            x: [...wI2[0].keys()].map(value => value / deviceFull.config_device.fs_analog * 1000),
            ys: lptcm ?
                [wI2[0], wI2[1], wI2[2]] as Array<Array<number>> :
                [wPNv2[0], wPNv2[1], wPNv2[2], wI2[0], wI2[1], wI2[2]] as Array<Array<number>>
        };
    });
    const range = main.map((d, index) => index)
    return range.map(id => [
        { x: main[id].x, ys: main[id].ys },
        { x: voltages[id] ? voltages[id]?.x : [], ys: [voltages[id] ? voltages[id].y : []] },
        { x: currents[id] ? currents[id].x : [], ys: [currents[id] ? currents[id].y : []] },
    ])

}

export const forHarmonics = (device: ReportList | undefined, deviceFull: DeviceFull | undefined): Array<Array<{ one: Array<number>, even: { x: Array<number>, y: Array<Array<number>> }, odd: { x: Array<number>, y: Array<Array<number>> } }>> => {
    if (device === undefined) {
        return [[{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }], [{ one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }, { one: [0, 0, 0], even: { x: [], y: [[]] }, odd: { x: [], y: [[]] } }]]
    }
    const evenx = Array(20).fill(1).map((value, index) => (index + 1) * 2) //20
    const oddx = Array(19).fill(1).map((value, index) => (index + 1) * 2 + 1) //19
    const [current, voltage] = prepareHarmonicsData(device.pwrQuality.thdI, device.pwrQuality.thdPNv, deviceFull?.nameplate.side_labels)
    return current.map((x, index) => [  //    SIDE 
        {   // CURRENT
            even: {
                x: evenx,
                y: current[index].map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
            },
            odd: {
                x: oddx,
                y: current[index].map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
            },
            one: current[index] && current[index][0] && current[index][1] && current[index][2] ? [current[index][0][0], current[index][1][0], current[index][2][0]] : [0, 0, 0]
        },
        {   // VOLTAGE
            even: {
                x: evenx,
                y: voltage[index].map((subarray) => subarray.filter((value, index) => evenx.includes(index + 1)))
            },
            odd: {
                x: oddx,
                y: voltage[index].map((subarray) => subarray.filter((value, index) => oddx.includes(index + 1)))
            },
            one: voltage[index] && voltage[index][0] && voltage[index][1] && voltage[index][2] ? [voltage[index][0][0], voltage[index][1][0], voltage[index][2][0]] : [0, 0, 0]
        }
    ])
}
//2->8  3->12 4->16 5 ->20 6->24
const prepareHarmonicsData = (I: Array<number>, V: Array<number>, side_labels: string[] | undefined): Array<Array<Array<Array<number>>>> => {
    const length = side_labels ? side_labels.length : 2
    if (I === undefined) return Array(length).fill([]);
    const sliceAmount = length * 4
    const harms1 = I.slice(sliceAmount);
    const arr1 = chunkArray(harms1, 150)
    const harmonics_current = arr1.map(a => chunkArray(a, 50))
    if (V === undefined) return [harmonics_current, []]
    const harms2 = V.slice(sliceAmount);
    const arr2 = chunkArray(harms2, 150)
    const harmonics_voltage = arr2.map(a => chunkArray(a, 50))
    return [harmonics_current, harmonics_voltage]
}

const TCMvariables = {
    primary: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',
        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'thdpnv-0',
        'thdpnv-1',
        'thdpnv-2',
        'thdpnv-3',
        'varpnv-0',
        'varpnv-1',
        'varpnv-2',
        'varpnv-3',
        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',
        'unbpnv-0',
        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',
        'unbppv-0',
        'actpwr-0',
        'cosphi-0',
        'apparpwr-0',
        'reactpwr-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
        'phasors-13',
        'phasors-15',
        'phasors-17',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'pnv-4',
        'pnv-5',
        'pnv-6',
        'pnv-7',
        'ppv-4',
        'ppv-5',
        'ppv-6',
        'ppv-7',
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'thdpnv-4',
        'thdpnv-5',
        'thdpnv-6',
        'thdpnv-7',
        'varpnv-4',
        'varpnv-5',
        'varpnv-6',
        'varpnv-7',
        'varppv-4',
        'varppv-5',
        'varppv-6',
        'varppv-7',
        'unbpnv-1',
        'thdppv-4',
        'thdppv-5',
        'thdppv-6',
        'thdppv-7',
        'unbppv-1',
        'actpwr-1',
        'cosphi-1',
        'apparpwr-1',
        'reactpwr-1',
        'phasors-7',
        'phasors-9',
        'phasors-11',
        'phasors-19',
        'phasors-21',
        'phasors-23',
    ], [
        'sf-3',
        'sf-4'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'varlcc-3',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'pwrloss-3',
        'rt-7',
    ], [
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-4',
        'rt-5',
        'rt-6',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
    ]]
}

const TCMDvariables = {
    primary: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',
        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',
        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',
        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',
        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',
        'unbi-0',
        'thdpnv-0',
        'thdpnv-1',
        'thdpnv-2',
        'thdpnv-3',
        'varpnv-0',
        'varpnv-1',
        'varpnv-2',
        'varpnv-3',
        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',
        'unbpnv-0',
        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',
        'unbppv-0',
        'actpwr-0',
        'cosphi-0',
        'apparpwr-0',
        'reactpwr-0',
        'phasors-1',
        'phasors-3',
        'phasors-5',
        'phasors-19',
        'phasors-21',
        'phasors-23',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'pnv-4',
        'pnv-5',
        'pnv-6',
        'pnv-7',
        'ppv-4',
        'ppv-5',
        'ppv-6',
        'ppv-7',
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'thdpnv-4',
        'thdpnv-5',
        'thdpnv-6',
        'thdpnv-7',
        'varpnv-4',
        'varpnv-5',
        'varpnv-6',
        'varpnv-7',
        'varppv-4',
        'varppv-5',
        'varppv-6',
        'varppv-7',
        'unbpnv-1',
        'thdppv-4',
        'thdppv-5',
        'thdppv-6',
        'thdppv-7',
        'unbppv-1',
        'actpwr-1',
        'cosphi-1',
        'apparpwr-1',
        'reactpwr-1',
        'phasors-7',
        'phasors-9',
        'phasors-11',
        'phasors-25',
        'phasors-27',
        'phasors-29',
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'pnv-8',
        'pnv-9',
        'pnv-10',
        'pnv-11',
        'ppv-8',
        'ppv-9',
        'ppv-10',
        'ppv-11',
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'thdpnv-8',
        'thdpnv-9',
        'thdpnv-10',
        'thdpnv-11',
        'varpnv-8',
        'varpnv-9',
        'varpnv-10',
        'varpnv-11',
        'varppv-8',
        'varppv-9',
        'varppv-10',
        'varppv-11',
        'unbpnv-2',
        'thdppv-8',
        'thdppv-9',
        'thdppv-10',
        'thdppv-11',
        'unbppv-2',
        'actpwr-2',
        'cosphi-2',
        'apparpwr-2',
        'reactpwr-2',
        'phasors-13',
        'phasors-15',
        'phasors-17',
        'phasors-31',
        'phasors-33',
        'phasors-35',
    ], [
        'sf-5',
        'sf-6'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'varlcc-3',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'pwrloss-3',
        'rt-11',
    ], [
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-8',
        'rt-9',
        'rt-10',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
    ]]
}


const TCMTvariables = {
    primary: [[
        'pnv-0',
        'pnv-1',
        'pnv-2',
        'pnv-3',

        'ppv-0',
        'ppv-1',
        'ppv-2',
        'ppv-3',

        'iv-0',
        'iv-1',
        'iv-2',
        'iv-3',

        'vari-0',
        'vari-1',
        'vari-2',
        'vari-3',

        'thdi-0',
        'thdi-1',
        'thdi-2',
        'thdi-3',

        'unbi-0',

        'thdpnv-0',
        'thdpnv-1',
        'thdpnv-2',
        'thdpnv-3',

        'varpnv-0',
        'varpnv-1',
        'varpnv-2',
        'varpnv-3',

        'varppv-0',
        'varppv-1',
        'varppv-2',
        'varppv-3',

        'unbpnv-0',

        'thdppv-0',
        'thdppv-1',
        'thdppv-2',
        'thdppv-3',

        'unbppv-0',

        'actpwr-0',
        'cosphi-0',
        'apparpwr-0',
        'reactpwr-0',

        'phasors-1',
        'phasors-3',
        'phasors-5',
        'phasors-25',
        'phasors-27',
        'phasors-29',
    ], [
        'sf-1',
        'sf-2'
    ]],
    secondary: [[
        'pnv-4',
        'pnv-5',
        'pnv-6',
        'pnv-7',
        'ppv-4',
        'ppv-5',
        'ppv-6',
        'ppv-7',
        'iv-4',
        'iv-5',
        'iv-6',
        'iv-7',
        'vari-4',
        'vari-5',
        'vari-6',
        'vari-7',
        'thdi-4',
        'thdi-5',
        'thdi-6',
        'thdi-7',
        'unbi-1',
        'thdpnv-4',
        'thdpnv-5',
        'thdpnv-6',
        'thdpnv-7',
        'varpnv-4',
        'varpnv-5',
        'varpnv-6',
        'varpnv-7',
        'varppv-4',
        'varppv-5',
        'varppv-6',
        'varppv-7',
        'unbpnv-1',
        'thdppv-4',
        'thdppv-5',
        'thdppv-6',
        'thdppv-7',
        'unbppv-1',
        'actpwr-1',
        'cosphi-1',
        'apparpwr-1',
        'reactpwr-1',
        'phasors-7',
        'phasors-9',
        'phasors-11',
        'phasors-31',
        'phasors-33',
        'phasors-35',
    ], [
        'sf-3',
        'sf-4'
    ]],
    tertiary: [[
        'pnv-8',
        'pnv-9',
        'pnv-10',
        'pnv-11',
        'ppv-8',
        'ppv-9',
        'ppv-10',
        'ppv-11',
        'iv-8',
        'iv-9',
        'iv-10',
        'iv-11',
        'vari-8',
        'vari-9',
        'vari-10',
        'vari-11',
        'thdi-8',
        'thdi-9',
        'thdi-10',
        'thdi-11',
        'unbi-2',
        'thdpnv-8',
        'thdpnv-9',
        'thdpnv-10',
        'thdpnv-11',
        'varpnv-8',
        'varpnv-9',
        'varpnv-10',
        'varpnv-11',
        'varppv-8',
        'varppv-9',
        'varppv-10',
        'varppv-11',
        'unbpnv-2',
        'thdppv-8',
        'thdppv-9',
        'thdppv-10',
        'thdppv-11',
        'unbppv-2',
        'actpwr-2',
        'cosphi-2',
        'apparpwr-2',
        'reactpwr-2',
        'phasors-13',
        'phasors-15',
        'phasors-17',
        'phasors-37',
        'phasors-39',
        'phasors-41',
    ], [
        'sf-5',
        'sf-6'
    ]],
    quaternary: [[
        'pnv-12',
        'pnv-13',
        'pnv-14',
        'pnv-15',
        'ppv-12',
        'ppv-13',
        'ppv-14',
        'ppv-15',
        'iv-12',
        'iv-13',
        'iv-14',
        'iv-15',
        'vari-12',
        'vari-13',
        'vari-14',
        'vari-15',
        'thdi-12',
        'thdi-13',
        'thdi-14',
        'thdi-15',
        'unbi-3',
        'thdpnv-12',
        'thdpnv-13',
        'thdpnv-14',
        'thdpnv-15',
        'varpnv-12',
        'varpnv-13',
        'varpnv-14',
        'varpnv-15',
        'varppv-12',
        'varppv-13',
        'varppv-14',
        'varppv-15',
        'unbpnv-3',
        'thdppv-12',
        'thdppv-13',
        'thdppv-14',
        'thdppv-15',
        'unbppv-3',
        'actpwr-3',
        'cosphi-3',
        'apparpwr-3',
        'reactpwr-3',
        'phasors-19',
        'phasors-21',
        'phasors-23',
        'phasors-43',
        'phasors-45',
        'phasors-47',
    ], [
        'sf-7',
        'sf-8'
    ]],
    general: [[
        'sf-0',
        'freq',
        'ldratio',
        'varlcc-3',
        'tappos',
        'iexc-0',
        'iexc-1',
        'iexc-2',
        'iexc-3',
        'pwrloss-3',
        'rt-15',
    ], [
        'fsv-0',
        'fsv-1',
        'fsv-2',
        'fsv-3',
        'iexc2f',
        'modpar-0',
        'varpar-0',
        'unbIexc-0',
        'tapchanger-2',
        'rc-0',
        'rc-1',
        'rc-2',
        'rc-3',
        'xm-0',
        'xm-1',
        'xm-3',
        'ev-0',
        'ev-1',
        'ev-2',
        'ev-3',
        'variexc-0',
        'variexc-1',
        'variexc-2',
        'variexc-3',
        'lccv-0',
        'lccv-1',
        'lccv-2',
        'lccv-3',
        'lccv-4',
        'unbrel-0',
        'unbrel-1',
        'unbrel-2',
        'pwrloss-0',
        'pwrloss-1',
        'pwrloss-2',
        'rt-0',
        'rt-1',
        'rt-2',
        'rt-3',
        'rt-12',
        'rt-13',
        'rt-14',
        'xm-2',
        'auxdig-0',
        'auxdig-1',
        'auxdig-2',
        'auxdig-3',
        'auxdig-4',
        'auxdig-5',
        'auxdig-6',
        'auxdig-7',
        'auxdig-8',
        'auxdig-9',
        'auxdig-10',
        'auxdig-11',
        'auxdig-12',
        'auxdig-13',
        'auxdig-14',
        'auxdig-15',
        'auxdig-16',
        'auxdig-17',
        'auxdig-18',
        'auxdig-19',
        'auxdig-20',
        'auxdig-21',
        'auxdig-22',
        'auxdig-23',
        'auxdig-24',
        'auxdig-25',
        'auxdig-26',
        'auxdig-27',
        'auxdig-28',
        'auxdig-29',
        'auxdig-30',
    ]]
}


// //TBF
// const TCMQvariables = {
//     primary: [[
//         'pnv-0',
//         'pnv-1',
//         'pnv-2',
//         'pnv-3',
//         'ppv-0',
//         'ppv-1',
//         'ppv-2',
//         'ppv-3',
//         'iv-0',
//         'iv-1',
//         'iv-2',
//         'iv-3',
//         'vari-0',
//         'vari-1',
//         'vari-2',
//         'vari-3',
//         'thdi-0',
//         'thdi-1',
//         'thdi-2',
//         'thdi-3',
//         'unbi-0',
//         'thdpnv-0',
//         'thdpnv-1',
//         'thdpnv-2',
//         'thdpnv-3',
//         'varpnv-0',
//         'varpnv-1',
//         'varpnv-2',
//         'varpnv-3',
//         'varppv-0',
//         'varppv-1',
//         'varppv-2',
//         'varppv-3',
//         'unbpnv-0',
//         'thdppv-0',
//         'thdppv-1',
//         'thdppv-2',
//         'thdppv-3',
//         'unbppv-0',
//         'actpwr-0',
//         'cosphi-0',
//         'apparpwr-0',
//         'reactpwr-0',
//         'phasors-1',
//         'phasors-3',
//         'phasors-5',
//         'phasors-19',
//         'phasors-21',
//         'phasors-23',
//     ], [
//         'sf-1',
//         'sf-2'
//     ]],
//     secondary: [[
//         'pnv-4',
//         'pnv-5',
//         'pnv-6',
//         'pnv-7',
//         'ppv-4',
//         'ppv-5',
//         'ppv-6',
//         'ppv-7',
//         'iv-4',
//         'iv-5',
//         'iv-6',
//         'iv-7',
//         'vari-4',
//         'vari-5',
//         'vari-6',
//         'vari-7',
//         'thdi-4',
//         'thdi-5',
//         'thdi-6',
//         'thdi-7',
//         'unbi-1',
//         'thdpnv-4',
//         'thdpnv-5',
//         'thdpnv-6',
//         'thdpnv-7',
//         'varpnv-4',
//         'varpnv-5',
//         'varpnv-6',
//         'varpnv-7',
//         'varppv-4',
//         'varppv-5',
//         'varppv-6',
//         'varppv-7',
//         'unbpnv-1',
//         'thdppv-4',
//         'thdppv-5',
//         'thdppv-6',
//         'thdppv-7',
//         'unbppv-1',
//         'actpwr-1',
//         'cosphi-1',
//         'apparpwr-1',
//         'reactpwr-1',
//         'phasors-7',
//         'phasors-9',
//         'phasors-11',
//         'phasors-25',
//         'phasors-27',
//         'phasors-29',
//     ], [
//         'sf-3',
//         'sf-4'
//     ]],
//     tertiary: [[
//         'pnv-8',
//         'pnv-9',
//         'pnv-10',
//         'pnv-11',
//         'ppv-8',
//         'ppv-9',
//         'ppv-10',
//         'ppv-11',
//         'iv-8',
//         'iv-9',
//         'iv-10',
//         'iv-11',
//         'vari-8',
//         'vari-9',
//         'vari-10',
//         'vari-11',
//         'thdi-8',
//         'thdi-9',
//         'thdi-10',
//         'thdi-11',
//         'unbi-2',
//         'thdpnv-8',
//         'thdpnv-9',
//         'thdpnv-10',
//         'thdpnv-11',
//         'varpnv-8',
//         'varpnv-9',
//         'varpnv-10',
//         'varpnv-11',
//         'varppv-8',
//         'varppv-9',
//         'varppv-10',
//         'varppv-11',
//         'unbpnv-2',
//         'thdppv-8',
//         'thdppv-9',
//         'thdppv-10',
//         'thdppv-11',
//         'unbppv-2',
//         'actpwr-2',
//         'cosphi-2',
//         'apparpwr-2',
//         'reactpwr-2',
//         'phasors-13',
//         'phasors-15',
//         'phasors-17',
//         'phasors-31',
//         'phasors-33',
//         'phasors-35',
//     ], [
//         'sf-5',
//         'sf-6'
//     ]],
//     general: [[
//         'sf-0',
//         'freq',
//         'ldratio',
//         'varlcc-3',
//         'tappos',
//         'iexc-0',
//         'iexc-1',
//         'iexc-2',
//         'iexc-3',
//         'pwrloss-3',
//         'rt-11',
//     ], [
//         'fsv-0',
//         'fsv-1',
//         'fsv-2',
//         'fsv-3',
//         'iexc2f',
//         'modpar-0',
//         'varpar-0',
//         'unbIexc-0',
//         'tapchanger-2',
//         'rc-0',
//         'rc-1',
//         'rc-2',
//         'rc-3',
//         'xm-0',
//         'xm-1',
//         'xm-3',
//         'ev-0',
//         'ev-1',
//         'ev-2',
//         'ev-3',
//         'variexc-0',
//         'variexc-1',
//         'variexc-2',
//         'variexc-3',
//         'lccv-0',
//         'lccv-1',
//         'lccv-2',
//         'lccv-3',
//         'lccv-4',
//         'unbrel-0',
//         'unbrel-1',
//         'unbrel-2',
//         'pwrloss-0',
//         'pwrloss-1',
//         'pwrloss-2',
//         'rt-0',
//         'rt-1',
//         'rt-2',
//         'rt-3',
//         'rt-8',
//         'rt-9',
//         'rt-10',
//         'xm-2',
//         'auxdig-0',
//         'auxdig-1',
//         'auxdig-2',
//         'auxdig-3',
//         'auxdig-4',
//         'auxdig-5',
//         'auxdig-6',
//         'auxdig-7',
//         'auxdig-8',
//         'auxdig-9',
//         'auxdig-10',
//         'auxdig-11',
//         'auxdig-12',
//     ]]
// }
// //TBF
// const TCMCvariables = {
//     primary: [[
//         'pnv-0',
//         'pnv-1',
//         'pnv-2',
//         'pnv-3',
//         'ppv-0',
//         'ppv-1',
//         'ppv-2',
//         'ppv-3',
//         'iv-0',
//         'iv-1',
//         'iv-2',
//         'iv-3',
//         'vari-0',
//         'vari-1',
//         'vari-2',
//         'vari-3',
//         'thdi-0',
//         'thdi-1',
//         'thdi-2',
//         'thdi-3',
//         'unbi-0',
//         'thdpnv-0',
//         'thdpnv-1',
//         'thdpnv-2',
//         'thdpnv-3',
//         'varpnv-0',
//         'varpnv-1',
//         'varpnv-2',
//         'varpnv-3',
//         'varppv-0',
//         'varppv-1',
//         'varppv-2',
//         'varppv-3',
//         'unbpnv-0',
//         'thdppv-0',
//         'thdppv-1',
//         'thdppv-2',
//         'thdppv-3',
//         'unbppv-0',
//         'actpwr-0',
//         'cosphi-0',
//         'apparpwr-0',
//         'reactpwr-0',
//         'phasors-1',
//         'phasors-3',
//         'phasors-5',
//         'phasors-19',
//         'phasors-21',
//         'phasors-23',
//     ], [
//         'sf-1',
//         'sf-2'
//     ]],
//     secondary: [[
//         'pnv-4',
//         'pnv-5',
//         'pnv-6',
//         'pnv-7',
//         'ppv-4',
//         'ppv-5',
//         'ppv-6',
//         'ppv-7',
//         'iv-4',
//         'iv-5',
//         'iv-6',
//         'iv-7',
//         'vari-4',
//         'vari-5',
//         'vari-6',
//         'vari-7',
//         'thdi-4',
//         'thdi-5',
//         'thdi-6',
//         'thdi-7',
//         'unbi-1',
//         'thdpnv-4',
//         'thdpnv-5',
//         'thdpnv-6',
//         'thdpnv-7',
//         'varpnv-4',
//         'varpnv-5',
//         'varpnv-6',
//         'varpnv-7',
//         'varppv-4',
//         'varppv-5',
//         'varppv-6',
//         'varppv-7',
//         'unbpnv-1',
//         'thdppv-4',
//         'thdppv-5',
//         'thdppv-6',
//         'thdppv-7',
//         'unbppv-1',
//         'actpwr-1',
//         'cosphi-1',
//         'apparpwr-1',
//         'reactpwr-1',
//         'phasors-7',
//         'phasors-9',
//         'phasors-11',
//         'phasors-25',
//         'phasors-27',
//         'phasors-29',
//     ], [
//         'sf-3',
//         'sf-4'
//     ]],
//     tertiary: [[
//         'pnv-8',
//         'pnv-9',
//         'pnv-10',
//         'pnv-11',
//         'ppv-8',
//         'ppv-9',
//         'ppv-10',
//         'ppv-11',
//         'iv-8',
//         'iv-9',
//         'iv-10',
//         'iv-11',
//         'vari-8',
//         'vari-9',
//         'vari-10',
//         'vari-11',
//         'thdi-8',
//         'thdi-9',
//         'thdi-10',
//         'thdi-11',
//         'unbi-2',
//         'thdpnv-8',
//         'thdpnv-9',
//         'thdpnv-10',
//         'thdpnv-11',
//         'varpnv-8',
//         'varpnv-9',
//         'varpnv-10',
//         'varpnv-11',
//         'varppv-8',
//         'varppv-9',
//         'varppv-10',
//         'varppv-11',
//         'unbpnv-2',
//         'thdppv-8',
//         'thdppv-9',
//         'thdppv-10',
//         'thdppv-11',
//         'unbppv-2',
//         'actpwr-2',
//         'cosphi-2',
//         'apparpwr-2',
//         'reactpwr-2',
//         'phasors-13',
//         'phasors-15',
//         'phasors-17',
//         'phasors-31',
//         'phasors-33',
//         'phasors-35',
//     ], [
//         'sf-5',
//         'sf-6'
//     ]],
//     general: [[
//         'sf-0',
//         'freq',
//         'ldratio',
//         'varlcc-3',
//         'tappos',
//         'iexc-0',
//         'iexc-1',
//         'iexc-2',
//         'iexc-3',
//         'pwrloss-3',
//         'rt-11',
//     ], [
//         'fsv-0',
//         'fsv-1',
//         'fsv-2',
//         'fsv-3',
//         'iexc2f',
//         'modpar-0',
//         'varpar-0',
//         'unbIexc-0',
//         'tapchanger-2',
//         'rc-0',
//         'rc-1',
//         'rc-2',
//         'rc-3',
//         'xm-0',
//         'xm-1',
//         'xm-3',
//         'ev-0',
//         'ev-1',
//         'ev-2',
//         'ev-3',
//         'variexc-0',
//         'variexc-1',
//         'variexc-2',
//         'variexc-3',
//         'lccv-0',
//         'lccv-1',
//         'lccv-2',
//         'lccv-3',
//         'lccv-4',
//         'unbrel-0',
//         'unbrel-1',
//         'unbrel-2',
//         'pwrloss-0',
//         'pwrloss-1',
//         'pwrloss-2',
//         'rt-0',
//         'rt-1',
//         'rt-2',
//         'rt-3',
//         'rt-8',
//         'rt-9',
//         'rt-10',
//         'xm-2',
//         'auxdig-0',
//         'auxdig-1',
//         'auxdig-2',
//         'auxdig-3',
//         'auxdig-4',
//         'auxdig-5',
//         'auxdig-6',
//         'auxdig-7',
//         'auxdig-8',
//         'auxdig-9',
//         'auxdig-10',
//         'auxdig-11',
//         'auxdig-12',
//     ]]
// }


export const TCMpermissions = {
    "electric_param": "005",
    "load_diagram": "00c",
    "tap_changer": "011",
    "power_quality": "012",
    "vector_diagram": "017",
    "waveforms": "01c",
    "global_status": "021",
    "excitation": "026",
    "tap_changer_diag": "028",
    "transformation_ratio": "029",
    "short_circuit": "02b",
    "harmonics": "030",
    "dynamic_impedance": "035",
    "inrush": "036",
}

export const getVariablesAdmin = (isTriple?: boolean, isQuad?: boolean): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    var five: any;
    if (isQuad) {
        one = TCMTvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
        one = one.reduce((a: any, b: any) => [...a, ...b], [])
        two = TCMTvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
        two = two.reduce((a: any, b: any) => [...a, ...b], [])
        three = TCMTvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
        three = three.reduce((a: any, b: any) => [...a, ...b], [])
        four = TCMTvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
        four = four.reduce((a: any, b: any) => [...a, ...b], [])
        five = TCMTvariables.quaternary.map((arr, indexArr) => arr.map((val, index) => val))
        five = five.reduce((a: any, b: any) => [...a, ...b], [])
        return [...one, ...two, ...four, ...five, ...three]
    } else if (isTriple) {
        one = TCMDvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
        one = one.reduce((a: any, b: any) => [...a, ...b], [])
        two = TCMDvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
        two = two.reduce((a: any, b: any) => [...a, ...b], [])
        three = TCMDvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
        three = three.reduce((a: any, b: any) => [...a, ...b], [])
        four = TCMDvariables.tertiary.map((arr, indexArr) => arr.map((val, index) => val))
        four = four.reduce((a: any, b: any) => [...a, ...b], [])
        return [...one, ...two, ...four, ...three]
    } else {
        one = TCMvariables.primary.map((arr, indexArr) => arr.map((val, index) => val))
        one = one.reduce((a: any, b: any) => [...a, ...b], [])
        two = TCMvariables.secondary.map((arr, indexArr) => arr.map((val, index) => val))
        two = two.reduce((a: any, b: any) => [...a, ...b], [])
        three = TCMvariables.general.map((arr, indexArr) => arr.map((val, index) => val))
        three = three.reduce((a: any, b: any) => [...a, ...b], [])
        return [...one, ...two, ...three]
    }
}

export const getVariables = (isTriple?: boolean, isQuad?: boolean): Array<string> => {
    var one: any;
    var two: any;
    var three: any;
    var four: any;
    var five: any;
    if (isQuad) {
        one = TCMTvariables.primary[0].map((val, index) => { return val })
        two = TCMTvariables.secondary[0].map((val, index) => { return val })
        three = TCMTvariables.general[0].map((val, index) => { return val })
        four = TCMTvariables.tertiary[0].map((val, index) => { return val })
        five = TCMTvariables.quaternary[0].map((val, index) => { return val })
        return [...one, ...two, ...four, ...five, ...three]
    } else if (isTriple) {
        one = TCMDvariables.primary[0].map((val, index) => { return val })
        two = TCMDvariables.secondary[0].map((val, index) => { return val })
        three = TCMDvariables.general[0].map((val, index) => { return val })
        four = TCMDvariables.tertiary[0].map((val, index) => { return val })
        return [...one, ...two, ...four, ...three]
    } else {
        one = TCMvariables.primary[0].map((val, index) => { return val })
        two = TCMvariables.secondary[0].map((val, index) => { return val })
        three = TCMvariables.general[0].map((val, index) => { return val })
        return [...one, ...two, ...three]
    }
}
