import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    subTitleCardBox: {
        backgroundColor: "white",
        boxShadow: "-1px 2px 4px 1px rgb(0 0 0 / 23%)",
        borderRadius: 10,
        // width: "100%",
        left: 0,
        right: 0,
        height: "100%",
    },
    noShadow: {
        width: "calc(100% - 8px)",
        boxShadow: "unset",
        margin: "4px"
    },
    subtitlecardtitle: {
        paddingLeft: "20px",
        paddingTop: "15px",
        height: "38px",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "16px",
        color: theme.palette.primary.main,
    },
    smallTitle: {
        fontSize: 17
    },
    subtitlecardmain: {
        height: (props) => (props as any).title ? "calc(100% - 38px)" : "100%",
        width: "100%",
        overflow: 'hidden'
    },
    subtitlecardtitlebigger: {
        paddingLeft: "20px",
        paddingTop: "11px",
        height: "38px",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "16px",
        color: theme.palette.primary.main,
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    }
}
));

interface SubTitleCardProps {
    title?: string,
    gridArea?: string,
    noShadow?: boolean,
    buttons?: any,
    divclass?: string,
    flexCenter?: boolean,
    removeFirstNWords?: number
}

export default function SubTitledCard(props: PropsWithChildren<SubTitleCardProps>) {
    const { title, children, gridArea, noShadow, buttons, divclass, flexCenter, removeFirstNWords } = props;
    const classes = useStyles(props)
    const intl = useIntl()
    let titleTranslated = intl.formatMessage({ id: title ? title : 'empty' })
    if (removeFirstNWords && titleTranslated.split(" ").length > removeFirstNWords) {
        titleTranslated = titleTranslated.split(" ").splice(removeFirstNWords).join(" ")
    }

    return (
        // eslint-disable-next-line no-useless-concat
        <div className={clsx(classes.subTitleCardBox, noShadow && classes.noShadow, divclass)} style={{ gridArea: `${gridArea}` }}>
            {title && <div className={clsx(buttons && classes.subtitlecardtitlebigger, !buttons && classes.subtitlecardtitle, titleTranslated.length > 24 && classes.smallTitle)}>{titleTranslated}{buttons}</div>}
            <div className={clsx(classes.subtitlecardmain, flexCenter && classes.flexCenter)}>{children}</div>
        </div>
    );
}