export interface User {
  _id: string;
  organization_id: string;
  devices_id: Array<string>;
  name: string;
  email: string;
  role: number;
  views: string[];
  pexp: string,
  fail_attempts: number,
  active: Boolean;
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface UserForm {
  organization_id: string;
  devices_id: Array<string>;
  name: string;
  email: string;
  role: number;
  password?: string;
}

export interface CurrentUser {
  id: string;
  name: string;
  email: string;
  assigned_devices: Array<string>;
  views: string[];
  reset: boolean,
  token: string;
  role: number;
  organization_id: string;
}

export enum Roles {
  ADMIN,
  ORGADMIN,
  USER,
  SPECTATOR,
  PENDING_MFA
}
