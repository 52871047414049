
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import LongDataBox from '../../../components/device/middle/LongDataBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forHarmonicsICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateH: {
      gridTemplateAreas: `
          "gr1"
          "gr2"`,
      gridTemplateRows: "16fr 1fr",
      gridTemplateColumns: "100%",
      maxHeight: "799px",
      height: "630px",
      gridGap: "6px",
      padding: "10px"
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    }
  }),
);
//MAYBE I CAN KEEP ALL THE GRAPHS AND JUST CHANGE IF THEY DISPLAY OR NOT

interface HarmonicsProps {

}

export default function Harmonics(props: React.PropsWithChildren<HarmonicsProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceReport } = useContext(EntitiesContext);
  const [parityPrimaryCurrent, setParityPrimaryCurrent] = useState<boolean>(false);
  const [parityPrimaryVoltage, setParityPrimaryVoltage] = useState<boolean>(false);
  const [paritySecondaryCurrent, setParitySecondaryCurrent] = useState<boolean>(false);
  const [paritySecondaryVoltage, setParitySecondaryVoltage] = useState<boolean>(false);
  const data = forHarmonicsICM(deviceReport);
  const changeParity = (side: number, current: number) => {
    if (side === 0) {
      if (current === 0) {
        setParityPrimaryCurrent(!parityPrimaryCurrent)
      } else {
        setParityPrimaryVoltage(!parityPrimaryVoltage)
      }
    } else {
      if (current === 0) {
        setParitySecondaryCurrent(!paritySecondaryCurrent)
      } else {
        setParitySecondaryVoltage(!paritySecondaryVoltage)
      }
    }
  };
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateH} >
          <SubTitledCard gridArea='gr1' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(0, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              ys={[data[0][0][(parityPrimaryCurrent ? 'even' : 'odd')].y]}
              x={data[0][0][(parityPrimaryCurrent ? 'even' : 'odd')].x}
              ysnames={[['ppv-0-icm', 'ppv-1-icm', 'ppv-2-icm']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2'>
            <LongDataBox
              parts={[
                { title: 'Vuv1:', value: si_format(data[0][0].one[0], 'A') },
                { title: 'Vvw1:', value: si_format(data[0][0].one[1], 'A') },
                { title: 'Vwu1:', value: si_format(data[0][0].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






