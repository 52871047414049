
import { Grid, isWidthDown, makeStyles, withWidth } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'; // TYPES ESTAO NESTE REPOSITORIO E NAO VEM NO PAKCAGE
import React, { useContext, useEffect, useState } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SmallTable from '../../../components/device/final/SmallTable';
import TitledCard from '../../../components/device/middle/TitledCard';
import { DeviceType, DeviceWaveforms } from '../../../interfaces/Device';
import { ListResponse, SingleResponse } from '../../../interfaces/Responses';
import { EntitiesContext } from "../../../contexts/entities-context";
import APIRequestContext from '../../../contexts/api-context';


const useStyles = () => {
  return makeStyles((theme) => ({
    fixedWidth: {
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        maxWidth: 'unset',
      },
      maxWidth: 100
    },
    remainingWidth: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: "calc(100% - 100px)"
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        width: "100%"
      },
      maxWidth: "calc(100% - 100px)"
    },
    formControl: {
      margin: theme.spacing(1),
      width: "216px"
    },
  }
  ))();
}

interface TCMProps {
  isTCMD?: boolean;
}

export default withWidth()(function Inrush(props: React.PropsWithChildren<TCMProps>) {
  const { isTCMD } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const [id, setId] = useState<string>()
  const [graph, setGraph] = useState<DeviceWaveforms>()
  const [loading, setLoading] = useState<boolean>(true)
  const { apiRequest } = useContext(APIRequestContext);
  const classes = useStyles()
  const [table, setTable] = useState<Array<any>>()
  const addHours = (value: Date, hours: number) => {
    var newDate = new Date()
    newDate.setTime(value.getTime() + hours * 60 * 60 * 1000)
    return newDate
  }
  const [value, setValue] = useState<[Date, Date]>([addHours(new Date(), -(24 * 31)), new Date()]);

  const getReports = (since: Date, until: Date, device_id: string | undefined) => {
    setLoading(true);
    apiRequest<ListResponse<any>>("GET", "api/v1/report/", {
      query: {
        type: isTCMD ? DeviceType.OLd : DeviceType["OL"],
        device_id: device_id,
        since: since.toISOString(),
        until: until.toISOString(),
      }
    })
      .then(result => {
        if (result.values.length === 0) setLoading(false);
        const table = result.values.map(value => {
          return value
        });
        setTable(table);
        getGraph(table[0]?.graph_id)
      })
      .catch(error => { setTable(undefined) });
  }

  const getGraph = (graph_id: string | undefined) => {
    if (graph_id && graph_id !== id) {
      setLoading(true);
      apiRequest<SingleResponse<any>>("GET", "api/v1/graph/" + graph_id)
        .then(result => { setGraph(result.value.waveforms); setId(result.value._id); setLoading(false); })
        .catch(error => { setGraph(undefined); setLoading(false); });
    } else if (graph_id === id) {
      setLoading(false);
    }
  }

  const updateList = (date: [Date, Date]) => {
    getReports(date[0], date[1], deviceFull?._id)
    setValue(date)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getReports(addHours(new Date(), -(24 * 7)), new Date(), deviceFull?._id) }, [])


  return (
    <div>
      <DateRangePicker
        className={classes.formControl}
        onChange={updateList}
        value={value}
        calendarIcon={<TodayIcon />}
        clearIcon={null}
        required
      />
      <Grid container>
        <Grid key={`titledcard-1`} className={classes.fixedWidth} item sm={isWidthDown('xs', (props as any).width) ? 12 : 1}>
          <SmallTable
            selected={id}
            list={table}
            id={["timestamp"]}
            label={['timestamp']}
            listButtons={table ? table.map(value => () => getGraph(value.graph_id)) : []}
            removeLabels={true}
          />
        </Grid>
        <Grid key={`titledcard-2`} className={classes.remainingWidth} item md={isWidthDown('xs', (props as any).width) ? 12 : true}>
          <TitledCard fillDiv forceHeight={isWidthDown('sm', (props as any).width) ? 400 : 602} title={'inrushGraph'} >
            <DraggableGraph
              x={graph ? graph.time.map((value: string) => parseFloat(value)) : []}
              ys={[!loading ? graph ? graph.wPNv ? graph.wPNv.map(arr => arr.map((value: string) => parseFloat(value))) : [[]] : [[]] : [undefined!]]}
              titley={['inrushUnits']}
              titlex={''}
              ysnames={[['0', '1', '2']]}
              graphType={'linesgl'}
              colors={[['#e23434', '#2aa060', '#3473ba']]}
              notTimezone
            />
          </TitledCard>
        </Grid>
      </Grid >
    </div>
  );
})






