import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, useTheme, withStyles } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CommonTime from '../../global/CommonTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: '#80808066'
    },
    table: {
      minWidth: 100
    },
    outerTable: {
      margin: 4,
      width: "calc(100% - 8px)",
      maxHeight: "100%",
      borderRadius: "10px",
      overflow: 'hidden'
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    circle: {
      marginRight: "5px",
      height: "15px",
      width: "15px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    },
    ballCell: {
      alignItems: "center",
      display: "flex"
    },
    link: {
      textDecoration: "unset",
      color: "black"
    },
    tablesortlabel: {
      width: "36px",
      overflow: "hidden"
    }
  }),
);

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    iconbutton: {
      padding: '3px'
    }
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      //width: "30%"

    },
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontSize: 12,
      padding: "4px 0px 4px 7px"
    },
    body: {
      fontSize: 14,
      color: theme.palette.common.black,
      padding: "4px 0px 4px 7px"
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
      '&:hover': {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[400]
      }
    },
  }),
)(TableRow);

const StyledTableRowFooter = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black
    },
  }),
)(TableRow);

const StyledTablePagination = withStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: "30px !important",
      width: '100%'
    }
  }),
)(TablePagination);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.iconbutton}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        className={classes.iconbutton}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
}



interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  id: Array<string>;
  label: Array<string>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, id, label } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const headCells: HeadCell[] = id.map((value, index) => { return { id: value, numeric: false, disablePadding: true, label: label[index] } })

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={`tablecell-${headCell.id}`}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tablesortlabel}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <FormattedMessage id={headCell.label} />
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


















interface SmallTableProps {
  list: Array<any> | undefined;
  id: Array<string>;
  selected?: string;
  label: Array<string>;
  listButtons: Array<any>;
  removeLabels?: boolean;
}

export default function SmallTable(props: SmallTableProps) {
  const classes = useStyles();
  const { list, id, label, listButtons, removeLabels, selected } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('status');

  useEffect(() => { setPage(0) }, [list])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /*                                                                                               ---------------------------FALTA LCOALE PARA TEMPO  */
  return (
    <TableContainer component={Paper} className={classes.outerTable}>
      {list !== undefined && <Table className={classes.table} size="small" aria-label="a dense table">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={list.length}
          id={id}
          label={label}
        />
        <TableBody>
          {stableSort(list, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <StyledTableRow onClick={listButtons[page * rowsPerPage + index]} className={clsx(row.graph_id === selected && classes.selected)} key={`table-row-${index}`}>
                  {id.map((value, index) => {
                    return <StyledTableCell width={30} key={'styledTableCell-' + index} component="th" scope="row">{value === 'timestamp' ?
                      (<div style={{ width: 62, overflowWrap: "anywhere" }}>
                        <CommonTime separate ms val={row[value] ? (row[value] as unknown as string) : '0'}></CommonTime>
                      </div>) :
                      row[value]}
                    </StyledTableCell>
                  })}
                </StyledTableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <StyledTableRowFooter>
            <StyledTablePagination
              rowsPerPageOptions={[]}
              colSpan={4}
              labelDisplayedRows={removeLabels ? () => { return '' } : undefined}
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </StyledTableRowFooter>
        </TableFooter>
      </Table>}
    </TableContainer>
  );
}
