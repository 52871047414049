import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { PropsWithChildren, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { EntitiesContext } from "../../contexts/entities-context";

const useStyles = makeStyles((theme) => ({
    SubNavBarList: {
        overflow: 'hidden',
        display: "flex",
        flexDirection: "row",
        minHeight: "50px",
        height: "67px",
        padding: "3px 0 0 0",
        rowGap: 0,
        background: theme.palette.primary.dark,
        marginBottom: "8px",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderBottom: "hsla(0,0%,50.2%,.2196078431372549)",
        borderBottomStyle: "solid",
        borderWidth: ".4px",
        listStyleType: "none",
        transform: "translate(-8px, 0px)",
        width: (props) => (props as any).scrollHasNavbar ? "calc(100% + 8px)" : "calc(100% + 16px)"
    },
    SubNavBarBox: {
        height: "fit-content",
        padding: "4px !important"
    },
    buttonBox: {
        "&:hover": {
            cursor: "pointer"
        },
        borderRadius: "10px",
        display: "flex",
        height: "58px",
        placeContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "unset",
        padding: "10px 15px"
    },
    subButtonBox1: {
        backgroundColor: "white",
    },
    subButtonBox2: {
        backgroundColor: "transparent",
    },
    boxTitle: {
        [theme.breakpoints.up('sm')]: {
            height: "fit-content",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "uppercase",
            lineHeight: "20px",
            textAlign: "center"
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    boxImage: {
        display: "block",
        padding: "15px 0",
        width: "30px",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginLeft: 0
        }
    },
    subBoxTitle1: {
        color: theme.palette.primary.main,
    },
    subBoxTitle2: {
        color: "white"
    },
}));

interface SubNavBarProps {
    buttons: Array<{
        name: string,
        icon: JSX.Element,
        altIcon: JSX.Element,
        link: string
    }>,
    setActive: any,
    active: any
    scrollHasNavbar?: any
}

export default function SubNavBar(props: PropsWithChildren<SubNavBarProps>) {
    const classes = useStyles(props);
    const { buttons, setActive, active, children } = props
    const { deviceFull } = useContext(EntitiesContext);
    const history = useHistory();

    const handleButtonClick = (index: any) => {
        setActive(index)
    };

    //useEffect(() => {
    //    console.log(device)
    //}, [device]);


    return (
        <ul className={classes.SubNavBarList}>
            {children &&
                children
            }
            {buttons.map((button, index) =>
                <li key={`subnavbar-${index}`} style={{ width: `calc(${100 / (children ? buttons.length + 1 : buttons.length)}%)` }} className={classes.SubNavBarBox}>
                    <span className={active === index ? classes.buttonBox + ' ' + classes.subButtonBox1 : classes.buttonBox + ' ' + classes.subButtonBox2} onClick={() => { history.push(button.link.includes('@id') ? button.link.replace('@id', `${deviceFull?._id}`) : button.link); handleButtonClick(index); }}>
                        <p className={active === index ? classes.boxTitle + ' ' + classes.subBoxTitle1 : classes.boxTitle + ' ' + classes.subBoxTitle2}>
                            <FormattedMessage id={button.name} />
                        </p>
                        {active === index && button.icon}
                        {!(active === index) && button.altIcon}
                    </span>
                </li>
            )}
        </ul>
    );
}