import { Fade, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import APIRequestContext from '../../contexts/api-context';
import { ToastContext } from "../../contexts/toast-context";
import { AlarmOccurrenceCondition, received } from '../../interfaces/Alerts';
import { DeviceList, DeviceType } from '../../interfaces/Device';
import { VariablesResponse } from '../../interfaces/Responses';
import { CurrentUser, User } from '../../interfaces/User';
import { si_format } from '../../utils/format-data';
import DraggableGraph from '../device/final/DraggableGraph';
import EntityDialog from '../entities/EntityDialog';
import CommonTime from '../global/CommonTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%"
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    circle: {
      marginRight: "5px",
      height: "25px",
      width: "25px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%",
      alignSelf: 'center'
    },
    circleDiv: {
      display: 'grid',
      width: 403,
      gridTemplateColumns: "0.07fr 0.9fr",
    },
    title: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: 14,
      fontWeight: 500,
    }
  }),
);

interface AlertInfoDialogProps {
  open: boolean;
  handleClose: (alarm?: received) => void;
  alarm: received
  users: User[]
  handleVerify: (alarm_id: string, user_id: string, user_name: string) => void;
  devices: Array<DeviceList>;
}

export default function AlertInfoDialog(props: AlertInfoDialogProps) {
  const { open, handleClose, users, alarm, handleVerify, devices } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { displayToast } = useContext(ToastContext);
  const { apiRequest } = useContext(APIRequestContext);
  const [history, setHistory] = React.useState<any>();
  const [graph, setGraph] = React.useState<any>({ ys: [], titley: [], ysnames: [] });
  const [high, setHigh] = React.useState<number>();
  const cu = localStorage.getItem("user");
  var currentUser: CurrentUser = JSON.parse(cu ? cu : '');
  const type = devices.find(d => d._id === alarm.device.id)?.type
  const append = type === DeviceType.Ld || type === DeviceType.Td ?
    "-triple" :
    type === DeviceType.I ?
      "-icm" :
      type === DeviceType.M ?
        "-mcm" :
        type === DeviceType.Gi ?
          "-gcm" :
          "";

  useEffect(() => {
    const doubleCheckValue = (conditions: AlarmOccurrenceCondition[], index: number, vars: any, average: number): number => {
      var indexRet = -1;
      conditions.forEach(cond => {
        if (Math.abs(getAverageFromArrayBeforeIndex(vars[cond.field], index, average) - cond.value) < 0.00001) indexRet = index;
        else if (Math.abs(getAverageFromArrayBeforeIndex(vars[cond.field], index + 1, average) - cond.value) < 0.00001) indexRet = index + 1;
        else if (Math.abs(getAverageFromArrayBeforeIndex(vars[cond.field], index - 1, average) - cond.value) < 0.00001) indexRet = index - 1;
        else if (Math.abs(getAverageFromArrayBeforeIndex(vars[cond.field], index - 2, average) - cond.value) < 0.00001) indexRet = index - 2;
      })
      return indexRet
    }

    const getAverageFromArrayBeforeIndex = (ar: Array<any>, index: number, samples: number): number => {
      const iter = Array.from({ length: samples }, (x, i) => i);   //0,1,2,...
      const sum = iter.reduce((prev, current) => { return ar[index - current] + prev }, 0)
      //console.log(sum / samples, iter)
      return sum / samples
    }
    const addDays = (date: Date, days: number) => {
      return new Date(date.getTime() + days * 86400000)
    }
    const getReportHistory = (device_id: string, middate: string, conds: AlarmOccurrenceCondition[]) => {
      const mid = new Date(middate)
      var variables = alarm.alarm.conditions.map(al => al.field).concat('timestamp')
      let uniqueVars: string[] = [];
      variables.forEach((c) => {
        if (!uniqueVars.includes(c)) {
          uniqueVars.push(c);
        }
      });
      //console.log(type, devices)
      if (type) {
        apiRequest<VariablesResponse<{ timestamp: string[] }>>("GET", "api/v1/report/", { query: { type: type, device_id: device_id, since: addDays(mid, -0.5).toISOString(), until: addDays(mid, 0.5).toISOString(), variables: uniqueVars } })
          .then(result => {
            if (result.success) {
              setHistory(result.values);
              setGraph({
                ys: [Object.keys(result.values).filter(key => key !== 'timestamp').map(key => result.values[(key as 'timestamp')])],
                titley: Object.keys(result.values).filter(key => key !== 'timestamp').map(v => v.concat(append)),
                ysnames: [Object.keys(result.values).filter(key => key !== 'timestamp').map(v => v.concat(append))]
              })
              const h = result.values.timestamp.findIndex((time: string) => dateCloseEnough(time, (alarm.created_at as unknown as string)));
              const h2 = doubleCheckValue(conds, h, result.values, alarm.alarm.samples_mean)
              setHigh(h2)
            }
          })
          .catch(error => { setHistory(undefined) });
      }
    }
    getReportHistory(alarm.device.id, (alarm.created_at as unknown as string), alarm.alarm.conditions);
  }, [alarm, apiRequest, append, devices, type])

  const handleSubmit = () => {
    //REMOVER VAZIOS
    apiRequest("PUT", "api/v1/alarm-occurrence/" + alarm._id)
      .then(result => {
        displayToast('info', 'alarmVerified', 'nothing');
      })
      .catch(error => {
        displayToast('error', 'alarmNotVerified', 'nothing')
      });
    handleVerify(alarm._id, currentUser.id, currentUser.email)
  }

  const dateCloseEnough = (date1: string, date2: string): boolean => {
    const d1 = new Date(date1)
    const d2 = new Date(date2)
    if (Math.abs(d1.getTime() - d2.getTime()) < 170000) {
      return true
    }
    return false;
  }


  return (
    <Fade in={open}>
      <EntityDialog
        open={open}
        title={<div className={classes.circleDiv}>
          <span className={classes.circle} style={{ backgroundColor: alarm.alarm.color }}></span>
          <Typography className={classes.title}>{alarm.alarm.name}</Typography>
          <span></span>
          <Typography className={classes.subtitle}>{alarm.device.name}</Typography>
        </div>}
        handleClose={() => handleClose()}
        handleSubmit={alarm.acknowledged_user ? undefined : () => handleSubmit()}
        submitTextUntranslated={'verify'}
        cancelTextUntranslated={'close'}
      >
        <>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography>{intl.formatMessage({ id: 'message' })}</Typography>
              <Typography>{alarm.alarm.message}</Typography>
            </Grid>
            <Grid style={{ alignSelf: "center" }} item xs={4}>
              <Typography>{intl.formatMessage({ id: 'users' })}</Typography>
              <Typography>{alarm.users_id.map(uid => users.find(user => user._id === uid)?.name).join(', ')}</Typography>
            </Grid>
            <Grid style={{ alignSelf: "center" }} item xs={4}>
              <Typography>{intl.formatMessage({ id: 'averageSamples' })}</Typography>
              <Typography>{alarm.alarm.samples_mean}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography>{intl.formatMessage({ id: 'activationTime' })}</Typography>
              <Typography><CommonTime val={alarm.created_at.toISOString ? alarm.created_at.toISOString() : (alarm.created_at as unknown as string)}></CommonTime></Typography>
            </Grid>
            <Grid style={{ alignSelf: "center" }} item xs={4}>
              <Typography>{intl.formatMessage({ id: 'conditions' })}</Typography>
              {alarm.alarm.conditions.map((condition, index) =>
                <Typography key={`titlealert-${index}`}><FormattedMessage id={type === DeviceType.I ? condition.field.concat('-icm') : type === DeviceType.M ? condition.field.concat('-mcm') : type === DeviceType.Gi ? condition.field.concat('-gcm') : condition.field} />: {si_format(condition.value, undefined, 3, true)} {condition.operator === 0 ? '>' : (condition.operator as unknown as number) === 1 ? '<' : '='} {condition.threshold}</Typography>)
              }
            </Grid>
          </Grid>

          {history && high !== undefined && high !== -1 && <Grid style={{ height: 300 }} container spacing={2}>
            <DraggableGraph x={history.timestamp}
              ys={graph.ys}
              graphType='linesgl'
              titlex=''
              titley={graph.titley}
              ysnames={graph.ysnames}
              colors=''
              highlight={high}
            />
          </Grid>}


        </>
      </EntityDialog >
    </Fade>
  );
}