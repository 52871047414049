import { IntlShape } from 'react-intl';
import { gaugeFinal2 } from '../components/device/final/RoundGauge';
import { SmallCornerBoxParts } from '../components/device/middle/SmallCornerBox';
import { DeviceFull, DeviceFullExpanded, DeviceList, DeviceListExpanded, DeviceStatus, DeviceType } from '../interfaces/Device';
import { ReportList } from '../interfaces/Report';
import { SubstationList, SubstationType } from '../interfaces/Substations';
import { CurrentUser, Roles, User } from '../interfaces/User';
import { gaugeMid } from '../pages/icm/tabs/ElectricParam';
import { gaugeProps } from '../pages/mcm/tabs/ElectricParam';
import { getICMVariables, getICMVariablesAdmin, getICM_I_Variables, getICM_I_VariablesAdmin, icmOrder, ICMpermissions } from '../utils/icm-functions';
import { getLPTCMCVariables, getLPTCMCVariablesAdmin, getLPTCMQVariables, getLPTCMQVariablesAdmin, getLPTCMTVariables, getLPTCMTVariablesAdmin, getLPTCMVariables, getLPTCMVariablesAdmin, LPTCMpermissions } from '../utils/lptcm-functions';
import { getVariables as getMCMVariables, getVariablesAdmin as getMCMVariablesAdmin, mcmOrder, MCMpermissions } from '../utils/mcm-functions';
import { getVariables, getVariablesAdmin, TCMpermissions } from '../utils/tcm-functions';
import { gcmOrder, GCMpermissions } from './gcm-functions';
import permissionBetter from './view-permissions';
import {format as d3Format} from "d3-format"

type sptype = { sF_limit1: number, sF_limit2: number, ld_limit1: number, ld_limit2: number, }
export type sfNewFormat = { value: number | undefined, color: string, name: string, level: number | undefined }
export type sfGroup = { maxIndex: number, sfs: Array<sfNewFormat> }
const ldIndex = 4;
export const levelColor = { "NOTSENDING": "grey", "EMPTY": "#00B1FF", "ZERO": '#3473ba', 0: '#2aa060', 1: '#ffc000', 2: '#e23434' }

const intersectArray = (arr1: Array<string>, arr2: Array<string>): Array<string> => {
    // console.log(arr2.filter(v => arr1.find(v2 => v.startsWith(v2) !== undefined)))
    return arr2.filter(v => arr1.find(v2 => v.startsWith(v2) !== undefined));
}

/**
 *
 *
 * @param {DeviceType} device_type
 * @param {(CurrentUser | User)} [currentUser]
 * @param {boolean} [extra] in case icm has voltages and currents or lptcm has voltages
 * @return {string[]} List of variables as strings
 */
export const getVariablesByRoleAndTypeAndViews = (device_type: DeviceType, currentUser?: CurrentUser | User, extra?: boolean) => {
    let v: string[] = []
    if (device_type === DeviceType["T"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    getVariablesAdmin() :
                    intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables()) :
                intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables()) :
            []
    } else if (device_type === DeviceType["Td"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    getVariablesAdmin(true) :
                    intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(true)) :
                intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(true)) :
            []
    } else if (device_type === DeviceType["L"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    !extra ? getLPTCMVariablesAdmin() : getVariablesAdmin() :
                    !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables()) :
                !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables()) :
            []
    } else if (device_type === DeviceType["Ld"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    !extra ? getLPTCMVariablesAdmin(true) : getVariablesAdmin(true) :
                    !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMVariables(true)) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(true)) :
                !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMVariables(true)) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(true)) :
            []
    } else if (device_type === DeviceType["L3"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    !extra ? getLPTCMTVariablesAdmin() : getVariablesAdmin(false, true) :
                    !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMTVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(false, true)) :
                !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMTVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getVariables(false, true)) :
            []
    } else if (device_type === DeviceType["L4"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    getLPTCMQVariablesAdmin() :
                    intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMQVariables()) :
                intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMQVariables()) :
            []
    } else if (device_type === DeviceType["L5"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    getLPTCMCVariablesAdmin() :
                    intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMCVariables()) :
                intersectArray(getVariablesPrefixFromViews(currentUser.views), getLPTCMCVariables()) :
            []
    } else if (device_type === DeviceType["I"]) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    !extra ? getICMVariablesAdmin() : getICM_I_VariablesAdmin() :
                    !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getICMVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getICM_I_Variables()) :
                !extra ? intersectArray(getVariablesPrefixFromViews(currentUser.views), getICMVariables()) : intersectArray(getVariablesPrefixFromViews(currentUser.views), getICM_I_Variables()) :
            []
    } else if (device_type === DeviceType["M"] || device_type === DeviceType["Gi"] || device_type === DeviceType['Gc'] || device_type === DeviceType['Gs'] || device_type === DeviceType['Gm']) {
        v = currentUser ?
            (currentUser as any).role !== undefined ?
                (currentUser as any).role === Roles.ADMIN ?
                    getMCMVariablesAdmin() :
                    intersectArray(getVariablesPrefixFromViews(currentUser.views), getMCMVariables()) :
                intersectArray(getVariablesPrefixFromViews(currentUser.views), getMCMVariables()) :
            []
    }
    return v
}


export const calculateSFLevel = (sp: sptype, sfs: number[]): 0 | 1 | 2 => {
    let max: 0 | 1 | 2 = 0
    sfs.forEach((sf, index) => {
        if (index < ldIndex) {
            max = sf < sp.sF_limit1 ? 0 : sf < sp.sF_limit2 ? 1 : 2
        } else {
            max = sf < sp.ld_limit1 ? 0 : sf < sp.ld_limit2 ? 1 : 2
        }
    })
    return max
}



export const calculateSFLevels = (sp: sptype, sfs: number[], type: DeviceType): (0 | 1 | 2)[] => {
    if (!sfs) return [2]
    const ret = sfs.map((sf, index) => {
        if (index < ldIndex) {
            return sf < sp.sF_limit1 ? 0 : sf < sp.sF_limit2 ? 1 : 2
        } else {
            return sf < sp.ld_limit1 ? 0 : sf < sp.ld_limit2 ? 1 : 2
        }
    })
    return ret
}


/**
 * Deletes extra severity factors when not needed
 *  i.e. a tcm has many severity factors but we only show the first
 * @param {(number[] | undefined)} sf
 * @param {DeviceType} deviceType
 * @return {*}  {number[]}
 */
const fixSF = (sf: number[] | undefined, deviceType: DeviceType): number[] => {
    return sf ? deviceType === DeviceType.T || deviceType === DeviceType.L || deviceType === DeviceType.Ld || deviceType === DeviceType.L3 || deviceType === DeviceType.L4 || deviceType === DeviceType.L5 || deviceType === DeviceType.Td ? sf.slice(0, 1) : sf : [];
}
/**
 *  basically its b - a
 *
 * @param {DeviceListExpanded} a
 * @param {DeviceListExpanded} b
 * @return {*} Difference between a and b, in relation to the sf
 */
export const fullSFSort = (a: DeviceListExpanded, b: DeviceListExpanded) => {
    const a1 = (a as unknown as DeviceListExpanded)
    const b1 = (b as unknown as DeviceListExpanded)
    const aNew = [(a.status === DeviceStatus.INACTIVE || a.status === DeviceStatus.DESTROYED) ? 0 : 1, a1.sfGroup && a1.sfGroup.sfs[a1.sfGroup.maxIndex] ? a1.sfGroup.sfs[a1.sfGroup.maxIndex].level : -1, a1.sfGroup && a1.sfGroup.sfs[a1.sfGroup.maxIndex] ? a1.sfGroup.sfs[a1.sfGroup.maxIndex].value : -9999, a.organization_name] as any[];
    const bNew = [(b.status === DeviceStatus.INACTIVE || b.status === DeviceStatus.DESTROYED) ? 0 : 1, b1.sfGroup && b1.sfGroup.sfs[b1.sfGroup.maxIndex] ? b1.sfGroup.sfs[b1.sfGroup.maxIndex].level : -1, b1.sfGroup && b1.sfGroup.sfs[b1.sfGroup.maxIndex] ? b1.sfGroup.sfs[b1.sfGroup.maxIndex].value : -9999, b.organization_name] as any[];
    // console.log("\n", a.name,"===", b.name)
    // console.log(aNew, bNew)
    let index = 0;
    while (index < aNew.length - 1) {
        // console.log(index, "   ", aNew[index], bNew[index])
        if (aNew[index] !== undefined && bNew[index] !== undefined) {
            if (aNew[index] !== bNew[index]) {
                if (aNew[index].toLocaleCompare && bNew[index].toLocaleCompare) {
                    console.log(aNew, bNew, bNew[index].toLocaleCompare(aNew[index]))
                    return bNew[index].toLocaleCompare(aNew[index]);
                }
                return bNew[index] - aNew[index]
            }
        } else if (bNew[index] !== undefined) {
            return 1
        } else if (aNew[index] !== undefined) {
            return -1
        }
        index++;
    }
    return 0
}

/**
 * Extends the number array into an object array with colors and extra information
 *
 * @param {(number[] | undefined)} sf
 * @param {sptype} limits
 * @param {DeviceStatus} status
 * @param {DeviceType} deviceType
 * @return {*}  {sfGroup} An array based on the sf but with extra info added as objects
 */
const fixAndTransformSF = (sf: number[] | undefined, limits: sptype, status: DeviceStatus, deviceType: DeviceType): sfGroup => {
    const sfs = fixSF(sf, deviceType)
    const names = deviceType === DeviceType.T || deviceType === DeviceType.L || deviceType === DeviceType.Ld || deviceType === DeviceType.L3 || deviceType === DeviceType.L4 || deviceType === DeviceType.L5 || deviceType === DeviceType.Td ? ["severity_factor"] : deviceType === DeviceType.M ? mcmOrder : deviceType === DeviceType.Gi || deviceType === DeviceType.Gc || deviceType === DeviceType.Gs || deviceType === DeviceType.Gm ? gcmOrder : icmOrder
    if (sfs && sfs.length > 0) {
        const levels = calculateSFLevels(limits, sfs, deviceType);
        let maxLevel = -1;
        let maxSF = -9999;
        let maxIndex = 0;
        const sfsGroup = sfs.map((v, index) => {
            if (levels[index] > maxLevel) {
                maxSF = v
                maxLevel = levels[index];
                maxIndex = index;
            } else if (levels[index] === maxLevel) {
                if (maxSF > v) {
                    maxSF = v
                    maxLevel = levels[index];
                    maxIndex = index;
                }
            }
            const colorName = v === 0 ? "ZERO" : (levels[index]);
            return ({ value: v, color: levelColor[colorName], name: names[index], level: levels[index] })
        })
        // console.log(sfsGroup, sf, limits, status, deviceType)
        return { maxIndex: maxIndex, sfs: sfsGroup }
    } else {
        return { maxIndex: 0, sfs: [{ value: undefined, color: (status === DeviceStatus.NOTSENDING || status === DeviceStatus.INACTIVE) ? levelColor["EMPTY"] : levelColor["NOTSENDING"], name: names[0], level: undefined }] }
    }
}

export const deviceListToDeviceListExpanded = (deviceList: DeviceList[]): DeviceListExpanded[] => {
    return (deviceList as DeviceListExpanded[]).map(v => {
        v.sfGroup = fixAndTransformSF(v.last_report?.sf, v.severity_param, v.status, v.type);
        return v;
    })
}

export const deviceFullToDeviceFullExpanded = (deviceList: DeviceFull): DeviceFullExpanded => {
    (deviceList as DeviceFullExpanded).sfGroup = fixAndTransformSF(deviceList.last_report?.sf, deviceList.severity_param, deviceList.status, deviceList.type);
    return (deviceList as DeviceFullExpanded);

}

/**
 * transforma os numeros em strings, vem dentro do plotly, se tirarmos isso, este nao existe
 * @param {number} n numero a formatar
 * @param {string} variable variavel a por no fim da string
 * @param {number} decimalN numero de casas decimais
 * @param {boolean} float usado se no angular era com small_si_format, numeros mais pequenos
 * @param {boolean} noTransform usado para so reduzir casas decimais
 * @returns {string} string com o formato si
 */
export const si_format = (n: number | undefined, variable?: string, decimalN?: number, float?: boolean, noTransform?: boolean): string => {
    const num = (noTransform ?
        n ?
            n.toFixed(decimalN !== undefined ? decimalN : 2) :
            '0' :
            d3Format(`.${decimalN !== undefined ? decimalN : 3}${float !== undefined ? 'f' : 's'}`)(n ? n : 0))
    const x = num.match(/[\d.]+|\D+/g)!;
    if (x[1]) return x[0] + (variable ? ' ' + x[1] + variable : x[1])
    else return x[0] + (variable ? ' ' + variable : '');
}

export const forLoadStatistics = (device: DeviceFull | undefined, report: ReportList | undefined, specialicm?: boolean): Array<SmallCornerBoxParts> => {
    // atual (device), diario (ultimo), semanal (ultimos 7), mensal (tudo)
    //device?.statistics?.loadRatio
    //report?.electricParam.loadRatio
    var weekAmount = 7;
    var monthAmount = 30;
    if (device?.statistics?.loadRatio && device?.statistics?.loadRatio.length < 7) {
        weekAmount = device?.statistics?.loadRatio?.length;
    }
    if (device?.statistics?.loadRatio && device?.statistics?.loadRatio.length < 30) {
        monthAmount = device?.statistics?.loadRatio?.length;
    }
    return [{
        title: 'currentLoad',
        value: report ?
            (report.type === DeviceType['M'] ||
                report.type === DeviceType['T'] ||
                report.type === DeviceType['Td'] ||
                report.type === DeviceType['L'] ||
                report.type === DeviceType['Ld'] ||
                report.type === DeviceType['L3'] ||
                report.type === DeviceType['L4'] ||
                report.type === DeviceType['L5'] ||
                (report.type === DeviceType['I'] && specialicm)) ?
                report.electricParam.loadRatio :
                si_format(report.electricParam.loadRatio, '%') :
            0
    },
    { title: 'dailyLoad', value: device ? device.statistics ? device.statistics.loadRatio ? si_format(device.statistics.loadRatio[device.statistics.loadRatio.length - 1], '%', 2, true) : '' : 'N/A' : '' },
    { title: 'weeklyLoad', value: device ? device.statistics ? device.statistics.loadRatio ? si_format(device.statistics.loadRatio.reduce((a, b, index) => { if (index >= (device.statistics!.loadRatio.length - weekAmount)) { return a + b } else { return a } }, 0) / weekAmount, '%', 2, true) : '' : 'N/A' : '' },
    { title: 'monthlyLoad', value: device ? device.statistics ? device.statistics.loadRatio ? si_format(device.statistics.loadRatio.reduce((a, b, index) => a + b) / monthAmount, '%', 2, true) : '' : 'N/A' : '' },]
}

export const forSeverityStatistics = (device: DeviceFull | undefined, report: ReportList | undefined): Array<SmallCornerBoxParts> => {
    // atual (device), diario (ultimo), semanal (ultimos 7), mensal (tudo)
    //device?.statistics?.loadRatio
    //report?.electricParam.loadRatio
    //console.log( device ? device.statistics ? device.statistics.sF ? si_format(device.statistics.sF[device.statistics.sF.length - 1], '%') : '' : '' : '' )
    var weekAmount = 7;
    var monthAmount = 30;
    if (device?.statistics?.sF && device?.statistics?.sF.length < 7) {
        weekAmount = device?.statistics?.sF?.length;
    }
    if (device?.statistics?.sF && device?.statistics?.sF.length < 30) {
        monthAmount = device?.statistics?.sF?.length;
    }
    return [{
        title: 'currentValue',
        value: report ? (report.type === DeviceType['T'] || report.type === DeviceType['L'] || report.type === DeviceType['Td'] || report.type === DeviceType['Ld'] || report.type === DeviceType['L3'] || report.type === DeviceType['L4'] || report.type === DeviceType['L5'] || report.type === DeviceType['I']) ? //THESE ONES USE THE ROUND GAUGE
            (report.diagnostic.sF && report.diagnostic.sF[0]) :
            si_format(report.diagnostic.sF[0], '%', 2, true) : ''
    },
    { title: 'dailyLoad', value: device ? device.statistics ? device.statistics.sF ? si_format(device.statistics.sF[device.statistics.sF.length - 1], '%', 2, true) : '' : 'N/A' : '' },
    { title: 'weeklyLoad', value: device ? device.statistics ? device.statistics.sF ? si_format(device.statistics.sF.reduce((a, b, index) => { if (index >= device.statistics!.sF.length - weekAmount) { return a + b } else { return a } }, 0) / weekAmount, '%', 2, true) : '' : 'N/A' : '' },
    { title: 'monthlyLoad', value: device ? device.statistics ? device.statistics.sF ? si_format(device.statistics.sF.reduce((a, b, index) => a + b) / monthAmount, '%', 2, true) : '' : 'N/A' : '' }]
}

export const forSCStatistics = (device: DeviceFull | undefined, report: ReportList | undefined, noShow?: boolean, emptytext?: string): Array<SmallCornerBoxParts> => {
    var weekAmount = 7;
    var monthAmount = 30;
    if (device?.statistics?.varLcc && device?.statistics?.varLcc.length < 7) {
        weekAmount = device?.statistics?.varLcc?.length;
    }
    if (device?.statistics?.varLcc && device?.statistics?.varLcc.length < 30) {
        monthAmount = device?.statistics?.varLcc?.length;
    }
    return noShow ?
        [{ title: 'empty', value: [emptytext ? emptytext : '', '', ''] },] :
        [{ title: 'currentValue', value: report ? si_format(report.diagnostic.varLcc[4], '%', 2, true) : '' },
        { title: 'dailyLoad', value: device ? device.statistics ? device.statistics.varLcc ? si_format(device.statistics.varLcc[device.statistics.varLcc.length - 1], '%', 2, true) : '' : 'N/A' : '' },
        { title: 'weeklyLoad', value: device ? device.statistics ? device.statistics.varLcc ? si_format(device.statistics.varLcc.reduce((a, b, index) => { if (index >= device.statistics!.varLcc.length - weekAmount) { return a + b } else { return a } }, 0) / weekAmount, '%', 2, true) : '' : 'N/A' : '' },
        { title: 'monthlyLoad', value: device ? device.statistics ? device.statistics.varLcc ? si_format(device.statistics.varLcc.reduce((a, b, index) => a + b) / monthAmount, '%', 2, true) : '' : 'N/A' : '' }]
}

//4*lados secundarios + 4*ladoAtual(primeiro secundario é 1) - 1
export const prepareForTransformRatioGauge = (deviceFull: DeviceFull | undefined, reportSelected: ReportList | undefined): Array<gaugeFinal2> => {
    const sidesSecond = deviceFull?.nameplate.side_labels ? deviceFull?.nameplate.side_labels.length - 1 : 1
    const d = deviceFull ? deviceFull.nameplate.side && deviceFull.nameplate.side[0].Tap[0] && deviceFull.nameplate.side[0].Tap[1] ? ((deviceFull.nameplate.side[0].Tap[0][1] - deviceFull.nameplate.side[0].Tap[1][1]) / (2 * deviceFull.nameplate.side[1].Un)) : undefined : undefined;
    let tapLimits = deviceFull && deviceFull.nameplate.side ? (deviceFull.nameplate.side[0].Tap.map((tapPos) => tapPos[1] / deviceFull.nameplate.side[1].Un - d!)).sort((a, b) => a - b) : [0, 100];
    tapLimits.unshift(tapLimits[0] - (tapLimits[1] - tapLimits[0]))
    const diffLimits = tapLimits ? Math.abs(tapLimits[0] - tapLimits[tapLimits.length - 1]) : 100;
    const maxSteps = Math.max(...tapLimits);
    const minSteps = Math.min(...tapLimits);
    var diffSteps = [...tapLimits];
    diffSteps.unshift(tapLimits[0] - diffLimits * 0.05);
    diffSteps.push(tapLimits[tapLimits.length - 1] + diffLimits * 0.05);
    let steps = tapLimits ?
        diffSteps.map((v, i) => [i === 0 ?
            -1000 :
            diffSteps[i - 1],
        diffSteps[i]]) :
        [[-1000, 1000]]
    steps.push([diffSteps[diffSteps.length - 1], 1000])
    const redChangePerStep = 71 / (tapLimits.length - 1);
    const colors = tapLimits ?
        steps.map((v, i) => (i === 0 || i === steps.length - 1 ?
            "#e23434" :
            i === 1 || i === steps.length - 2 ?
                "#ffc000" :
                `rgb(${113 - redChangePerStep * (i - 2)}, 160, ${42 + redChangePerStep * (i - 2)})`
        )) :
        ["#e23434", "#ffc000", "#2aa060", "#ffc000", "#e23434"]
    const indexSide = 1
    const transRatioData = [{
        name: 'transformRatio',
        colors: colors,
        max: maxSteps,
        steps: steps,
        value: reportSelected?.auxVar.auxNum && reportSelected?.auxVar.auxNum[(4 * sidesSecond) + (4 * indexSide) - 1],
        min: minSteps
    }
    ];
    return transRatioData
}

export const createMovingAverage = (data: Array<number>, window?: number): Array<number> => {
    if (!window) {
        window = 5
    }
    if (!data || data.length === 0) {
        return []
    }
    let prev = Array(0)
    let len = 0
    return data.map((value, index, array) => {
        value = array[array.length - 1 - index];
        if (value === null || value === undefined) return (null as unknown as number);//careful
        len = prev.push(value);
        if (len > window!) {
            prev.shift()
        };
        return prev.reduce((a, b) => a + b) / prev.length
    }).reverse()
}

export const makeArrowsFromDataPolar = (amplitudes: number, angulos: number, max: number): { amplitude: Array<number>, angulo: Array<number> } => { // VER SE POLAR JA FAZ SETAS NO FUTURO
    if (amplitudes === 0 && angulos === 0) {
        return { amplitude: [0, 0], angulo: [0, 0] }
    }
    const size = 0.025 * max * 2// * amplitudes;
    const height = 0.05 * max * 2// * amplitudes
    let invTan = Math.atan(size / (amplitudes - height)); // amplitude from the bottom
    invTan = invTan * (180 / Math.PI);
    //console.log(max, amplitudes, angulos, invTan, height)
    return {
        amplitude:
            [
                0,
                amplitudes,
                amplitudes - height,
                amplitudes - height,
                amplitudes,
                amplitudes - height,
                amplitudes - height,
                amplitudes,
                amplitudes - height,
                amplitudes - height,
                amplitudes,
                amplitudes - height,
                amplitudes - height,
                amplitudes
            ],
        angulo:
            [
                0,
                angulos,
                angulos - (invTan),
                angulos + (invTan),
                angulos,
                angulos - (invTan) / 2,
                angulos + (invTan) / 2,
                angulos,
                angulos - (invTan) / 4 * 3,
                angulos + (invTan) / 4 * 3,
                angulos,
                angulos - (invTan) / 4,
                angulos + (invTan) / 4,
                angulos
            ]
    }
}

export const chunkArray = (myArray1: Array<any>, chunk_size: number) => { // splits array into chunk_size chunks, if its not multiple, the last one hass only the remaining
    const results = [];
    if (!myArray1) return []
    const myArray = [...myArray1];
    if (myArray) {
        while (myArray.length) {
            results.push(myArray.splice(0, chunk_size))
        }
    }

    return results;
}

export const minArray = (arr: Array<any>) => arr.reduce((min, currentValue) => Math.min(min, currentValue), arr[0]);
export const maxArray = (arr: Array<any>) => arr.reduce((max, currentValue) => Math.max(max, currentValue), arr[0]);

//tcmd- IA,IB,IC,Ia,Ib,Ic,Ia2,Ib2,Ic2,   VA,VB,VC,Va,Vb,Vc,Va2,Vb2,Vc2
export const formatForPhasor = (array: Array<number>): { amplitudes: Array<Array<number>> | undefined, degrees: Array<Array<number>> | undefined } => {
    try {
        if (array.length > 20) {
            const arrayHelp = splitIntoEvensAndOdds(array)    ///      0 has magnitudes      1 has phase
            //tcmd- IA,IB,IC,Ia,Ib,Ic,Ia2,Ib2,Ic2,VA,VB,VC,Va,Vb,Vc,Va2,Vb2,Vc2   [magnitudes, phases] of these values
            //tcm- IA,IB,IC,Ia,Ib,Ic,VA,VB,VC,Va,Vb,Vc   [magnitudes, phases] of these values
            if (array.length > 30) {
                if (array.length > 40) {
                    const bitsMagnitude = chunkArray(arrayHelp[1], 3) // IA,IB,IC     Ia,Ib,Ic    Ia2,Ib2,Ic2      VA,VB,VC    Va,Vb,Vc     Va2,Vb2,Vc2    amplitudes
                    const bitsPhase = chunkArray(arrayHelp[0], 3) //     IA,IB,IC     Ia,Ib,Ic    Ia2,Ib2,Ic2      VA,VB,VC    Va,Vb,Vc     Va2,Vb2,Vc2    degrees
                    // console.log(bitsMagnitude, bitsPhase);
                    const degs = [bitsPhase[4].concat(bitsPhase[0]), bitsPhase[5].concat(bitsPhase[1]), bitsPhase[6].concat(bitsPhase[2]), bitsPhase[7].concat(bitsPhase[3])]//voltage first
                    const mags = [bitsMagnitude[4].concat(bitsMagnitude[0]), bitsMagnitude[5].concat(bitsMagnitude[1]), bitsMagnitude[6].concat(bitsMagnitude[2]), bitsMagnitude[7].concat(bitsMagnitude[3])]//voltage first
                    return { amplitudes: mags, degrees: degs }
                }
                const bitsMagnitude = chunkArray(arrayHelp[1], 3) // IA,IB,IC     Ia,Ib,Ic    Ia2,Ib2,Ic2      VA,VB,VC    Va,Vb,Vc     Va2,Vb2,Vc2    amplitudes
                const bitsPhase = chunkArray(arrayHelp[0], 3) //     IA,IB,IC     Ia,Ib,Ic    Ia2,Ib2,Ic2      VA,VB,VC    Va,Vb,Vc     Va2,Vb2,Vc2    degrees
                //console.log(bitsMagnitude, bitsPhase);
                const degs = [bitsPhase[3].concat(bitsPhase[0]), bitsPhase[4].concat(bitsPhase[1]), bitsPhase[5].concat(bitsPhase[2])]//voltage first
                const mags = [bitsMagnitude[3].concat(bitsMagnitude[0]), bitsMagnitude[4].concat(bitsMagnitude[1]), bitsMagnitude[5].concat(bitsMagnitude[2])]//voltage first
                return { amplitudes: mags, degrees: degs }
            }
            const halfwayThrough1 = Math.floor(arrayHelp[0].length / 2)      // HALF OF AMPLITUDES (LEFT HAS CURRENT RIGHT HAS VOLTAGE)
            const quarter1 = Math.floor(halfwayThrough1 / 2)      // QUARTER   (LEFT HAS PRIMARY, RIGHT HAS SECONDARY)
            const halfwayThrough2 = Math.floor(arrayHelp[1].length / 2)      // HALF OF DEGREES   (LEFT HAS CURRENT RIGHT HAS VOLTAGE)
            const quarter2 = Math.floor(halfwayThrough1 / 2)      // QUARTER   (LEFT HAS PRIMARY, RIGHT HAS SECONDARY)
            const arr1 = [
                arrayHelp[0].slice(halfwayThrough1, halfwayThrough1 + quarter1)//voltage first
                    .concat(arrayHelp[0].slice(0, quarter1)),
                arrayHelp[0].slice(halfwayThrough1 + quarter1, array.length)
                    .concat(arrayHelp[0].slice(quarter1, halfwayThrough1)),
            ]
            const arr2 = [
                arrayHelp[1].slice(halfwayThrough2, halfwayThrough2 + quarter2)//voltage first
                    .concat(arrayHelp[1].slice(0, quarter2)),
                arrayHelp[1].slice(halfwayThrough2 + quarter2, array.length)
                    .concat(arrayHelp[1].slice(quarter2, halfwayThrough2)),
            ]
            return { amplitudes: arr2, degrees: arr1 }
        } else {
            return { amplitudes: undefined, degrees: undefined }
        }
    } catch {
        return { amplitudes: undefined, degrees: undefined }
    }
}

const splitIntoEvensAndOdds = (array: Array<number>): Array<Array<number>> => {
    const Arr2 = [];
    const Arr3 = [];

    for (const i in array) {
        if (i in array) {
            if (parseInt(i, 10) % 2 === 0) {
                Arr3.push(array[i]);
            } else {
                Arr2.push(array[i]);
            }
        }
    }
    return [Arr2, Arr3];
}

export const swapSides = (dev: DeviceFull): DeviceFull => {
    const side0 = dev.nameplate.side[0]
    const sidetap0 = dev.nameplate.side[0].Tap
    const sidelabels0 = dev.nameplate.side_labels[0]

    dev.nameplate.side[0] = dev.nameplate.side[1]
    dev.nameplate.side_labels[0] = dev.nameplate.side_labels[1]

    dev.nameplate.side[1] = side0
    dev.nameplate.side_labels[1] = sidelabels0

    dev.nameplate.side[1].Tap = dev.nameplate.side[0].Tap
    dev.nameplate.side[0].Tap = sidetap0

    return dev
}


export const translatedSideTextToFinalText = (translatedText: string, sideNames?: string[]): string => {
    //console.log(translatedText,sideNames)
    if (sideNames) {
        const regex = /{#\d#}/;
        const match = translatedText.search(regex);
        if (match !== -1) {
            return translatedText.replace(regex, sideNames[parseInt(translatedText[match + 2])])
        } else {
            return translatedText
        }
    } else {
        return translatedText
    }
}

export const variableValueToFixedVariableValue = (value: string, deviceType: DeviceType): string => {
    //console.log(translatedText,sideNames)
    if (deviceType === DeviceType.L3) {
        return `${value}-quad`;
    } if (deviceType === DeviceType.L4) {
        return `${value}-quin`;
    } if (deviceType === DeviceType.L5) {
        return `${value}-sext`;
    } if (deviceType === DeviceType.Td || deviceType === DeviceType.Ld) {
        return `${value}-triple`;
    } if (deviceType === DeviceType.I) {
        return `${value}-icm`;
    } if (deviceType === DeviceType.M) {
        return `${value}-mcm`;
    } if (deviceType === DeviceType.Gi) {
        return `${value}-gcm`;
    } else {
        return value;
    }
}


export const deviceTypeToRouteString = (dt: DeviceType): string => {
    switch (dt) {
        case DeviceType.T: return "tcm";
        case DeviceType.Td: return "tcmd";
        case DeviceType.L: return "lptcm";
        case DeviceType.Ld: return "lptcmd";
        case DeviceType.L3: return "lptcmt";
        case DeviceType.L4: return "lptcmq";
        case DeviceType.L5: return "lptcmc";
        case DeviceType.M: return "mcm";
        case DeviceType.I: return "icm";
        case DeviceType.Gi: return "gcm";
        case DeviceType.Gc: return "gcm";
        case DeviceType.Gs: return "gcm";
        case DeviceType.Gm: return "gcm";
        default: return "tcm"
    }
}

export const substationTypeToRouteString = (dt: SubstationType): string => {
    switch (dt) {
        case SubstationType['TCM']: return "tcm";
        case SubstationType['LPTCM']: return "lptcm";
        case SubstationType['MCM']: return "mcm";
        case SubstationType['GCM']: return "gcm";
        case SubstationType['PVM']: return "pvm";
        case SubstationType['WTM']: return "wtm";
        case SubstationType['BCM']: return "bcm";
        default: return "tcm"
    }
}


export const deviceTypeToSubstationType = (dt: DeviceType, substations?: SubstationList[], deviceid?: string): SubstationType => {
    const found = deviceid ? substations?.find(sub => sub.devices_id.includes(deviceid)) : undefined
    if (found) {
        return found.type
    } else {
        switch (dt) {
            case DeviceType['M']: return SubstationType["MCM"]
            case DeviceType['L']: return SubstationType["LPTCM"]
            case DeviceType['T']: return SubstationType["TCM"]
            case DeviceType['Gi']: return SubstationType["GCM"]
            case DeviceType['Gc']: return SubstationType["GCM"]
            case DeviceType['Gs']: return SubstationType["GCM"]
            case DeviceType['Gm']: return SubstationType["GCM"]
            case DeviceType['Ld']: return SubstationType["LPTCM"]
            case DeviceType['L3']: return SubstationType["LPTCM"]
            case DeviceType['L4']: return SubstationType["LPTCM"]
            case DeviceType['L5']: return SubstationType["LPTCM"]
            case DeviceType['Td']: return SubstationType["TCM"]
            default: return SubstationType["TCM"]
        }
    }
}


export const textToSubstationType = (s: string) => {
    switch (s) {
        case "TCM": return SubstationType.TCM
        case "LPTCM": return SubstationType.LPTCM
        case "MCM": return SubstationType.MCM
        case "GCM": return SubstationType.GCM
        case "PVM": return SubstationType.PVM
        case "WTM": return SubstationType.WTM
        case "BCM": return SubstationType.BCM
        default: return undefined
    }
}


export const clientType2types = {
    [DeviceType['T']]: [DeviceType['T'], DeviceType['Td']],
    [DeviceType['I']]: [DeviceType.I],
    [DeviceType['L']]: [DeviceType['L'], DeviceType['Ld'], DeviceType.L3, DeviceType.L4, DeviceType.L5],
    [DeviceType['M']]: [DeviceType['M']],
    [DeviceType['Gi']]: [DeviceType['Gi'], DeviceType.Gc, DeviceType.Gm, DeviceType.Gs],
}

export const types2clientType = {
    [DeviceType['T']]: DeviceType['T'],
    [DeviceType['Td']]: DeviceType['T'],
    [DeviceType['I']]: DeviceType.I,
    [DeviceType['L']]: DeviceType['L'],
    [DeviceType['Ld']]: DeviceType['L'],
    [DeviceType.L3]: DeviceType['L'],
    [DeviceType.L4]: DeviceType['L'],
    [DeviceType.L5]: DeviceType['L'],
    [DeviceType['M']]: DeviceType['M'],
    [DeviceType['Gi']]: DeviceType['Gi'],
    [DeviceType.Gc]: DeviceType['Gi'],
    [DeviceType.Gm]: DeviceType['Gi'],
    [DeviceType.Gs]: DeviceType['Gi'],
}

export function verifyPassword(str: string) {
    if (!(str.length > 8 && /[a-z]/.test(str) && /[A-Z]/.test(str) && /[0-9]/.test(str) && /[!@#$%^&*]/.test(str))) {
        return false;
    }
    const lower = str.toLowerCase();
    const forbidden_words = [
        "000", "111", "222", "333", "444", "555", "666", "777", "888", "999",
        "012", "123", "234", "345", "456", "567", "678", "789", "890",
        "aaa", "bbb", "ccc", "ddd", "eee", "fff", "ggg", "hhh", "iii", "jjj", "kkk", "lll", "mmm", "nnn", "ooo", "ppp", "qqq", "rrr", "sss", "ttt", "uuu", "vvv", "www", "xxx", "yyy", "zzz",
        "abc", "def", "xyz", "ijk", "qwe", "asd", "zxc", "qaz", "wsx", "aze", "qsd", "wxc", "aqw", "xyc", "qay", "qze",
        "admin", "client", "user", "super", "test", "agent", "contoso", "enging", "ems", "predit", "tcm", "mcm", "icm", "gcm", "xcm", "transf", "motor", "pass", "pwd", "access", "secret"
    ];
    if (forbidden_words.some(x => lower.includes(x))) {
        return false;
    }
    return true;
}


export const getPermissionObjectByDevice = (type?: DeviceType) => {
    switch (type) {
        case (DeviceType.T):
        case (DeviceType.Td):
            return TCMpermissions;
        case (DeviceType.L):
        case (DeviceType.Ld):
        case (DeviceType.L3):
        case (DeviceType.L4):
        case (DeviceType.L5):
            return LPTCMpermissions;
        case (DeviceType.Gi):
        case (DeviceType.Gc):
        case (DeviceType.Gm):
        case (DeviceType.Gs):
            return GCMpermissions;
        case (DeviceType.I):
            return ICMpermissions;
        case (DeviceType.M):
            return MCMpermissions;
        default: return TCMpermissions
    }
}

export const makePermissionsObject = (tabs: string[] | undefined, permissionsObject: { [key: string]: string }, user: CurrentUser | undefined): { [key: string]: boolean } => {
    let permissions = {};
    const isAdmin = user && user.role === Roles.ADMIN;
    Object.keys(permissionsObject).forEach(key => {
        permissions = { ...permissions, ...{ [key]: tabs && (isAdmin || tabs.includes(permissionsObject[key])) } }
        // permissions = { ...permissions, ...{ [key]: tabs && (isAdmin || tabs.includes(permissionsObject[key])) } }
    })
    return permissions;
}

export const getVariablesPrefixFromViews = (views: Array<string>) => {
    return Array.from(new Set(views ? views.map(view => { const per = permissionBetter.find(p => p.tab === view); return per && per.vr ? per.vr : [] }).flat() : []));
}

export const naGaugeProps = (title: string): gaugeProps => {
    return [{
        variable: '',
        title: title,
        array: [
            { name: "na", value: undefined, color: '#f9c937', max: undefined }
        ]
    }]
}

export const naGaugeFinal2 = (title: string): gaugeFinal2 => {
    return {
        name: title,
        colors: [""],
        max: 0,
        steps: [[0]],
        value: undefined,
        min: 0
    }
}

export const naSmallCornerBoxParts = (title: string): SmallCornerBoxParts[] => {
    return [{
        title: title,
        value: undefined
    },]
}

export const naDraggableGraph: { x: Array<number | string>, ys: Array<Array<number | string>> } = {
    x: [0],
    ys: [[]]
}

export const naVectorGraph: { amplitude: number[]; angulo: number[]; } = {
    amplitude: [],
    angulo: []
}

export const naGaugeMid = (title: string, sidenames: string[], intl: IntlShape): gaugeMid => {
    return {
        title: title,
        array: sidenames.map(v => ({
            name: intl.formatMessage({ id: "na" }),
            value: undefined,
            color: "",
            max: undefined
        }))
    }
}