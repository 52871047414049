
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forWaveformsICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateWV: {
      gridTemplateAreas: `
          "gr1 gr2"`,
      gridTemplateColumns: "2fr 1fr",
      maxHeight: "750px",
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('md')]: {
        maxHeight: "300px",
        gridTemplateColumns: "70% 29%",
        gridTemplateRows: "100%",
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1" 
        "gr2"`,
        gridTemplateColumns: "100%",
        gridTemplateRows: "repeat(2,49.5%)",
        maxHeight: "600px",
        height: '600px',
        gridGap: "6px",
        padding: "10px",
      }
    },
  }),
);

interface WaveProps {

}

export default function Waveforms(props: React.PropsWithChildren<WaveProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceFull, deviceWaveforms } = useContext(EntitiesContext);
  const data = forWaveformsICM(deviceWaveforms, deviceFull)
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateWV}>
          <SubTitledCard gridArea='gr1' title="waveforms">
            {data[0][0] && <DraggableGraph
              x={(data[0][0].x as number[])}
              ys={[data[0][0].ys.slice(0, 3)] as number[][][]}
              titlex={'msUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0-icm', 'pnv-1-icm', 'pnv-2-icm']]}
              colors={[["#e23434", "#2aa060", "#3473ba"]]}
              graphType={'linegl'}
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard>
          {data[0][1] && <SubTitledCard gridArea='gr2' title='voltagePark'>
            {data[0][1] && <DraggableGraph
              x={data[0][1].x as number[]}
              ys={[data[0][1].ys] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#3473ba"]]}
              graphType={'linegl'}
              staticGraph
              sameScaleXandY
              onlyMarkers
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard>}
        </TitledCard>

      </Grid>
    </Grid >
  );
}






