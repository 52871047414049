import { Button, createStyles, Grid, isWidthDown, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableGraph from "../../../components/device/final/DraggableGraph";
import GaugeGraph from "../../../components/device/final/GaugeGraph";
import PolarGraph from "../../../components/device/final/PolarGraph";
import RoundGauge from "../../../components/device/final/RoundGauge";
import LongDataBox from "../../../components/device/middle/LongDataBox";
import SmallCornerBox from "../../../components/device/middle/SmallCornerBox";
import SubTitledCard from "../../../components/device/middle/SubTitledCard";
import TitledCard from "../../../components/device/middle/TitledCard";
import { ThemeExtended } from "../../../components/global/Theme";
import APIRequestContext from "../../../contexts/api-context";
import { EntitiesContext } from "../../../contexts/entities-context";
import { ReportList, ReportWaveforms } from "../../../interfaces/Report";
import { forLoadStatistics, forSeverityStatistics, getPermissionObjectByDevice, makePermissionsObject, prepareForTransformRatioGauge, si_format } from "../../../utils/format-data";
import { forGaugesLPTCM, forHarmonics, forVectorGraphsLPTCM, forWaveforms } from "../../../utils/lptcm-functions";
import { fixForPowerQualityGauges, forExcitationGauges, forPowerQualityGauges } from "../../../utils/tcm-functions";



const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    mainDiv: {
      marginTop: 10,
      marginBottom: 10,
      '& > .MuiGrid-item': {
        padding: 5
      }
    },

    gridtemplateEP1: {
      gridTemplateAreas: `
          "ga1 ga1 gaq1 gaq1 gaq2 gaq2 gaq2 gaq2"
          "gv1 gv1 gv1 gv2 gv2 gv2 gv2 gv2"
          "gw1 gw1 gw1 gw1 gw1 gw1 gw1 gw1"
          "gw1 gw1 gw1 gw1 gw1 gw1 gw1 gw1"
          "gw2 gw2 gw2 gw3 gw3 gw3 gw3 gw3"
          "gh1 gh1 gh1 gh1 gh1 gh1 gh1 gh1"
          "gh1 gh1 gh1 gh1 gh1 gh1 gh1 gh1"
          "gh2 gh2 gh2 gh2 gh2 gh2 gh2 gh2"
          "gh3 gh3 gh3 gh3 gh3 gh3 gh3 gh3"
          "gh3 gh3 gh3 gh3 gh3 gh3 gh3 gh3"
          "gh4 gh4 gh4 gh4 gh4 gh4 gh4 gh4"`,
      gridTemplateRows: "repeat(4,1fr) 300px 38px repeat(2,1fr) 38px",
      gridTemplateColumns: "repeat(8,1fr)",
      maxHeight: 1814,
      minHeight: 1814,
      gridGap: "6px",
      padding: "10px",
    },
    gridtemplateEP2: {
      gridTemplateAreas: `
          "ga1 ga1 gaq1 gaq1 gaq2 gaq2"
          "gv1 gv1 gv1 gv2 gv2 gv2"
          "gw1 gw1 gw1 gw1 gw1 gw1"
          "gw1 gw1 gw1 gw1 gw1 gw1"
          "gw2 gw2 gw2 gw3 gw3 gw3"
          "gh1 gh1 gh1 gh1 gh1 gh1"
          "gh1 gh1 gh1 gh1 gh1 gh1"
          "gh2 gh2 gh2 gh2 gh2 gh2"`,
      gridTemplateRows: "repeat(4,1fr) 300px repeat(2,1fr) 38px",
      gridTemplateColumns: "repeat(6,1fr)",
      maxHeight: 1650,
      minHeight: 1650,
      gridGap: "6px",
      padding: "10px",
    },

    gridtemplateEP3: {
      gridTemplateAreas: `
          "ga1" 
          "gaq1" 
          "gaq2"
          "gv1" 
          "gv2"
          "gw1"
          "gw2" 
          "gw3"
          "gh1"
          "gh2"`,
      gridTemplateRows: "repeat(5,1fr) 250px 250px 250px 320px 38px",
      gridTemplateColumns: "1fr",
      maxHeight: 2350,
      gridGap: "6px",
      padding: "10px",
    },

    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    },
    singleDiv: {
      display: "grid",
      gridTemplateAreas: `
      "g1 g2 g3 g5"`,
      gridTemplateColumns: "repeat(4,1fr)",
      gridTemplateRows: "100%",
      gridGap: 6,
      padding: 10,
      minHeight: 179
    },
    singleDiv5: {
      display: "grid",
      gridTemplateAreas: `
          "g1 g2 g3 g4 g5"`,
      gridTemplateColumns: "repeat(5,1fr)",
      gridTemplateRows: "1fr",
      gridGap: 6,
      padding: 10,
      minHeight: 179
    }
  })
)

interface SingleReportProps {
  reportSelected: ReportList | undefined;
  waveformsSelected: ReportWaveforms | undefined;
  sideNames?: string[];
  hasV?: boolean;
}

export default function SingleReport(props: SingleReportProps) {
  const { reportSelected, waveformsSelected, sideNames, hasV } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const { user } = useContext(APIRequestContext);
  const classes = useStyles();
  const intl = useIntl();
  const permissions = makePermissionsObject(user?.views, getPermissionObjectByDevice(deviceFull?.type), user);
  let sideNamesFixed: string[] = sideNames ? sideNames : ['1o', '2o'];
  let xsLength: (6 | 4 | 3 | 2)[] = sideNamesFixed.map(x => (Math.floor(12 / sideNamesFixed.length) as (6 | 4 | 3 | 2)));
  if ((xsLength as number[]).reduce((a, b) => a + b) < 12) { xsLength[0] = (xsLength[0] + 1 as (6 | 4 | 3 | 2)); xsLength[1] = (xsLength[1] + 1 as (6 | 4 | 3 | 2)) }
  const elecParamData = forGaugesLPTCM(reportSelected, deviceFull);
  const loadGauge = forLoadStatistics(deviceFull, reportSelected)[0];
  const qualityData = fixForPowerQualityGauges(forPowerQualityGauges(reportSelected, deviceFull), intl, sideNamesFixed, hasV, hasV, hasV);
  const vectorData = forVectorGraphsLPTCM(reportSelected);
  const waveData = forWaveforms(waveformsSelected, deviceFull, true);
  var sfLimits = [1, 2];
  if ((deviceFull?.severity_param.sF_limit1 || deviceFull?.severity_param.sF_limit1 === 0) && (deviceFull?.severity_param.sF_limit2 || deviceFull?.severity_param.sF_limit2 === 0)) sfLimits = [deviceFull?.severity_param.sF_limit1, deviceFull?.severity_param.sF_limit2];
  const severityGauge = forSeverityStatistics(deviceFull, reportSelected)[0];
  const excitationData = forExcitationGauges(reportSelected).map(g => { g.array.map(ar => ar.name = intl.formatMessage({ id: ar.name })); return g });
  const transRatioData = prepareForTransformRatioGauge(deviceFull, reportSelected);
  const harmonicsData = forHarmonics(reportSelected, sideNamesFixed);
  const [parityCurrent, setParityCurrent] = useState<boolean[]>([false, false, false, false, false, false]);
  const [parityVoltage, setParityVoltage] = useState<boolean[]>([false, false, false, false, false, false]);
  const changeParity = (side: number, current: number) => {
    if (current === 0) {
      setParityCurrent(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    } else {
      setParityVoltage(x => { const ret = [...x]; ret[side] = !x[side]; return ret })
    }
  };

  return (<Grid className={classes.mainDiv} container>




    <Grid item xs={12}>
      <TitledCard gridTemplate={classes.singleDiv}>
        {<SubTitledCard gridArea={"g1"} flexCenter title={'severityFactor'}>
          <RoundGauge empty={!permissions["global_status"]} height={110} data={{ name: '', value: (severityGauge!.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[-1, sfLimits[0]], [sfLimits[0], sfLimits[1]], [sfLimits[1], 3]], max: 3, unit: '%', min: -3 }}></RoundGauge>
        </SubTitledCard>}

        {<SubTitledCard gridArea={"g2"} title={'excitation'}>
          <GaugeGraph empty={!permissions["excitation"]} data={excitationData![0].array}>
          </GaugeGraph>
        </SubTitledCard>}

        {<SubTitledCard flexCenter gridArea={"g3"} title={'transformRatio'}>
          <RoundGauge empty={!permissions["transformation_ratio"]} height={110} data={transRatioData![0]}>
          </RoundGauge>
        </SubTitledCard>}

        {<SubTitledCard gridArea={"g5"} flexCenter title={'currentLoad'}>
          <RoundGauge empty={!permissions["load_diagram"]} height={110} data={{ name: '', value: (loadGauge?.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[0, 80], [80, 100], [100, 125]], max: 125, unit: '%' }}></RoundGauge>
        </SubTitledCard>}

      </TitledCard>
    </Grid>


    {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-lptcm-${side}`} item xs={xsLength[side]}>
      <TitledCard gridTemplate={sideNamesFixed.length > 3 ? classes.gridtemplateEP3 : classes.gridtemplateEP2} title={sideNamesFixed[side]?.slice(0, 2)} number={sideNamesFixed[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>

        {elecParamData![side] && elecParamData![side].map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard1-${side * 3 + type}`} title={elecParamData![side][type].title}>
          <GaugeGraph empty={!permissions["electric_param"]} smallNumber data={elecParamData![side][type].array}></GaugeGraph>
        </SubTitledCard>)}

        {qualityData![side] && qualityData![side].map((value2: any, type: number) => (<React.Fragment key={`subtitledcard2-${type + side * 3}`} >
          {qualityData![side][type] && <SubTitledCard removeFirstNWords={isWidthDown('xl', (props as any).width) && (sideNamesFixed.length > 2) ? 2 : 0} gridArea={`gaq${type + 1}`} title={qualityData![side][type].title}>
            <GaugeGraph empty={!permissions["power_quality"]} smallNumber bigText data={qualityData![side][type].array}></GaugeGraph>
          </SubTitledCard>}
        </React.Fragment>))}

        <SubTitledCard title={"vector_diagram"} gridArea='gv1'>
          <PolarGraph
            empty={!permissions["vector_diagram"]}
            ys={vectorData ? vectorData[side].map(form => form.angulo).slice(0,3) : [[]]}
            ysnames={side === 0 ? ['IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic']}
            colors={['#f15d5d', '#51c787', '#6299d8']}
            xs={vectorData ? vectorData[side].map(form => form.amplitude).slice(0,3) : []}
          ></PolarGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gv2' title='current'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: side === 0 ? 'iv-0' : 'iv-4', value: [si_format(vectorData ? vectorData[side][0].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[side][0].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'iv-1' : 'iv-5', value: [si_format(vectorData ? vectorData[side][1].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[side][1].angulo[1] : 0, 'º', 1, true)] },
              { title: side === 0 ? 'iv-2' : 'iv-6', value: [si_format(vectorData ? vectorData[side][2].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[side][2].angulo[1] : 0, 'º', 1, true)] },
            ]}
          ></SmallCornerBox>
        </SubTitledCard>

        {<><SubTitledCard title={"waveforms"} gridArea='gw1'>
          <DraggableGraph
            empty={!permissions["waveforms"]}
            x={(waveData && waveData[side] ? waveData[side][0].x : [] as number[])}
            ys={[waveData && waveData[side] ? waveData[side][0].ys : [[]]] as number[][][]}
            titlex={'msUnits'}
            titley={['amplitudeUnits']}
            ysnames={[side === 0 ? ['iv-0', 'iv-1', 'iv-2'] : ['iv-4', 'iv-5', 'iv-6']]}
            colors={[["#e23434", "#2aa060", "#3473ba"]]}
            graphType={'linegl'}
            notTimezone
            removeLegendPrefix
          ></DraggableGraph>
        </SubTitledCard>
          <SubTitledCard gridArea='gw2' title='voltagePark'>
            <DraggableGraph
              empty={!permissions["waveforms"]}
              x={hasV ? waveData && waveData[side] ? waveData[side][1].x : [] as number[] : []}
              ys={hasV ? [waveData && waveData[side] ? waveData[side][1].ys : [[]]] as number[][][] : [[[]]]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[['red']]}
              graphType={'linegl'}
              staticGraph={true}
              sameScaleXandY
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gw3' title='currentPark'>
            {<DraggableGraph
              empty={!permissions["waveforms"]}
              x={waveData && waveData[side] && waveData[side][2] ? waveData[side][2].x : [] as number[]}
              ys={[waveData && waveData[side] && waveData[side][2] ? waveData[side][2].ys : [[]]] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[['red']]}
              graphType={'linegl'}
              staticGraph={true}
              sameScaleXandY
              notTimezone
            ></DraggableGraph>}
          </SubTitledCard></>
        }

        {<React.Fragment key={"harmonics1"}>
          <SubTitledCard gridArea='gh1' title='current' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              empty={!permissions["harmonics"]}
              ys={harmonicsData[side] ?
                [harmonicsData[side][0][parityCurrent[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={harmonicsData[side] ? harmonicsData[side][0][parityCurrent[side] ? 'even' : 'odd'].x : []}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gh2'>
            <LongDataBox
              empty={!permissions["harmonics"]}
              parts={[
                { title: 'Ia1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[0], 'A') },
                { title: 'Ib1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[1], 'A') },
                { title: 'Ic1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][0].one[2], 'A') },
              ]}
            ></LongDataBox>
          </SubTitledCard>
          {hasV && <SubTitledCard gridArea='gh3' title='voltage' buttons={
            <Button className={classes.button} onClick={() => { changeParity(side, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
          }>
            <DraggableGraph
              empty={!permissions["harmonics"]}
              ys={harmonicsData[side] ?
                [harmonicsData[side][1][parityVoltage[side] ? 'even' : 'odd'].y] :
                [[[]]]}
              x={harmonicsData[side][1][parityVoltage[side] ? 'even' : 'odd'].x}
              ysnames={[side === 0 ? ['IA', 'IB', 'IC', 'IA', 'IB', 'IC'] : ['Ia', 'Ib', 'Ic', 'Ia', 'Ib', 'Ic']]}
              colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
              graphType="bar"
              titlex=""
              titley={["harmonicsUnits"]}
              paddBot={25}
              notTimezone
            ></DraggableGraph>
          </SubTitledCard>}
          {hasV && <SubTitledCard gridArea='gh4'>
            <LongDataBox
              empty={!permissions["harmonics"]}
              parts={[
                { title: 'Va1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[0], 'V') },
                { title: 'Vb1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[1], 'V') },
                { title: 'Vc1:', value: si_format(harmonicsData && harmonicsData[side] && harmonicsData[side][1].one[2], 'V') },
              ]}
            ></LongDataBox>
          </SubTitledCard>}
        </React.Fragment>}

      </TitledCard>
    </Grid>)}


  </Grid>)
}