import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import locale_en from "../assets/locales/en.json";
import locale_es from "../assets/locales/es.json";
import locale_pt from "../assets/locales/pt.json";

const TranslationContext = React.createContext<{
  setBothLanguageVars: (l: string) => void;
  language: string;
}>({
  setBothLanguageVars: () => { },
  language: ""
})
export default TranslationContext

interface dataKeys {
  [key: string]: any;
}
const data: dataKeys = {
  'es': locale_es,
  'pt': locale_pt,
  'en': locale_en
};

export function TranslationProvider(props: React.PropsWithChildren<{}>) {
  const [language, setLanguage] = useState<string>('');
  const [languageMSG, setLanguageMSG] = useState<Record<string, string>>();

  const setBothLanguageVars: any = (l: string) => {
    localStorage.setItem("language", l);
    setLanguage(l);
    setLanguageMSG(data[l]);
  }


  const translationError = (something: Error) => {
    //console.log(something.message)
  }

  useEffect(() => {
    const lang = localStorage.getItem("language");
    const l = lang ? lang : navigator.language?.split(/[-_]/)[0];
    if (l === 'es' || l === 'pt' || l === 'en') {
      setLanguage(l);
      setLanguageMSG(data[l]);
    } else {
      setLanguage('en');
      setLanguageMSG(data['en']);
    }
  }, [])

  return (
    <TranslationContext.Provider value={{ setBothLanguageVars, language }}>
      <IntlProvider onError={translationError} locale={language} key={language} messages={languageMSG}>
        {props.children}
      </IntlProvider>
    </TranslationContext.Provider>
  );
}