import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gaugeFinal } from '../../../pages/tcm/tabs/ElectricParam';
import { naGaugeMid, si_format } from '../../../utils/format-data';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerGauge: {
            width: "100%",
            height: "37px",
            margin: 'auto',
            textAlign: "center"
        },
        outerGaugeSmall: {
            width: "100%",
            height: "27px",
            margin: 'auto',
            textAlign: "center"
        },
        innerGauge: {
            borderRadius: "0px 5px 5px 0px",
            height: "25px"
        },
        innerGaugeSmall: {
            borderRadius: "0px 5px 5px 0px",
            height: "18px"
        },
        gaugeText: {
            fontSize: "13px",
            color: theme.palette.primary.main,
            fontWeight: 700,
            height: "37px",
            margin: "auto"
        },
        gaugeTextSmall: {
            fontSize: "13px",
            color: theme.palette.primary.main,
            fontWeight: 700,
            height: "27px",
            margin: "auto"
        },
        gaugeTextHorizontal: {
            paddingTop: 4
        },
        beforeGauge: {
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: "center",
            marginRight: "4px",
            marginLeft: "4px",
            whiteSpace: "nowrap"
        },
        beforeGaugeEmpty: {
            width: 0,
            display: "none"
        },
        outerGaugeEmpty: {
            width: 0,
        },
        afterGauge: {
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: "4px",
            marginLeft: "4px",
            textAlign: "center",
            whiteSpace: "nowrap"
        },
        afterGaugeEmpty: {
            width: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: 0,
            marginLeft: 0,
        },
        gaugesHolder: {
            display: "grid",
            paddingTop: "10px",
            height: '100%',
            maxWidth: "100%",
            width: "100%"
        },
        gaugesHolderHorizontal: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            paddingTop: "10px",
        },
        moreSpaceBetween: {
            marginLeft: 15,
            marginRight: 15
        },
        centerChild: {
            "& > .MuiGrid-item": {
                display: "grid",
            }
        },
        middleBox:
        {
            flexGrow: 1
        }
    }),
);

interface gaugeMid extends Array<gaugeFinal> {
}

interface DeviceDialogProps {
    data: gaugeMid,
    variable?: string,
    horizontal?: boolean,
    smallNumber?: boolean,
    bigText?: boolean,
    bigValue?: boolean,
    moreSpaceBetween?: boolean,
    compact?: boolean,
    empty?: boolean,
}


export default function DeviceDialog(props: React.PropsWithChildren<DeviceDialogProps>) {
    const classes = useStyles();
    const { data, horizontal, variable, smallNumber, moreSpaceBetween, compact, empty } = props;
    const ref = useRef(null);
    const intl = useIntl();
    const dataFinal = empty ? naGaugeMid("", [""], intl).array : data;
    const values = dataFinal && dataFinal.map((value, index) => (value.value || value.value === 0) ? smallNumber ? si_format(value.value, variable, 2, true) : value.name.localeCompare("PF") === 0 ? si_format(value.value, variable, 2, true) : si_format(value.value, variable) : '');

    //[style.background]="colorScheme.domain[i2]"[style.width.%]="calcSize(item.value,device.ppv[i])"
    return (
        <>
            <div ref={ref} className={horizontal && !empty ? classes.gaugesHolderHorizontal : classes.gaugesHolder}>
                {!horizontal && <Grid className={classes.centerChild} container>

                    <Grid item >

                        {dataFinal && dataFinal.map((value, index) => <div key={`gauge-${index}`} className={moreSpaceBetween ? classes.moreSpaceBetween : ''} style={{ display: "flex" }}>
                            <div className={classes.beforeGauge + ' ' + (!compact ? classes.gaugeText : classes.gaugeTextSmall) + ' ' + (horizontal ? classes.gaugeTextHorizontal : '') + ' ' + ((!value.value && value.value !== 0) ? classes.beforeGaugeEmpty : '')}>
                                {value.name}
                            </div>
                        </div>)}

                    </Grid>
                    <Grid item className={classes.middleBox}>

                        {dataFinal && dataFinal.map((value, index) => <div key={`gauge-${index}`} className={moreSpaceBetween ? classes.moreSpaceBetween : ''} style={{ display: "flex" }}>
                            <div className={((!compact ? classes.outerGauge : classes.outerGaugeSmall))}>

                                {(!value.value && value.value !== 0) && <div className={classes.gaugeTextSmall}>{value.name}</div>}

                                <div className={(!compact ? classes.innerGauge : classes.innerGaugeSmall)} style={{ backgroundColor: `${value.color}`, width: `${(value.value || value.value === 0) && value.max ? value.value / value.max * 100 : 0}%` }}>
                                </div>
                            </div>
                        </div>)}

                    </Grid>
                    <Grid item >

                        {dataFinal && dataFinal.map((value, index) => <div key={`gauge-${index}`} className={moreSpaceBetween ? classes.moreSpaceBetween : ''} style={{ display: "flex" }}>
                            <div className={((!value.value && value.value !== 0) ? classes.afterGaugeEmpty : '') + ' ' + classes.afterGauge + ' ' + (!compact ? classes.gaugeText : classes.gaugeTextSmall) + ' ' + (horizontal ? classes.gaugeTextHorizontal : '')}>

                                {values[index]}

                            </div>
                        </div>)}

                    </Grid>

                </Grid>}


                {horizontal && !empty && dataFinal && dataFinal.map((value, index) => <div key={`gauge-${index}`} className={moreSpaceBetween ? classes.moreSpaceBetween : ''} style={{ display: "flex" }}>

                    <div className={classes.beforeGauge + ' ' + (!compact ? classes.gaugeText : classes.gaugeTextSmall) + ' ' + (horizontal ? classes.gaugeTextHorizontal : '') + ' ' + ((!value.value && value.value !== 0) ? classes.beforeGaugeEmpty : '')}>
                        {value.name}
                    </div>

                    <div className={((!value.value && value.value !== 0) ? classes.outerGaugeEmpty : (!compact ? classes.outerGauge : classes.outerGaugeSmall))}>
                        <div className={(!compact ? classes.innerGauge : classes.innerGaugeSmall)} style={{ backgroundColor: `${value.color}`, width: `${(value.value || value.value === 0) && value.max ? value.value / value.max * 100 : 0}%` }}>
                        </div>
                    </div>

                    <div className={((!value.value && value.value !== 0) ? classes.afterGaugeEmpty : '') + ' ' + classes.afterGauge + ' ' + (!compact ? classes.gaugeText : classes.gaugeTextSmall) + ' ' + (horizontal ? classes.gaugeTextHorizontal : '')}>
                        {(value.value || value.value === 0) ? smallNumber ? si_format(value.value, variable, 2, true) : value.name.localeCompare("PF") === 0 ? si_format(value.value, variable, 2, true) : si_format(value.value, variable) : ''}
                    </div>

                </div>)}

                {horizontal && empty && <div className={clsx(classes.gaugeTextHorizontal, classes.gaugeText)}><FormattedMessage id={"na"}></FormattedMessage></div>}
            </div>
        </>
    );
}