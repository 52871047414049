import { Button, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, useTheme, withStyles } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CommonTime from '../../components/global/CommonTime';
import { AlarmContext } from '../../contexts/alarm-context';
import APIRequestContext from '../../contexts/api-context';
import { ToastContext } from "../../contexts/toast-context";
import { received, receivedExtended } from '../../interfaces/Alerts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verifyButton: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      padding: '2px 4px',
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      textTransform: 'none'
    },
    actionPart: {
      paddingRight: "0px !important",
      width: "auto !important"
    },
    table: {
      minWidth: 650
    },
    outerTable: {
      marginLeft: "8px",
      marginRight: "8px",
      width: "calc(100% - 16px)",
      maxHeight: "100%",
      overflowY: "auto",
      borderRadius: "10px",
      marginBottom: 5
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    circle: {
      marginRight: "5px",
      height: "15px",
      width: "15px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    },
    ballCell: {
      alignItems: "center",
      display: "flex"
    },
    link: {
      textDecoration: "unset",
      color: "black"
    },
    iconButton: {
      padding: 3
    },
    iconButtonSingle: {
      marginRight: 14
    }
  }),
);

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "auto"
    },
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontSize: 12
    },
    body: {
      fontSize: 14,
      color: theme.palette.common.black,
      maxHeight: 43,
      maxWidth: 400,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow:"ellipsis"
    },
  }),
)(TableCell);


const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.black,
    },
  }),
)(TableRow);

const StyledTablePagination = withStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: "30px !important"
    }
  }),
)(TablePagination);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export interface HeadCell<T> {
  disablePadding: boolean;
  id: T;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: Array<HeadCell<any>>
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={`tableHead-${headCell.id}`}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <FormattedMessage id={headCell.label}></FormattedMessage>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Order = 'asc' | 'desc';


interface EntityTableTableProps {
  list: Array<any>;
  formatColor: any;
  headCells: Array<HeadCell<string>>,
  total: number,
  dot: number,
  dates: number[],
  actions1?: (id: string) => void,
  buttons1?: JSX.Element,
  actions2?: (id: string) => void,
  buttons2?: JSX.Element,
  actions3?: (id: string) => void,
  buttons3?: JSX.Element,
  setRows: (rows: number) => void,
  setPage: (page: number) => void,
  page: number,
  rows: number,
  setOrderBy: (order: string) => void,
  setOrder: (order: Order) => void,
  order: Order,
  orderBy: string,
  oversizedColumn?: number
}












export default function EntityTableTable(props: EntityTableTableProps) {
  const classes = useStyles();
  const { list, formatColor, headCells, total, dot, dates, actions1, buttons1, actions2, buttons2, actions3, buttons3, setPage, setRows, page, rows, setOrderBy, setOrder, order, orderBy } = props;
  const intl = useIntl()
  const { alerts, notAcknowledged, setAlerts, setNotAcknowledged } = useContext(AlarmContext);
  const { displayToast } = useContext(ToastContext);
  const { user } = useContext(APIRequestContext);
  const { apiRequest } = useContext(APIRequestContext);

  const handleSubmit = (alarm: receivedExtended) => {
    //REMOVER VAZIOS
    apiRequest("PUT", "api/v1/alarm-occurrence/" + alarm._id)
      .then(result => {
        displayToast('info', 'alarmVerified', 'nothing');
        handleVerify(alarm, user ? user?.id : '', user ? user.email : '')
      })
      .catch(error => {
        displayToast('error', 'alarmNotVerified', 'nothing')
      });
  }

  const handleVerify = (alarm: receivedExtended, id: string, email: string) => {
    if (alarm) {
      const tempAlert: received = { ...alarm, acknowledged_user: { email: email, id: id } }
      updateAlerts(tempAlert);
      updateNotAcknowledged(tempAlert);
    }
  }

  const updateAlerts = (updated: received) => {
    var c = alerts;
    c.map(alert => {
      var a2 = alert;
      if (updated._id === a2._id) {
        a2.acknowledged_user = updated.acknowledged_user
        a2.acknowledged_email = updated.acknowledged_user ? updated.acknowledged_user.email : ''
      }
      return a2
    })
    setAlerts(c)
  }

  const updateNotAcknowledged = (updated: received) => {
    var c = notAcknowledged;
    c.splice(c.findIndex(c2 => c2._id === updated._id), 1)
    setNotAcknowledged(c)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (setPage !== undefined) setPage(newPage)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    console.log(parseInt(event.target.value, 10))
    if (setRows) setRows(parseInt(event.target.value, 10))
  };

  //const formatType = (type: string): string => {
  //  return type === '1' ? 'MCM' : type === '2' ? 'LPTCM' : type === '3' ? 'LPTCM-d' : type === '4' ? 'TCM' : type === '8' ? 'ICM' : 'NOT DEFINED'
  //}

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onlyAction1 = () => {
    if (!actions2 && !actions3 && !buttons2 && !buttons3) {
      return true;
    } else {
      return false;
    }
  }

  /*                                                                                               ---------------------------FALTA LCOALE PARA TEMPO  */
  return (
    <TableContainer component={Paper} className={classes.outerTable}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={headCells.length}
          headCells={headCells}
        />
        <TableBody>
          {list.map((row, index) => {
            return (
              <StyledTableRow key={`table-row-${index}`}>
                {headCells.map((headcell, index2) => {
                  var val: any = row[headcell.id]
                  if (val && val.length === 0) {
                    val = <FormattedMessage id={'undefined'}></FormattedMessage>
                  }
                  if (dot === index2) {
                    return <StyledTableCell key={`table-cell-${index}-${index2}`} component="th" scope="row">
                      <div className={classes.ballCell}>
                        <span className={classes.circle} style={{ backgroundColor: formatColor(row) }}></span>
                        {val}
                      </div>
                    </StyledTableCell>
                  }
                  if (dates.includes(index2)) {
                    if (!val) val = row.created_at
                    return <StyledTableCell key={`table-cell-${index}-${index2}`} align="right">
                      <CommonTime val={val}></CommonTime>
                    </StyledTableCell>
                  }
                  if (headcell.id === 'device_name') {
                    return <StyledTableCell key={`table-cell-${index}-${index2}`} align="left">
                      {row.device_type_name && <Tooltip title={intl.formatMessage({ id: 'control' })}>
                        <Link href={`/#/${row.device_type_name}/${row.device.id}`}>
                          {val}
                        </Link>
                      </Tooltip>}
                      {!row.device_type_name && val}
                    </StyledTableCell>
                  }
                  if (headcell.id === 'acknowledged_email') {
                    if (val) return <StyledTableCell key={`table-cell-${index}-${index2}`} align="right">{val ? val.join ? val.join(', ') : val : ''}</StyledTableCell>
                    else return <StyledTableCell key={`table-cell-${index}-${index2}`} align="right"><Button className={classes.verifyButton} onClick={() => handleSubmit(row)}><Check /><FormattedMessage id={'verify'} /></Button></StyledTableCell>
                  }
                  if (headcell.id === 'actions') {
                    return <StyledTableCell className={classes.actionPart} key={`table-cell-${index}-${index2}`} align="right">
                      {actions1 && buttons1 && <IconButton className={clsx(classes.iconButton, onlyAction1() && classes.iconButtonSingle)} onClick={() => { actions1((row._id as unknown as string)) }}>{buttons1}</IconButton>}
                      {actions2 && buttons2 && <IconButton className={classes.iconButton} onClick={() => { actions2((row._id as unknown as string)) }}>{buttons2}</IconButton>}
                      {actions3 && buttons3 && <IconButton className={classes.iconButton} onClick={() => { actions3((row._id as unknown as string)) }}>{buttons3}</IconButton>}
                    </StyledTableCell>
                  }
                  if (index2 === 0) return <StyledTableCell key={`table-cell-${index}-${index2}`} align="left">{val}</StyledTableCell>
                  return <StyledTableCell key={`table-cell-${index}-${index2}`} align="right">{val ? val.join ? val.join(', ') : val : ''}</StyledTableCell>
                }
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 15, 20, { label: `${intl.formatMessage({ id: 'all' })}`, value: total }]}
              colSpan={4}
              count={total}
              rowsPerPage={rows}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer >
  );
}
