
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
//import { si_format } from '../../../utils/format-data';
import { forWaveforms } from '../../../utils/mcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateWV: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateColumns: "50% 24.5% 24.5%",
      gridTemplateRows: "100%",
      maxHeight: "749px",
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1 gr1" 
          "gr2 gr3"`,
        gridTemplateRows: "repeat(2,49.6%)",
        gridTemplateColumns: "repeat(2,49.6%)",
        maxHeight: theme.shape.graphBox.biggest.height,
        height: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px",
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1" 
        "gr2"
        "gr3"`,
        gridTemplateColumns: "100%",
        gridTemplateRows: '1fr 0.4fr 0.4fr',
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px",
      }
    },
  }),
);

export default function Waveforms(props: React.PropsWithChildren<{ hasV?: boolean }>) {
  const classes = useStyles();
  const { hasV } = props;
  const { deviceFull, deviceWaveforms } = useContext(EntitiesContext);
  const data = forWaveforms(deviceWaveforms, deviceFull)
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateWV}>
          <SubTitledCard gridArea='gr1'>
            {data[0] && <DraggableGraph
              x={data[0].x.map((v: any) => v.replace ? v.replace(",", ".") : v).filter((v, i) => i > 2 || hasV) as number[]}//quick comma fix
              ys={[data[0].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v)).filter((v, i) => i > 2 || hasV)] as number[][][]}//quick comma fix
              titlex={'msUnits'}
              titley={['amplitudeUnits']}
              ysnames={[hasV ? ['ppv-0-mcm', 'ppv-1-mcm', 'ppv-2-mcm', 'iv-0-mcm', 'iv-1-mcm', 'iv-2-mcm'] : ['iv-0-mcm', 'iv-1-mcm', 'iv-2-mcm']]}
              colors={[hasV ? ["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8'] : ['#f15d5d', '#51c787', '#6299d8']]}
              graphType={'linegl'}
              notTimezone
              removeLegendPrefix
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title='voltagePark'>
            {data[1] && <DraggableGraph
              empty={!hasV}
              x={data[1].x.map((v: any) => v.replace ? v.replace(",", ".") : v) as number[]}
              ys={[data[1].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v))] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#3473ba"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
            ></DraggableGraph>}
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title='currentPark'>
            {data[2] && <DraggableGraph
              x={data[2].x.map((v: any) => v.replace ? v.replace(",", ".") : v) as number[]}
              ys={[data[2].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v))] as number[][][]}
              titlex={'amplitudeUnits'}
              titley={['amplitudeUnits']}
              ysnames={[['pnv-0']]}
              colors={[["#e23434"]]}
              graphType={'linegl'}
              staticGraph
              notTimezone
              sameScaleXandY
            ></DraggableGraph>}
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






