import React, { useContext, useEffect, useState } from "react";
import { WsMessage } from "../interfaces/Websocket";
import APIRequestContext, { socketURL } from "./api-context";

export const SocketContext = React.createContext<{
  socket: WebSocket | undefined,
  state: number,
  joinRoom: ((room: string, options?: string[]) => void) | undefined;
  leaveRoom: ((room: string) => void) | undefined;
}>({
  socket: undefined,
  state: 0,
  joinRoom: (room) => { },
  leaveRoom: (room) => { },
})

export function SocketProvider(props: React.PropsWithChildren<{}>) {
  const { user } = useContext(APIRequestContext);
  const [webSocket, setWebsocket] = useState<WebSocket>();
  const [webSocketState, setWebsocketState] = useState<number>(WebSocket.CONNECTING);
  const [webSocketTimeout, setWebsocketTimeout] = useState<number>();
  const [lastRelevantMessage, setLastRelevantMessage] = useState<string>();

  useEffect(() => {
    if (user !== undefined) {
      const connect = (timeout?: number) => {
        setWebsocketState(WebSocket.CONNECTING);
        const newTimeout = timeout === undefined ? 3000 : timeout > 300000 ? 300000 : timeout + 5000;
        const socket = new WebSocket(socketURL + '?token=' + user.token);
        socket.onclose = () => {
          setWebsocketState(WebSocket.CLOSED);
          setWebsocketTimeout(() => window.setTimeout(() => connect(newTimeout), newTimeout));
        };
        socket.onmessage = (e) => {
          if (JSON.parse(e.data).type === "registered" && lastRelevantMessage !== undefined) {
            socket.send(lastRelevantMessage);
          }
        }
        socket.onopen = () => {
          setWebsocketState(WebSocket.OPEN);
          setWebsocket(socket);
        };
      };

      connect();
    } else {
      setLastRelevantMessage(undefined);
      if (webSocket !== undefined && webSocket.readyState === WebSocket.OPEN) {
        webSocket.close();
      }
      setWebsocket(undefined);
    }
    return () => clearTimeout(webSocketTimeout);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (webSocket !== undefined && webSocket.readyState === WebSocket.OPEN && lastRelevantMessage !== undefined) {
      webSocket.send(lastRelevantMessage);
    }
  }, [webSocket, lastRelevantMessage]);

  const joinRoom = (room: string, options?: string[], previousTimeout?: number) => {
    if (room !== '') {
      if (room === 'join_dashboard') {
        if (options) {
          setLastRelevantMessage(JSON.stringify({ type: 'join_dashboard', data: options } as WsMessage))
        }
        else {
          setLastRelevantMessage(JSON.stringify({ type: 'join_dashboard' } as WsMessage))
        }
      } else {
        setLastRelevantMessage(JSON.stringify({ type: 'join_room', data: room } as WsMessage))
      }
    }
  }

  const leaveRoom = (room: string, previousTimeout?: number) => {
    setLastRelevantMessage(undefined);
    if (webSocket !== undefined && webSocket.readyState === WebSocket.OPEN) {
      if (room === 'leave_dashboard') {
        webSocket.send(JSON.stringify({ type: 'leave_dashboard' } as WsMessage))
      } else {
        webSocket.send(JSON.stringify({ type: 'leave_room', data: room } as WsMessage))
      }
    }
  }

  return (
    <SocketContext.Provider value={{ socket: webSocket, state: webSocketState, joinRoom, leaveRoom }}>
      {props.children}
    </SocketContext.Provider>);
}