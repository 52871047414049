
import { Grid, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplatePQ: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateColumns: "repeat(3,33.1%)",
      gridTemplateRows: "100%",
      height: theme.shape.graphBox.smallest.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1"
          "gr2"
          "gr3"`,
        gridTemplateColumns: "100%",
        gridTemplateRows: "repeat(3,32.9%)",
        height: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px",
      }
    }
  }),
);

export default withWidth()(function PowerQuality(props: React.PropsWithChildren<{
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
  sideNames: Array<string>//the names of the sides
}>) {
  const classes = useStyles();
  const { deviceFull, deviceMainHistory } = useContext(EntitiesContext);
  const { hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, sideNames } = props;
  const sides = [hasV1, hasV2, hasV3, hasV4 === true, hasV5 === true, hasV6 === true];
  const translationAppend = useMemo(() => {
    switch (sideNames.length) {
      case 2:
        return "";
      case 3:
        return "-triple";
      case 4:
        return "-quad";
      case 5:
        return "-quin";
      case 6:
        return "-sext";
    }
  }, [sideNames.length]);
  return (
    <Grid container className={classes.SideContainer}>
      {deviceFull && deviceFull.nameplate.side_labels.map((value, side) => <Grid key={`titledcard-${side}`} item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplatePQ} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull.nameplate.side[side].Un, 'V')}`}>
          <SubTitledCard gridArea='gr1' title={"unbalance"}>
            <DraggableGraph
              ys={sides[side] ? [[
                deviceMainHistory ? deviceMainHistory[(`unbi-${side}` as 'unbi-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`unbpnv-${side}` as 'unbpnv-1')] : undefined,
              ]] :
                [[
                  deviceMainHistory ? deviceMainHistory[(`unbi-${side}` as 'unbi-1')] : undefined,
                ]]}
              ysnames={sides[side] ? [[
                `unbi-${side}${translationAppend}`,
                `unbpnv-${side}${translationAppend}`,
              ]] : [[`unbi-${side}`]]}
              colors={sides[side] ? [[
                '#e23434',
                '#3473ba'
              ]] : [['#e23434']]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['unbalanceUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              removeLegendPrefix
              enableReportLinks
              forceLegend={!sides[side]}
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title={"thdv"}>
            <DraggableGraph
              ys={sides[side] ? [[
                deviceMainHistory ? deviceMainHistory[(`thdpnv-${side * 4}` as 'thdpnv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdpnv-${1 + side * 4}` as 'thdpnv-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdpnv-${2 + side * 4}` as 'thdpnv-1')] : undefined,
              ]] : [[[]]]}
              ysnames={[[
                `thdpnv-${side * 4}${translationAppend}`,
                `thdpnv-${1 + side * 4}${translationAppend}`,
                `thdpnv-${2 + side * 4}${translationAppend}`,
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={sides[side] ? 'no7days' : 'na'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr3' title={"thdc"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[(`thdi-${side * 4}` as 'thdi-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdi-${1 + side * 4}` as 'thdi-1')] : undefined,
                deviceMainHistory ? deviceMainHistory[(`thdi-${2 + side * 4}` as 'thdi-1')] : undefined,
              ]]}
              ysnames={[[
                `thdi-${side * 4}${translationAppend}`,
                `thdi-${1 + side * 4}${translationAppend}`,
                `thdi-${2 + side * 4}${translationAppend}`,
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['thdUnits']}
              graphType={'linegl'}
              emptyMessage={'no7days'}
              removeLegendPrefix
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
}
)





