import { makeStyles, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { verifyPassword } from '../../utils/format-data';

interface RemoveDialogProps {
  open: boolean,
  handleAgree: (password: string) => void,
  handleClose: () => void
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "200px"
  },
}));

export default function AssuranceDialog(props: RemoveDialogProps) {
  const { handleAgree, open, handleClose } = props;
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<number>(0);
  const [password2, setPassword2] = useState<string>('');
  const classes = useStyles();
  const intl = useIntl();

  const handleChange = (value: string, n: number) => {
    if (n === 0) {
      setPassword(value)
      console.log(verifyPassword(value),value)
      if (!verifyPassword(value)) {
        setError(2)
      } else if (value !== password2) {
        setError(1)
      } else {
        setError(0)
      }
    }
    if (n === 1) {
      setPassword2(value)
      if (!verifyPassword(value)) {
        setError(2)
      } else if (value !== password) {
        setError(1)
      } else {
        setError(0)
      }
    }
  }

  const handleSubmit = () => {
    if (verifyPassword(password)) {
      if (password === password2) {
        handleAgree(password);
      } else {
        setError(1)
      }
    } else {
      setError(2)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-describedby="remove-dialog-description" >

      <TextField
        id="select-search"
        label={<FormattedMessage id="password" />}
        type="password"
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        value={password}
        onChange={evt => handleChange(evt.target.value, 0)}
      />

      <TextField
        id="select-search-repeat"
        label={<FormattedMessage id="repeatPassword" />}
        type="password"
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        value={password2}
        {...(error === 1 && { error: true, helperText: `${intl.formatMessage({ id: 'mismatch' })}` })}
        {...(error === 2 && { error: true, helperText: `${intl.formatMessage({ id: 'passwordSpecialMessage' })}` })}
        onChange={evt => handleChange(evt.target.value, 1)}
      />

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id='cancel'></FormattedMessage>
        </Button>
        <Button onClick={() => handleSubmit()} color="primary" autoFocus>
          <FormattedMessage id='submit'></FormattedMessage>
        </Button>
      </DialogActions>
    </Dialog>
  );
}