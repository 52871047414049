import { Button, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { default as React, ReactElement, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ReactComponent as Gmotor } from "../../assets/equipmentIcons/g_motor_blueoptimized.svg";
import { ReactComponent as Motor } from "../../assets/equipmentIcons/motor_blueoptimized.svg";
import { ReactComponent as Pvm } from "../../assets/equipmentIcons/pvm_blueoptimized.svg";
import { ReactComponent as Bcm } from "../../assets/equipmentIcons/bcm_blueoptimized.svg";
import { ReactComponent as SimpleT } from "../../assets/equipmentIcons/simpleToptimized.svg";
import { ReactComponent as SimpleW } from "../../assets/equipmentIcons/simpleWoptimized.svg";
import { EntitiesContext } from "../../contexts/entities-context";
import { SubstationType } from '../../interfaces/Substations';
import AlertsMenu from "./AlertsMenu";
import SocketMenu from "./SocketMenu";
import { ThemeExtended } from './Theme';
import UsersMenu from "./UserMenu";


const useStyles = () => {
  return makeStyles((theme: ThemeExtended) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 10,
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: "#cfcfcf",
      borderBottomStyle: 'solid',
      borderBottomWidth: '.4px',
      "& .MuiButton-root": {
        minWidth: 57,
        [theme.breakpoints.down('xs')]: {
          minWidth: theme.spacing(4),
        }
      }
    },
    appBar: {
      top: 0,
      left: "auto",
      right: 0,
      display: "flex",
      position: "absolute",
      boxSizing: "border-box",
      flexShrink: 0,
      flexDirection: "column",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - ${theme.spacing(9)}px)`,
      marginLeft: theme.spacing(9),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
      }
    },
    appBarShift: {
      marginLeft: theme.shape.drawer.width,
      width: `calc(100% - ${theme.shape.drawer.width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
      }
    },
    title: {
      flexGrow: 1,
      paddingLeft: '33px',
      fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: '26px',
      color: theme.palette.primary.main,
      fontFamily: 'Titillium Web',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
      }
    },
    formControl: {
      borderLeft: ".4px solid hsla(0,0%,50.2%,.3764705882352941)",
      paddingLeft: 27,
      paddingTop: 20,
      height: 70,
    },
    select: {
      "&:before": {
        display: "none"
      },
      "&:after": {
        display: "none"
      },
      "& MuiSelect-icon": {
        display: "none"
      },
      "& .MuiSelect-selectMenu": {
        fontSize: "14px",
        textTransform: "uppercase",
        fontWeight: 600,
        color: "rgb(100, 139, 178)",
        paddingRight: 4
      },
      marginTop: "0px !important"
    },
    languageTitle: {
      fontSize: "17px",
      textTransform: "uppercase",
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
    menuItem: {
      fontSize: "14px",
      textTransform: "uppercase",
      fontWeight: 600,
      textAlign: "center",
      color: theme.palette.primary.light,
    },
    titleHolder: {
      display: "inline-flex",
      alignItems: "center"
    },
    title1: {
      paddingLeft: 2,
      fontSize: 27,
      fontWeight: 500,
      textTransform: "none"
    },
    title2: {
      fontSize: 27,
      fontWeight: 660,
      textTransform: "capitalize"
    },
    titleBar: {
      fontSize: 31,
      fontWeight: 700,
      paddingLeft: 11,
      paddingBottom: 4,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    title3: {
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: 26,
      position: "relative",
      padding: ".625rem 0",
      marginLeft: "1rem",
      whiteSpace: 'pre',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    titleImage: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      height: 40,
      width: 40,
      paddingBottom: 3,
      opacity: 1,
      verticalAlign: "middle",
      "& path": {
        fill: theme.palette.primary.main,
        transform: "scale(0.31)",
      }
    },
    divider: {
      height: 70
    },
    shortenButton: {
      height: '100%',
      width: 0,
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(9),
        display: 'unset',
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(6),
        display: 'unset',
      }
    }
  }))();
}



export default function Layout(props: { setOpenSidebar: any, openSidebar: boolean, drawerOpen: boolean, setLanguage: any, languages: Array<string>, language: string }) {
  const classes = useStyles();
  const { setLanguage, languages, language, setOpenSidebar, openSidebar } = props;
  const location = useLocation()
  const { substations, organizations } = useContext(EntitiesContext);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: React.MouseEvent<EventTarget>) => {
    setLanguage((event.target as any).getAttribute('value'))
    setOpen(false);
    return;
  }

  //TODO TRANSLATE THIS NEXT
  const tcmtitle = <div className={classes.titleHolder}>
    <SimpleT className={classes.titleImage} ></SimpleT>
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>Transf</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"TCM"}/></div>
  </div>

  const lptcmtitle = <div className={classes.titleHolder}>
    <SimpleT className={classes.titleImage} ></SimpleT>
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>Transf</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"LPTCM"}/></div>
  </div>

  const pvmtitle = <div className={classes.titleHolder}>
    <Pvm className={classes.titleImage} />
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>PV</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"PVM"}/></div>
  </div>

  const mcmtitle = <div className={classes.titleHolder}>
    <Motor className={classes.titleImage} />
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>Mot</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"MCM"}/></div>
  </div>

  const gcmtitle = <div className={classes.titleHolder}>
    <Gmotor className={classes.titleImage} />
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>Gen</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"GCM"}/></div>
  </div>

  const wtmtitle = <div className={classes.titleHolder}>
    <SimpleW className={classes.titleImage} />
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>WindT</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"WTM"}/></div>
  </div>

  const bcmtitle = <div className={classes.titleHolder}>
    <Bcm className={classes.titleImage} />
    <div className={classes.title1}>Predit</div>
    <div className={classes.title2}>BS</div>
    <div className={classes.titleBar}>|</div>
    <div className={classes.title3}><FormattedMessage id={"BCM"}/></div>
  </div>

  const makeTitle = (location: string | undefined, id?: string, third?: string): ReactElement => {
    //console.log(location,id)
    if (location) {
      // console.log("if location")
      if (id && id !== "org" && location !== 'substation') {                              // SE TIVER ID E NAO FOR SUBESTACAO ENTAO ENTROU NUM DEVICE
        const sub = substations?.find(sub => sub.devices_id.includes(id))
        // console.log("if id !substation",sub)
        if (sub) {
          if (sub.type === SubstationType['PVM']) {
            return pvmtitle;
          }
          if (sub.type === SubstationType['TCM']) {
            return tcmtitle;
          }
          if (sub.type === SubstationType['LPTCM']) {
            return lptcmtitle;
          }
          if (sub.type === SubstationType['MCM']) {
            return mcmtitle;
          }
          if (sub.type === SubstationType['GCM']) {
            return gcmtitle;
          }
          if (sub.type === SubstationType['WTM']) {
            return wtmtitle;
          }
          if (sub.type === SubstationType['BCM']) {
            return bcmtitle;
          }
        }
      }
      if (id && id === "org" && location !== 'substation') {                   // IF ID org , its in the admin page for organization
        // console.log("if id !substation")
        const org = organizations?.find(org => org._id === third)
        if (location === "tcm" || location === 'tcmd') {
          return <> {tcmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "lptcm" || location === 'lptcmd') {
          return <> {lptcmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "mcm") {
          return <> {mcmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "pvm") {
          return <> {pvmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "gcm") {
          return <> {gcmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "wtm") {
          return <> {wtmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
        if (location === "bcm") {
          return <> {bcmtitle} <div className={classes.title3}>{`- ${org?.name}`}</div></>;
        }
      }
      if (location === "substation") {                                   // SE FOR SUBESTACAO 
        // console.log("if substation")
        const sub = substations?.find(sub => sub._id === id)
        if (sub?.type === SubstationType['PVM']) {
          return <> {pvmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['TCM']) {
          return <> {tcmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['LPTCM']) {
          return <> {lptcmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['MCM']) {
          return <> {mcmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['GCM']) {
          return <> {gcmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['WTM']) {
          return <> {wtmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        if (sub?.type === SubstationType['BCM']) {
          return <> {bcmtitle} <div className={classes.title3}>{`> ${sub?.name}`}</div></>;
        }
        return <React.Fragment><FormattedMessage id={location} />{`: ${sub?.name}`}</React.Fragment>
      }
      if (location === "tcm" || location === 'tcmd') {                               // PAGINAS SIMPLES, como dashboard ou variantes, ou alertas....
        return tcmtitle;
      }
      if (location === "lptcm" || location === 'lptcmd' || location === 'lptcmt' || location === 'lptcmq' || location === 'lptcmc') {
        return lptcmtitle;
      }
      if (location === "icm") {
        return pvmtitle;
      }
      if (location === "mcm") {
        return mcmtitle;
      }
      if (location === "pvm") {
        return pvmtitle;
      }
      if (location === "gcm") {
        return gcmtitle;
      }
      if (location === "wtm") {
        return wtmtitle;
      }
      if (location === "bcm") {
        return bcmtitle;
      }
      if (location === "help") {
        return <><FormattedMessage id={location} /><div style={{ color: 'transparent' }}>{packageJson.version}</div></>;
        // ADDED VERSION OF THE PROJECT HERE
      }
      // console.log("else")
      return <FormattedMessage id={location} />
    } else {
      //console.log("main else")
      return <FormattedMessage id={"dashboard"} />
    }
  }

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  //console.log("TITLE:>", makeTitle(location.pathname.slice(1).split('/')[0], location.pathname.slice(1).split('/')[1]))
  //console.log("TITLEPOS>:", location.pathname.slice(1).split('/')[0], location.pathname.slice(1).split('/')[1])
  return (
    <div className={clsx(classes.appBar, props.drawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.shortenButton}><Button onClick={() => setOpenSidebar(!openSidebar)} ><MenuIcon /></Button></div>
        <div className={classes.title}>{makeTitle(location.pathname.slice(1).split('/')[0], location.pathname.slice(1).split('/')[1], location.pathname.slice(1).split('/')[2])}</div>
        <Divider className={classes.divider} orientation='vertical' />

        <SocketMenu />
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.languageTitle}
        >
          {language}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {languages && languages.map((value, index) => <MenuItem onClick={handleChange} className={classes.menuItem} key={`select-${index}`} value={value}>{value}</MenuItem>)}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <AlertsMenu />
        <UsersMenu />
      </Toolbar>
    </div>
  );
}