
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import { GraphPartsInterface } from '../../../interfaces/helper';
import { ThemeExtended } from '../../global/Theme';
import DraggableGraph from '../final/DraggableGraph';
import SubTitledCard from './SubTitledCard';

const useStyles = makeStyles((theme: ThemeExtended) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            overflowY: 'auto'
        },
        SideContainer: {
            display: "flex",
            height: "100%"
        },
        height: {
            height: theme.shape.graphBox.normal.height,
            width: "99.5%",
            [theme.breakpoints.down('sm')]: {
                height: "350px",
            }
        }
    }),
);

interface justgraphprops extends GraphPartsInterface {
    title: string
}

export default function JustGraph(props: React.PropsWithChildren<justgraphprops>) {
    const classes = useStyles();
    const { title } = props;
    const mainGridReference = useRef(null);
    //primario e secundario
    return (
        <Grid ref={mainGridReference} container className={classes.SideContainer}>
            <Grid className={classes.height} key={`titledcard-1`} item md={12}>
                <SubTitledCard divclass={classes.height} noShadow={true} gridArea='gr1' title={title}>
                    <DraggableGraph
                        {...props}
                    ></DraggableGraph>
                </SubTitledCard>
            </Grid>
        </Grid >
    );
}
