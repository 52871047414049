import { JSXElementConstructor, PropsWithChildren, useContext, useEffect, useState } from "react";
import APIRequestContext from "../../contexts/api-context";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { Roles } from "../../interfaces/User";

export default function PrivateRoute(props: PropsWithChildren<{ roles: Array<Roles>, Component: JSXElementConstructor<any>, extra?: any } & RouteProps>) {
    const { user } = useContext(APIRequestContext);
    const { Component, roles, ...rest } = props;
    const [isVisible, seVisibility] = useState(user !== undefined && roles.some((r) => r === user.role));

    useEffect(() => {
        seVisibility(user !== undefined && roles.some((r) => r === user.role));
    }, [user, roles])

    return (
        <Route {...rest} render={(props) => isVisible ? <Component {...props} /> : <Redirect to="/" />} />
    );
}