import { CircularProgress, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Plotly from '../../../utils/custom-plotly';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fixDiv: {
            width: "100%",
            overflow: "hidden",
            borderRadius: "10px 10px 10px 10px",
            //height: "275px"
        },
        circularProgress: {
            margin: "auto"
        },
        text: {
            "& text": {
                fontFamily: "Titillium Web,\"Open Sans\", verdana, arial, sans-serif !important",
                fill: theme.palette.primary.main,
                fillOpacity: 1,
                color: theme.palette.primary.main,
                height: 37,
                margin: "auto",
                fontSize: "13px !important",
                fontWeight: 700,
                whiteSpace: "nowrap",
            }
        }
    }),
);


interface PolarGraphProps {
    xs: Array<Array<number>>
    ys: Array<Array<number>> // | undefined
    ysnames: string[],
    colors: string[],
    verySmall?: boolean,
    empty?: boolean,
}



export default function PolarGraph(props: React.PropsWithChildren<PolarGraphProps>) {
    const classes = useStyles();
    const { xs, ys, ysnames, colors, verySmall, empty } = props;
    const intl = useIntl()
    const [undef, setUndef] = useState<boolean>(true);
    const plotlyId = Math.round(Math.random() * 10000000).toString();
    const theme = useTheme();

    useEffect(() => {
        const max = xs && xs.length > 0 ? xs.map(x => x[1]).reduce((a, b) => a > b ? a : b) : 1
        const config: Partial<Plotly.Config> = empty ? {
            responsive: false,
            displayModeBar: false,
            staticPlot: true,
            displaylogo: false
        } : {
                responsive: true,
                displayModeBar: false
            }
        const data: Plotly.Data[] = ys.map((yval, index) => {
            return {
                type: 'scatterpolar',
                name: ysnames[index],
                marker: {
                    color: colors[index],
                    size: 5
                },
                r: xs[index],
                theta: yval,
                mode: 'lines',
                hovertemplate: '<i>Phase</i>: ' + (yval[1] ? yval[1].toLocaleString() : "") +
                    '<br><i>Amplitude</i>: ' + (xs[index] && xs[index][1] ? xs[index][1].toLocaleString() : ""),
                line: {
                    width: verySmall ? 1.5 : 2.5,
                }
            }
        });
        const layoutEmpty: Partial<Plotly.Layout> = { ///          PREPARE THE LAYOUTS
            polar: {
                radialaxis: {
                    visible: false
                },
                angularaxis: {
                    visible: false
                }
            },
            xaxis: {
                visible: false
            },
            yaxis: {
                visible: false
            },
            annotations: [
                {
                    text: intl.formatMessage({ id: "na" }),
                    xref: "paper" as unknown as any,
                    yref: "paper" as unknown as any,
                    showarrow: false,
                    font: {
                        size: 19,
                        color: theme.palette.primary.main
                    }
                }
            ]
        }
        const layoutNormal: Partial<Plotly.Layout> = {
            showlegend: true,
            margin: {
                t: verySmall ? 0 : 30,
                l: verySmall ? 30 : 40,
                r: 0,
                b: verySmall ? 0 : 25,
                pad: 0
            },
            ...(verySmall && { legend: { x: 0.9, y: 1, bgcolor: "transparent", font: { size: 10 } } }),
            polar: {
                bgcolor: 'transparent',
                angularaxis: {          ///  angulo
                    tickwidth: 1,
                    linewidth: 2,
                    layer: 'below traces' as 'below traces',
                    color: 'rgba(54,97,146,0.8)',
                    tickangle: verySmall ? 45 : undefined,
                    tickfont: verySmall ? {
                        size: 11
                    } : {}
                },
                radialaxis: {          /// amplitude
                    side: 'counterclockwise' as 'clockwise',
                    showline: false,
                    linewidth: 0.5,
                    tickwidth: 0.5,
                    color: 'rgba(88,88,88,0.8)',
                    gridwidth: 0.5,
                    dtick: 0.2,
                    range: [0, max === 0 ? 0.01 : max],
                    tickfont: verySmall ? {
                        size: 10
                    } : {}
                }
            }
        }
        const tempUndefined = !ys.some(item => item !== undefined)
        let emptyFinal = false;
        if (!tempUndefined) {
            emptyFinal = ys.reduce((a, b) => (a as Array<number>).concat(b !== undefined ? b : []), []).length === 0
        }
        let layout;
        emptyFinal = empty ? empty : emptyFinal;
        if (emptyFinal) { layout = layoutEmpty } else { layout = layoutNormal }
        if (data && layout && !tempUndefined) {
            try {
                Plotly.newPlot(plotlyId, empty ? undefined : data, layout, config)
            } catch (e) {
                // WHEN THE GRAPH IS TOO SMALL IT GIVES ERROR and doesnt work
            }
        }
        setUndef(tempUndefined);
    }, [colors, empty, intl, plotlyId, theme.palette.primary.main, verySmall, xs, ys, ysnames]);

    return (<div style={{ height: "100%", display: "flex" }}>
        {undef && <CircularProgress className={classes.circularProgress} color="inherit" />}
        {/* {!undef && <Plot className={classes.fixDiv} data={data} layout={layout} config={config}></Plot>} */}
        <div id={plotlyId} className={clsx(classes.fixDiv, empty && classes.text)}></div>
    </div>
    );
}