
import React, { useContext } from 'react';
import BigGraphSmallData from '../../../components/device/middle/BigGraphSmallData';
import { EntitiesContext } from "../../../contexts/entities-context";


export default function DiagTapChanger(props: React.PropsWithChildren<any>) {
  const { deviceFull } = useContext(EntitiesContext);
  let tapTotal = deviceFull && deviceFull.statistics && deviceFull.statistics.tapChanges;
  return (
    <BigGraphSmallData
      big={{
        titlex: '',
        graphType: 'bar',
        ys: [[
          deviceFull && deviceFull.statistics && deviceFull.statistics.tapOccurrence ? deviceFull.statistics.tapOccurrence.map((value) => value / deviceFull!.statistics!.tapOccurrence.reduce((a, b) => a + b) * 100) : [],
        ]],
        ysnames: [["sf-0", "avg"]],
        colors: [[(deviceFull && deviceFull.statistics && deviceFull.statistics.tapOccurrence ?
          deviceFull.statistics.tapOccurrence.map((value) => value / deviceFull!.statistics!.tapOccurrence.reduce((a, b) => a + b) * 100) :
          []).map((value) => {
            if (value > 80) {
              return '#e23434b3' as string
            } else if (value > 60) {
              return '#ff8f4db3' as string
            } else if (value > 40) {
              return '#ffc000b3' as string
            } else if (value > 20) {
              return '#2aa060b3' as string
            } else {
              return '#006400b3' as string
            }
          }
          )]],
        titley: ["tapUsage"],
        emptyMessage: 'no7days',
        notTimezone: true,
        x: deviceFull && deviceFull.statistics && deviceFull.statistics.tapOccurrence ? deviceFull.statistics.tapOccurrence.map((value, index) => index + 1) : []
      }}
      title='tapUsageTitle'
      small={
        [[{ title: 'tapNumber', value: tapTotal ? tapTotal.toString() : 0 }]]
      }
      autoHeight
      smallTitles={['tapTotal']}
    ></BigGraphSmallData>
  );
}






