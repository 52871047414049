
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PolarGraph from '../../../components/device/final/PolarGraph';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forVectorGraphsICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateVG: {
      gridTemplateAreas: `
          "gr1 gr2"
          "gr1 gr3"`,
      gridTemplateRows: "0.7fr 1.3fr",
      gridTemplateColumns: "1fr 250px",
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "gr1"
          "gr2"`,
        gridTemplateRows: "67% 32%",
        gridTemplateColumns: "100%",
        maxHeight: "670px",
        gridGap: "6px",
        padding: "10px",
      }
    },
  }),
);

interface VectorProps {

}

export default function VectorGraph(props: React.PropsWithChildren<VectorProps>) {
  const classes = useStyles();
  const { deviceReport } = useContext(EntitiesContext);
  //const { hasI } = props;
  const intl = useIntl()
  const data = forVectorGraphsICM(deviceReport, false)
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateVG}>
          <SubTitledCard gridArea='gr1'>
            <PolarGraph
              ys={data ? data.map(form => form.angulo) : [[]]}
              ysnames={['Vuv', 'Vvw', 'Vwu']}
              colors={['#f15d5d', '#51c787', '#6299d8']}
              xs={data ? data.map(form => form.amplitude) : []}
            ></PolarGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr2' title='voltage'>
            <SmallCornerBox
              parts={[
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: 'ppv-0-icm', value: [si_format(data ? data[0].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[0].angulo[1] : 0, 'º', 1, true)] },
                { title: 'ppv-1-icm', value: [si_format(data ? data[1].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[1].angulo[1] : 0, 'º', 1, true)] },
                { title: 'ppv-2-icm', value: [si_format(data ? data[2].amplitude[1] : 0, 'pu', 2, true), si_format(data ? data[2].angulo[1] : 0, 'º', 1, true)] },
              ]}
              height={"unset"}
            ></SmallCornerBox>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}






