import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceFull } from '../../../interfaces/Device'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "2.25rem"
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
    formAction: {
      marginTop: "10px",
      textAlign: "right"
    },
    mainDiv: {
      display: "flex",
      margin: 15,
      width: "100%",
      justifyContent: "space-evenly"
    },
    subTitle: {
      margin: "18px 15px 0 15px",
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.53,
      color: theme.palette.primary.main,
    },
    subText: {
      fontWeight: 700,
      whiteSpace: "pre-line"
    },
    tableColumns: {
      display: "grid",
      gridTemplateColumns: "50% 50%"
    },
    centerTable: {
      margin: "auto"
    }
  }),
);

interface DeviceDialogProps {
  open: boolean;
  device?: DeviceFull;
  handleClose: () => void;
  handleSubmit?: () => void;
}

export default function DeviceDialog(props: React.PropsWithChildren<DeviceDialogProps>) {
  const { open, device, handleClose, handleSubmit } = props;
  const classes = useStyles();


  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitle}>
            <div><FormattedMessage id="generalInfo" /></div>
            <IconButton size="small" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              <h3 className={classes.subTitle}><FormattedMessage id="buildInfo" /></h3>
              <div className={classes.mainDiv}>
                <div>
                  <div className={classes.subText}><FormattedMessage id="manufacturer" /></div>
                  {device?.nameplate.manufacturer}
                </div>
                <div>
                  <div className={classes.subText}><FormattedMessage id="model" /></div>
                  {device?.nameplate.model}
                </div>
                <div>
                  <div className={classes.subText}><FormattedMessage id="serial" /></div>
                  {device?.nameplate.serial_number}
                </div>
                <div>
                  <div className={classes.subText}><FormattedMessage id="year" /></div>
                  {device?.nameplate.year}
                </div>
              </div>
            </div>
            <div>
              <h3 className={classes.subTitle}><FormattedMessage id="nominalParams" /></h3>
              <div className={classes.mainDiv}>

                <div>
                  <div className={classes.subText}><FormattedMessage id="power" /></div>
                  {device?.nameplate.side[0].Sn_ONAF} kVA
                </div>
                <div>
                  <div className={classes.subText}><FormattedMessage id="voltage" /></div>
                  {device?.nameplate.side[0].Un} V
                </div>

                <div>
                  <div className={classes.subText}><FormattedMessage id="current" /></div>
                  {device?.nameplate.side[0].In} A
                </div>
                <div>
                  <div className={classes.subText}><FormattedMessage id="frequency" /></div>
                  {device?.nameplate.frequency} Hz
                </div>
              </div>
            </div>


          </div>






          <div className={classes.formAction}>
            <Button onClick={handleClose} className={classes.cancelButton}><FormattedMessage id="cancel" /></Button>
            {handleSubmit && <Button variant="contained" color="primary" onClick={handleSubmit}><FormattedMessage id="submit" /></Button>}
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}