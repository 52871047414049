import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'; // TYPES ESTAO NESTE REPOSITORIO E NAO VEM NO PAKCAGE
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "225px",
    paddingTop: 15
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: "107px"
  },
  pageLegend: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    marginTop: theme.spacing(1)
  }
}));

interface DynamicImpedanceSearchProps {
  handleUpdate: (filters: {
    since: string;
    until: string;
    starttap: number;
    endtap: number;
  }) => void;
  maxTap: number;
}

export default function DynamicImpedanceSearch(props: DynamicImpedanceSearchProps) {
  const { handleUpdate, maxTap } = props;
  const classes = useStyles();

  const addHours = (value: Date, hours: number) => {
    var newDate = new Date()
    newDate.setTime(value.getTime() + hours * 60 * 60 * 1000)
    return newDate
  }
  const [value, onChange] = useState<[Date, Date]>([addHours(new Date(), -(24 * 31)), new Date()]);
  const [filters, setFilters] = useState({ starttap: -1, endtap: -1 });

  useEffect(() => {
    handleUpdate({
      since: value[0].toISOString(),
      until: value[1].toISOString(),
      starttap: parseInt(filters.starttap.toString(), 10),
      endtap: parseInt(filters.endtap.toString(), 10)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, value])

  const handleChange = (value: any, key: string) => {
    setFilters({ ...filters, [key]: value });
  };


  const makeTapList = (maxTap: number, otherTap: number, thistap: number): Array<number> => {
    if (maxTap === undefined) {
      return []
    }
    if (otherTap === -1) {
      return Array(maxTap).fill(1).map((val, ind) => ind + 1)
    }
    let ret = []
    if (otherTap - 1 > 0) {
      ret.push(otherTap - 1)
    }
    if (otherTap + 1 < maxTap + 1 && otherTap + 1 > 0) {
      ret.push(otherTap + 1)
    }
    return ret
  }

  return (
    <Grid container>
      <Grid item>
        <DateRangePicker
          className={classes.formControl}
          onChange={onChange}
          value={value}
          calendarIcon={<TodayIcon />}
          clearIcon={null}
          required
        />
      </Grid>
      <Grid item>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-stap"><FormattedMessage id={'startTap'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'stap', id: 'select-stap' }}
            value={filters.starttap}
            onChange={evt => handleChange(evt.target.value, "starttap")}
          >
            <MenuItem aria-label="None" value={-1} ><i><FormattedMessage id={'all'} /></i></MenuItem>
            {makeTapList(maxTap, filters.endtap, filters.starttap).map((x, i) => <MenuItem key={`select-stap-${i}`} value={x}>{x}</MenuItem >)}
          </Select>
        </FormControl>
        <FormControl className={classes.formControlSmall}>
          <InputLabel htmlFor="select-etap"><FormattedMessage id={'endTap'}></FormattedMessage></InputLabel>
          <Select
            inputProps={{ name: 'etap', id: 'select-etap' }}
            value={filters.endtap}
            onChange={evt => handleChange(evt.target.value, "endtap")}
          >
            <MenuItem aria-label="None" value={-1} ><i><FormattedMessage id={'all'} /></i></MenuItem>
            {makeTapList(maxTap, filters.starttap, filters.endtap).map((x, i) => <MenuItem key={`select-etap-${i}`} value={x}>{x}</MenuItem >)}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}