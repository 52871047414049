import { DeviceType } from "../interfaces/Device";

// export enum DeviceType {
//   M = "1", // MCM
//   L = "2", // LP-TCM
//   Ld = "3", // LP-TCMd
//   T = "4", // TCM
//   DZ = "5",   // dynz ou dynamic impedance 
//   OL = "7", // OLTC ou inrush ou corrente de magnetizacao
//   I = "8", // ICM
//   Gi = "9", // GCM
//   Td = "A",//"10", // TCM duplo sec
//   OLd = "B",//"11", // OLTCd
//   DZd = "D",//"13" // inrushd
//   X = "E", // XCM (Rn)
//   Gc = "F", // GCM Conventional
//   Gs = "G", // GCM Synchronous
//   Gm = "H", // GCM Magnets
//   L3 = "I", // lptcm triple sec
//   L4 = "J", // lptcm quadruple sec
//   L5 = "K", // lptcm quintuple sec
// }

const ViewProduct = {
  GENERAL: 0,
  ANALYTICS: 1
}

const permissionBetter = [
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "000",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "001",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "002",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "003",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "004",
    vr: ["sf", "ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "005",
    vr: ["sf", "ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "006",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "007",
    vr: ["ppv", "iv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq", "thdppv"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "008",
    vr: ["ppv", "iv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq", "thdppv"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "009",
    vr: ["iv", "vari", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "00a",
    vr: ["ppv", "iv", "vari", "freq", "apparpwr", "actpwr", "reactpwr", "cosphi"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "00b",
    vr: ["iv", "ppv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "00c",
    vr: ["iv", "ppv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "00d",
    vr: ["iv", "ppv", "varppv", "pnv", "freq", "vari", "varppv", "apparpwr", "reactpwr"],
    vw: [],
  },
  {
    name: "Control Panel - Mechanical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "00e",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Control Panel - Mechanical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "00f",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "010",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "011",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "012",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "013",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "014",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "015",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "016",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Tap Changer",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "017",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Control Panel - Tap Changer",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "018",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "019",
    vr: ["thdpnv", "thdi", "unbi", "unbpnv", "unbppv"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "01a",
    vr: ["thdpnv", "thdi", "unbi", "unbpnv", "unbppv"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "01b",
    vr: ["thdi", "unbi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "01c",
    vr: ["thdi", "unbi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "01d",
    vr: ["unbi", "unbppv", "thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "01e",
    vr: ["unbi", "unbppv", "thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "01f",
    vr: ["thdppv", "thdi", "unbi", "unbppv"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "020",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "021",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "022",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "023",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "024",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "025",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "026",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "027",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "028",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "029",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "02a",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "02b",
    vr: ["graph"],
    vw: ["wppv", "wi", "wiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "02c",
    vr: ["graph"],
    vw: ["wppv", "wi", "wiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "02d",
    vr: ["graph"],
    vw: ["wppv", "wi", "wvpiexc", "time"],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "02e",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "02f",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "030",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "031",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "032",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "033",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "034",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Excitation Currents",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "035",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Diagnostic - Excitation Currents",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "036",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Diagnostic - Excitation Currents",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "037",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Diagnostic - Excitation Currents",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "038",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Diagnostic - Tap Changer",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "039",
    vr: [],
    vw: [],
  },
  {
    name: "Diagnostic - Tap Changer",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "03a",
    vr: [],
    vw: [],
  },
  {
    name: "Diagnostic - Transformation Ratio",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "03b",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Diagnostic - Transformation Ratio",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "03c",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Diagnostic - Transformation Ratio",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "03d",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Diagnostic - Transformation Ratio",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "03e",
    vr: ["rt"],
    vw: [],
  },
  {
    name: "Diagnostic - Short Circuit Inductance",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "03f",
    vr: ["varlcc", "oltc", "graph"],
    vw: [],
  },
  {
    name: "Diagnostic - Short Circuit Inductance",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "040",
    vr: ["varlcc", "oltc", "graph"],
    vw: [],
  },
  {
    name: "Diagnostic - Spectral Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "041",
    vr: ["graph"],
    vw: ["wdynz"],
  },
  {
    name: "Diagnostic - Spectral Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "042",
    vr: ["graph"],
    vw: ["wdynz"],
  },
  {
    name: "Diagnostic - Instantaneous Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "043",
    vr: ["ppv", "iv", "apparpwr", "actpwr", "reactpwr", "cosphi", "graph"],
    vw: ["wpnv"],
  },
  {
    name: "Diagnostic - Instantaneous Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "044",
    vr: ["ppv", "iv", "apparpwr", "actpwr", "reactpwr", "cosphi", "graph"],
    vw: ["wpnv"],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "045",
    vr: ["thdpnv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "046",
    vr: ["thdpnv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.L,
    tab: "047",
    vr: ["thdpnv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Ld,
    tab: "048",
    vr: ["thdpnv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.M,
    tab: "049",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gi,
    tab: "04a",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.I,
    tab: "04b",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Dynamic Impedance",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "04c",
    vr: ["graph"],
    vw: ["wdynz", "time"],
  },
  {
    name: "Diagnostic - Dynamic Impedance",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "04d",
    vr: ["graph"],
    vw: ["wdynz", "time"],
  },
  {
    name: "Diagnostic - Inrush Current",
    product: ViewProduct.GENERAL,
    solution: DeviceType.T,
    tab: "04e",
    vr: ["graph"],
    vw: ["wpnv", "time"],
  },
  {
    name: "Diagnostic - Inrush Current",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Td,
    tab: "04f",
    vr: ["graph"],
    vw: ["wpnv", "time"],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "050",
    vr: ["sf", "ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "051",
    vr: ["sf", "ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "052",
    vr: ["sf", "ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "053",
    vr: ["iv", "ppv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "054",
    vr: ["iv", "ppv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "055",
    vr: ["iv", "ppv", "pnv", "vari", "varpnv", "varppv", "apparpwr", "actpwr", "reactpwr", "cosphi", "freq"],
    vw: [],
  },
  {
    name: "Control Panel - Mechanical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "056",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Control Panel - Mechanical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "057",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Control Panel - Mechanical Parameters",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "058",
    vr: ["iexc"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "059",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "05a",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Load Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "05b",
    vr: ["ldratio"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "05c",
    vr: ["unbi", "unbppv", "thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "05d",
    vr: ["unbi", "unbppv", "thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Power Quality",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "05e",
    vr: ["unbi", "unbppv", "thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "05f",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "060",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Vector Diagram",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "061",
    vr: ["phasors"],
    vw: [],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "062",
    vr: ["graph"],
    vw: ["wppv", "wi", "wiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "063",
    vr: ["graph"],
    vw: ["wppv", "wi", "wiexc", "time"],
  },
  {
    name: "Control Panel - Waveforms",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "064",
    vr: ["graph"],
    vw: ["wppv", "wi", "wiexc", "time"],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "065",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "066",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Global Status",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "067",
    vr: ["sf"],
    vw: [],
  },
  {
    name: "Diagnostic - Spectral Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "068",
    vr: ["graph"],
    vw: ["wdynz"],
  },
  {
    name: "Diagnostic - Spectral Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "069",
    vr: ["graph"],
    vw: ["wdynz"],
  },
  {
    name: "Diagnostic - Spectral Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "06a",
    vr: ["graph"],
    vw: ["wdynz"],
  },
  {
    name: "Diagnostic - Instantaneous Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "06b",
    vr: ["ppv", "iv", "apparpwr", "actpwr", "reactpwr", "cosphi", "graph"],
    vw: ["wpnv"],
  },
  {
    name: "Diagnostic - Instantaneous Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "06c",
    vr: ["ppv", "iv", "apparpwr", "actpwr", "reactpwr", "cosphi", "graph"],
    vw: ["wpnv"],
  },
  {
    name: "Diagnostic - Instantaneous Analysis",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "06d",
    vr: ["ppv", "iv", "apparpwr", "actpwr", "reactpwr", "cosphi", "graph"],
    vw: ["wpnv"],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gc,
    tab: "06e",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gs,
    tab: "06f",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Diagnostic - Harmonics",
    product: ViewProduct.GENERAL,
    solution: DeviceType.Gm,
    tab: "070",
    vr: ["thdppv", "thdi"],
    vw: [],
  },
  {
    name: "Control Panel - Navbar",
    product: 0,
    solution: DeviceType.L3,
    tab: "071",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: []
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: 0,
    solution: DeviceType.L3,
    tab: "072",
    vr: ["ppv", "thdppv", "varppv", "varpnv", "pnv", "iv", "vari", "freq", "apparpwr", "actpwr", "reactpwr", "cosphi"],
    vw: []
  },
  {
    name: "Control Panel - Load Diagram",
    product: 0,
    solution: DeviceType.L3,
    tab: "073",
    vr: ["ldratio"],
    vw: []
  },
  {
    name: "Control Panel - Power Quality",
    product: 0,
    solution: DeviceType.L3,
    tab: "074",
    vr: ["thdpnv", "thdi", "unbi", "unbpnv", "unbppv"],
    vw: []
  },

  {
    name: "Control Panel - Vector Diagram",
    product: 0,
    solution: DeviceType.L3,
    tab: "075",
    vr: ["phasors"],
    vw: []
  },

  {
    name: "Control Panel - Waveforms",
    product: 0,
    solution: DeviceType.L3,
    tab: "076",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"]
  },

  {
    name: "Diagnostic - Global Status",
    product: 0,
    solution: DeviceType.L3,
    tab: "077",
    vr: ["sf"],
    vw: []
  },

  {
    name: "Diagnostic - Excitation Currents",
    product: 0,
    solution: DeviceType.L3,
    tab: "078",
    vr: ["iexc"],
    vw: []
  },

  {
    name: "Diagnostic - Transformation Ratio",
    product: 0,
    solution: DeviceType.L3,
    tab: "079",
    vr: ["rt"],
    vw: []
  },

  {
    name: "Diagnostic - Harmonics",
    product: 0,
    solution: DeviceType.L3,
    tab: "07a",
    vr: ["thdpnv", "thdi"],
    vw: []
  },

  {
    name: "Control Panel - Navbar",
    product: 0,
    solution: DeviceType.L4,
    tab: "07b",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: []
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: 0,
    solution: DeviceType.L4,
    tab: "07c",
    vr: ["ppv", "thdppv", "varppv", "varpnv", "pnv", "iv", "vari", "freq", "apparpwr", "actpwr", "reactpwr", "cosphi"], vw: []
  },
  {
    name: "Control Panel - Load Diagram",
    product: 0,
    solution: DeviceType.L4,
    tab: "07d",
    vr: ["ldratio"],
    vw: []
  },
  {
    name: "Control Panel - Power Quality",
    product: 0,
    solution: DeviceType.L4,
    tab: "07e",
    vr: ["thdpnv", "thdi", "unbi", "unbpnv", "unbppv"],
    vw: []
  },

  {
    name: "Control Panel - Vector Diagram",
    product: 0,
    solution: DeviceType.L4,
    tab: "07f",
    vr: ["phasors"],
    vw: []
  },

  {
    name: "Control Panel - Waveforms",
    product: 0,
    solution: DeviceType.L4,
    tab: "080",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"]
  },

  {
    name: "Diagnostic - Global Status",
    product: 0,
    solution: DeviceType.L4,
    tab: "081",
    vr: ["sf"],
    vw: []
  },

  {
    name: "Diagnostic - Excitation Currents",
    product: 0,
    solution: DeviceType.L4,
    tab: "082",
    vr: ["iexc"],
    vw: []
  },

  {
    name: "Diagnostic - Transformation Ratio",
    product: 0,
    solution: DeviceType.L4,
    tab: "083",
    vr: ["rt"],
    vw: []
  },

  {
    name: "Diagnostic - Harmonics",
    product: 0,
    solution: DeviceType.L4,
    tab: "084",
    vr: ["thdpnv", "thdi"],
    vw: []
  },



  {
    name: "Control Panel - Navbar",
    product: 0,
    solution: DeviceType.L5,
    tab: "085",
    vr: ["sf", "ldratio", "iexc", "tappos"],
    vw: []
  },
  {
    name: "Control Panel - Electrical Parameters",
    product: 0,
    solution: DeviceType.L5,
    tab: "086",
    vr: ["ppv", "thdppv", "varppv", "varpnv", "pnv", "iv", "vari", "freq", "apparpwr", "actpwr", "reactpwr", "cosphi"],
    vw: []
  },
  {
    name: "Control Panel - Load Diagram",
    product: 0,
    solution: DeviceType.L5,
    tab: "087",
    vr: ["ldratio"],
    vw: []
  },
  {
    name: "Control Panel - Power Quality",
    product: 0,
    solution: DeviceType.L5,
    tab: "077",
    vr: ["thdpnv", "thdi", "unbi", "unbpnv", "unbppv"],
    vw: []
  },

  {
    name: "Control Panel - Vector Diagram",
    product: 0,
    solution: DeviceType.L5,
    tab: "089",
    vr: ["phasors"],
    vw: []
  },

  {
    name: "Control Panel - Waveforms",
    product: 0,
    solution: DeviceType.L5,
    tab: "08a",
    vr: ["graph"],
    vw: ["wpnv", "wi", "wvpiexc", "time"]
  },

  {
    name: "Diagnostic - Global Status",
    product: 0,
    solution: DeviceType.L5,
    tab: "08b",
    vr: ["sf"],
    vw: []
  },

  {
    name: "Diagnostic - Excitation Currents",
    product: 0,
    solution: DeviceType.L5,
    tab: "08c",
    vr: ["iexc"],
    vw: []
  },

  {
    name: "Diagnostic - Transformation Ratio",
    product: 0,
    solution: DeviceType.L5,
    tab: "08d",
    vr: ["rt"],
    vw: []
  },

  {
    name: "Diagnostic - Harmonics",
    product: 0,
    solution: DeviceType.L5,
    tab: "08e",
    vr: ["thdpnv", "thdi"],
    vw: []
  }

]


export default permissionBetter;