
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import GaugeGraph from '../../../components/device/final/GaugeGraph';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { forGaugesICM } from '../../../utils/icm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga1 gr1"
          "ga2 gr1"`,
        gridTemplateRows: "repeat(2, calc(50% - 3px))",
        gridTemplateColumns: "calc(13% - 3px) calc(87% - 3px)",
        maxHeight: "670px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "ga1 gr1"
          "ga2 gr1"`,
        gridTemplateRows: "1fr 1fr",
        gridTemplateColumns: "20% 79.5%",
        maxHeight: "670px",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
          "ga1"
          "gr1"`,
        gridTemplateRows: "0.2fr 1fr",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

interface ElectricParamProps {

}

export interface gaugeFinal {
  name: string,
  value: number | undefined,
  color: string,
  max: number | undefined
}

export interface gaugeMid {
  title: string
  array: Array<gaugeFinal>
}

export interface gaugeProps extends Array<gaugeMid> {
}


export default function ElectricParam(props: React.PropsWithChildren<ElectricParamProps>) {
  const classes = useStyles();
  //const { hasI } = props;
  const { deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const gaugeData = forGaugesICM(deviceReport);
  //primario e secundario
  return (
    <Grid container className={classes.SideContainer}>
      <Grid item xs={12}>
        <TitledCard gridTemplate={classes.gridtemplateEP1} >
          <SubTitledCard gridArea={`ga1`} title={gaugeData[0].title}>
            <GaugeGraph bigValue data={gaugeData[0].array} variable={gaugeData[0].variable}></GaugeGraph>
          </SubTitledCard>
          <SubTitledCard gridArea='gr1' title={"voltage"}>
            <DraggableGraph
              ys={[[
                deviceMainHistory ? deviceMainHistory[`ppv-0`] : undefined,
                deviceMainHistory ? deviceMainHistory[`ppv-1`] : undefined,
                deviceMainHistory ? deviceMainHistory[`ppv-2`] : undefined
              ]]}
              ysnames={[[
                (`ppv-0-icm`),
                (`ppv-1-icm`),
                (`ppv-2-icm`)
              ]]}
              colors={[[
                '#e23434',
                '#2aa060',
                '#3473ba'
              ]]}
              x={deviceMainHistory ? deviceMainHistory.timestamp : undefined}
              titlex={''}
              titley={['voltageUnits']}
              graphType={"linegl"}
              emptyMessage={'no7days'}
              enableReportLinks
            ></DraggableGraph>
          </SubTitledCard>
        </TitledCard>

      </Grid>
    </Grid >
  );
}
