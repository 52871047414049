
import { Grid, isWidthDown, makeStyles, withWidth } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import DraggableGraph from '../../../components/device/final/DraggableGraph';
import SmallTable from '../../../components/device/final/SmallTable';
import DynamicImpedanceSearch from '../../../components/device/middle/DynamicImpedanceSearch';
import TitledCard from '../../../components/device/middle/TitledCard';
import APIRequestContext from '../../../contexts/api-context';
import { EntitiesContext } from "../../../contexts/entities-context";
import { DeviceType, DeviceWaveforms } from '../../../interfaces/Device';
import { ListResponse, SingleResponse } from '../../../interfaces/Responses';

const useStyles = () => {
  return makeStyles((theme) => ({
    fixedWidth: {
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        maxWidth: 'unset',
      },
      maxWidth: 178
    },
    remainingWidth: {
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        maxWidth: '100%',
      },
      maxWidth: "calc(100% - 178px)"
    },
  }
  ))();
}

interface TCMProps {
  isTCMD?: boolean;
}

export default withWidth()(function DynamicImpedance(props: React.PropsWithChildren<TCMProps>) {
  const { isTCMD } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [startTap, setStartTap] = useState<number | undefined>()
  const [endTap, setEndTap] = useState<number | undefined>()
  const [table, setTable] = useState<Array<any>>()
  const [id, setId] = useState<string>()
  const { apiRequest } = useContext(APIRequestContext);
  const [graph, setGraph] = useState<DeviceWaveforms>()
  const [loading, setLoading] = useState<boolean>(true)
  const classes = useStyles()
  const taps = useMemo(() => {
    let a = deviceFull?.nameplate.side[0].Tap.map(v => v[0]).length
    if (a) return a;
    else return 0;
  }, [deviceFull])

  const getReports = (since: string, until: string, device_id: string | undefined, tapS?: string, tapE?: string) => {
    setLoading(true);
    apiRequest<ListResponse<any>>("GET", "api/v1/report/", {
      query: {
        type: isTCMD ? DeviceType.DZd : DeviceType["DZ"],
        device_id: device_id,
        since: (new Date(since)).toISOString(),
        until: (new Date(until)).toISOString(),
        ...(tapS && tapE ? ({ transition: `${tapS + '-' + tapE}` }) : {})
      }
    })
      .then(result => {
        if (result.values.length === 0) setLoading(false);
        const table = result.values.map(value => {
          value.startTap = value.tapChanger.oltcTransitions[0][0];
          value.endTap = value.tapChanger.oltcTransitions[0][1];
          return value
        });
        setTable(table);
        getGraph(table[0]?.graph_id)
      })
      .catch(error => { setTable(undefined) });
  }

  const getGraph = (graph_id: string | undefined) => {
    // console.log(graph_id)
    if (graph_id && graph_id !== id) {
      setLoading(true);
      apiRequest<SingleResponse<any>>("GET", "api/v1/graph/" + graph_id)
        .then(result => { setGraph(result.value.waveforms); setId(result.value._id); setLoading(false); })
        .catch(error => { setGraph(undefined); setLoading(false); });
    } else if (graph_id === id) {
      setLoading(false);
    } else {
      setLoading(false);
      setGraph(undefined)
    }
  }

  const updateList = (filters: any) => {
    const previousTaps = startTap === -1 || endTap === -1 ? true : false
    if (startDate?.getTime() === (new Date(filters.since)).getTime() && endDate?.getTime() === (new Date(filters.until)).getTime()) {  // same date
      if (startTap === filters.starttap && endTap === filters.endtap) {// same tap
        //console.log('no update both')
      } else if ((filters.starttap === -1 || filters.endtap === -1) && previousTaps) { // one of the taps is still -1 and it was also before
        //console.log('no update same')
      } else if ((filters.starttap === -1 || filters.endtap === -1) && !previousTaps) { // one of the taps is still -1 and it was not before
        //console.log('update tap new empty')
        getReports(filters.since, filters.until, deviceFull?._id)
        setStartTap(filters.starttap)
        setEndTap(filters.endtap)
      } else if (filters.starttap !== -1 && filters.endtap !== -1) { //both taps are not -1
        //console.log('update tap new not empty')
        getReports(filters.since, filters.until, deviceFull?._id, filters.starttap, filters.endtap)
        setStartTap(filters.starttap)
        setEndTap(filters.endtap)
      }
    } else { // DIFFERENT DATES
      if (filters.starttap === -1 || filters.endtap === -1) {
        //console.log('update date+tap? empty')
        getReports(filters.since, filters.until, deviceFull?._id)
      } else {
        //console.log('update date+tap?')
        getReports(filters.since, filters.until, deviceFull?._id, filters.starttap, filters.endtap)
      }
      setStartDate(new Date(filters.since))
      setEndDate(new Date(filters.until))
      setStartTap(filters.starttap)
      setEndTap(filters.endtap)
    }

  }

  return (
    <div>
      <DynamicImpedanceSearch handleUpdate={updateList} maxTap={taps}></DynamicImpedanceSearch>
      <Grid container>
        <Grid key={`titledcard-1`} className={classes.fixedWidth} item md={isWidthDown('xs', (props as any).width) ? 12 : 1}>
          <SmallTable
            selected={id}
            list={table}
            id={["startTap", "endTap", "timestamp"]}
            label={['startTap', 'endTap', 'timestamp']}
            listButtons={table ? table.map(value => () => getGraph(value.graph_id)) : []} />
        </Grid>
        <Grid key={`titledcard-2`} className={classes.remainingWidth} item md={isWidthDown('xs', (props as any).width) ? 12 : 11}>
          <TitledCard fillDiv forceHeight={isWidthDown('sm', (props as any).width) ? 400 : 602} title={'dynamicImpedance'} >
            <DraggableGraph
              x={graph ? graph.time.map((value: string) => parseFloat(value)) : []}
              ys={!loading ? graph ? graph.wDynZ ? [graph.wDynZ.map(arr => arr.map((value: string) => parseFloat(value)))] : [[[]]] : [[[]]] : [[undefined!]]}
              titley={['impedanceUnits']}
              titlex={''}
              ysnames={[['Za', 'Zb', 'Zc']]}
              graphType={'linesgl'}
              colors={[['#e23434', '#2aa060', '#3473ba']]}
              notTimezone
            />
          </TitledCard>
        </Grid>
      </Grid >
    </div>
  );
})






