
import { createStyles, Fade, FormControl, Grid, IconButton, InputAdornment, Link, Tooltip, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ThemeExtended } from '../../components/global/Theme';
import UserMFA from '../../components/user/UserMFA';
import APIRequestContext from '../../contexts/api-context';
import { ToastContext } from "../../contexts/toast-context";
import { CurrentUser, Roles } from "../../interfaces/User";

const useStyles = makeStyles((theme: ThemeExtended) =>
    createStyles({
        loginSpectator: {
            '&:hover': {
                cursor: 'pointer',
            }
        },
        cardWidth: {
            width: "450px",
        },
        card: {
            '&::before': {
                // filter: "blur(20px)",
                background: `url("${theme.palette.images.greeting}") 0 / cover fixed`,
                content: "''",
                margin: "-35px",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                filter: "blur(7px)",
                zIndex: -1,
            },
            borderRadius: 8,
            overflow: "hidden",
            background: "#ffffffcf",
            alignSelf: "center",
            position: "relative",
            margin: "auto auto",
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        form: {
            margin: "10px",
            width: "calc(100% - 10px)"
        },
        mfaform: {
            margin: "auto",
            width: "80px"
        },
        button: {
            margin: "10px",
            // width: "calc(100% - 300px)",
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
        },
        buttonSubmit: {
            margin: "37px",
            width: "calc(100% - 100px)",
            alignSelf: "center",
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
        },
        buttonPressed: {
            backgroundColor: "#3761939e",
            "&:hover": {
                backgroundColor: "#376193c9 !important"
            }
        },
        board: {
            // backgroundImage: `url("${theme.palette.images.greeting}")`,
            // backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
            // backgroundSize: "cover",      
            zIndex: 1,
            background: `url("${theme.palette.images.greeting}") 0 / cover fixed`,
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-evenly",
        },
        logo: {
            width: "300px",
            // height: 69,
            paddingTop: "4vh",
            alignSelf: "center",
        },
        MainLogin: {
            textAlign: "center",
            fontSize: "36px",
            fontWeight: 300,
            color: "#3c4858"
        },
        TextFieldsLogin: {
            justifyContent: "center",
        },
        ForgotPassword: {
            fontSize: "11px",
            marginBottom: "45px"
        },
        BottomInfo: {
            top: "92%",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
        },
        MuiCardActionsRoot: {
            "& > div": {
                [theme.breakpoints.down('xs')]: {
                    width: "76%"
                },
            },
            justifyContent: "center",
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        CardContentRoot: {
            [theme.breakpoints.down('xs')]: {
                padding: '16px 0 16px 0',
            },
        }
    })
,);

export default function Login() {
    const classes = useStyles();
    const { apiRequest, login } = useContext(APIRequestContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logging, setLogging] = useState(false);
    const { displayToast } = useContext(ToastContext);
    const [showPassword, setShowPassword] = useState(false);
    const [totp, setTotp] = useState<string>("");
    const [mfa, setMfa] = useState(false);
    const [forceMFA, setForceMFA] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [spectating, setSpectating] = useState(false);
    const theme: ThemeExtended = useTheme();
    const history = useHistory();
    const intl = useIntl();

    const errorMessage = (rej: any) => {
        const text = rej && rej.substring && rej.indexOf("*") !== -1 ? rej.substring(rej.indexOf("*") + 1, rej.lastIndexOf("*")) : "";
        if (text.localeCompare("blocked") === 0) {
            displayToast('error', 'failedLoginBlocked', 'nothing');
        } else if (text.localeCompare("deactivated") === 0) {
            displayToast('error', 'failedLoginDeactivated', 'nothing');
        } else if (text.localeCompare("mfa") === 0) {
            if (!totp && mfa) displayToast('error', 'failed2FA', 'nothing');
            else setMfa(true);
        } else {
            displayToast('error', 'failedLogin', 'nothing');
        }
    }

    const handleSubmit = async (event: any) => {
        setSpectating(false);
        loginFn(false, event)
    }

    const handleSpectatorSubmit = async (event: any) => {
        setSpectating(true);
        loginFn(true, event)
    }

    const loginFn = async (spectator: boolean, event: any) => {
        setLogging(true);
        event.preventDefault()
        const result = await apiRequest<CurrentUser>("POST", "api/v1/auth/login", { jsonData: { email, password, totp: totp, spectator: spectator } })
            .catch((rej) => {
                errorMessage(rej);
            });
        if (result) {
            if (result.reset) {
                displayToast('warning', "resetPassword", "nothing", 120000, () => { history.push("/userDetails") });
            }
            if (result.role === Roles.PENDING_MFA) {
                setForceMFA(true);
            }
            login(result);
        }
        else setLogging(false)
    }

    const afterSetMFA = () => {
        setForceMFA(false);
        setMfa(true);
        setLogging(false)
    }

    return (
        <div className={classes.board}>
            <img className={classes.logo} src={theme.palette.images.greetingLogo} alt="enging-logo"></img>
            <Card className={clsx(classes.card, !forceMFA && classes.cardWidth)}>
                <form>
                    {mfa ?
                        <Fade in={mfa}>
                            <>
                                <h3 className={classes.MainLogin}><FormattedMessage id="code" /></h3>
                                <CardContent className={classes.CardContentRoot}>
                                    <Grid container className={classes.TextFieldsLogin} spacing={1} alignItems="flex-end">
                                        <Grid xs={1} item>
                                            <VpnKeyIcon />
                                        </Grid>
                                        <Grid xs={3} item>
                                            <TextField
                                                className={classes.mfaform}
                                                id="2fa-form"
                                                label={intl.formatMessage({ id: "code" })}
                                                value={totp}
                                                onChange={evt => setTotp(evt.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className={classes.MuiCardActionsRoot} disableSpacing>
                                    <FormControl>
                                        <Button type='submit' variant="contained" className={clsx({ [classes.buttonSubmit]: true, [classes.buttonPressed]: logging })} color="primary" onClick={spectating ? handleSpectatorSubmit : handleSubmit}><FormattedMessage id="submit"></FormattedMessage></Button>
                                    </FormControl>
                                </CardActions>
                            </>
                        </Fade>
                        :
                        forceMFA ?
                            <Fade in={forceMFA}>
                                <UserMFA noButton endFn={afterSetMFA}></UserMFA>
                            </Fade> :
                            <Fade in={!mfa}>
                                <>
                                    <h3 className={classes.MainLogin}><FormattedMessage id='login' /></h3>
                                    <CardContent className={classes.CardContentRoot}>
                                        <Grid container className={classes.TextFieldsLogin} spacing={1} alignItems="flex-end">
                                            <Grid xs={1} item>
                                                <EmailIcon />
                                            </Grid>
                                            <Grid xs={8} item>
                                                <TextField
                                                    className={classes.form}
                                                    id="email-form"
                                                    label="Email"
                                                    value={email}
                                                    onChange={evt => setEmail(evt.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.TextFieldsLogin} spacing={1} alignItems="flex-end">
                                            <Grid xs={1} item>
                                                <LockIcon />
                                            </Grid>
                                            <Grid xs={8} item>
                                                <TextField
                                                    className={classes.form}
                                                    id="pass-form"
                                                    label="Password"
                                                    type={showPassword ? "type" : "password"}
                                                    value={password}
                                                    onChange={evt => setPassword(evt.target.value)} InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions className={classes.MuiCardActionsRoot} disableSpacing>
                                        <FormControl>
                                            <Button type='submit' variant="contained" className={clsx({ [classes.button]: true, [classes.buttonPressed]: logging })} color="primary" onClick={handleSubmit}><FormattedMessage id='login' /></Button>
                                        </FormControl>
                                    </CardActions>

                                    <Grid container className={classes.TextFieldsLogin} spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <Tooltip title="Contact Enging" aria-label="Contact Enging">
                                                <p className={classes.ForgotPassword}><FormattedMessage id='forgotPassword' /></p>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <p onClick={handleSpectatorSubmit} className={clsx(classes.loginSpectator, classes.ForgotPassword)}><FormattedMessage id='loginSpectator' /> </p>
                                        </Grid>
                                    </Grid>
                                </>
                            </Fade>}
                </form>
            </Card>
            <Grid container className={classes.BottomInfo} spacing={1} alignItems="flex-end">
                {/* <Grid item>
                    <Tooltip title="Contact Enging" aria-label="Contact Enging">
                        <p className={classes.ForgotPassword}>Contact Enging</p>
                    </Tooltip>
                </Grid> */}
                <Grid item>
                    <Tooltip title={theme.palette.images.website} aria-label="Enging">
                        <Link target='_blank' href={`https://www.${theme.palette.images.website}`}><p>{theme.palette.images.website}</p></Link>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}