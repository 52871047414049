
import { Grid, isWidthDown, withWidth } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PolarGraph from '../../../components/device/final/PolarGraph';
import SmallCornerBox from '../../../components/device/middle/SmallCornerBox';
import SubTitledCard from '../../../components/device/middle/SubTitledCard';
import TitledCard from '../../../components/device/middle/TitledCard';
import { ThemeExtended } from '../../../components/global/Theme';
import { EntitiesContext } from "../../../contexts/entities-context";
import { si_format } from '../../../utils/format-data';
import { forVectorGraphs } from '../../../utils/tcm-functions';

const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper,
      overflowY: 'auto'
    },
    SideContainer: {
      display: "flex",
      height: "100%"
    },
    gridtemplateVG: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateColumns: "1fr 0.25fr 0.25fr",
      maxHeight: "670px",
      height: theme.shape.graphBox.smallest.height,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('md')]: { //menos de 1280 
        gridTemplateAreas: `
          "gr1" 
          "gr2"
          "gr3"`,
        gridTemplateRows: "32.5% 33% 33%",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: 650,
        gridGap: "6px",
        padding: "10px"
      }
    },
  }),
);

export default withWidth()(function VectorGraph(props: React.PropsWithChildren<{
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
  hasV4?: boolean,
  hasV5?: boolean,
  hasV6?: boolean,
  sideNames: Array<string>//the names of the sides
}>) {
  const classes = useStyles();
  const { deviceFull, deviceReport } = useContext(EntitiesContext);
  const data = forVectorGraphs(deviceReport)
  // console.log(data)
  const intl = useIntl()
  const { hasV1, hasV2, hasV3, hasV4, hasV5, hasV6, sideNames } = props;
  const sides = [hasV1, hasV2, hasV3, hasV4 === true, hasV5 === true, hasV6 === true];
  return (
    <Grid container className={classes.SideContainer}>
      {data && data.map((value, side) => <Grid key={`titledcard-${side}`} item xs={isWidthDown('sm', (props as any).width) ? 12 : sideNames.length > 2 ? 4 : 6}>

        <TitledCard gridTemplate={classes.gridtemplateVG} title={sideNames[side]?.slice(0, 2)} number={sideNames[side]?.slice(2, 3)} measure={`${si_format(deviceFull!.nameplate.side[side].Un, 'V')}`}>

          <SubTitledCard gridArea='gr1'>
            <PolarGraph
              ys={sides[side] ?
                value.map(form => form.angulo) :
                value.map(form => form.angulo).filter((v, ind) => ind > 2)
              }
              ysnames={side === 0 ?
                sides[side] ? ['VA', 'VB', 'VC', 'IA', 'IB', 'IC'] : ['IA', 'IB', 'IC'] :
                sides[side] ? ['Va', 'Vb', 'Vc', 'Ia', 'Ib', 'Ic'] : ['Ia', 'Ib', 'Ic']
              }
              colors={['#e23434', '#2aa060', '#3473ba', '#f15d5d', '#51c787', '#6299d8']}
              xs={sides[side] ?
                value.map(form => form.amplitude) :
                value.map(form => form.amplitude).filter((v, ind) => ind > 2)
              }
            ></PolarGraph>
          </SubTitledCard>

          <SubTitledCard gridArea='gr2' title='voltage'>
            <SmallCornerBox
              small
              parts={sides[side] ? [
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: side === 0 ? 'VA' : 'Va', value: [si_format(value[0].amplitude[1], 'pu', 2, true), si_format(value[0].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'VB' : 'Vb', value: [si_format(value[1].amplitude[1], 'pu', 2, true), si_format(value[1].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'VC' : 'Vc', value: [si_format(value[2].amplitude[1], 'pu', 2, true), si_format(value[2].angulo[1], 'º', 1, true)] },
              ] : [
                  { title: 'empty', value: [intl.formatMessage({ id: 'na' }), '', ''] },
                ]}
            ></SmallCornerBox>
          </SubTitledCard>

          <SubTitledCard gridArea='gr3' title='current'>
            <SmallCornerBox
              small
              parts={[
                { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
                { title: side === 0 ? 'IA' : 'Ia', value: [si_format(value[3].amplitude[1], 'pu', 2, true), si_format(value[3].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'IB' : 'Ib', value: [si_format(value[4].amplitude[1], 'pu', 2, true), si_format(value[4].angulo[1], 'º', 1, true)] },
                { title: side === 0 ? 'IC' : 'Ic', value: [si_format(value[5].amplitude[1], 'pu', 2, true), si_format(value[5].angulo[1], 'º', 1, true)] },
              ]}
            ></SmallCornerBox>
          </SubTitledCard>

        </TitledCard>

      </Grid>)
      }
    </Grid >
  );
})






