import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableGraph from "../../../components/device/final/DraggableGraph";
import GaugeGraph from "../../../components/device/final/GaugeGraph";
import PolarGraph from "../../../components/device/final/PolarGraph";
import RoundGauge from "../../../components/device/final/RoundGauge";
import LongDataBox from "../../../components/device/middle/LongDataBox";
import SmallCornerBox from "../../../components/device/middle/SmallCornerBox";
import SubTitledCard from "../../../components/device/middle/SubTitledCard";
import TitledCard from "../../../components/device/middle/TitledCard";
import { ThemeExtended } from "../../../components/global/Theme";
import APIRequestContext from "../../../contexts/api-context";
import { EntitiesContext } from "../../../contexts/entities-context";
import { DeviceType } from "../../../interfaces/Device";
import { ReportList, ReportWaveforms } from "../../../interfaces/Report";
import { forLoadStatistics, makePermissionsObject, si_format } from "../../../utils/format-data";
import { GCMpermissions } from "../../../utils/gcm-functions";
import { forGauges, forGlobalStatus, forHarmonics, forMechanicalGauges, forPowerQualityGauges, forVectorGraphs, forWaveforms, MCMpermissions } from '../../../utils/mcm-functions';


const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga1" "ga2" "ga3" "ga4"`,
        gridTemplateRows: "repeat(4, 24.3%)",
        gridTemplateColumns: "1fr",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
      },
      [theme.breakpoints.down('xs')]: {
      }
    },


    gridtemplateEP2: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga1" "ga2" "ga3"`,
        gridTemplateRows: "1fr 1fr 1fr",
        gridTemplateColumns: "1fr",
        height: theme.shape.graphBox.normal.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa        
      },
      [theme.breakpoints.down('xs')]: {
      }
    },

    sfBox: {
      gridTemplateAreas: `"g0" "g1" "g2" "g3" "g4"`,
      height: theme.shape.graphBox.normal.height,
      gridGap: "6px",
      padding: "10px",
      gridTemplateRows: "repeat(5,19.24%)",
      gridTemplateColumns: "100%",
    },

    gridtemplateVG: {
      [theme.breakpoints.up('md')]: {
        gridTemplateAreas: `
          "gr1 gr2 gr3"`,
        gridTemplateColumns: "50% 24.69% 24.69%",
        maxHeight: "670px",
        height: theme.shape.graphBox.smallest.height,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.between('sm', 'md')]: { //entre 600 e 960   telemovel landscapa
        gridTemplateAreas: `
          "gr1 gr1" 
          "gr2 gr3"`,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "50% 50%",
        maxHeight: "unset",
        height: "500px",
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateAreas: `
        "gr1" 
        "gr2" 
        "gr3"`,
        gridTemplateRows: "repeat(3, 32.76%)",
        gridTemplateColumns: '100%',
        maxHeight: "unset",
        height: "630px",
        gridGap: "6px",
        padding: "10px"
      },
    },

    gridtemplateWV: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateColumns: "50% 24.69% 24.69%",
      gridTemplateRows: "100%",
      maxHeight: "749px",
      height: 450,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1 gr1" 
          "gr2 gr3"`,
        gridTemplateRows: "repeat(2,49.6%)",
        gridTemplateColumns: "repeat(2,49.6%)",
        maxHeight: theme.shape.graphBox.biggest.height,
        height: theme.shape.graphBox.biggest.height,
        gridGap: "6px",
        padding: "10px",
      },
      [theme.breakpoints.down('xs')]: { //menos de 600 telemovel portrati
        gridTemplateAreas: `
        "gr1" 
        "gr2"
        "gr3"`,
        gridTemplateColumns: "100%",
        gridTemplateRows: '1fr 0.4fr 0.4fr',
        maxHeight: "unset",
        height: 'unset',
        gridGap: "6px",
        padding: "10px",
      }
    },

    gridtemplateSA1: {
      [theme.breakpoints.up('sm')]: {
        gridTemplateAreas: `
      "gr1 gr2"`,
        gridTemplateColumns: "50% 49.7%",
        gridTemplateRows: "100%",
        maxHeight: "650px",
        height: 450,
        gridGap: "6px",
        padding: "10px"
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1"
          "gr2"`,
        gridGap: "6px",
        padding: "10px",
        height: 620,
        gridTemplateColumns: "100%",
        gridTemplateRows: "repeat(2,49.7%)",
      }
    },

    IA: {
      gridTemplateAreas: `
        "gr1"`,
      gridTemplateColumns: "100%",
      maxHeight: "1050px",
      gridGap: "6px",
      padding: "10px"
    },


    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    },


    gridtemplateH: {
      gridTemplateAreas: `
          "gr1 gr3"
          "gr2 gr4"`,
      gridTemplateRows: "16fr 1fr",
      gridTemplateColumns: "49.85% 49.85%",
      maxHeight: "799px",
      height: 380,
      gridGap: "6px",
      padding: "10px",
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: `
          "gr1"
          "gr2"
          "gr3" 
          "gr4"`,
        gridTemplateRows: "44.7% 4.4% 44.7% 4.4%",
        gridTemplateColumns: "100%",
        maxHeight: "unset",
        height: "900px",
        gridGap: "6px",
        padding: "10px",
      }
    },
    displayTitle: {
      marginTop: "-6px",
      color: theme.palette.primary.light,
      height: 33,
      fontSize: 20,
      fontStyle: "normal",
      textAlign: "left",
      fontWeight: 700,
      padding: "1px 2px",
      marginLeft: 10
    },
    displayTitleSelected: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
      marginTop: "-6px",
      color: "white",
      height: 33,
      fontSize: 20,
      fontStyle: "normal",
      textAlign: "left",
      fontWeight: 700,
      padding: "1px 2px",
      marginLeft: 10,
      backgroundColor: theme.palette.primary.light
    },
    mainDiv: {
      marginTop: 10,
      marginBottom: 10,
      '& > .MuiGrid-item': {
        padding: 5
      }
    },
    differentH1: {
      margin: 0,
      color: theme.palette.primary.main,
      borderTop: "solid"
    }
  }),
);


interface SingleReportProps {
  reportSelected: ReportList | undefined;
  waveformsSelected: ReportWaveforms | undefined;
  isGCM?: boolean;
  hasV?: boolean;
}

export default function SingleReport(props: SingleReportProps) {
  const { reportSelected, waveformsSelected, isGCM, hasV } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const { user } = useContext(APIRequestContext);
  const classes = useStyles();
  const intl = useIntl();
  const permissions = makePermissionsObject(user?.views, deviceFull?.type === DeviceType.Gi ? GCMpermissions : MCMpermissions, user);
  const gaugeData = forGauges(reportSelected);
  const gaugeData2 = forMechanicalGauges(reportSelected, deviceFull, true);
  const gauge = forLoadStatistics(deviceFull, reportSelected)[0];
  const gaugeData3 = forPowerQualityGauges(reportSelected, hasV === false, true).map(g => { g.array.map(ar => ar.name = intl.formatMessage({ id: ar.name })); return g });
  const vectorData = forVectorGraphs(reportSelected);
  const waveData = forWaveforms(waveformsSelected, deviceFull);
  const sfData = forGlobalStatus(reportSelected, deviceFull, isGCM);
  const harmonicsData = forHarmonics(reportSelected);

  const [parityPrimaryCurrent, setParityPrimaryCurrent] = useState<boolean>(false);
  const [parityPrimaryVoltage, setParityPrimaryVoltage] = useState<boolean>(false);
  const [paritySecondaryCurrent, setParitySecondaryCurrent] = useState<boolean>(false);
  const [paritySecondaryVoltage, setParitySecondaryVoltage] = useState<boolean>(false);
  const [graphToShow, setGraphToShow] = React.useState<string>('epva');
  const changeParity = (side: number, current: number) => {
    if (side === 0) {
      if (current === 0) {
        setParityPrimaryCurrent(!parityPrimaryCurrent)
      } else {
        setParityPrimaryVoltage(!parityPrimaryVoltage)
      }
    } else {
      if (current === 0) {
        setParitySecondaryCurrent(!paritySecondaryCurrent)
      } else {
        setParitySecondaryVoltage(!paritySecondaryVoltage)
      }
    }
  };
  const handleChange = (name: string) => {
    setGraphToShow(name)
    return;
  }

  return (<Grid className={classes.mainDiv} container>

    <Grid item xs={3}>
      <TitledCard title={"electric_param"} gridTemplate={classes.gridtemplateEP1}>
        {gaugeData && gaugeData.map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard-${type}`} title={gaugeData[type].title}>
          <GaugeGraph empty={!permissions["electric_param"] || ((type === 0 || type === 2) && hasV === false)} compact data={gaugeData[type].array}></GaugeGraph>
        </SubTitledCard>)}
        {<SubTitledCard flexCenter gridArea='ga4' title={'loadJust'}>
          <RoundGauge empty={!permissions["load_diagram"]} height={120} data={{ name: '', value: (gauge!.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[0, 95], [95, 110], [110, 125]], max: 125, unit: '%' }}></RoundGauge>
        </SubTitledCard>}
      </TitledCard>
    </Grid>

    <Grid item xs={3}>
      {<TitledCard title={"mechanical_param"} gridTemplate={classes.gridtemplateEP1}>
        {gaugeData2 && gaugeData2.map((value2: any, type: number) => <SubTitledCard flexCenter gridArea={`ga${type + 1}`} key={`subtitledcard2-${type}`} title={gaugeData2[type].title}>
          {gaugeData2[type].array && gaugeData2[type].array.map((d, index) => <RoundGauge empty={!permissions["mechanical_param"] || hasV === false} height={110} key={`gauge-${index}`} data={d}></RoundGauge>)}
        </SubTitledCard>)}
      </TitledCard>}
    </Grid>

    <Grid item xs={3}>
      {<TitledCard title={"power_quality"} gridTemplate={classes.gridtemplateEP2}>
        {gaugeData3 && gaugeData3.map((value2: any, type: number) => <SubTitledCard gridArea={`ga${type + 1}`} key={`subtitledcard3-${type}`} title={gaugeData3[type].title}>
          <GaugeGraph empty={!permissions["power_quality"] || (type === 1 && hasV === false)} smallNumber bigText data={gaugeData3[type].array}></GaugeGraph>
        </SubTitledCard>)}
      </TitledCard>}
    </Grid>

    <Grid item xs={3}>
      {<TitledCard gridTemplate={classes.sfBox} title={'severityFactors'}>
        {sfData && sfData.map((d, index) => <SubTitledCard flexCenter key={`g${index}`} gridArea={`g${index}`} title={d.name}>
          <RoundGauge empty={!permissions["global_status"]} height={80} data={d} />
        </SubTitledCard>)}
      </TitledCard>}
    </Grid>


    <Grid item xs={12}>
      {<TitledCard title={"vector_diagram"} gridTemplate={classes.gridtemplateVG}>
        <SubTitledCard gridArea='gr1'>
          <PolarGraph
            empty={!permissions["vector_diagram"]}
            ys={vectorData ? vectorData.map(form => form.angulo).filter((v, ind) => ind < 3 || hasV) : [[]]}
            ysnames={['Iu', 'Iv', 'Iw', 'Vu', 'Vv', 'Vw']}
            colors={['#f15d5d', '#51c787', '#6299d8', '#e23434', '#2aa060', '#3473ba']}
            xs={vectorData ? vectorData.map(form => form.amplitude).filter((v, ind) => ind < 3 || hasV) : []}
          ></PolarGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr2' title='voltage'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"] || hasV === false}
            wide
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: 'pnv-0-mcm', value: [si_format(vectorData ? vectorData[3].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[3].angulo[1] : 0, 'º', 1, true)] },
              { title: 'pnv-1-mcm', value: [si_format(vectorData ? vectorData[4].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[4].angulo[1] : 0, 'º', 1, true)] },
              { title: 'pnv-2-mcm', value: [si_format(vectorData ? vectorData[5].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[5].angulo[1] : 0, 'º', 1, true)] },
            ]}
          ></SmallCornerBox>
        </SubTitledCard>
        <SubTitledCard gridArea='gr3' title='current'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            wide
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: 'iv-0-mcm', value: [si_format(vectorData ? vectorData[0].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[0].angulo[1] : 0, 'º', 1, true)] },
              { title: 'iv-1-mcm', value: [si_format(vectorData ? vectorData[1].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[1].angulo[1] : 0, 'º', 1, true)] },
              { title: 'iv-2-mcm', value: [si_format(vectorData ? vectorData[2].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData ? vectorData[2].angulo[1] : 0, 'º', 1, true)] },
            ]}
          ></SmallCornerBox>
        </SubTitledCard>
      </TitledCard>}
    </Grid>


    <Grid item xs={12}>
      {<TitledCard title={"waveforms"} gridTemplate={classes.gridtemplateWV}>
        <SubTitledCard gridArea='gr1'>
          {waveData![0] && <DraggableGraph
            empty={!permissions["waveforms"]}
            x={waveData![0].x.map((v: any) => v.replace ? v.replace(",", ".") : v).filter((v, i) => i > 2 || hasV) as number[]}//quick comma fix
            ys={[waveData![0].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v)).filter((v, i) => i > 2 || hasV)] as number[][][]}//quick comma fix
            titlex={'msUnits'}
            titley={['amplitudeUnits']}
            ysnames={[hasV ? ['ppv-0-mcm', 'ppv-1-mcm', 'ppv-2-mcm', 'iv-0-mcm', 'iv-1-mcm', 'iv-2-mcm'] : ['iv-0-mcm', 'iv-1-mcm', 'iv-2-mcm']]}
            colors={[hasV ? ["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8'] : ['#f15d5d', '#51c787', '#6299d8']]}
            graphType={'linegl'}
            notTimezone
            removeLegendPrefix
          ></DraggableGraph>}
        </SubTitledCard>
        <SubTitledCard gridArea='gr2' title='voltagePark'>
          {waveData![0] && <DraggableGraph
            empty={!permissions["waveforms"] || hasV === false}
            x={waveData![1] ? waveData![1].x.map((v: any) => v.replace ? v.replace(",", ".") : v) as number[] : []}
            ys={waveData![1] ? [waveData![1].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v))] as number[][][] : [[[]]]}
            titlex={'amplitudeUnits'}
            titley={['amplitudeUnits']}
            ysnames={[['pnv-0']]}
            colors={[["#3473ba"]]}
            graphType={'linegl'}
            staticGraph
            notTimezone
            sameScaleXandY
          ></DraggableGraph>}
        </SubTitledCard>
        <SubTitledCard gridArea='gr3' title='currentPark'>
          {waveData![0] && <DraggableGraph
            empty={!permissions["waveforms"]}
            x={waveData![2] ? waveData![2].x.map((v: any) => v.replace ? v.replace(",", ".") : v) as number[] : []}
            ys={waveData![2] ? [waveData![2].ys.map((v2: any) => v2.map((v: any) => v.replace ? v.replace(",", ".") : v))] as number[][][] : [[[]]]}
            titlex={'amplitudeUnits'}
            titley={['amplitudeUnits']}
            ysnames={[['pnv-0']]}
            colors={[["#e23434"]]}
            graphType={'linegl'}
            staticGraph
            notTimezone
            sameScaleXandY
          ></DraggableGraph>}
        </SubTitledCard>
      </TitledCard>}
    </Grid>


    <Grid item xs={12}>
      {<TitledCard title={"spectral_analysis"} gridTemplate={classes.gridtemplateSA1}>
        <SubTitledCard gridArea='gr1' title={"spectrums"} buttons={<>
          <Button
            onClick={() => handleChange('epva')}
            className={graphToShow === 'epva' ? classes.displayTitleSelected : classes.displayTitle}
          >
            EPVA
        </Button>
          <Button
            onClick={() => handleChange('mcsa')}
            className={graphToShow === 'mcsa' ? classes.displayTitleSelected : classes.displayTitle}
          >
            MCSA
        </Button>
        </>
        }>
          <DraggableGraph
            empty={!permissions["spectral_analysis"]}
            ys={graphToShow === 'mcsa' ? [
              waveformsSelected && (waveformsSelected.wDynZ as any)[1] ? [(waveformsSelected.wDynZ as any)[1].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v))] : [[]],
            ] : [hasV ? [
              waveformsSelected && (waveformsSelected.wDynZ as any)[3] ? (waveformsSelected.wDynZ as any)[3].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : [],
              waveformsSelected && (waveformsSelected.wDynZ as any)[2] ? (waveformsSelected.wDynZ as any)[2].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : [],
            ] : [
                waveformsSelected && (waveformsSelected.wDynZ as any)[2] ? (waveformsSelected.wDynZ as any)[2].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : [],
              ]]}
            ysnames={graphToShow === 'mcsa' ? [[
              'MCSA',
            ]] : [hasV ? [
              'EPVAv',
              `EPVAi`,
            ] : [
                `EPVAi`,
              ]]
            }
            colors={graphToShow === 'mcsa' ? [[
              '#e23434',
            ]] : [hasV ? [
              '#3473ba',
              '#e23434',
            ] : [
                '#e23434',
              ]]}
            x={waveformsSelected ? (waveformsSelected.wDynZ as any)[0].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined}
            titlex={'freq-des-description'}
            titley={['amplitudeUnitsPercent']}
            graphType={'linegl'}
            emptyMessage={'nodata'}
            yrange={graphToShow === 'mcsa' ? [-100, 1.5] : [0, 5.1]}
            xrange={[0, 140]}
            notTimezone
          />
        </SubTitledCard>
        <SubTitledCard gridArea='gr2' title={"PQSpectrum"}>
          <DraggableGraph
            empty={!permissions["spectral_analysis"] || hasV === false}
            ys={[[
              waveformsSelected && (waveformsSelected.wDynZ as any) && (waveformsSelected.wDynZ as any)[5] ? (waveformsSelected.wDynZ as any)[5].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : [[]],
              waveformsSelected && (waveformsSelected.wDynZ as any) && (waveformsSelected.wDynZ as any)[4] ? (waveformsSelected.wDynZ as any)[4].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : [[]],
            ]]}
            ysnames={[[
              `reactpwr-0-mcm`,
              'P'
            ]]}
            colors={[[
              '#e23434',
              '#2aa060',
            ]]}
            x={waveformsSelected ? (waveformsSelected.wDynZ as any)[0].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined}
            titlex={'freq-des-description'}
            titley={['amplitudeUnitsPercent']}
            graphType={'linegl'}
            emptyMessage={'nodata'}
            yrange={[0, 5.1]}
            xrange={[0, 140]}
            notTimezone
          />
        </SubTitledCard>
      </TitledCard>}
    </Grid>

    <Grid item xs={12}>
      {<TitledCard title={"instant_analysis"} gridTemplate={classes.IA}>
        <SubTitledCard title={"voltAndCurr"} gridArea='gr1'>
          {!isGCM && <DraggableGraph
            empty={!permissions["instant_analysis"]}
            ys={waveformsSelected && (waveformsSelected.wPNv as any) ? hasV ? [[
              (waveformsSelected.wPNv as any)[4].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[5].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[6].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
            ], [
              (waveformsSelected.wPNv as any)[1].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[2].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[3].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
            ]
            ] : [[
              waveformsSelected ? (waveformsSelected.wPNv as any)[1].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined,
              waveformsSelected ? (waveformsSelected.wPNv as any)[2].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined,
              waveformsSelected ? (waveformsSelected.wPNv as any)[3].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined,
            ]
              ] : [[[]]]}
            ysnames={hasV ? [[
              'ppv-0-mcm',
              'ppv-1-mcm',
              'ppv-2-mcm',
            ], [
              'iv-0-mcm',
              'iv-1-mcm',
              'iv-2-mcm'
            ]
            ] : [[
              'iv-0-mcm',
              'iv-1-mcm',
              'iv-2-mcm'
            ]
              ]}
            colors={hasV ? [[
              '#c70000',
              '#1e7b49',
              '#224a77'
            ], [
              '#ec4747',
              '#2ebd70',
              '#4684c9'
            ]] : [[
              '#ec4747',
              '#2ebd70',
              '#4684c9'
            ]]}
            x={waveformsSelected ? (waveformsSelected.wPNv as any)[0].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : []}
            titlex={'secondsUnits'}
            titley={hasV ? ['voltageUnits', 'currentUnits'] : ["currentUnits"]}
            graphType={"linegl"}
            emptyMessage={'nodata'}
            twoAxisWidth={hasV}
            notTimezone
            removeLegendPrefix
          ></DraggableGraph>}
          {isGCM && <DraggableGraph
            empty={!permissions["instant_analysis"]}
            ys={waveformsSelected && (waveformsSelected.wPNv as any) ? [[
              (waveformsSelected.wPNv as any)[7]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[8]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[9]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
            ], [
              (waveformsSelected.wPNv as any)[1]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[2]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[3]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
            ], [
              (waveformsSelected.wPNv as any)[4]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[5]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
              (waveformsSelected.wPNv as any)[6]?.map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)),
            ]
            ] : [[[]]]}
            ysnames={[[
              'ppv-0-mcm',
              'ppv-1-mcm',
              'ppv-2-mcm',
            ], [
              'iv-0-mcm',
              'iv-1-mcm',
              'iv-2-mcm'
            ], [
              'Iru',
              'Irv',
              'Irw',
            ]
            ]}
            colors={[[
              '#c70000',
              '#1e7b49',
              '#224a77'
            ], [
              '#ec4747',
              '#2ebd70',
              '#4684c9'
            ], [
              '#ec4747',
              '#2ebd70',
              '#4684c9'
            ]]}
            x={waveformsSelected ? (waveformsSelected.wPNv as any)[0].map((v: string) => parseFloat(v.replace ? v.replace(",", ".") : v)) : undefined}
            titlex={'secondsUnits'}
            titley={['voltageStatorUnits', 'currentStatorUnits', 'currentRotorUnits']}
            graphType={"linegl"}
            emptyMessage={'nodata'}
            threeAxisWidth
            notTimezone
            removeLegendPrefix
          ></DraggableGraph>}
        </SubTitledCard>
      </TitledCard>}
    </Grid>



    <Grid item xs={12}>
      {<TitledCard title={"harmonics"} gridTemplate={classes.gridtemplateH} >
        <SubTitledCard gridArea='gr1' title='voltage' buttons={
          <Button className={classes.button} onClick={() => { changeParity(0, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
        }>
          <DraggableGraph
            empty={!permissions["harmonics"] || !hasV}
            ys={[harmonicsData![0][0][(parityPrimaryCurrent ? 'even' : 'odd')].y]}
            x={harmonicsData![0][0][(parityPrimaryCurrent ? 'even' : 'odd')].x}
            ysnames={[['ppv-0-icm', 'ppv-1-icm', 'ppv-2-icm']]}
            colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
            graphType="bar"
            titlex=""
            titley={["harmonicsUnits"]}
            paddBot={25}
            notTimezone
          ></DraggableGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr2'>
          <LongDataBox
            empty={!permissions["harmonics"] || !hasV}
            parts={[
              { title: 'Vuv1:', value: si_format(harmonicsData![0][0].one[0], 'V') },
              { title: 'Vvw1:', value: si_format(harmonicsData![0][0].one[1], 'V') },
              { title: 'Vwu1:', value: si_format(harmonicsData![0][0].one[2], 'V') },
            ]}
          ></LongDataBox>
        </SubTitledCard>
        <SubTitledCard gridArea='gr3' title='current' buttons={
          <Button className={classes.button} onClick={() => { changeParity(0, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
        }>
          <DraggableGraph
            empty={!permissions["harmonics"]}
            ys={[harmonicsData![0][1][(parityPrimaryVoltage ? 'even' : 'odd')].y]}
            x={harmonicsData![0][1][(parityPrimaryVoltage ? 'even' : 'odd')].x}
            ysnames={[['iv-0-icm', 'iv-1-icm', 'iv-2-icm']]}
            colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
            graphType="bar"
            titlex=""
            titley={["harmonicsUnits"]}
            paddBot={25}
            notTimezone
          ></DraggableGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr4'>
          <LongDataBox
            empty={!permissions["harmonics"]}
            parts={[
              { title: 'Iu1:', value: si_format(harmonicsData![0][1].one[0], 'A') },
              { title: 'Iv1:', value: si_format(harmonicsData![0][1].one[1], 'A') },
              { title: 'Iw1:', value: si_format(harmonicsData![0][1].one[2], 'A') },
            ]}
          ></LongDataBox>
        </SubTitledCard>
      </TitledCard>}

    </Grid>

  </Grid>)
}