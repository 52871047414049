import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableGraph from "../../../components/device/final/DraggableGraph";
import GaugeGraph from "../../../components/device/final/GaugeGraph";
import LongDataBox from "../../../components/device/middle/LongDataBox";
import PolarGraph from "../../../components/device/final/PolarGraph";
import RoundGauge from "../../../components/device/final/RoundGauge";
import SmallCornerBox from "../../../components/device/middle/SmallCornerBox";
import SubTitledCard from "../../../components/device/middle/SubTitledCard";
import TitledCard from "../../../components/device/middle/TitledCard";
import { ThemeExtended } from "../../../components/global/Theme";
import { EntitiesContext } from "../../../contexts/entities-context";
import { ReportList, ReportWaveforms } from "../../../interfaces/Report";
import { forLoadStatistics, makePermissionsObject, si_format } from "../../../utils/format-data";
import { forPowerQualityGauges } from "../../../utils/gcm-functions";
import { forGaugesICM, forGlobalStatus, forHarmonicsICM, forVectorGraphsICM, forWaveformsICM, ICMpermissions } from "../../../utils/icm-functions";
import APIRequestContext from "../../../contexts/api-context";



const useStyles = makeStyles((theme: ThemeExtended) =>
  createStyles({
    mainDiv: {
      marginTop: 10,
      marginBottom: 10,
      '& > .MuiGrid-item': {
        padding: 5
      }
    },

    gridtemplateEP1: {
      [theme.breakpoints.up('md')]: {  //MAIS DE 960
        gridTemplateAreas: `
          "ga1 ga2"`,
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 1fr",
        maxHeight: "670px",
        width: "100%",
        gridGap: "6px",
        padding: "10px",
        height: theme.shape.graphBox.FourGaugeHeight.height - 52,
      },
    },

    gridtemplatePQ: {
      display: "grid",
      gridTemplateAreas: `"gaq1 gaq2 gaq3"`,
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "6px",
      padding: "10px",
      height: theme.shape.graphBox.FourGaugeHeight.height - 52,
    },
    gridtemplateLF: {
      display: "grid",
      gridTemplateAreas: `"ga3"`,
      gridGap: "6px",
      padding: "10px",
      height: theme.shape.graphBox.FourGaugeHeight.height - 52,
    },
    gridtemplateWV: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateColumns: `3fr 1fr 1fr`,
      gridTemplateRows: "1fr",
      height: 400,
      maxHeight: "750px",
      gridGap: "6px",
      padding: "10px",
    },
    sfBox: {
      gridTemplateAreas: `
          "g0 g1"`,
      gridGap: "6px",
      padding: "10px",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr 1fr",
      height: theme.shape.graphBox.FourGaugeHeight.height - 52,
    },
    gridtemplateH: {
      gridTemplateAreas: `
          "gr1 gr3"
          "gr2 gr4"`,
      gridTemplateRows: "16fr 1fr",
      gridTemplateColumns: "49.85% 49.85%",
      maxHeight: "799px",
      height: 400,
      gridGap: "6px",
      padding: "10px",
    },
    button: {
      padding: "0 4px",
      marginLeft: "14px",
      fontSize: "12px"
    },
    gridtemplateVG: {
      gridTemplateAreas: `
          "gr1 gr2 gr3"`,
      gridTemplateRows: "1fr",
      gridTemplateColumns: "3fr 1fr 1fr",
      height: theme.shape.graphBox.smallest.height,
      gridGap: "6px",
      padding: "10px",
    },
  })
)

interface SingleReportProps {
  reportSelected: ReportList | undefined;
  waveformsSelected: ReportWaveforms | undefined;
  hasI?: boolean;
}

export default function SingleReport(props: SingleReportProps) {
  const { reportSelected, waveformsSelected, hasI } = props;
  const { deviceFull } = useContext(EntitiesContext);
  const { user } = useContext(APIRequestContext);
  const classes = useStyles();
  const intl = useIntl();
  const permissions = makePermissionsObject(user?.views, ICMpermissions, user);
  const elecData = forGaugesICM(reportSelected, !hasI, intl);
  const loadGauge = forLoadStatistics(deviceFull, reportSelected, hasI)[0];
  const qualityData = forPowerQualityGauges(reportSelected, !hasI, intl).map(g => { g.array.forEach(ar => ar.name = intl.formatMessage({ id: ar.name })); return g });
  const vectorData = forVectorGraphsICM(reportSelected, hasI ? true : false);
  const waveData = forWaveformsICM(waveformsSelected, deviceFull, hasI ? true : false);
  const glabalData = forGlobalStatus(reportSelected);
  const [parityPrimaryCurrent, setParityPrimaryCurrent] = useState<boolean>(false);
  const [parityPrimaryVoltage, setParityPrimaryVoltage] = useState<boolean>(false);
  const [paritySecondaryCurrent, setParitySecondaryCurrent] = useState<boolean>(false);
  const [paritySecondaryVoltage, setParitySecondaryVoltage] = useState<boolean>(false);
  const harmonicsData = forHarmonicsICM(reportSelected);
  const changeParity = (side: number, current: number) => {
    if (side === 0) {
      if (current === 0) {
        setParityPrimaryCurrent(!parityPrimaryCurrent)
      } else {
        setParityPrimaryVoltage(!parityPrimaryVoltage)
      }
    } else {
      if (current === 0) {
        setParitySecondaryCurrent(!paritySecondaryCurrent)
      } else {
        setParitySecondaryVoltage(!paritySecondaryVoltage)
      }
    }
  };

  return (<Grid className={classes.mainDiv} container>

    <Grid item xs={3}>
      <TitledCard title={"electric_param"} gridTemplate={classes.gridtemplateEP1} >
        {elecData!.map((g, index) => <SubTitledCard key={`elec${index}`} gridArea={`ga${index + 1}`} title={g.title}>
          <GaugeGraph empty={!permissions["electric_param"]} bigValue={!(g.array[0] && g.array[0].value === undefined)} data={g.array} variable={g.variable}>
          </GaugeGraph>
        </SubTitledCard>)}
      </TitledCard>
    </Grid>

    <Grid item xs={2}>
      <TitledCard title={"load_factor"} gridTemplate={classes.gridtemplateLF} >
        <SubTitledCard flexCenter gridArea='ga3' title={'loadJust'}>
          <RoundGauge empty={!permissions["load_diagram"]} height={120} data={{ name: '', value: (loadGauge!.value as number), colors: ["#2aa060", "#ffc000", "#c20000"], steps: [[0, 80], [80, 100], [100, 125]], max: 125, unit: '%' }}></RoundGauge>
        </SubTitledCard>
      </TitledCard>
    </Grid>

    <Grid item xs={4}>
      <TitledCard title={"power_quality"} gridTemplate={classes.gridtemplatePQ} >
        {qualityData && qualityData.map((value2: any, type: number) => <SubTitledCard removeFirstNWords={2} gridArea={`gaq${type + 1}`} key={`subtitledcard-${type}`} title={qualityData[type].title}>
          <GaugeGraph empty={!permissions["power_quality"]} smallNumber bigText={!(value2.array[0] && value2.array[0].value === undefined)} data={qualityData[type].array}></GaugeGraph>
        </SubTitledCard>)}
      </TitledCard>
    </Grid>

    <Grid item xs={3}>
      <TitledCard gridTemplate={classes.sfBox} title={'severityFactors'}>
        {glabalData && glabalData.map((d, index) => <SubTitledCard flexCenter key={`g${index}`} gridArea={`g${index}`} title={d.name}>
          <RoundGauge empty={!permissions["global_status"]} height={110} data={d} />
        </SubTitledCard>)}
      </TitledCard>
    </Grid>


    <Grid item xs={12}>
      <TitledCard title={"vector_diagram"} gridTemplate={classes.gridtemplateVG}>
        <SubTitledCard gridArea='gr1'>
          <PolarGraph
            empty={!permissions["vector_diagram"]}
            ys={vectorData ? vectorData.map(form => form.angulo) : [[]]}
            ysnames={['Vuv', 'Vvw', 'Vwu', 'Iu', 'Iv', 'Iw']}
            colors={['#e23434', '#2aa060', '#3473ba', '#f15d5d', '#51c787', '#6299d8']}
            xs={vectorData ? vectorData.map(form => form.amplitude) : []}
          ></PolarGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr2' title='voltage'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: 'ppv-0-icm', value: [si_format(vectorData && vectorData[0] ? vectorData[0].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[0] ? vectorData[0].angulo[1] : 0, 'º', 1, true)] },
              { title: 'ppv-1-icm', value: [si_format(vectorData && vectorData[1] ? vectorData[1].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[1] ? vectorData[1].angulo[1] : 0, 'º', 1, true)] },
              { title: 'ppv-2-icm', value: [si_format(vectorData && vectorData[2] ? vectorData[2].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[2] ? vectorData[2].angulo[1] : 0, 'º', 1, true)] },
            ]}
            height={""}
          ></SmallCornerBox>
        </SubTitledCard>
        <SubTitledCard gridArea='gr3' title='current'>
          <SmallCornerBox
            empty={!permissions["vector_diagram"]}
            parts={[
              { title: 'empty', value: [intl.formatMessage({ id: 'amplitude' }), intl.formatMessage({ id: 'phase' })] },
              { title: 'iv-0-icm', value: [si_format(vectorData && vectorData[3] ? vectorData[3].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[3] ? vectorData[3].angulo[1] : 0, 'º', 1, true)] },
              { title: 'iv-1-icm', value: [si_format(vectorData && vectorData[4] ? vectorData[4].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[4] ? vectorData[4].angulo[1] : 0, 'º', 1, true)] },
              { title: 'iv-2-icm', value: [si_format(vectorData && vectorData[5] ? vectorData[5].amplitude[1] : 0, 'pu', 2, true), si_format(vectorData && vectorData[5] ? vectorData[5].angulo[1] : 0, 'º', 1, true)] },
            ]}
            height={""}
          ></SmallCornerBox>
        </SubTitledCard>
      </TitledCard>
    </Grid>

    <Grid item xs={12}>
      <TitledCard title={"waveforms"} gridTemplate={classes.gridtemplateWV}>
        <SubTitledCard gridArea='gr1'>
          {waveData![0][0] && <DraggableGraph
            empty={!permissions["waveforms"]}
            x={(waveData![0][0].x as number[])}
            ys={[waveData![0][0].ys.slice(0, 6)] as number[][][]}
            titlex={'msUnits'}
            titley={['amplitudeUnits']}
            ysnames={[['pnv-0-icm', 'pnv-1-icm', 'pnv-2-icm', 'iv-0-icm', 'iv-1-icm', 'iv-2-icm']]}
            colors={[["#e23434", "#2aa060", "#3473ba", '#f15d5d', '#51c787', '#6299d8']]}
            graphType={'linegl'}
            notTimezone
          ></DraggableGraph>}
        </SubTitledCard>
        {waveData && <SubTitledCard gridArea='gr2' title='voltagePark'>
          {waveData![0][1] && <DraggableGraph
            empty={!permissions["waveforms"]}
            x={waveData![0][1].x as number[]}
            ys={[waveData![0][1].ys] as number[][][]}
            titlex={'amplitudeUnits'}
            titley={['amplitudeUnits']}
            ysnames={[['pnv-0']]}
            colors={[["#3473ba"]]}
            graphType={'linegl'}
            staticGraph
            sameScaleXandY
            onlyMarkers
            notTimezone
          ></DraggableGraph>}
        </SubTitledCard>}
        {waveData && <SubTitledCard gridArea='gr3' title='currentPark'>
          {waveData![0][2] && <DraggableGraph
            empty={!permissions["waveforms"]}
            x={waveData![0][2].x as number[]}
            ys={[waveData![0][2].ys] as number[][][]}
            titlex={'amplitudeUnits'}
            titley={['amplitudeUnits']}
            ysnames={[['iv-0']]}
            colors={[["#e23434"]]}
            graphType={'linegl'}
            staticGraph
            sameScaleXandY
            onlyMarkers
            notTimezone
          ></DraggableGraph>}
        </SubTitledCard>}
      </TitledCard>
    </Grid>


    <Grid item xs={12}>
      <TitledCard title={"harmonics"} gridTemplate={classes.gridtemplateH} >
        <SubTitledCard gridArea='gr1' title='current' buttons={
          <Button className={classes.button} onClick={() => { changeParity(0, 0) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
        }>
          <DraggableGraph
            empty={!permissions["harmonics"]}
            ys={[harmonicsData![0][0][(parityPrimaryCurrent ? 'even' : 'odd')].y]}
            x={harmonicsData![0][0][(parityPrimaryCurrent ? 'even' : 'odd')].x}
            ysnames={[['ppv-0-icm', 'ppv-1-icm', 'ppv-2-icm']]}
            colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
            graphType="bar"
            titlex=""
            titley={["harmonicsUnits"]}
            paddBot={25}
            notTimezone
          ></DraggableGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr2'>
          <LongDataBox
            empty={!permissions["harmonics"]}
            parts={[
              { title: 'Vuv1:', value: si_format(harmonicsData![0][0].one[0], 'A') },
              { title: 'Vvw1:', value: si_format(harmonicsData![0][0].one[1], 'A') },
              { title: 'Vwu1:', value: si_format(harmonicsData![0][0].one[2], 'A') },
            ]}
          ></LongDataBox>
        </SubTitledCard>
        <SubTitledCard gridArea='gr3' title='voltage' buttons={
          <Button className={classes.button} onClick={() => { changeParity(0, 1) }} variant="outlined"><FormattedMessage id="evenodd"></FormattedMessage></Button>
        }>
          <DraggableGraph
            empty={!permissions["harmonics"]}
            ys={[harmonicsData![0][1][(parityPrimaryVoltage ? 'even' : 'odd')].y]}
            x={harmonicsData![0][1][(parityPrimaryVoltage ? 'even' : 'odd')].x}
            ysnames={[['iv-0-icm', 'iv-1-icm', 'iv-2-icm']]}
            colors={[['#e23434b3', '#2aa060b3', '#3473bab3']]}
            graphType="bar"
            titlex=""
            titley={["harmonicsUnits"]}
            paddBot={25}
            notTimezone
          ></DraggableGraph>
        </SubTitledCard>
        <SubTitledCard gridArea='gr4'>
          <LongDataBox
            empty={!permissions["harmonics"]}
            parts={[
              { title: 'Iu1:', value: si_format(harmonicsData![0][1].one[0], 'V') },
              { title: 'Iv1:', value: si_format(harmonicsData![0][1].one[1], 'V') },
              { title: 'Iw1:', value: si_format(harmonicsData![0][1].one[2], 'V') },
            ]}
          ></LongDataBox>
        </SubTitledCard>
      </TitledCard>
    </Grid>

  </Grid >)
}