
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import JustGraph from '../../../components/device/middle/JustGraph';
import { EntitiesContext } from "../../../contexts/entities-context";


export default function TransformRatio(props: React.PropsWithChildren<{ swappedSides: boolean, sideNames: string[] }>) {
  const { deviceMainHistory, deviceFull } = useContext(EntitiesContext);
  const { swappedSides, sideNames } = props;
  const sideAddNumber = 4 * (sideNames.length > 2 ? sideNames.length > 3 ? sideNames.length > 4 ? sideNames.length > 5 ? 4 : 3 : 2 : 1 : 0);
  const intl = useIntl()

  const getClosest = (value: any, upOrDown: string, arrayToLook: Array<any>) => {
    var onepercent = arrayToLook[0] - arrayToLook[1];
    if (!onepercent || onepercent < 0) onepercent = 0.01
    else onepercent = onepercent / 8
    let ret = 0;
    if (arrayToLook.length === 0) {
      return 0
    }
    if (upOrDown === 'down') {
      let i = 0;
      while (arrayToLook[i] > value) {
        ret = arrayToLook[i]
        i++;
      }
      //console.log('ret', ret, '\nvalue', value, '\narray', arrayToLook, '\narray[i]', arrayToLook[i])
      if (ret === 0) {
        return value - onepercent;
      } else {
        if (arrayToLook[i]) {
          return arrayToLook[i] - onepercent;
        }
        if (ret < value) {
          return ret - onepercent;
        } else {
          return value - onepercent
        }
      }
    }
    if (upOrDown === 'up') {
      let i = 0;
      while (arrayToLook[i] > value) {
        ret = arrayToLook[i]
        i++;
      }
      if (ret === 0) {
        return value + onepercent;
      } else {
        return ret + onepercent;
      }
    }
  }
  const minArray = (arr: Array<number>) => {
    var f = arr ? arr.find(v => v !== null) : -1;
    f = f ? f : 0
    return arr ? arr.reduce((min, currentValue) => currentValue !== null ? min < currentValue ? min : currentValue : min, f) : -1;
  }
  const maxArray = (arr: Array<number>) => arr ? arr.reduce((max, currentValue) => Math.max(max, currentValue), arr[0]) : -1;

  const hasSteps = deviceFull ?
    deviceFull.nameplate.side ?
      deviceFull.nameplate.side[0].Tap[1] ?
        true :
        false :
      false :
    false

  const realUn = hasSteps ? deviceFull ? deviceFull.nameplate.side ? (swappedSides ? deviceFull.nameplate.side[0].Un : deviceFull.nameplate.side[1].Un) : 0 : 0 : 0;

  const d = hasSteps ? deviceFull ? deviceFull.nameplate.side ? ((deviceFull.nameplate.side[0].Tap[0][1] - deviceFull.nameplate.side[0].Tap[1][1]) /
    (2 * realUn)) : undefined : undefined : undefined;   // VER FORMULA ESTIMA 

  const lines = (deviceMainHistory && deviceFull) ? hasSteps ? deviceFull.nameplate.side ? (deviceFull.nameplate.side[0].Tap.map((tapPos) => {
    return {
      xref: 'x',
      yref: 'y',
      x0: deviceMainHistory.timestamp ? new Date(deviceMainHistory.timestamp[0]) : [],
      y0: tapPos[1] / realUn - d!,  // RESTO DA FORMUAL ESTIMA
      x1: deviceMainHistory.timestamp ? new Date(deviceMainHistory.timestamp[deviceMainHistory.timestamp.length - 1]) : [],
      y1: tapPos[1] / realUn - d!,  // RESTO DA FORMUAL ESTIMA
      line: {
        color: 'rgb(169, 169, 169)',
        width: 1,
        dash: 'dot'
      }
    }
  })) : undefined : undefined : undefined;

  const roughAverageDifference = // difference between lines
    hasSteps ? deviceFull ? deviceFull.nameplate.side ? (((deviceFull.nameplate.side[0].Tap[0][1] / realUn - d!) +
      (deviceFull.nameplate.side[0].Tap[1][1] / realUn - d!)) /
      2 - (deviceFull.nameplate.side[0].Tap[0][1] / realUn - d!) + 0.004) : undefined : undefined : undefined;

  const annot = hasSteps ? deviceFull ? deviceFull.nameplate.side ? (deviceFull.nameplate.side[0].Tap.map((tapPos) => {
    return {
      xref: 'paper',
      yref: 'y',
      x: 0,
      y: tapPos[1] / realUn - d! - roughAverageDifference!,
      text: '<b>' + intl.formatMessage({ id: 'tap', defaultMessage: '', description: '' }) + ' ' + tapPos[0] + '</b>',
      xanchor: 'left',
      yanchor: 'bottom',
      showarrow: false,
      opacity: 0.30,
      font: {
        size: 16,
        color: 'black'
      }
    }
  })) : undefined : undefined : undefined;
  // console.log(
  //   deviceMainHistory ? deviceMainHistory!['rt-7'] : [],
  //   maxArray(deviceMainHistory ? deviceMainHistory['rt-7'] : []),
  //   minArray(deviceMainHistory ? deviceMainHistory['rt-7'] : []),
  //   [
  //     getClosest(minArray(deviceMainHistory ? deviceMainHistory['rt-7'] : []), 'down', lines ? lines.map((value) => value.y0) : []),
  //     getClosest(maxArray(deviceMainHistory ? deviceMainHistory['rt-7'] : []), 'up', lines ? lines.map((value) => value.y0) : [])
  //   ])
  return (
    <JustGraph
      graphType={'linegl'}
      ys={[[
        deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : undefined,
      ]]}
      ysnames={[[(`rt-${7 + sideAddNumber}` as 'rt-7')]]}
      colors={[['red']]}
      titley={["transformRatio"]}
      titlex={''}
      title={'transformRatio'}
      x={deviceMainHistory ? deviceMainHistory['timestamp'] : undefined}
      yrange={hasSteps ?
        [
          getClosest(minArray(deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : []), 'down', lines ? lines.map((value) => value.y0) : []),
          getClosest(maxArray(deviceMainHistory ? deviceMainHistory[(`rt-${7 + sideAddNumber}` as 'rt-7')] : []), 'up', lines ? lines.map((value) => value.y0) : [])
        ] :
        undefined}
      shapes={lines}
      annotations={annot}
      emptyMessage={'no7days'}
      enableReportLinks
    ></JustGraph>
  );
}
