import { Button, InputBase, LinearProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/SearchRounded';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import APIRequestContext from '../../../contexts/api-context';
import { DeviceType } from '../../../interfaces/Device';
import { ListResponse } from '../../../interfaces/Responses';
import { translatedSideTextToFinalText } from '../../../utils/format-data';
import EntityDialog from '../../entities/EntityDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      margin: `${theme.spacing(1)}px`,
      width: `calc(100% - ${theme.spacing(2)}px)`
    },
    formControlSmall: {
      width: "100%"
    },
    formNoShrink: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    alignSelects: {
      marginLeft: 10,
      width: "calc(100% - 16px)"
    },
    alignConditions: {
      marginLeft: 10,
      width: "calc(100% - 16px)",
      marginTop: 10
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.dark,
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.contrastText, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: "100% !important"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100% !important',
    },
    listInput: {
      height: 400,
      overflowY: "auto"
    },
    checkbox: {
      color: theme.palette.primary.main + '!important'
    },
    finalBox: {
      paddingLeft: 23,
      paddingTop: 10,
      fontWeight: 700
    },
    progress: {
      marginTop: 25
    }
  }),
);

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  deviceID: string;
  deviceName: string;
  deviceType: DeviceType;
  variables: { value: string; label: string; index: number; }[];
  dateStart: string;
  dateEnd: string;
  sideLabels?: string[];
}

export default function DownloadCsvDialog(props: DialogProps) {
  const intl = useIntl();
  const { open, handleClose, deviceName, deviceID, deviceType, variables, dateStart, dateEnd, sideLabels } = props;
  const sideLabelsChanged = sideLabels?.map(s => s.localeCompare('1o') === 0 ? 'Prim' : s.localeCompare('2o') === 0 ? 'Sec' : s.localeCompare('2o1') === 0 ? 'Sec1' : s.localeCompare('2o2') === 0 ? 'Sec2' : s.localeCompare('3o') === 0 ? 'Ter' : 'Unset')
  const classes = useStyles();
  const { apiRequestSplit } = useContext(APIRequestContext);
  const [tempVars, setTempVars] = React.useState<{ value: string, label: string, index: number }[]>(variables);
  const [search, setSearch] = React.useState<string>('');
  const [checked, setChecked] = React.useState<number[]>([]);
  const [progress, setProgress] = React.useState<number>(0);
  const [showProgress, setShowProgress] = React.useState<boolean>(false);

  useEffect(() => {
    setTempVars(variables.filter(v => v.label.toLocaleLowerCase().includes(search)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])


  useEffect(() => {
    setSearch('');
    setChecked([]);
    setProgress(0);
    setShowProgress(false)
  }, [open])

  const makeCSVReverse = (arr: string, data: { [key: string]: Array<number | null | string> }) => {
    if (data.hasOwnProperty('timestamp')) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {  /// COLUNAS
          arr = arr + (key.toString() === 'timestamp' ?
            key.toString() :
            deviceType === DeviceType['I'] ?
              intl.formatMessage({ id: `${key}-icm` }) :
              deviceType === DeviceType['M'] ?
                intl.formatMessage({ id: `${key}-mcm` }) :
                deviceType === DeviceType['Gi'] ?
                  intl.formatMessage({ id: `${key}-gcm` }) :
                  sideLabels && sideLabels.length > 2 ?
                    translatedSideTextToFinalText(intl.formatMessage({ id: `${key}-triple` }), sideLabelsChanged) :
                    intl.formatMessage({ id: key })) + ',';
        }
      }
      arr = arr.slice(0, arr.length - 1)
      arr = arr + '\n';
      for (const index in data['timestamp']) {   ///  LINHAS
        for (const key in data) {
          if (data.hasOwnProperty(key)) {  /// COLUNAS
            arr = arr + data[key][index] + ',';
          }
        }
        arr = arr.slice(0, arr.length - 1)
        arr = arr + '\n'
      }
    }
    return arr
  }

  const handleSubmit = () => {
    if (checked.length > 0) {
      setShowProgress(true)
      setProgress(0)
      var vs = variables.filter((v, i) => checked.indexOf(v.index) !== -1).map(v => v.value);
      setProgress((prev: number) => prev + 5);
      const name = deviceName + '_' + new Date(dateStart).getDate() + '-' + (new Date(dateStart).getMonth() + 1) + '-' + new Date(dateStart).getFullYear() + '_' + new Date(dateEnd).getDate() + '-' + (new Date(dateEnd).getMonth() + 1) + '-' + new Date(dateEnd).getFullYear() + '.csv'
      vs.unshift('timestamp')
      apiRequestSplit<ListResponse<any>>("GET", "api/v1/report/", { query: { type: deviceType, device_id: deviceID, since: dateStart, until: dateEnd, variables: vs } })
        .then(result => {
          let arr = '';
          setProgress((prev: number) => prev + 85);
          arr = makeCSVReverse(arr, (result.values as any))
          const bytes = new TextEncoder().encode(arr);
          const blob = new Blob([bytes], {
            type: "text/csv;charset=utf-8"
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setProgress((prev: number) => prev + 10);
          setTimeout(() => setShowProgress(false), 1000)
          //saveAs(new Blob([arr], { type: 'text/csv' }), (filename + '.csv'));
        })
        .catch(error => {
          setProgress((prev: number) => prev + 95);
          setTimeout(() => setShowProgress(false), 1000)
        })
    }
  }

  const handleToggle = (value: number) => {
    //console.log(value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    setChecked(tempVars.map((v, i) => i))
  }

  const handleRemoveAll = () => {
    setChecked([])
  }

  return (
    <EntityDialog
      open={open}
      title={intl.formatMessage({ id: 'selectVariables' })}
      handleClose={() => handleClose()}
      handleSubmit={() => handleSubmit()}
      submitTextUntranslated={'download'}>
      <>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={search}
                onChange={event => { setSearch(event.target.value) }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>

          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              size="small"
              className={classes.formControlSmall}
              onClick={handleSelectAll}
            >
              <FormattedMessage id='selectAll' />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              size="small"
              className={classes.formControlSmall}
              onClick={handleRemoveAll}
            >
              <FormattedMessage id='unselectAll' />
            </Button>
          </Grid>

        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List dense className={classes.listInput}>
              {tempVars.map((value, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                return (
                  <ListItem onClick={() => handleToggle(value.index)} key={value.index} button>
                    <ListItemText id={labelId} primary={value.label} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        className={classes.checkbox}
                        edge="end"
                        onChange={() => handleToggle(value.index)}
                        checked={checked.indexOf(value.index) !== -1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid container spacing={2}>

            <Grid item xs={3}>
              <div className={classes.finalBox}>
                <FormattedMessage id={"total"} />: {checked.length}
              </div>
            </Grid>
          </Grid>

        </Grid>

      </>

      {showProgress && <LinearProgress className={classes.progress} variant="determinate" value={progress} />}
    </EntityDialog >
  );
}