import { withWidth } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import ReportViewer from '../../components/device/final/ReportViewer';
import ControlInfoBar from '../../components/device/middle/ControlInfoBar';
import HistoryPage from '../../components/device/middle/HistoryPage';
import MainDeviceDashboard from '../../components/device/middle/MainDeviceDashboard';
import { EntitiesContext } from '../../contexts/entities-context';
//import report1 from '../../assets/icons/report.webp';
//import report2 from '../../assets/icons/reportwhite.webp';
import { DeviceFull, DeviceList, DeviceNameplate, DeviceType } from '../../interfaces/Device';
import { ReportList, ReportWaveforms } from '../../interfaces/Report';
import { GCMpermissions } from '../../utils/gcm-functions';
import ElectricParam from '../mcm/tabs/ElectricParam';
import GlobalStatus from '../mcm/tabs/GlobalStatus';
import Harmonics from '../mcm/tabs/Harmonics';
import InstantAnalysis from '../mcm/tabs/InstantAnalysis';
import LoadDiagram from '../mcm/tabs/LoadDiagram';
import MechanicalParam from '../mcm/tabs/MechanicalParam';
import PowerQuality from '../mcm/tabs/PowerQuality';
import SingleReport from '../mcm/tabs/SingleReport';
import SpectralAnalysis from '../mcm/tabs/SpectralAnalysis';
import VectorGraph from '../mcm/tabs/VectorGraph';
import Waveforms from '../mcm/tabs/Waveforms';
import GcmInfoDialog from './dialogs/GcmInfoDialog';



interface GCMPageProps {
  device: { nameplate?: DeviceNameplate, list?: DeviceList },
  setDevice: any,
  location: any,
}


const variables = ["ldratio", "ppv-0", "ppv-1", "ppv-2", "iv-0", "iv-1", "iv-2", "unbi-0", "unbppv-0", "thdppv-0", "thdppv-1", "thdppv-2", "thdi-0", "thdi-1", "thdi-2", "iexc-0", "iexc-1", "iexc-2", "iexc-3", "cosphi-0", "actpwr-0",
  'sf-0', 'sf-1', 'sf-2', 'sf-3', 'sf-4'] as const //baixo diag, cima control
const variables2 = (variables as any)
variables2.unshift('timestamp')
type GUV = typeof variables[number];
type time = { 'timestamp': string[] }
export type GcmUsableVariables = time & {
  [K in GUV]: Array<number>;
}

export default withWidth()(function GCMPage(props: GCMPageProps) {
  const { location } = props
  const [hasV, setHasV] = useState<boolean>(true);
  ///TODO DELETE
  const { deviceFull } = useContext(EntitiesContext);
  ///TODO DELETE IF THIS EVER CHANGES

  const processingAffectingContexts = (result: DeviceFull): DeviceFull => {
    return result;
  }

  const processingGeneral = (result: DeviceFull): DeviceFull => {
    if (!result.config_device.analog_reading_labels.some(l => l.startsWith('V'))) setHasV(false);
    return result;
  }

  const type = useMemo(() => deviceFull ? deviceFull.type : DeviceType.Gi, [deviceFull])//just for this solution that uses the same name for everything but has different type

  return (
    <MainDeviceDashboard
      location={location}
      deviceType={type}
      processingAffectingContexts={processingAffectingContexts}
      processingGeneral={processingGeneral}
      DeviceInfoDialog={GcmInfoDialog}
      variables={variables2}
      controlInfoBar={{ element: <ControlInfoBar maxSFCount={5}></ControlInfoBar>, tabCode: "005" }}
      controlComponents={[
        {
          element: <ElectricParam hasV={hasV}></ElectricParam>,
          title: "electric_param"
        }, {
          element: <MechanicalParam></MechanicalParam>,
          title: "mechanical_param"
        }, {
          element: <LoadDiagram></LoadDiagram>,
          title: "load_diagram"
        }, {
          element: <PowerQuality hasV={hasV}></PowerQuality>,
          title: "power_quality"
        }, {
          element: <VectorGraph hasV={hasV}></VectorGraph>,
          title: "vector_diagram"
        }, {
          element: <Waveforms hasV={hasV}></Waveforms>,
          title: "waveforms"
        }
      ].map(v => ({ ...v, ...{ tabCode: GCMpermissions[(v.title as keyof typeof GCMpermissions)] } }))}
      diagnosticComponents={[
        {
          element: <GlobalStatus isGCM></GlobalStatus>,
          title: "global_status"
        }, {
          element: <SpectralAnalysis hasV={hasV}></SpectralAnalysis>,
          title: "spectral_analysis"
        }, {
          element: <InstantAnalysis hasV={hasV} isGCM></InstantAnalysis>,
          title: "instant_analysis"
        }, {
          element: <Harmonics hasV={hasV}></Harmonics>,
          title: "harmonics"
        }
      ].map(v => ({ ...v, ...{ tabCode: GCMpermissions[(v.title as keyof typeof GCMpermissions)] } }))}
      historyComponents={[
        {
          element: <HistoryPage type={type}></HistoryPage>,
          title: "trendAn",
        },
        {
          element: <ReportViewer>
            {(props: { reportSelected: ReportList | undefined, waveformsSelected: ReportWaveforms | undefined, isGCM?: boolean }) => <SingleReport hasV={hasV} isGCM={true} reportSelected={props.reportSelected} waveformsSelected={props.waveformsSelected} />}
          </ReportViewer>,
          title: "sampleAn",
        },
      ]}
    ></MainDeviceDashboard>);
})