export interface received {
  _id: string;
  users_id: Array<string>;
  device: {
    id: string;
    name: string;
  };
  organization_id: string;
  alarm: {
    name: string;
    logic: AlarmConditionLogic;
    conditions: Array<AlarmOccurrenceCondition>;
    message: string;
    color: string;
    samples_mean: number;
  };
  acknowledged_user?: {
    id: string;
    email: string;
  };
  created_at: Date;
  updated_at?: Date;
}

export interface receivedExtended extends received {
  alarm_name: string,
  alarm_message: string,
  acknowledged_email: string,
  device_name: string,
  device_type_name: string | undefined,
}

export interface config {
  _id: string;
  organization_id: string;
  users_id: Array<string>;
  devices: Array<{
    id: string;
    active: boolean;
  }>;
  owner_id: string;
  name: string;
  message: string;
  color: string;
  send_by_email: boolean;
  show_in_dashboard: boolean;
  samples_mean: number;
  logic: AlarmConditionLogic;
  conditions: Array<AlarmCondition>;
  created_at: string;
  updated_at?: string;
}

export interface configExtended extends config {
  operator: string,
  devicesNames: string[]
}

export interface AlarmOccurrenceCondition extends AlarmCondition {
  value: number;
}

export enum AlarmConditionLogic {
  AND,
  OR,
}

export interface AlarmCondition {
  threshold: number;
  operator: AlarmConditionOperator | string;
  field: string;
}

export enum AlarmConditionOperator {
  GREATER,
  LESSER,
  EQUAL,
}


export interface AlarmWebsocket {//'occurrence'  para mudar
  id: string,
  message: string,
  device: string,
  datetime: string
}

export interface CreateAlarm {
  organization_id: string;
  users_id: Array<string>;
  devices: Array<string>;
  name: string;
  message: string;
  color: string;
  send_by_email: boolean;
  show_in_dashboard: boolean;
  samples_mean: number;
  logic: string;
  conditions: Array<AlarmCondition>;
  id?: string;
}

export interface CreateAlarmForSubmission {
  organization_id: string;
  users_id: Array<string>;
  devices_id: Array<string>;
  name: string;
  message: string;
  color: string;
  send_by_email: boolean;
  show_in_dashboard: boolean;
  samples_mean: number;
  logic: number;
  conditions: Array<AlarmCondition>;
}