
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import BigGraphSmallData from '../../../components/device/middle/BigGraphSmallData';
import { EntitiesContext } from "../../../contexts/entities-context";
import { createMovingAverage, forSCStatistics } from '../../../utils/format-data';


export default function ShortCircuit(props: React.PropsWithChildren<{
  hasV1: boolean,//has voltages on main side
  hasV2: boolean,//has voltages on first secondary side
  hasV3: boolean,//has voltages on second secondary side
}>) {
  const { deviceFull, deviceReport, deviceMainHistory } = useContext(EntitiesContext);
  const { hasV1, hasV2 } = props;
  const show = hasV1 && hasV2
  const line1 = 3
  const line2 = -3
  const intl = useIntl();

  const toLocalTimezone = (ISOdate: string): string => {
    //console.log(ISOdate)
    const value = new Date(ISOdate);
    return `${value.getFullYear()}-${value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getDate() < 10 ? '0' + value.getDate() : value.getDate()}T${value.getHours() < 10 ? '0' + value.getHours() : value.getHours()}:${value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()}`
  }

  const lines = deviceFull && deviceMainHistory !== undefined ? [{
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line1,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line1,
    line: {
      color: '#f7dc99',
      width: 1,
      dash: 'dot'
    }
  }, {
    xref: 'x',
    yref: 'y',
    x0: toLocalTimezone(deviceMainHistory['timestamp'][0]),
    y0: line2,
    x1: toLocalTimezone(deviceMainHistory['timestamp'][deviceMainHistory['timestamp'].length - 1]),
    y1: line2,
    line: {
      color: '#f7dc99',
      width: 1,
      dash: 'dot'
    }
  }] : undefined;

  return (
    <BigGraphSmallData
      big={{
        graphType: 'linegl',
        ys: show ? [[
          deviceMainHistory ? deviceMainHistory['varlcc-3'] : undefined,
          deviceMainHistory ? createMovingAverage(deviceMainHistory['varlcc-3'], 5) : undefined,
        ]] : [[[]]],
        ysnames: [["varlcc-3", "average"]],
        colors: [['#b0b0b0', "#008cff"]],
        titley: ["deltaLccUnits"],
        yrange: [-4, 4],
        titlex: '',
        shapes: lines,
        x: deviceMainHistory ? deviceMainHistory['timestamp'] : undefined,
        emptyMessage: show ? 'no7days' : 'na',
        enableReportLinks: true
      }}
      title={'deltaLcc'}
      small={
        [forSCStatistics(deviceFull, deviceReport, !show, intl.formatMessage({ id: 'na' }))]
      }
      autoHeight></BigGraphSmallData>
  );
}






