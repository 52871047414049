import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import locale_en from "../assets/locales/en.json";
import locale_es from "../assets/locales/es.json";
import locale_pt from "../assets/locales/pt.json";
import LayoutComponent from "../components/global/Layout";
import PrivateRoute from "../components/global/PrivateRoute";
import APIRequestContext from "../contexts/api-context";
import TranslationContext from "../contexts/translation-context";
import { Roles } from "../interfaces/User";
import AlertPage from "../pages/alert/AlertPage";
import LoginPage from "../pages/auth/Login";
import DashboardPage from "../pages/dashboard/DashboardPage";
import UserDetailsPage from "../pages/details/UserDetailsPage";
import GCMPage from "../pages/gcm/GCMPage";
import ICMPage from "../pages/icm/ICMPage";
import ContactsPage from "../pages/info/ContactsPage";
import HelpPage from "../pages/info/HelpPage";
import LPTCMPage from "../pages/lptcm/LPTCMPage";
import LPTCMCPage from "../pages/lptcmc/LPTCMCPage";
import LPTCMDPage from "../pages/lptcmd/LPTCMDPage";
import LPTCMQPage from "../pages/lptcmq/LPTCMQPage";
import LPTCMTPage from "../pages/lptcmt/LPTCMTPage";
import MCMPage from "../pages/mcm/MCMPage";
import TCMPage from "../pages/tcm/TCMPage";
import TCMDPage from "../pages/tcmd/TCMDPage";




interface dataKeys {
  [key: string]: any;
}

interface RoutesProps {
  data: dataKeys
}

const data: dataKeys = {
  'es': locale_es,
  'pt': locale_pt,
  'en': locale_en
};
export default function RoutesComponent(props: RoutesProps) {
  const { user } = useContext(APIRequestContext);
  const { language, setBothLanguageVars } = useContext(TranslationContext);

  return (<>{user && user.role !== Roles.PENDING_MFA ?
    <LayoutComponent setLanguage={setBothLanguageVars} language={language} languages={Object.keys(data)}>
      <Switch>
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/dashboard" Component={DashboardPage} />

        <PrivateRoute roles={[Roles.ADMIN]} exact path="/tcm/org/:id" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/control" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/control/:tab" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/diagnostic" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/diagnostic/:tab" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/history" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/history/:tab" Component={TCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcm/:id/history/:tab/:id" Component={TCMPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/control" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/control/:tab" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/diagnostic" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/diagnostic/:tab" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/history" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/history/:tab" Component={TCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/tcmd/:id/history/:tab/:id" Component={TCMDPage} />

        <PrivateRoute roles={[Roles.ADMIN]} exact path="/lptcm/org/:id" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/control" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/control/:tab" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/diagnostic" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/diagnostic/:tab" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/history" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/history/:tab" Component={LPTCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcm/:id/history/:tab/:id" Component={LPTCMPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/control" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/control/:tab" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/diagnostic" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/diagnostic/:tab" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/history" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/history/:tab" Component={LPTCMDPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmd/:id/history/:tab/:id" Component={LPTCMDPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/control" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/control/:tab" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/diagnostic" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/diagnostic/:tab" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/history" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/history/:tab" Component={LPTCMTPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmt/:id/history/:tab/:id" Component={LPTCMTPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/control" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/control/:tab" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/diagnostic" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/diagnostic/:tab" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/history" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/history/:tab" Component={LPTCMQPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmq/:id/history/:tab/:id" Component={LPTCMQPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/control" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/control/:tab" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/diagnostic" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/diagnostic/:tab" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/history" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/history/:tab" Component={LPTCMCPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/lptcmc/:id/history/:tab/:id" Component={LPTCMCPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/control" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/control/:tab" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/diagnostic" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/diagnostic/:tab" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/history" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/history/:tab" Component={ICMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/icm/:id/history/:tab/:id" Component={ICMPage} />

        <PrivateRoute roles={[Roles.ADMIN]} exact path="/mcm/org/:id" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/control" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/control/:tab" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/diagnostic" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/diagnostic/:tab" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/history" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/history/:tab" Component={MCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/mcm/:id/history/:tab/:id" Component={MCMPage} />

        <PrivateRoute roles={[Roles.ADMIN]} exact path="/gcm/org/:id" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/control" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/control/:tab" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/diagnostic" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/diagnostic/:tab" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/history" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/history/:tab" Component={GCMPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/gcm/:id/history/:tab/:id" Component={GCMPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/substation/:id" Component={DashboardPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/pvm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN]} exact path="/pvm/org/:id" Component={DashboardPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/wtm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN]} exact path="/wtm/org/:id" Component={DashboardPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/bcm" Component={DashboardPage} />
        <PrivateRoute roles={[Roles.ADMIN]} exact path="/bcm/org/:id" Component={DashboardPage} />

        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER]} exact path="/alerts" Component={AlertPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER]} exact path="/alerts/received" Component={AlertPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER]} exact path="/alerts/config" Component={AlertPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/help" Component={HelpPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/help/tcm" Component={HelpPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/help/mcm" Component={HelpPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/help/lptcm" Component={HelpPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/help/icm" Component={HelpPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER, Roles.SPECTATOR]} exact path="/contacts" Component={ContactsPage} />
        <PrivateRoute roles={[Roles.ADMIN, Roles.ORGADMIN, Roles.USER]} exact path="/userDetails" Component={UserDetailsPage} />
      </Switch>
    </LayoutComponent>
    : <LoginPage />}
  </>);
}