import { createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { PropsWithChildren, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlarmCondition, AlarmConditionOperator } from '../../interfaces/Alerts';
import { DeviceType } from '../../interfaces/Device';
import { VariablesGroupType, VariablesType } from './CreateAlarmDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsMultiple: {
      width: 40,
      color: 'black',
      '&.Mui-selected': {
        '&:hover': {
          backgroundColor: '#4d74a4',
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    }
  }),
);

interface conditionsProps {
  val: AlarmCondition[],
  onClick: any,
  variables: VariablesGroupType,
  type?: DeviceType,
}

export default function ConditionsBit(props: PropsWithChildren<conditionsProps>) {
  const { val, onClick, variables, type } = props
  const intl = useIntl();
  const classes = useStyles();
  const help = type ? variables[type] : { normal: [] };
  const [variablesState, setVariablesState] = React.useState<VariablesType>(help ? help : { normal: [] })

  useEffect(() => {
    const helpVar = type ? variables[type] : { normal: [] };
    setVariablesState(helpVar ? helpVar : { normal: [] })
    //console.log('aaaa',type,helpVar)
  }, [type, variables])

  const handleChange = (i: number, value: any, key: string) => {
    var clone = val.slice();
    clone[i] = ({ ...clone[i], [key]: value });
    onClick(clone)
  }

  const onClickAdd = () => {
    onClick(val.concat({ threshold: 0, operator: AlarmConditionOperator['GREATER'].toString(), field: '' }))
  }

  const onClickRemove = (i: number) => {
    var clone = val.slice();
    clone.splice(i, 1)
    onClick(clone)
  }

  //useEffect(() => { console.log('useeffect',translationPostfix) }, [translationPostfix])

  return (
    <div style={{ width: "100%" }}>
      {val.map((cond, i) => {
        return <Grid key={'conditions-' + i} container spacing={2}>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="select-users"><FormattedMessage id={'variables'}></FormattedMessage></InputLabel>
              <Select
                autoWidth
                inputProps={{ name: 'variable', id: `select-variable-${i}` }}
                value={cond.field}
                onChange={evt => handleChange(i, (evt.target.value as string), "field")}
              >
                {variablesState.special && variablesState.special.map((x, i) =>
                  <MenuItem key={'select-variable-' + i} value={x} >{intl.formatMessage({
                    id: type === DeviceType.T || type === DeviceType.L ?
                      x.concat('-triple') :
                      x
                  }).replace("{#1#}", "Sec").replace("{#2#}", "Ter")}</MenuItem>
                )}
                {!variablesState.special && variablesState.normal.map((x, i) =>
                  <MenuItem key={'select-variable-' + i} value={x} >{intl.formatMessage({
                    id: type === DeviceType.I ?
                      x.concat('-icm') :
                      type === DeviceType.M ?
                        x.concat('-mcm') :
                        type === DeviceType.Gi ?
                          x.concat('-gcm') :
                          x
                  })}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ textAlign: "center" }} item xs={5}>
            <FormControl component="fieldset">
              <Typography style={{ alignSelf: "start" }}><FormattedMessage id={'operator'}></FormattedMessage></Typography>


              <ToggleButtonGroup
                value={cond.operator}
                exclusive
                onChange={(evt, val) => handleChange(i, val, "operator")}
                aria-label="text alignment"
              >
                <ToggleButton className={classes.buttonsMultiple} value={AlarmConditionOperator['LESSER']} aria-label="left aligned">
                  {'<'}
                </ToggleButton>
                <ToggleButton className={classes.buttonsMultiple} value={AlarmConditionOperator['EQUAL']} aria-label="centered">
                  {'='}
                </ToggleButton>
                <ToggleButton className={classes.buttonsMultiple} value={AlarmConditionOperator['GREATER']} aria-label="centered">
                  {'>'}
                </ToggleButton>
              </ToggleButtonGroup>

            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label={intl.formatMessage({ id: 'threshold' })}
              value={cond.threshold}
              fullWidth
              autoComplete='off'
              type='number'
              onChange={evt => handleChange(i, parseFloat(evt.target.value), "threshold")}
            />
          </Grid>

          <Grid item xs={1}>
            {val.length > 1 && <Tooltip title={intl.formatMessage({ id: "deleteCondition" })}>
              <IconButton onClick={() => onClickRemove(i)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
          </Grid>

        </Grid>
      })}
      <Tooltip title={intl.formatMessage({ id: "addCondition" })}>
        <IconButton onClick={onClickAdd} aria-label="delete">
          <AddIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
/// POR     <Tooltip title="Delete">
//  <IconButton aria-label="delete">
//  <DeleteIcon />
//</IconButton>
//</Tooltip>