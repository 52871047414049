import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Alarm1 } from '../../assets/navigationIcons/alert1optimized.svg';
import { ReactComponent as Alarm2 } from '../../assets/navigationIcons/alert2optimized.svg';
import ScrollableContent from '../../components/global/ScrollableContent';
import SubNavBar from '../../components/global/SubNavBar';
import AlertConfig from './AlertConfig';
import AlertReceived from './AlertReceived';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxImageWhite: {
      display: "block",
      width: "30px",
      marginLeft: "10px",
      overflow: "visible",
      "& path": {
        fill: theme.palette.grey[50]
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginLeft: 0
      }
    },
    boxImage: {
      display: "block",
      width: "30px",
      marginLeft: "10px",
      overflow: "visible",
      "& path": {
        fill: theme.palette.primary.main
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginLeft: 0
      }
    },
  }
  )
)

interface AlertPageProps {
  history: any,
  location: any,
  match: any,
  staticContext: any
}// I dont think it need any of this

const routesAlarms: ({ [route: string]: number }) = {
  'received': 0,
  "config": 1
}

export default function AlertPage(props: AlertPageProps) {
  const { location } = props
  const [active, setActive] = useState<number>(0);
  const classes = useStyles();

  const buttons = [
    {
      name: "received",
      link: "/alerts/received",
      icon: <Alarm1 className={classes.boxImage} />,
      altIcon: <Alarm1 className={classes.boxImageWhite} />,
    },
    {
      name: "config",
      link: "/alerts/config",
      icon: <Alarm2 className={classes.boxImage} />,
      altIcon: <Alarm2 className={classes.boxImageWhite} />,
    }
  ]

  useEffect(() => {
    setActive(location.pathname.split('/')[2] ? routesAlarms[location.pathname.split('/')[2]] : 0);
  }, [location]);

  return (<div style={{ transform: "translate(0px, -16px)", height: "100%", width: "100%" }}>

    <SubNavBar buttons={buttons} active={active} setActive={setActive}></SubNavBar>
    <ScrollableContent>
      {active === 0 && <AlertReceived></AlertReceived>}
      {active === 1 && <AlertConfig></AlertConfig>}
    </ScrollableContent>
  </div>
  );
}