import { IconButton } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

export interface CustomNavLinkProps {
    text: string,
    icon?: JSX.Element,
    iconSelected?: JSX.Element,
    link: string,
    color?: string,
    mini?: boolean,
    handleClick?: any,
    open?: boolean,
    hasSub?: boolean,
    onMouseEnter?: any,
    onMouseLeave?: any,
}

const useStyles = makeStyles((theme) => ({
    navLink: {
        width: "100%",
        padding: "8px 16px 8px 8px",
        height: '65px'
    },
    miniNavLink: {
        height: '40px',
        textAlign: 'end'
    },
    navLinkSelected: {
        backgroundColor: theme.palette.primary.light + " !important",
        color: theme.palette.primary.contrastText
    },
    navLinkIcon: {
        color: theme.palette.primary.contrastText
    },
    navLinkText: {
        paddingLeft: 9,
        color: theme.palette.primary.contrastText,
        whiteSpace: 'break-spaces'
    }
}));

function CustomNavLink(props: CustomNavLinkProps & RouteComponentProps & PropsWithChildren<any>) {
    const classes = useStyles();
    const { forwardRef, icon, text, link, color, mini, handleClick, open, hasSub, iconSelected, children, onMouseEnter, onMouseLeave } = props;

    const CustomLink = React.useMemo(
        () => React.forwardRef<HTMLAnchorElement | null>((linkProps, ref) => (<Link ref={ref} to={link} {...linkProps} />)),
        [link],
    );

    const selected = props.location?.pathname === props.link;

    return (
        <li ref={forwardRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ListItem
                disableGutters
                selected={selected}
                className={clsx(classes.navLink, selected && classes.navLinkSelected, mini && classes.miniNavLink)}
                button
                component={CustomLink}
            >
                {icon && <ListItemIcon className={classes.navLinkIcon}>{(selected && iconSelected) ? iconSelected : icon}</ListItemIcon>}
                <ListItemText style={color ? selected ? { color: 'white' } : { color: color } : {}} className={classes.navLinkText}>{!mini && <FormattedMessage id={text}></FormattedMessage>}{mini && text}</ListItemText>
                {hasSub && open && <IconButton onClick={(e) => { handleClick() }} aria-label="delete" size="small">
                    <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>}
                {hasSub && !open && <IconButton onClick={handleClick} aria-label="delete" size="small">
                    <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>}
            </ListItem>
            {children}
        </li>
    );
}
const withRouterAndRef = (Wrapped: (props: CustomNavLinkProps & RouteComponentProps & PropsWithChildren<any>) => JSX.Element): React.ForwardRefExoticComponent<PropsWithChildren<any> & React.RefAttributes<any>> => {
    const WithRouter = withRouter(Wrapped);
    const WithRouterAndRef = React.forwardRef<CustomNavLinkProps & RouteComponentProps & PropsWithChildren<any>>((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ))
    const name = Wrapped.name;
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
}
const routedCustomNavLink = withRouterAndRef(CustomNavLink);
export default routedCustomNavLink;